import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../BaseUrl";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { convertDate, convertTime } from "../../../webutils";
const CompanyLogs = () => {
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [loginData, setLoginData] = useState([]);
  const companyId = sessionStorage?.getItem("c_id");
  const activityLog = (page) => {
    axios
      .get(`${baseUrl}super-admin/fetch-log-histories/${companyId}/?page=${page}`, config)
      .then((respo) => {
        setLoginData(respo?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    activityLog(1);
  }, []);
  return (
    <div className="customer_proposal_page">
      <div className="leads-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Login Time</th>
              <th>Logout Time</th>
              <th>Login Hour</th>
              <th>Login Status</th>
              <th>Login Person</th>
            </tr>
          </thead>
          <tbody>
            {loginData?.data?.length > 0 ? (
              loginData?.data?.map((item, index) => {
                let newDate = new Date(item?.loggedIn);
                let logoutDate = new Date(item?.loggedOut);
                let dateString = convertDate(item?.loggedIn);
                let timeString = convertTime(item?.loggedIn)
                let logoutDateString = item?.loggedOut === null ? null : convertDate(item?.loggedOut);
                let logoutTimeString = item?.loggedOut === null ? null : convertTime(item?.loggedOut);
                let currentTime = new Date();
                let defaultDifference = currentTime - newDate;
                let loginHrs = (defaultDifference / (1000 * 60 * 60))?.toFixed(2);
                let isLoggedIn = false;
                let loginStaus
                // console.log(loginHrs, newDate, currentTime, "loginHrs")
                if (item?.loggedOut === null && +loginHrs < 24.00) {
                  isLoggedIn = true;
                  loginStaus = <span style={{ color: "green" }}>Logged In</span>
                } else if (item?.loggedOut !== null) {
                  if (currentTime < logoutDate) {
                    let timeDifference = currentTime - newDate;
                    loginHrs = (timeDifference / (1000 * 60 * 60))?.toFixed(2);
                    isLoggedIn = false;
                    loginStaus = <span style={{ color: "red" }}>Logged Out</span>
                  } else {
                    let timeDifference = logoutDate - newDate;
                    loginHrs = (timeDifference / (1000 * 60 * 60))?.toFixed(2);
                    isLoggedIn = false;
                    loginStaus = <span style={{ color: "red" }}>Logged Out</span>
                  }

                } else {
                  let secondDate = new Date(newDate);
                  secondDate.setTime(newDate.getTime() + (24 * 60 * 60 * 1000));
                  logoutDateString = convertDate(secondDate);
                  logoutTimeString = convertTime(secondDate);
                  loginHrs = 24.00
                  isLoggedIn = false;
                  loginStaus = <span style={{ color: "red" }}>Logged Out</span>
                }

                return (
                  <tr key={index}>
                    <td>{index + (loginData?.current_page * loginData?.page_size - (+loginData?.page_size - 1))}</td>
                    <td>
                      {dateString}, {timeString}
                    </td>
                    <td>
                      {logoutDateString}, {logoutTimeString}
                    </td>
                    <td>{loginHrs} Hour</td>
                    <td>
                      {loginStaus}
                    </td>
                    <td>{item?.admin_type}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img width={300} src="./images1/noLogin.png" />
                    <p
                      style={{
                        color: "#595959",
                        fontWeight: "600",
                      }}
                    >
                      No login activity seen till now!
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {loginData?.data?.length > 0 && (
          <div className="pegination">
            <div className="peginationinner">
              <MdKeyboardArrowLeft
                className="arrowPreNext"
                onClick={() => {
                  loginData?.previous_page != null &&
                    activityLog(loginData?.previous_page);
                }}
              />
              <span>
                {loginData?.current_page} out of{" "}
                {Math.ceil(loginData?.pages)}
              </span>
              <MdKeyboardArrowRight
                className="arrowPreNext"
                onClick={() => {
                  loginData?.next_page != null &&
                    activityLog(loginData?.next_page);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CompanyLogs;
