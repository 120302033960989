import React, { useState } from 'react';
import MuiButton from "@mui/material/Button";
import Button from '@mui/material/Button';

import zIndex from '@mui/material/styles/zIndex';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import InputField from '../../reusableComponent/InputField';
import TextArea from '../../reusableComponent/TextArea';

export function UpdateDialog({ open, handleClose, selectedModule, setSelectedModule, updatedContent, setUpdatedContent, handleUpdateSubmit}) {
  // console.log("updateddddd content.......",updatedContent);
  const [selectedCompany, setSelectedCompany] = useState("");

  const handleCompanySelect = (e) => {
    setSelectedCompany(e.target.value);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (

    <Dialog open={open} onClose={handleClose}
      PaperProps={{ style: { width: '60%', maxWidth: '500px' } }}
    >
      <DialogTitle>{"Update Template"}</DialogTitle>
      <DialogContent>
        {/* <div className="inner-form-title">
          <label>Select Customer Name</label>
          <select value={selectedCompany} onChange={handleCompanySelect}
          >
            <option value="" disabled>Select Customer Name</option>
            <option value="customer_name">customer_name</option>
          </select>
        </div> */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <FormControl variant="outlined" style={{ flex: 1, marginRight: '8px' }}>
            <InputLabel id="customer-select-label">Select Customer Name</InputLabel>
            <Select
              labelId="customer-select-label"
              id="customer-select"
              value={selectedCompany}
              onChange={handleCompanySelect}
              label="Select Customer Name"
            >
              <MenuItem value="" disabled>Select Customer Name</MenuItem>
              <MenuItem value="customer_name">customer_name</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ flex: 1 }}>
            <InputLabel id="module-select-label">Select Module</InputLabel>
            <Select
              labelId="module-select-label"
              id="module-select"
              value={selectedModule}
              onChange={(e) => setSelectedModule(e.target.value)}
              label="Select Module"
            >
              <MenuItem value="company_templates">Company</MenuItem>
              <MenuItem value="leads">Leads</MenuItem>
              <MenuItem value="leads2">Leads2</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="inner-form-title">
          <InputField
            inputLabel={"Template Heading"}
            type="text"
            value={updatedContent.heading}
            onChange={(e) => setUpdatedContent({ ...updatedContent, heading: e.target.value })}
            placeholder="Template Heading"
          />
        </div>
        <div className="inner-form-title">
          <TextArea
            fullWidth
            inputLabel={"Template Body"}
            value={updatedContent.body}
            onChange={(e) => setUpdatedContent({ ...updatedContent, body: e.target.value })}
            placeholder="Template Body"
            style={{ height: '200px', width: '100%' }}
          />
        </div>
        <div className="inner-form-title">
          <InputField
            inputLabel={"Template Footer"}
            type="text"
            value={updatedContent.footer}
            onChange={(e) => setUpdatedContent({ ...updatedContent, footer: e.target.value })}
            placeholder="Template Footer"
          />
        </div>
        {/* Buttons to insert customer name placeholder */}
        {selectedCompany && (
          <div className="insert-customer-name-buttons">
            <MuiButton
              onClick={() => {
                const customerName = "customer_name";
                if (customerName) {
                  const placeholder = `{{${customerName}}}`;
                  const templateHeading = updatedContent?.heading;

                  // Check if heading is empty or undefined
                  if (!templateHeading) {
                    setUpdatedContent({
                      ...updatedContent,
                      heading: placeholder, // Set only customer_name if heading is empty
                    });
                  } else {
                    setUpdatedContent({
                      ...updatedContent,
                      heading: `${templateHeading} ${placeholder}`, // Append if not empty
                    });
                  }
                }
              }}
            >
              Add to Heading
            </MuiButton>
            <MuiButton
              onClick={() => {
                const customerName = "customer_name";
                if (customerName) {
                  const placeholder = `{{${customerName}}}`;
                  const templateBody = updatedContent?.body;

                  // Check if body  is empty or undefined
                  if (!templateBody) {
                    setUpdatedContent({
                      ...updatedContent,
                      body: placeholder, // Set only customer_name if body is empty
                    });
                  } else {
                    setUpdatedContent({
                      ...updatedContent,
                      body: `${templateBody} ${placeholder}`, // Append if not empty
                    });
                  }
                }
              }}
            >
              Add to Body
            </MuiButton>
            <MuiButton
              onClick={() => {
                const customerName = "customer_name";
                if (customerName) {
                  const placeholder = `{{${customerName}}}`;
                  const templateFooter = updatedContent?.footer;

                  // Check if footer is empty or undefined
                  if (!templateFooter) {
                    setUpdatedContent({
                      ...updatedContent,
                      footer: placeholder, // Set only customer_name if footer is empty
                    });
                  } else {
                    setUpdatedContent({
                      ...updatedContent,
                      footer: `${templateFooter} ${placeholder}`, // Append if not empty
                    });
                  }
                }
              }}
            >
              Add to Footer
            </MuiButton>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={handleUpdateSubmit}>Update</MuiButton>
        <MuiButton onClick={handleClose}>Cancel</MuiButton>
      </DialogActions>
    </Dialog>
  );
}