import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import Button from "../../reusableComponent/Button";
import InputField from "../../reusableComponent/InputField";
import "../../Outlet/Customers/mystyle/style.css";
import axios from "axios";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useSelector } from "react-redux";
import { CgNotes } from "react-icons/cg";
import { FaTasks } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-hot-toast";
import { baseUrl } from "../../../BaseUrl";

const EmployeeNoteTask = ({ employeeAllData, noteType }) => {
    // console.log("emp of note and task",employeeAllData,noteType)
  const cid = sessionStorage.getItem("c_id");
  const [popupToggle, setPopUpToggle] = useState("");
  const handleChange = (e) => {
    setPopUpToggle("ongoing_popup");
  };
  const selectedLeadDelete = useSelector((store) => store?.main);
  const [allReminderData, setAllReminderData] = useState([]);
  const [activeNotes, setActiveNotes] = useState(false);
  const [noteTaskData, setNoteTaskData] = useState({
    noteDescription: null,
    taskDateTime: null,
  });
  const [templateText, setTemplateText] = useState(null);
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [searchElement, setSearchElement] = useState("");
  const getReminderAPI = `${baseUrl}leads/fetch-lead-superadmin/${noteType}/?leadId=${employeeAllData?._id}&search=${searchElement}` ;
  // console.log("api",getReminderAPI);
  const reminderFormHandler = (e) => {
    const { value, name } = e.target;
    setNoteTaskData({
      ...noteTaskData,
      [name]: value,
    });
  };
  const [reminderProfilePopup, setOpenReminderProfilePopup] = useState("");
  const [selectedReminderData, setSelectedReminderData] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState("task");
  const selectedCompanyData = selectedLeadDelete?.leadData;
  const [changeStatus, setChangeStatus] = useState("");
  const [taskDateErr, setTaskDateErr] = useState(false);
  const [isNotify, setIsNotify] = useState(false);
  const [popUpType, setPopUpType] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const editSelectedLeadd = useSelector((store) => store?.main?.leadData);

  
  const reminderData = () => {
    setAllReminderData([]);
    axios
      .get(`${getReminderAPI}`, config)
      .then((res) => {
        // console.log("reminder", res.data);
        setAllReminderData(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    // setPopUpToggle("");
  };
  useEffect(() => {
    reminderData();
  }, [noteType,searchElement]);
  // console.log(allReminderData);
  const openNotes = () => {
    setActiveNotes(true);
  };
  const closeNotes = () => {
    setActiveNotes(false);
    setNoteTaskData({
      noteDescription: "",
      taskDateTime: "",
    });
  };
  const handleReminderView = (data) => {
    // console.log("data in erm",data)
    setOpenReminderProfilePopup("ongoing_popup");
    setSelectedReminderData(data);

    // console.log(data, "data for ");
    if (data?.status === "Unread") {
      updateStatusNote(data?._id, "Pending");
    }
  };
  const handleToggleComponent = (activeClass) => {
    setSelectedComponent(activeClass);
  };
  const handlePopupClose = () => {
    setOpenReminderProfilePopup("");
    handleToggleComponent("task");
  };

  let taskDateFormate = new Date(
    selectedReminderData?.datetime
      ? selectedReminderData?.datetime
      : selectedReminderData?.createdAt
  );
  let taskDate = taskDateFormate?.toLocaleDateString();
  let taskTime = taskDateFormate?.toLocaleTimeString();

  const updateStatusNote = (id, changeStatus) => {
    const formDataForNote = {
      status: changeStatus,
    };
    // console.log("changeeeeeee",changeStatus);
    axios
      .put(`${baseUrl}leads/${id}/update/task-status`, formDataForNote, config)
      .then((respo) => {
        toast?.success(`${noteType} updated Successfully!`);
        reminderData();
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  // WhatsApp MessageSender

  const sendWhatsAppMessage = (template, leadtitem) => {
    const formData = {
      mobile: leadtitem.mobile_number,
      message: template,
    };
    axios
      .post(`${baseUrl}super_admin_lead/whatsapp-temp/`, formData, config)
      .then((respo) => {
        toast?.success(respo?.data?.message);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div>
      {/* Note & Task Popup Open here */}
      <div
        style={activeNotes ? { display: "block" } : { display: "none" }}
        className="notes-overlay"
      >
        <div className="notes-overlay-inner">
          <div
            style={{
              display: "block",
            }}
            className="notes-form-container"
          >
            <div className="title-note">
              <div style={{ color: "#1877F2" }}>
                Add {popUpType === "Reminder" ? "Reminder" : noteType}
              </div>
              <div onClick={closeNotes} className="close-notes">
                <CloseOutlinedIcon />
              </div>
            </div>
            {
              <div className="notes-form">
                <div className="note-input-box">
                  <label className="note-label">
                    {popUpType === "Reminder"
                      ? "Reminder Message"
                      : `Write Your ${noteType}`}
                  </label>
                  <textarea
                    style={{
                      maxWidth: "300px",
                    }}
                    className="note-inchar note-text"
                    name="noteDescription"
                    value={noteTaskData?.noteDescription}
                    onChange={(e) => reminderFormHandler(e)}
                  />
                </div>
                {noteType !== "Note" && (
                  <div className="note-input-box">
                    <label className="note-label">
                      Pick{popUpType === "Reminder" && " Reminder"} Date and
                      Time
                    </label>
                    <input
                      className="note-inchar"
                      type="datetime-local"
                      name="taskDateTime"
                      value={noteTaskData?.taskDateTime}
                      onChange={(e) => reminderFormHandler(e)}
                    />
                    {noteType === "Task" && (
                      <span
                        style={{
                          fontSize: "10px",
                          color: "red",
                          display: `${taskDateErr ? "block" : "none"}`,
                        }}
                      >
                        Please select Date & Time
                      </span>
                    )}
                  </div>
                )}
                {popUpType === null && noteType === "Task" && (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      fontSize: "14px",
                      color: "#595959",
                    }}
                  >
                    <input
                      id="notify"
                      name="notify"
                      type="checkbox"
                      onChange={(e) => {
                        setIsNotify(e?.target?.checked);
                        if (e?.target?.checked) {
                          let taskDate = new Date(noteTaskData?.taskDateTime);
                          let header = "Hi there!\n";
                          let footer = "\n\nDate & Time :";
                          let template = `${header}${
                            noteTaskData?.noteDescription
                          }${footer} ${taskDate?.toLocaleDateString()}, ${taskDate?.toLocaleTimeString()}`;
                          setTemplateText(template);
                        }
                      }}
                    />
                    <label htmlFor="notify">Do you want notify to lead?</label>
                  </div>
                )}
                {isNotify &&
                  noteTaskData?.noteDescription !== null &&
                  noteTaskData?.taskDateTime !== "" && (
                    <div>
                      <div
                        style={{
                          color: "#1877F2",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Preview{" "}
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          (This message will be send to lead)
                        </span>
                      </div>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          fontSize: "12px",
                          backgroundColor: "#FFFFFF",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                          borderRadius: "5px",
                          padding: "5px",
                          width: "80%",
                          marginTop: "5px",
                        }}
                      >
                        {templateText}
                      </div>
                    </div>
                  )}
                {/* <button
                  onClick={() => reminderSubmitter(templateText)}
                  className="note-save-btn"
                >
                  {popUpType === "Reminder"
                    ? "Set Reminder"
                    : `Save ${noteType}`}
                </button> */}
              </div>
            }
          </div>
        </div>
      </div>
      {/* Popup close here */}
      <div
        className={reminderProfilePopup + " " + "ongoing_popup_hide"}
        disabled
      >
        {/* popup code open */}
        <div className="popup-main">
          <div className="popup-card">
            <RxCross2 onClick={handlePopupClose} className="cross-icons" />
            <div className="link-wrapper">
              <Link
                className={selectedComponent === "task" ? "active-class" : null}
                onClick={() => handleToggleComponent("task")}
              >
                {noteType}
              </Link>
              <Link
                className={
                  selectedComponent === "company" ? "active-class" : null
                }
                onClick={() => handleToggleComponent("company")}
              >
                Lead Profilee
              </Link>
            </div>
            {selectedComponent === "task" && (
              <div className="task-details-main">
                <div className="flex-class">
                  <span>Date</span>
                  <span>:</span>
                  <span>{taskDate}</span>
                </div>
                <div className="flex-class">
                  <span>Time</span>
                  <span>:</span>
                  <span>{taskTime}</span>
                </div>
                <div className="flex-class">
                  <span>Description</span>
                  <span>:</span>
                  <span>{selectedReminderData?.description}</span>
                </div>
                <div className="flex-class">
                  <span>Status</span>
                  <span>:</span>
                  <div
                    style={{
                      display: "inline",
                    }}
                  >
                    <select
                      onChange={(e) => {
                        updateStatusNote(
                          selectedReminderData?._id,
                          e?.target?.value
                        );
                      }}
                      value={selectedReminderData?.status}
                      name="changeStatus"
                      style={{
                        border: "none",
                        outline: `1px solid ${
                          selectedReminderData?.status === "Pending"
                            ? "#ff8c00"
                            : "#008000"
                        }`,
                        borderRadius: "3px",
                        padding: "5px",
                        backgroundColor: `${
                          selectedReminderData?.status === "Pending"
                            ? "#FFA50030"
                            : "#00FF0030"
                        }`,
                        color: `${
                          selectedReminderData?.status === "Pending"
                            ? "#ff8c00"
                            : "#008000"
                        }`,
                      }}
                    >
                      {selectedReminderData?.status === "Pending" && (
                        <option value="Pending">Pending</option>
                      )}

                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            {selectedComponent === "company" && (
              <>
                <div className="task-details-main">
                  <div className="flex-class">
                    <span>Lead's Name</span>
                    <span>:</span>
                    <span>{editSelectedLeadd?.name}</span>
                  </div>
                  <div className="flex-class">
                    <span>Email</span>
                    <span>:</span>
                    <span>{editSelectedLeadd?.email}</span>
                  </div>
                  <div className="flex-class">
                    <span>Mobile No.</span>
                    <span>:</span>
                    <span>{editSelectedLeadd?.mobile}</span>
                  </div>
                  <div className="flex-class">
                    <span>Business Category</span>
                    <span>:</span>
                    <span>{editSelectedLeadd?.category}</span>
                  </div>
                  <div className="flex-class">
                    <span>City</span>
                    <span>:</span>
                    <span>{editSelectedLeadd?.city}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* popup code close */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Button
          className={"simpleBtn proposalBtn"}
          text={`Set Lead ${noteType}`}
          onClick={() => {
            setPopUpType(null);
            openNotes();
          }}
          startIcon={noteType === "Note" ? <CgNotes /> : <FaTasks />}
        /> */}
        <InputField
          placeholder="Search..."
          endIcon={<AiOutlineSearch />}
          className="searchInput"
          value={searchElement}
          onChange={(e) => setSearchElement(e?.target?.value)}

        />
      </div>
      <div className="leads-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th
                style={{
                  width: "300px",
                }}
              >
                {noteType}
              </th>
              {noteType === "Task" && <th>Task Time</th>}
              <th>Created At</th>
              <th>Status</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {allReminderData ? (
              allReminderData?.data?.map((reminderItem, index) => {
                let taskDate = new Date(reminderItem?.datetime);
                // console.log("reminder...........",allReminderData?.data.reverse());

                let convertedTaskDate = taskDate.toLocaleDateString();
                let convertedTaskTime = taskDate.toLocaleTimeString();
                let date = new Date(reminderItem?.createdAt);
                // console.log("dateee", date);
                let convertedDate = date.toLocaleDateString();
                let convertedTime = date.toLocaleTimeString();
                return (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{reminderItem?.description}</td>
                    {noteType === "Task" && (
                      <td>{`${convertedTaskDate}, ${convertedTaskTime}`}</td>
                    )}
                    <td>{`${convertedDate}, ${convertedTime}`}</td>
                    <td
                      style={{
                        color: `${
                          reminderItem?.status === "Pending"
                            ? "#ff8c00"
                            : reminderItem?.status === "Completed"
                            ? "#008000"
                            : "#ff8c00"
                        }`,
                      }}
                    >
                      {reminderItem?.status}
                    </td>
                   
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={7}
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    color: "#595959",
                  }}
                >
                  No {noteType} Added Yet!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeNoteTask;
