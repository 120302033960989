import React, { useEffect, useState } from "react";
import "./LeadProfile.css";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Draggable from "react-draggable";
import { RxDragHandleDots1 } from "react-icons/rx";
import {convertDate} from "../../webutils";

const CustomerDetails = ({ editSelectedLead }) => {
  let date = new Date(editSelectedLead?.createdAt);

  let convertedDate = convertDate(date);
  let dealDateTime = new Date(editSelectedLead?.dealClosingDate);
  let dealDate = convertDate(dealDateTime);
  let dealTime = dealDateTime.toLocaleTimeString();
  let expectedDateTime = new Date(editSelectedLead?.expected_dealClosingDate);
  let expectedDate = convertDate(expectedDateTime);
  let expectedTime = expectedDateTime.toLocaleTimeString();
  const cid = sessionStorage.getItem("c_id");
  const [noteList, setNoteList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [noteData, setNoteData] = useState({
    note: "",
  });
  const [errorVal, setErrorVal] = useState("none");
  const [wait, setWait] = useState("Save");
  const [completedTask, setCompletedTask] = useState([]);
  const [completedNote, setCompletedNote] = useState([]);
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const getNoteAPI = `${baseUrl}super_admin_lead/leadid_and_notetype/?leadID=${cid ? cid : editSelectedLead?.id
    }&note_type=Note`;
  const getTaskAPI = `${baseUrl}super_admin_lead/leadid_and_notetype/?leadID=${cid ? cid : editSelectedLead?.id
    }&note_type=Task`;

  const noteFormHandler = (e) => {
    const { name, value } = e.target;
    setNoteData({ ...noteData, [name]: value });
    // Code here .....
  };
  const createNotes = (e) => {
    e.preventDefault();
    setWait("Please wait...");
    const formData = {
      lead_id: editSelectedLead?._id,
      description: noteData?.note,
      note_type: "Note",
      datetime: null,
    };
    if (formData?.description !== "") {
      axios
        .post(`${baseUrl}leads/${formData.lead_id}/create-lead-superadmin/Note`, formData, config)
        .then(() => {
          toast?.success(`Note is created Successfully!`);
          setWait("Saved");
          noteRecentList();
          setTimeout(() => {
            clearNote();
            setWait("Save");
          }, 1000);
        });
    } else {
      setErrorVal("block");
      setWait("Try Again!");
    }
  };
  const noteRecentList = () => {
    axios
      ?.get(getNoteAPI, config)
      .then((respo) => {
        // console.log(respo?.data, "noteList");
        // let unreadData = respo?.data?.filter((data) => {
        //   return data?.status === "Unread";
        // });
        setNoteList(respo?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const taskRecentList = () => {
    axios
      ?.get(getTaskAPI, config)
      .then((respo) => {
        // console.log(respo?.data, "taskList");
        let unreadData = respo?.data?.filter((data) => {
          return data?.status === "Pending";
        });
        setTaskList(unreadData);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const clearNote = () => {
    setNoteData({
      note: "",
    });
    setErrorVal("none");
  };

  const handleCompletedTasks = (e, id) => {
    const { checked } = e.target;
    if (completedTask?.includes(id)) {
      const updatedTask = completedTask?.filter((item) => item !== id);
      setCompletedTask(updatedTask);
    } else {
      setCompletedTask([...completedTask, id]);
    }
  };
  const handleCompletedNotes = (e, id) => {
    const { checked } = e.target;
    if (completedNote?.includes(id)) {
      const updatedNote = completedNote?.filter((item) => item !== id);
      setCompletedNote(updatedNote);
    } else {
      setCompletedNote([...completedNote, id]);
    }
  };

  // console.log(completedNote)
  useEffect(() => {
    noteRecentList();
    taskRecentList();
  }, []);
  return (
    <div className="profile_section">
      <Toaster position="top-center" reverseOrder={false} />
      <div
        style={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
        }}
        className="profile_inner"
      >
        <div
          style={{
            flexDirection: "column",
          }}
          className="lead-profile-section"
        >
          {/* profile left */}
          <div className="lead-personal-details">
            <div className="">
              <div
                style={{
                  fontSize: "1.3rem",
                  color: "#FFFFFF",
                  fontWeight: "500",
                }}
              >
                {editSelectedLead?.company_name
                  ? editSelectedLead?.company_name
                  : "-----"}
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  color: "#F4F4F4",
                  fontWeight: "500",
                }}
              >
                {editSelectedLead?.company_email ? (
                  <a
                    className="mailTO"
                    href={"mailto:" + editSelectedLead?.company_email}
                  >
                    {editSelectedLead?.company_email}
                  </a>
                ) : (
                  "-----"
                )}
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  color: "#FFFFFF",
                  fontWeight: "500",
                }}
              >
                {editSelectedLead?.mobile_number
                  ? editSelectedLead?.mobile_number
                  : "+91 813XXXXXXX"}
              </div>
              <div
                style={{
                  padding: "5px",
                  borderRadius: "3px",
                  textAlign: "center",
                  color: `${editSelectedLead?.status === "New"
                    ? "#036603"
                    : editSelectedLead?.status === "Viewed"
                      ? "#1877F2"
                      : editSelectedLead?.status === "Contacted"
                        ? "#FF4500"
                        : editSelectedLead?.status === "Deal Done"
                          ? "#036603"
                          : editSelectedLead?.status === "Unqualified"
                            ? "#8B0000"
                            : "#036603"
                    }`,
                  backgroundColor: `${editSelectedLead?.status === "New"
                    ? "#77faae"
                    : editSelectedLead?.status === "Viewed"
                      ? "#1877F245"
                      : editSelectedLead?.status === "Contacted"
                        ? "#FF450045"
                        : editSelectedLead?.status === "Deal Done"
                          ? "#77faae"
                          : editSelectedLead?.status === "Unqualified"
                            ? "#8B000045"
                            : "#77faae"
                    }`,
                  width: "fit-content",
                  fontSize: "0.80rem",
                  marginTop: "3px",
                }}
              >
                {editSelectedLead?.status ? editSelectedLead?.status : "New"}
              </div>
            </div>
          </div>
          {/* profile right */}
          <div className="other-lead-profile">
            <div className="other-lead-profile-inner">
              <span>Bussiness Category</span>
              <span>
                {editSelectedLead?.bussiness_category
                  ? editSelectedLead?.bussiness_category
                  : "Professional Services"}
              </span>
            </div>
            <div className="other-lead-profile-inner">
              <span>Website</span>
              <span>
                {editSelectedLead?.website ? editSelectedLead?.website : "----"}
              </span>
            </div>
            <div className="other-lead-profile-inner">
              <span>Source</span>
              <span>
                {editSelectedLead?.source ? editSelectedLead?.source : "-----"}
              </span>
            </div>
            <div className="other-lead-profile-inner">
              <span>Lead Date</span>
              <span>{convertedDate}</span>
            </div>
            <div className="other-lead-profile-inner">
              <span style={{ marginTop: "5px" }}>Deal Value</span>
              <span
                style={{
                  color: "green",
                  fontSize: "1rem",
                  backgroundColor: "#FFFFFF",
                  padding: "5px 10px",
                  borderRadius: "40px",
                  marginTop: "5px",
                }}
              >
                &#8377;{" "}
                {editSelectedLead?.dealValue
                  ? (+editSelectedLead?.dealValue)?.toFixed(2)
                  : "00.00"}
              </span>
            </div>
          </div>
          {/* Description & Address STart */}
          <div
            style={{
              border: "none",
            }}
            className="address-description-container"
          >
            <div>
              <div className="description-addr-title">Description:</div>
              <div className="description-addr">
                {editSelectedLead?.description
                  ? editSelectedLead?.description
                  : "-----"}
              </div>
            </div>
            <div>
              <div className="description-addr-title">Address Details:</div>
              <div className="description-addr">
                {editSelectedLead?.lead_address
                  ? editSelectedLead?.lead_address
                  : "-----"}
              </div>
              <div className="description-addr">
                {editSelectedLead?.city ? editSelectedLead?.city : "-----"}
              </div>
              <div className="description-addr">
                {editSelectedLead?.pincode
                  ? editSelectedLead?.pincode
                  : "-----"}
              </div>
              <div className="description-addr">
                {editSelectedLead?.state ? editSelectedLead?.state : "-----"}
              </div>
              <div className="description-addr">
                {editSelectedLead?.country
                  ? editSelectedLead?.country
                  : "-----"}
              </div>
            </div>
          </div>
          {/* Description & Address End */}
        </div>

        <div>
          {/* {console.log(editSelectedLead, "jggjgjhgjhg")} */}
          <div>
            {/* <div>Deal Value:</div> */}
            <div style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              padding: "10px 0",
            }}>
              <div style={{ borderColor: "orange" }} className="deal-card">
                <div className="deal-type">
                  Expected Deal Value
                </div>
                <div className="deal-card-value">
                  &#8377;{editSelectedLead?.expected_dealValue || 0}
                </div>
                <div className="deal-card-date">
                  {expectedDate}, {expectedTime}
                </div>
              </div>
              <div style={{
                borderColor: "#0adb0a",
              }} className="deal-card">
                <div className="deal-type">
                  Deal Value
                </div>
                <div className="deal-card-value">
                  &#8377;{editSelectedLead?.dealValue || 0}
                </div>
                <div className="deal-card-date">
                  {dealDate}, {dealTime}
                </div>
              </div>
            </div>
          </div>
          <div style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}>
            <Draggable handle="#task">
              <div
                style={{
                  borderColor: "orange",
                }}
                className="address-description-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#393939",
                      padding: "5px 0px",
                    }}
                  >
                    Pending Task
                  </div>
                  <div
                    style={{
                      color: "#181877F2",
                      fontSize: "18px",
                      cursor: "grab",
                    }}
                    id="task"
                  >
                    <RxDragHandleDots1 />
                  </div>
                </div>
                <table
                  style={{
                    fontSize: "12px",
                    borderCollapse: "collapse",
                    width: "100%",
                  }}
                >
                  <tbody>
                    {taskList?.length > 0 ? (
                      taskList?.map((item, i) => {
                        let taskDate = new Date(item?.datetime && item?.datetime);
                        let convertedTaskDate = convertDate(taskDate);
                        let convertedTaskTime = taskDate.toLocaleTimeString();
                        return (
                          <tr key={i + 1}>
                            <td
                              style={{
                                width: "25px",
                              }}
                            >
                              <div class="round">
                                <input
                                  type="checkbox"
                                  id={`checkbox${i}`}
                                  checked={!!completedTask?.includes(`noteCheckbox${i}`)}
                                  onChange={(e) =>
                                    handleCompletedTasks(e, `noteCheckbox${i}`)
                                  }
                                />
                                <label for={`checkbox${i}`} />
                              </div>
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                color: "#595959",
                                fontWeight: "400",
                              }}
                            >
                              {i + 1}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                color: "#595959",
                                fontWeight: "400",
                                width: "200px",
                              }}
                            >
                              <span
                                style={{
                                  textDecoration: !!completedTask?.includes(`noteCheckbox${i}`) && "line-through",
                                }}
                              >
                                {item?.description}
                              </span>
                              <div
                                style={{
                                  padding: "5px 0",
                                  color: "#1877F2b5",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  fontSize: "12px !important",
                                }}
                              >
                                {`${convertedTaskDate}, ${convertedTaskTime}`}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={5}
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#595959",
                            fontWeight: "500",
                            padding: "10px",
                          }}
                        >
                          No Unread Task!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Draggable>
            <Draggable handle="#note">
              <div
                style={{
                  borderColor: "#1877F2",
                }}
                className="address-description-container"
              >
                <div>
                  <form>
                    <div className="note-input-box">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            color: "#393939",
                            padding: "5px 0px",
                          }}
                        >
                          Write Your Note
                        </div>
                        <div
                          style={{
                            color: "#181877F2",
                            fontSize: "18px",
                            cursor: "grab",
                          }}
                          id="note"
                        >
                          <RxDragHandleDots1 />
                        </div>
                      </div>
                      <textarea
                        // rows={5}
                        style={{
                          maxWidth: "300px",
                          height: "70px",
                        }}
                        className="note-inchar note-text"
                        name="note"
                        value={noteData?.note}
                        onChange={(e) => noteFormHandler(e)}
                      />
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#fe3939",
                          display: errorVal,
                        }}
                      >
                        Please enter any value!
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          width: "80px",
                          textAlign: "center",
                          backgroundColor: "#fe3939",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontSize: "14px",
                        }}
                        className="note-save-btn"
                        onClick={clearNote}
                        type="submit"
                      >
                        Clear
                      </div>
                      <button
                        style={{
                          minWidth: "80px",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                        className="note-save-btn"
                        onClick={(e) => createNotes(e)}
                        type="submit"
                      >
                        {wait}
                      </button>
                    </div>
                  </form>
                </div>
                <table
                  style={{
                    fontSize: "12px",
                    borderCollapse: "collapse",
                    width: "100%",
                  }}
                >
                  <tbody>
                    {noteList?.length > 0 ? (
                      noteList?.map((item, i) => {
                        let taskDate = new Date(
                          item?.created_at && item?.created_at
                        );
                        let convertedTaskDate = convertDate(taskDate);
                        let convertedTaskTime = taskDate.toLocaleTimeString();
                        return (
                          <tr key={i + 1}>
                            <td
                              style={{
                                width: "25px",
                              }}
                            >
                              <div class="round">
                                <input
                                  type="checkbox"
                                  id={`noteCheckbox${i}`}
                                  checked={!!completedNote?.includes(`noteCheckbox${i}`)}
                                  onChange={(e) =>
                                    handleCompletedNotes(e, `noteCheckbox${i}`)
                                  }
                                />
                                <label for={`noteCheckbox${i}`} />
                              </div>
                            </td>
                            <td
                              style={{
                                width: "20px",
                                padding: "5px",
                                color: "#595959",
                                fontWeight: "400",
                              }}
                            >
                              {i + 1}.
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                color: "#595959",
                                fontWeight: "400",
                                textAlign: "left",
                                whiteSpace: "break-spaces",
                              }}
                            >
                              <span
                                style={{
                                  textDecoration: !!completedNote?.includes(`noteCheckbox${i}`) && "line-through",
                                }}
                              >
                                {item?.description}
                              </span>
                              <div
                                style={{
                                  padding: "5px 0",
                                  color: "#1877F2b5",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  fontSize: "12px !important",
                                }}
                              >
                                {`${convertedTaskDate}, ${convertedTaskTime}`}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={5}
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#595959",
                            fontWeight: "500",
                            padding: "10px",
                          }}
                        >
                          No Unread Note!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Draggable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
