import React, { useEffect, useState } from "react";
import "../Customers/Customer.css";
import {
  MdDeleteOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineListAlt,
  MdOutlineSummarize,
} from "react-icons/md";
import { BsReply } from "react-icons/bs";
import { HiOutlineFilter, HiOutlinePencil } from "react-icons/hi";
import { AiOutlineClose, AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../../ReduxSlices/MainSlice";
import { baseUrl } from "../../../BaseUrl";
import InputField from "../../reusableComponent/InputField";
import {convertDate, convertTime} from "../../webutils";
import toast from "react-hot-toast";
// const baseUrl = process.env.REACT_APP_API_URL;
const EmployeeTicket = () => {
  
  const [wait, setWait] = useState("Assign");
  const [toggleAssign, setToggleAssign] = useState({
    classN: "assignPopup",
    ticket_ticket_id: "",
    ticket_no: "",
    category: "",
    priority: "",
    date: "",
    time: "",
    subject: "",
  });
  const [employeeId, setEmployeeId] = useState("");
  const [ticketData, setTicketData] = useState([]);
  const [openEditCompany, setOpenEditCompany] = useState("dashboard");
  const [editSelectedItem, setEditSelectedItem] = useState([]);
  const [searchData, setSearchData] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeAllData = JSON?.parse(localStorage.getItem("employee_profile"));

  const getTicketDataAPI = `${baseUrl}employyee/assign/fetch/ticket/${employeeAllData?._id}`;
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const getFilteredTicket = (filter) => {
    axios
      .get(
        `${baseUrl}super-admin/tickets/fetch-list/?page=1`,
        config
      )
      .then((respo) => {
        if (respo.status === 200) {
          // console.log(respo)
        }
      });
  };
  const [employeeData, setEmployeeData] = useState();
  const getEmployeeList = () => {
    axios
      .get(`${baseUrl}add-admin/profile-list-super-admin`, config)
      .then((respo) => {
        if (respo.status === 200) {
          setEmployeeData(respo?.data?.data);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  const getTicket = (page, search) => {
    axios
      .get(`${getTicketDataAPI}/?page=${page}&search=${search}`, config)
      .then((res) => {
        setTicketData(res?.data);

      })
      .catch((err) => {
        // console.log(err)
      });
  };
  const formData = {
    ticket_id: toggleAssign?.ticket_id,
    admin_user: employeeId,
  };
  const assignAdminUser = () => {
    axios
      .post(`${baseUrl}super-admin/tickets-assigned`, formData, config)
      .then((respo) => {
        if (respo.status === 200|| 201) {
          toast.success(respo?.data?.message);
          // toast.success("Ticket assigned successfully!");
          setWait("Assigned");
          // console.log('ticktick',respo);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setWait("Err");

        // console.log(err)
      });
  };
  useEffect(() => {
    getFilteredTicket("New");
    getEmployeeList();
  }, []);
  useEffect(() => {
    getTicket(1, searchData)
  }, [searchData]);
  return (
    <div className="customers_page">
      {/* Assign empployee Portal */}
      <div style={{}} className={toggleAssign?.classN} id="assignEmployee">
        <div className="assignPopupInner">
          <div className="containerTicket">
            <div
              className="cutAssign"
              onClick={() => {
                setToggleAssign({
                  classN: "assignPopup",
                  ticket_id: "",
                  ticket_no: "",
                  category: "",
                  priority: "",
                  date: "",
                  time: "",
                  subject: "",
                });
              }}
            >
              <AiOutlineClose />
            </div>
            <div className="assign_heading" style={{ textAlign: "center" }}>
              Assign Employee For Ticket
            </div>
            <div className="ticketDetailsContainer">
              <div className="rightTicket">
                <div className="ticketTitle">Ticket-Details</div>
                <div className="deatilsGridForTicket">
                  <div className="ticketTite">Ticket Number</div>
                  <div>:</div>
                  <div className="ticketNames">{toggleAssign?.ticket_no}</div>
                  <div className="ticketTite">Category</div>
                  <div>:</div>
                  <div className="ticketNames">{toggleAssign?.sector}</div>
                  <div className="ticketTite">Priority</div>
                  <div>:</div>
                  <div className="ticketNames">{toggleAssign?.priority}</div>
                </div>
                <div className="subjectTicket">Subject</div>
                <div className="ticketNames">{toggleAssign?.subject}</div>
              </div>
              <div
                className="chooseEmployeeSection"
                style={{ padding: "10px" }}
              >
                <div className="assign_heading">
                  Select Employee To Assign This Ticket
                </div>
                <select
                  className="selectEmployee"
                  onChange={(e) => setEmployeeId(e.target.value)}
                >
                  <option value={""}>Choose Employee</option>
                  {employeeData?.map((data, i) => {
                    return (
                      <option key={i} value={data?._id}>
                        {data?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="assignBtn">
              <button
                onClick={() => {
                  assignAdminUser();
                  setTimeout(() => {
                    getTicket(1, "");
                  }, 1000);
                  setTimeout(() => {
                    setToggleAssign({
                      classN: "assignPopup",
                      ticket_id: "",
                      ticket_no: "",
                      category: "",
                      priority: "",
                      date: "",
                      time: "",
                      subject: "",
                    });
                  }, 2000);
                }}
              >
                {wait}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="customers_inner">
        {openEditCompany === "dashboard" && (
          <>
            <div className="customer_table_container">
              <div className="customer_table_up">
                <div className="ttl_flex">
                  <div className="customer_summery">
                    <div className="summery_icon">
                      <MdOutlineSummarize />
                    </div>
                    <div className="summer_heading">Tickets Summery</div>
                  </div>
                  <InputField
                    onChange={(e) => setSearchData(e?.target?.value)}
                    placeholder={"search..."}
                    endIcon={<AiOutlineSearch />}
                  />
                </div>
                <div
                  style={{flexWrap: "wrap" }}
                  className="summery_container"
                >
                  <div className="summery_card">
                    <div className="summery_card_inner">
                      <span>{ticketData?.total_ticket_count ? ticketData?.total_ticket_count : 0}</span>
                      <span>Total Tickets</span>
                    </div>
                  </div>
                  <div className="summery_card">
                    <div className="summery_card_inner">
                      <span>{ticketData?.newTickets_count ? ticketData?.newTickets_count : 0}</span>
                      <span style={{ color: "green" }}>New Tickets</span>
                    </div>
                  </div>
                  <div className="summery_card">
                    <div className="summery_card_inner">
                      <span>
                        {ticketData?.pendingTickets_count ? ticketData?.pendingTickets_count : 0}
                      </span>
                      <span style={{ color: "orange" }}>Pending Tickets</span>
                    </div>
                  </div>
                  <div className="summery_card">
                    <div className="summery_card_inner">
                      <span>{ticketData?.closedTickets_count ? ticketData?.closedTickets_count : 0}</span>
                      <span style={{ color: "red" }}>Closed Tickets</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer_table">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ticket Number</th>
                      <th>Subject</th>
                      <th>Company Name</th>
                    
                      <th>Priority</th>
                      <th>Status</th>
                      <th>Date & Time</th>
                      <th>Assign To</th>
                      <th>Reply</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(ticketData)} */}
                    {ticketData?.data?.map((item, index) => {
                      let date = new Date(item?.createdAt);
                      let convertedDate = convertDate(item?.createdAt);
                      let convertedTime = convertTime(item?.createdAt);
                      return (
                        <>
                          <tr key={index + 1}>
                            {/* <td>{index + (ticketData?.page * 10 - 9)}</td> */}
                            <td>{index +1}</td>
                            <td>{item?.ticket_id}</td>
                            <td>{item?.subject}</td>
                            {/* <td>{item?.company_name} {item?.sub_company_name}</td> */}

                            <td><div>
                        <div style={{fontSize: "15px"}}>{item?.company_name ? item?.company_name : "---"}</div>
                        <div style={{
                          fontSize: "12px",
                          backgroundColor: "#1877F231",
                          color: "#1877F2",
                          width: "fit-content",
                          padding: "5px",
                          borderRadius: "5px",
                        }}>{item?.sub_company_name ? item?.sub_company_name : "---"}</div>
                        </div></td>
                            <td>{item?.priority}</td>
                            {item?.status === "New" ? (
                              <td style={{ color: "green" }}>{item?.status}</td>
                            ) : item?.status === "Pending" ? (
                              <td style={{ color: "orange" }}>
                                {item?.status}
                              </td>
                            ) : (
                              <td style={{ color: "red" }}>{item?.status}</td>
                            )}
                            <td>
                              {convertedDate}, {convertedTime}
                            </td>
                            <td>
                              {item?.assign_to ? (
                                <div style={{ position: "relative" }}>
                                  <span>{item?.assign_to?.name}</span>
                                  <button
                                    className={`assignTickets reAssign`}
                                    onClick={() => {
                                      setToggleAssign({
                                        classN: "assignPopupShow",
                                        ticket_id: item?.ticket_id,
                                        ticket_no: item?.ticket_id,
                                        category: item?.sector,
                                        priority: item?.priority,
                                        date: convertedDate,
                                        time: convertedTime,
                                        subject: item?.subject,
                                      });
                                    }}
                                  >
                                    Re-Assign
                                  </button>
                                </div>
                              ) : (
                                <button
                                  className="assignTickets"
                                  onClick={() => {
                                    setToggleAssign({
                                      classN: "assignPopupShow",
                                      ticket_id: item?.ticket_id,
                                      ticket_no: item?.ticket_id,
                                      category: item?.sector,
                                      priority: item?.priority,
                                      date: convertedDate,
                                      time: convertedTime,
                                      subject: item?.subject,
                                    });
                                  }}
                                >
                                  Assign
                                </button>
                              )}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <div className="action_btns">
                                {/* {
                                                                        item?.status === 'New' && localStorage.getItem('permissions') === 'Super_user' ? '' : */}
                                <BsReply
                                  className="action_icon_view"
                                  onClick={() => {
                                    navigate("/ticket_reply");
                                    dispatch(updateUserData(item));
                                    sessionStorage.setItem("ticket", item?.ticket_id);
                                    //   handleCompanyProfile(item);
                                  }}
                                />
                                {/* } */}
                              </div>
                            </td>
                          </tr>
                          <tr style={{ display: "none" }}>
                            <td colSpan={2}>Image of {index + 1}</td>
                            <td colSpan={7}> {item?.description}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {ticketData?.data?.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "40px",
                      backgroundColor: "#c9c9c9",
                      fontSize: "30px",
                      fontWeight: "600",
                      color: "#595959",
                    }}
                  >
                    No Data Available!
                  </div>
                )}
                {ticketData?.data?.length > 0 && (
                  <div className="pegination">
                    <div className="peginationinner">
                      <MdKeyboardArrowLeft
                        className="arrowPreNext"
                        onClick={() => {
                          ticketData?.previous_page != null &&
                            getTicket(ticketData?.previous_page, "");
                        }}
                      />
                      <span>
                        {ticketData?.current_page} out of{" "}
                        {Math.ceil(ticketData?.total_ticket_count / ticketData?.page_size)}
                      </span>
                      <MdKeyboardArrowRight
                        className="arrowPreNext"
                        onClick={() => {
                          ticketData?.next_page != null &&
                            getTicket(ticketData?.next_page, "");
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeTicket;
