// import React, { useEffect, useState } from "react";
// import { MdOutlineAutoGraph, MdOutlineSummarize } from "react-icons/md";
// import { HiOutlineFilter, HiOutlineTemplate } from "react-icons/hi";
// import { FiUser } from "react-icons/fi";
// import { RiFileList3Line } from "react-icons/ri";
// import "./LeadProfile.css";

// import { useNavigate } from "react-router-dom";
// import LeadDetails from "./LeadDetails";
// import Proposals from "./Proposals";
// import Reminder from "./Reminder";
// import { useSelector } from "react-redux";
// import SentMail from "./SentMail";
// import { CgNotes } from "react-icons/cg";
// import { FaTasks } from "react-icons/fa";
// import Leads from "./leads";
// import CreateLead from "./createLead";

// const ViewLeads = ({setOpenEditLeads}) => {
//   const pageValue = sessionStorage?.getItem("navigate");
//   const editSelectedLead = useSelector((store) => store?.main?.leadData);
//   console.log("editttttt in view............",editSelectedLead);
//   const [profileComponent, setProfileComponent] = useState(<LeadDetails />);
//   const [selectedBtn, setSelectedBtn] = useState("Profile");
//   const navigate = useNavigate();

//   const handleLeadUpdate = () => {
//     setOpenEditLeads("update_lead");
//   };
//   const handleToggleComponent = (value) => {
//     setSelectedBtn(value);
//     sessionStorage?.setItem("navigate", value)
//     if (value === "Profile") {
//       setProfileComponent(<LeadDetails />);
//     } else if (value === "Proposals") {
//       setProfileComponent(<Proposals />);
//     } else if (value == "Note" || value === "Task") {
//       setProfileComponent(<Reminder />);
//     } else if (value == "SentMAil") {
//       setProfileComponent(<SentMail />);
//     } else {
//       setProfileComponent(null);
//     }
//   };
//   useEffect(() => {
//     handleToggleComponent(pageValue);
//   }, [pageValue]);
//   // console.log(pageValue)
//   // console.log(object);
//   return (
//     <div className="customers_page">
//       <div className="customers_inner">
//         <div className="customers_headeing">
//           <div className="heading_left">
//             <div className="form_title">Lead Profile <span style={{ color: '#1877F2', fontWeight: '500' }}>({editSelectedLead?.name})</span></div>
//           </div>
//           <div className="heading_right">
//             <div
//               className="back_btn"
//               onClick={() => {
//                 sessionStorage?.setItem("navigate", "Profile")
//                 navigate("/leads");
//                 {
//                   editSelectedLead && setOpenEditLeads("lead_table");
//                 }
//               }}
//             >
//               <span>
//                 <HiOutlineFilter />
//               </span>
//               <span>Back</span>
//             </div>
//           </div>
//         </div>
//         <div className="customer_table_container">
//           <div className="customer_profile_up">
//             <div className="profile_options">
//               <div
//                 className="profile_option"
//                 onClick={() => handleToggleComponent("Profile")}
//                 style={{
//                   border: selectedBtn === "Profile" && "1px solid gray",
//                 }}
//               >
//                 <FiUser />
//                 <div className="option_txt">Profile</div>
//               </div>
//               <div
//                 className="profile_option"
//                 onClick={() => handleLeadUpdate()}
//                 style={{
//                   border: selectedBtn === "updateProfile" && "1px solid gray",
//                 }}
//               >
//                 <RiFileList3Line />
//                 <div className="option_txt">Update Profile</div>
//               </div>
//               <div
//                 className="profile_option"
//                 onClick={() => handleToggleComponent("Proposals")}
//                 style={{
//                   border: selectedBtn === "Proposals" && "1px solid gray",
//                 }}
//               >
//                 <RiFileList3Line />
//                 <div className="option_txt">Proposals</div>
//               </div>

//               <div
//                 className="profile_option"
//                 onClick={() => handleToggleComponent("Note")}
//                 style={{
//                   border: selectedBtn === "Note" && "1px solid gray",
//                 }}
//               >
//                 <CgNotes />
//                 <div className="option_txt">Notes</div>
//               </div>
//               <div
//                 className="profile_option"
//                 onClick={() => handleToggleComponent("Task")}
//                 style={{
//                   border: selectedBtn === "Task" && "1px solid gray",
//                 }}
//               >
//                 <FaTasks />
//                 <div className="option_txt">Tasks</div>
//               </div>
//               {/* <div
//                 className="profile_option"
//                 onClick={() => handleToggleComponent("SentMAil")}
//                 style={{
//                   border: selectedBtn === "SentMAil" && "1px solid gray",
//                 }}
//               >
//                 <MdOutlineAutoGraph />
//                 <div className="option_txt">Sent Mail</div>
//               </div> */}
//             </div>
//           </div>

//           {selectedBtn === "Profile" && (
//             <LeadDetails editSelectedLead={editSelectedLead} />
//           )}
//           {/* {selectedBtn === "updateProfile" && (
//             <CreateLead
//               editSelectedLead={editSelectedLead}
//               setOpenEditLeads={setOpenEditLeads}
//               setAllLeadData={setAllLeadData}
//             />
//           )} */}
//           {/* {selectedBtn === "Update Profile" && (
//             <Leads editSelectedLead={editSelectedLead}  />
//           )} */}
//           {/* {selectedBtn === "Statement" && <LeadDetails />} */}
//           {selectedBtn === "Proposals" && (
//             <Proposals editSelectedLead={editSelectedLead} />
//           )}
//           {(selectedBtn === "Note" || selectedBtn === "Task") && (
//             <Reminder editSelectedLead={editSelectedLead} noteType={selectedBtn} />
//           )}
//           {selectedBtn === "SentMAil" && (
//             <SentMail editSelectedLead={editSelectedLead} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// // };
// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
// } from '@mui/material';
// import LeadDetails from './LeadDetails'; // Assuming you have this component
// import Proposals from './Proposals'; // Assuming you have this component
// import Reminder from './Reminder'; // Assuming you have this component
// import { HiOutlineFilter } from 'react-icons/hi';
// import { FiUser } from 'react-icons/fi';
// import { RiFileList3Line } from 'react-icons/ri';
// import { CgNotes } from 'react-icons/cg';
// import { FaTasks } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import LeadDetails from './LeadDetails'; // Assuming you have this component
import Proposals from './Proposals'; // Assuming you have this component
import Reminder from './Reminder'; // Assuming you have this component
import { HiOutlineFilter } from 'react-icons/hi';
import { FiUser } from 'react-icons/fi';
import { RiFileList3Line } from 'react-icons/ri';
import { CgNotes } from 'react-icons/cg';
import { FaTasks } from 'react-icons/fa';
import InputField from "../../reusableComponent/InputField";
import axios from 'axios';
// import { toast } from 'react-toastify';
import { baseUrl } from '../../../BaseUrl';
import TextArea from '../../reusableComponent/TextArea';
import { handleLeadData } from '../../../ReduxSlices/MainSlice';

const ViewLeads = ({ setOpenEditLeads, setAllLeadData }) => {
  const dispatch = useDispatch();
  const editSelectedLead = useSelector((store) => store?.main?.leadData);
  const [openDialog, setOpenDialog] = useState(false);
  // const [editSelectedLead, setEditSelectedLead] = useState(null);
    // console.log("editseelkcted",editSelectedLead)
  const [leadFormData, setLeadFormData] = useState({
    category: editSelectedLead?.category || '',
    company: editSelectedLead?.company || '',
    name: editSelectedLead?.name || '',
    email: editSelectedLead?.email || '',
    mobile: editSelectedLead?.mobile || '',
    phone: editSelectedLead?.phone || '',
    area: editSelectedLead?.area || '',
    city: editSelectedLead?.city || '',
    state: editSelectedLead?.state || '',
    country: editSelectedLead?.country || '',
    pincode: editSelectedLead?.pincode || '',
    description: editSelectedLead?.description || '',
    status: editSelectedLead?.status || 'New',
    dealValue: editSelectedLead?.dealValue || '',
    expected_dealValue: editSelectedLead?.expected_dealValue || '',
  });

  const [errors, setErrors] = useState({});
  const [selectedBtn, setSelectedBtn] = useState("Profile"); // Initialize selectedBtn
  const navigate = useNavigate();
  const pageValue = sessionStorage?.getItem("navigate");
  // console.log("pageValue",pageValue)

  const handleToggleComponent = (value) => {
    setSelectedBtn(value); // Set selected button state
    sessionStorage?.setItem("navigate", value);
  };

  const handleOpenDialog = (lead) => {
    // setEditSelectedLead(lead);
    setLeadFormData({ ...lead }); // Populate form with lead data
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // setEditSelectedLead(null);
  };

  const validateForm = () => {
    const newErrors = {};

    // Required fields validation
    if (!leadFormData.company) newErrors.company = "Please enter company name!";
    // if (!leadFormData.email) newErrors.email = "Please enter email id!";
    if (!leadFormData.mobile) newErrors.mobile = "Please enter mobile number!";
    // if (!leadFormData.state) newErrors.state = "Please enter state!";

    // State and Country validation (must not contain numbers)
    const textPattern = /^[A-Za-z\s]+$/; // Only letters and spaces
    if (leadFormData.state && !textPattern.test(leadFormData.state)) {
      // console.log("eadFormData.country",leadFormData.state)
      newErrors.state = "State must contain only letters!";
    }
    if (leadFormData.city && !isNaN(leadFormData.city)) {
      // console.log("eadFormData.country",leadFormData.city)
      newErrors.city = "City must contain only letters!";
    }
    // console.log("leadFormData.country",leadFormData.country)
    if (leadFormData.country && !textPattern.test(leadFormData.country)) {
      // console.log("eadFormData.country",leadFormData.country)
      newErrors.country = "Country must contain only letters!";
    }

    // Email format validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (leadFormData.email && !emailPattern.test(leadFormData.email)) {
      newErrors.email = "Please enter a valid email address!";
    }

    // Mobile number validation (must be numeric and exactly 10 digits)
    const mobilePattern = /^\d{10}$/;
    if (leadFormData.mobile && !mobilePattern.test(leadFormData.mobile)) {
      newErrors.mobile = "Mobile number must be a 10-digit number!";
    }


    // Numeric validation 
    if (leadFormData.phone && isNaN(leadFormData.phone)) {
      newErrors.phone = "Phone number must be a number!";
    }
  

    // Pincode validation (if provided, must be numeric and 6 digits)
    const pincodePattern = /^\d{6}$/;
    if (leadFormData.pincode && !pincodePattern.test(leadFormData.pincode)) {
      newErrors.pincode = "Pincode must be a 6-digit number!";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  const createLeadAPI = `${baseUrl}leads/`;
  const updateLeadDetails = async (e) => {
    const token = localStorage.getItem("saToken2");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    // console.log("leadddddddddd........",leadFormData);
    e.preventDefault();
    if (validateForm()) {
      // try {
        const res = await axios.put(`${createLeadAPI}${editSelectedLead?._id}/update-lead-details-superadmin`, leadFormData, config);
        if(res.status === 200){
          // console.log("resssssssss",res?.data?.data)
          toast.success("Lead updated successfully!");
          dispatch(handleLeadData(res?.data?.data));
          setAllLeadData((old) => old?.results?.map((obj) => {
            if (obj?._id === editSelectedLead._id) {
              return { ...obj, ...leadFormData}; 
            }
            return obj;
          }));
          setOpenDialog(false);
          // setOpenEditLeads("lead_table");
          // navigate("/leads");
        // }
      }
      //  catch (err) {
      //   toast.error(err?.response?.data?.message);
      // }
    }
  };

  useEffect(() => {
    handleToggleComponent(pageValue);
  }, [pageValue]);

  return (
    <div className="customers_page">
      <div className="customers_inner">
        <div className="customers_headeing">
          <div className="heading_left">
            <div className="form_title">Lead Profile <span style={{ color: '#1877F2', fontWeight: '500' }}>({editSelectedLead?.name})</span></div>
          </div>
          <div className="heading_right">
            <div
              className="back_btn"
              onClick={() => {
                sessionStorage?.setItem("navigate", "Profile");
                navigate("/leads");
                setOpenEditLeads("lead_table");
              }}
            >
              <span>
                <HiOutlineFilter />
              </span>
              <span>Back</span>
            </div>
          </div>
        </div>

        <div className="customer_table_container">
          <Button onClick={() => handleOpenDialog(editSelectedLead)}>Update Lead</Button>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{editSelectedLead ? "Update Lead" : "Create New Lead"}</DialogTitle>
            <DialogContent>
              <form onSubmit={updateLeadDetails}>
                <div className="inner-form-title">
                  <div>General Details</div>
                </div>
                <div className="general-info-container">
                  <InputField
                    inputLabel={"Lead Name"}
                    type="text"
                    name={"name"}
                    value={leadFormData.name}
                    onChange={(e) => setLeadFormData({ ...leadFormData, name: e.target.value })}
                    errorResponse={errors.name}
                  />
                  <InputField
                    inputLabel={"Company Name"}
                    type="text"
                    name={"company"}
                    value={leadFormData.company}
                    onChange={(e) => setLeadFormData({ ...leadFormData, company: e.target.value })}
                    errorResponse={errors.company}
                  />
                  <InputField
                    inputLabel={"Email Id"}
                    type="email"
                    name={"email"}
                    value={leadFormData.email}
                    onChange={(e) => setLeadFormData({ ...leadFormData, email: e.target.value })}
                    errorResponse={errors.email}
                  />
                  <InputField
                    inputLabel={"Mobile Number"}
                    type="text"
                    name={"mobile"}
                    value={leadFormData.mobile}
                    onChange={(e) => setLeadFormData({ ...leadFormData, mobile: e.target.value })}
                    errorResponse={errors.mobile}
                  />
                  <InputField
                    inputLabel={"Category"}
                    type="text"
                    name={"category"}
                    value={leadFormData.category}
                    onChange={(e) => setLeadFormData({ ...leadFormData, category: e.target.value })}
                  />
                  <InputField
                    inputLabel={"Phone Number"}
                    type="text"
                    name={"phone"}
                    value={leadFormData.phone}
                    onChange={(e) => setLeadFormData({ ...leadFormData, phone: e.target.value })}
                  />
                </div>
                <TextArea
                  inputLabel={"Description"}
                  name={"description"}
                  value={leadFormData.description}
                  onChange={(e) => setLeadFormData({ ...leadFormData, description: e.target.value })}
                />
                <div className="inner-right-container">
                  <div className="inner-form-title">
                    <div>Address Details</div>
                  </div>
                  <TextArea
                    inputLabel={"Address"}
                    name={"area"}
                    value={leadFormData.area}
                    onChange={(e) => setLeadFormData({ ...leadFormData, area: e.target.value })}
                  />
                  <div className="general-info-container">
                    <InputField
                      inputLabel={"City"}
                      type="text"
                      name={"city"}
                      value={leadFormData.city}
                      onChange={(e) => setLeadFormData({ ...leadFormData, city: e.target.value })}
                    />
                    <InputField
                      inputLabel={"Pin Code"}
                      type="text"
                      name={"pincode"}
                      value={leadFormData.pincode}
                      onChange={(e) => setLeadFormData({ ...leadFormData, pincode: e.target.value })}
                      errorResponse={errors.pincode}
                    />
                    <InputField
                      inputLabel={"State"}
                      type="text"
                      name={"state"}
                      value={leadFormData.state}
                      onChange={(e) => setLeadFormData({ ...leadFormData, state: e.target.value })}
                      errorResponse={errors.state}
                    />
                    <InputField
                      inputLabel={"Country"}
                      type="text"
                      name={"country"}
                      value={leadFormData.country}
                      onChange={(e) => setLeadFormData({ ...leadFormData, country: e.target.value })}
                    />
                  </div>
                </div>


                {/* <Button
                text={"Update"}
                className="leadSubmitBtn"
                onClick={updateLeadDetails}
              /> */}
              </form>
            </DialogContent>
            <DialogActions>
            {/* {editSelectedLead && ( */}
              <Button
                text={"Update"}
                className="leadSubmitBtn"
                color='primary'
                onClick={updateLeadDetails}>Update</Button>
             
            {/* )} */}
              <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
            </DialogActions>
          </Dialog>

          <div className="customer_profile_up">
            <div className="profile_options">
              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Profile")}
                style={{ border: selectedBtn === "Profile" && "1px solid gray" }}
              >
                <FiUser />
                <div className="option_txt">Profile</div>
              </div>
              <div
                className="profile_option"
                onClick={() => handleOpenDialog(editSelectedLead)}
              >
                <FiUser />
                <div className="option_txt">Update Profile</div>
              </div>
              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Proposals")}
                style={{ border: selectedBtn === "Proposals" && "1px solid gray" }}
              >
                <RiFileList3Line />
                <div className="option_txt">Proposals</div>
              </div>
              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Note")}
                style={{ border: selectedBtn === "Note" && "1px solid gray" }}
              >
                <CgNotes />
                <div className="option_txt">Notes</div>
              </div>
              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Task")}
                style={{ border: selectedBtn === "Task" && "1px solid gray" }}
              >
                <FaTasks />
                <div className="option_txt">Tasks</div>
              </div>
            </div>
          </div>

          {/* Render the selected component based on selectedBtn */}
          {selectedBtn === "Profile" && <LeadDetails editSelectedLead={editSelectedLead} />}
          {selectedBtn === "Proposals" && <Proposals editSelectedLead={editSelectedLead} />}
          {(selectedBtn === "Note" || selectedBtn === "Task") && <Reminder editSelectedLead={editSelectedLead} noteType={selectedBtn} />}
        </div>
      </div>
    </div>
  );
};

export default ViewLeads;




