import React, { useEffect, useState } from "react";
import "./Statement.css";
import { baseUrl } from "../../../../BaseUrl";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

const WhatsAppGreenTick = () => {
  const token = localStorage.getItem("saToken2");
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const cid = sessionStorage.getItem("c_id");

  const [greenTickData, setGreenTickData] = useState("");
  const [status, setStatus] = useState();
  const [wait, setWait] = useState("Submit");
  const [itemId, setItemId] = useState('');

  const getGreenTickData = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}whatsapp-green-trick/${cid}/`,
        config
      );
        setGreenTickData(data);
    } catch (err) {
      console.error(err);
    }
  };

  const updateStatusGreenTick = async (id) => {
    setWait("Please wait...");
    try {
      await axios.patch(
        `${baseUrl}whatsapp-green-trick/${id}/`,
        { status },
        config
      );
      setTimeout(() => {
        toast.success("WhatsApp Green Tick updated successfully!");
        setWait("Submit");
        getGreenTickData();
      }, 2000);
    } catch (err) {
      toast.error("Something went wrong!");
      console.error(err);
      setWait('Please try again!')
      setTimeout(() => {
        setWait('Submit')
      }, 1000);
    }
  };

  useEffect(() => {
    getGreenTickData();
  }, []);

  return (
    <div
      className="statement_page"
      style={{
        padding: "10px",
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />
      <div
        className="greenTickContainer"
        style={{
          width: "100%",
        }}
      >
        <div className="greenTickDetailsContainer">
          {greenTickData?.length > 0 ? (
            <div>
              <h5>
                Application received for WhatsApp Green Tick.
              </h5>
              {greenTickData?.map((item, i) => {
                return (
                  <div className="greenTickMain">
                    <div>
                      <div className="greenTickDetails">
                        <span className="greenTickTitle">Logo</span>{" "}
                        <span>:</span>
                        <span className="greenTickContent">
                          <img
                            style={{
                              maxWidth: "200px",
                            }}
                            src="https://chatsense.in/images/chatsense-logo.png"
                            alt="logo"
                          />
                        </span>
                        <span className="greenTickTitle">Display Name</span>{" "}
                        <span>:</span>
                        <span className="greenTickContent">
                          {item?.display_name}
                        </span>
                        <span className="greenTickTitle">Mobile Number</span>{" "}
                        <span>:</span>
                        <span className="greenTickContent">{item?.mobile}</span>
                        <span className="greenTickTitle">
                          Business Category
                        </span>{" "}
                        <span>:</span>
                        <span className="greenTickContent">
                          {item?.business_category}
                        </span>
                        <span className="greenTickTitle">Email Id</span>{" "}
                        <span>:</span>
                        <span className="greenTickContent">
                          {item?.email_id}
                        </span>
                        <span className="greenTickTitle">Website</span>{" "}
                        <span>:</span>
                        <span className="greenTickContent">
                          <a href="https://chatsense.in" target="blank">
                            https://chatsense.in
                          </a>
                        </span>
                        <span className="greenTickTitle">Address</span>{" "}
                        <span>:</span>
                        <span className="greenTickContent">
                          {item?.address}
                        </span>
                        <span className="greenTickTitle">Description</span>{" "}
                        <span>:</span>
                        <span className="greenTickContent">
                          {item?.description}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          marginTop: "20px",
                        }}
                        className="greenTickRightContainer"
                      >
                        <div className="greenTickDetails">
                          <span className="greenTickTitle">Company Name</span>{" "}
                          <span>:</span>
                          <span className="greenTickContent">
                            {item?.company_name
                              ? item?.company_name
                              : item?.display_name}
                          </span>
                          <span className="greenTickTitle">Amount</span>{" "}
                          <span>:</span>
                          <span className="greenTickContent">
                            &#8377; {item?.amount}
                          </span>
                          <span className="greenTickTitle">Status</span>{" "}
                          <span>:</span>
                          <span
                            style={{
                              color: `${
                                item?.status === "pending"
                                  ? "orange"
                                  : item?.status === "rejected"
                                  ? "red"
                                  : "green"
                              }`,
                            }}
                            className="greenTickContent"
                          >
                            {item?.status}
                          </span>
                          <span className="greenTickTitle">
                            Previously Applied
                          </span>{" "}
                          <span>:</span>
                          <span className="greenTickContent">N/A</span>
                          <span className="greenTickTitle">
                            Applied On
                          </span>{" "}
                          <span>:</span>
                          <span className="greenTickContent">
                            {item?.apply_date}
                          </span>
                          <span className="greenTickTitle">Action</span>{" "}
                          <span>:</span>
                          <span className="greenTickContent">
                            <select
                              onChange={(e) => {
                                setStatus(e?.target?.value)
                                setItemId(item?.id)
                              }}
                              className="greenTickSelect"
                            >
                              <option
                                selected={
                                  item?.status === "pending" ? true : false
                                }
                                value={"pending"}
                              >
                                Pending
                              </option>
                              <option
                                selected={
                                  item?.status === "approved" ? true : false
                                }
                                value={"approved"}
                              >
                                Approved
                              </option>
                              <option
                                selected={
                                  item?.status === "rejected" ? true : false
                                }
                                value={"rejected"}
                              >
                                Rejected
                              </option>
                            </select>
                          </span>
                        </div>
                        <div
                          style={{
                            display: `${item?.status === "approved" && "none"}`,
                          }}
                          className="greenTickButton"
                        >
                          <button
                            onClick={() => updateStatusGreenTick(item?.id)}
                          >
                            {item?.id != itemId ? 'Submit' : wait}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                color: "#595959",
                fontWeight: "600",
                fontSize: "1rem",
              }}
            >
              No Green Tick Requested Yet!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppGreenTick;
