import React, { useEffect, useState } from "react";
import "./Customer.css";
import {
  MdDeleteOutline,
  MdOutlineListAlt,
  MdOutlineSummarize,
} from "react-icons/md";
import { HiOutlineFilter, HiOutlinePencil } from "react-icons/hi";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import CreateForm from "./CreateForm";
import BillingAddress from "./BillingAddress";

const CreateCustomer = () => {
  const [detailBillingComp, setDetailBillingComp] = useState("createForm");
  const [borderBottomA, setBorderBottomA] = useState("2px solid #555555");
  const [borderBottomB, setBorderBottomB] = useState("2px solid #ffffff");
  const [createdCompanyData, setCreatedCompanyData] = useState(null);
  // console.log('createdCData..',createdCompanyData);
  const navigate = useNavigate();

  // console.log(createdCompanyData, "customerdata");
  return (
    <div className="customers_page">
      <div className="customers_inner">
        <div className="customers_headeing">
          <div className="heading_left">
            <div className="form_title">Create Company</div>
          </div>
          <div className="heading_right">
            <div
              className="back_btn"
              onClick={() => {
                navigate("/customers");
              }}
            >
              <span>
                <HiOutlineFilter />
              </span>
              <span>Back</span>
            </div>
          </div>
        </div>
        <div className="customer_table_container">
          <div className="customer_form_up">
            <div
              style={{ borderBottom: borderBottomA }}
              className="customer_summery detail_btn"
              onClick={() => {
                setDetailBillingComp("createForm");
                setBorderBottomA("2px solid #555555");
                setBorderBottomB("2px solid #FFFFFF");
              }}
            >
              <div className="summery_icon">
                <MdOutlineSummarize />
              </div>
              <div className="summer_heading">Customers Details</div>
            </div>
            <div
              style={{ borderBottom: borderBottomB }}
              className="customer_summery detail_btn"
              onClick={() => {
                setDetailBillingComp("BillingAddress");
                setBorderBottomA("2px solid #ffffff");
                setBorderBottomB("2px solid #555555");
              }}
            >
              <div className="summery_icon">
                <MdOutlineSummarize />
              </div>
              <div className="summer_heading">Billing & Shipping Address</div>
            </div>
          </div>
          <div>
            {detailBillingComp === "createForm" && (
              <CreateForm
                setDetailBillingComp={setDetailBillingComp}
                setBorderBottomA={setBorderBottomA}
                setBorderBottomB={setBorderBottomB}
                setCreatedCompanyData={setCreatedCompanyData}
              />
            )}
            {detailBillingComp === "BillingAddress" && (
              <BillingAddress createdCompanyData={createdCompanyData} setDetailBillingComp={setDetailBillingComp}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomer;
