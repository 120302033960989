import React from 'react'

const AnalyticBar = ({
    barPathWidth,
    barPathHeight,
    barPathColor,
    dealvalue,
    expectedDealvalue,
    valueColor,
    title,
    titleColor,
    preText,
    postText

}) => {
    const valuePercentage = (dealvalue, expectedDealvalue) => {
        return ((+dealvalue / +expectedDealvalue) * 100)?.toFixed(0)

    }
    return (
        <div>
            <h5 style={{
                color: titleColor || "#595959",
                marginBottom: "3px",
            }}>{title || "Title Here"}</h5>
            <div style={{
                width: barPathWidth || '300px',
                border: '1px solid #5ca1f9',
                position: 'relative',
                height: barPathHeight || "30px",
                borderRadius: "5px",
                overflow: "hidden",
                backgroundColor: barPathColor || "#f14c4c",
            }} className='bar-path'>
                <div style={{
                    width: `${valuePercentage(dealvalue || 0, expectedDealvalue || 1) || "0"}%`,
                    height: "100%",
                    backgroundColor: valueColor || "#3eff80"
                }} className='active-bar'>

                </div>
                <div style={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    bottom: '0',
                    right: '0',
                    zIndex: '9999999',
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    color: "white"
                }}>
                    <div >{preText}{dealvalue || 0}{postText}</div>
                    <div>/</div>
                    <div>{preText}{expectedDealvalue || 0}{postText}</div>
                </div>
            </div>
        </div>
    )
}

export default AnalyticBar