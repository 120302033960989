import React, { useEffect, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import CountryCode from "./ProfileCard/CountryCode.json";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import AddAmont from "./addAmount";
import AddAmount from "./addAmount";
import toast, { Toaster } from "react-hot-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MuiButton from "@mui/material/Button";

// const PlanSetting = () => {
//   var token;
//   if (localStorage.getItem("saToken2"))
//     token = localStorage.getItem("saToken2");
//   var config = { headers: { Authorization: `Bearer ${token}` } };
//   const [selectedPricing, setSelectedPricing] = useState(null);
//   const [wait, setWait] = useState("Submit");
//   const [openDialog, setOpenDialog] = useState(false);
//   const [moneyDeductionlistData, setmoneyDeductionListData] = useState([]);
//   const [countryData, setCountryData] = useState([]);
//   const [editedData, setEditedData] = useState(null);
//   const [messageCategory, setMessageCategory] = useState([
//     "Service",
//     "Marketing",
//     "Authentication",
//     "Utility",
//   ]);
//   const [templateAmountData, setTemplateAmountData] = useState({
//     category: "",
//     price: "",
//   });
//   const [messageAmount, setMessageAmount] = useState(0);
//   const [editInstance, setEditInstance] = useState("");
//   const [errors, setErrors] = useState("");
//   const [searchableData, setSearchableData] = useState("");
//   const [filteredData, setFilteredData] = useState([]);
//   const [planListData, setPlanListData] = useState([]);
//   const [planData, setPlanData] = useState([]);
//   const [planId, setPlanId] = useState(null);
//   // const [filteredPricing, setFilteredPricing] = useState([]);
//   // const []

//   const handleChange = (e) => {
//     setErrors("");
//     const { name, value } = e.target;
//     setTemplateAmountData({
//       ...templateAmountData,
//       [name]: value,
//     });
//   };

//   // get list of money deduction
//   const moneyDeductionList = () => {
//     axios
//       .get(`${baseUrl}message/price/fetch-via-superadmin`, config)
//       .then((respo) => {
//         if (respo?.status) {
//           console.log('money......../////',respo?.data?.data);

//           setmoneyDeductionListData(respo?.data);
//           setFilteredData(respo?.data);
//           // console.log("mas...../", respo?.data?.data);
//         }
//       })
//       .catch((err) => {
//         // console.log(err);
//       });
//   };
//   const planList = () => {
//     axios
//       .get(`${baseUrl}subscription/super-admin/fetch-card`, config)
//       .then((respo) => {
//         setPlanListData(respo?.data?.fetchingCards);
//         // console.log('resp card........../',respo?.data?.fetchingCards);
//       })
//       .catch((err) => {
//         // console.log(err);
//       });
//   };
//   useEffect(() => {
//     planList();
//   }, []);
//   useEffect(() => {
//     let filteredPlanData = planListData?.filter((item) => item?._id === planId);
//     // console.log('plannnnnn',filteredPlanData);
//     setPlanData(filteredPlanData);
//   }, [planId]); 
//   // useEffect(() => {
//   //   if (countryData[1] === "India" || countryData === "Indonesia") {
//   //     setMessageCategory(["Service", "Marketing", "Utility"]);
//   //   } else {
//   //     setMessageCategory(["Service", "Marketing", "Authentication", "Utility"]);
//   //   }
//   // }, []);
//   // Add Deduction money
//   // console.log(planListData, "dataPlan");
//   // console.log(planData[0], "planData");
//   // console.log(planId, "planId");
//   const formData = {
//     plan_id: planData[0]?._id,
//     // company_id: planData[0]?.is_custom ? planData[0]?.company_name : null,
//     // is_custom: planData[0]?.is_custom,
//     // country_code: countryData[0],
//     // country: countryData[1],
//     // category: templateAmountData?.category?.toLocaleLowerCase(),
//     // price: templateAmountData?.price,
//   };
//   // console.log(formData, "formData");

//   const submitPriceData = (e) => {
//     e.preventDefault();
//     if (formData?.plan_id === "") {
//       setErrors("planErr");
//     } else if (formData?.country === "" || formData?.country === undefined) {
//       setErrors("countryErr");
//     } else if (formData?.category == "") {
//       setErrors("categoryErr");
//     } else if (formData?.price == "") {
//       setErrors("priceErr");
//     } 
//     else {
//       setWait("Please wait...");
//       axios
//       ?.post(`${baseUrl}message/price/upload`, formData, config)
//       .then((respo) => {
//           if (respo?.status === 200) {
//             setTimeout(() => {
//               setWait("Price Submitted Successfully!");
//             }, 1000);
//             setTimeout(() => {
//               moneyDeductionList();
//               setTemplateAmountData({
//                 category: "",
//                 price: "",
//               });
//               setCountryData([]);
//               setOpenDialog(false);
//               setWait("Submit");
//               setEditInstance("");
//             }, 2000);
//           }
//         })
//         .catch((err) => {
//           // console.log(err);
//         });
//     }
//   };
//   // Edit Deduction Money
//   const editPriceData = (e, id) => {
//     e.preventDefault();
//     if (formData?.country === "" || formData?.country === undefined) {
//       setErrors("countryErr");
//     } else if (formData?.category == "") {
//       setErrors("categoryErr");
//     } else if (formData?.price == "") {
//       setErrors("priceErr");
//     } else {
//       setWait("Please wait...");
//       axios
//         ?.patch(`${baseUrl}add-payment-deduction/${id}/`, formData, config)
//         .then((respo) => {
//           if (respo?.status === 200) {
//             setTimeout(() => {
//               setWait("Price Submitted Successfully!");
//             }, 1000);
//             setTimeout(() => {
//               moneyDeductionList();
//               setTemplateAmountData({
//                 category: "",
//                 price: "",
//               });
//               setCountryData([]);
//               setOpenDialog(false);
//               setWait("Submit");
//               setEditInstance("");
//             }, 2000);
//           }
//         })
//         .catch((err) => {
//           // console.log(err);
//         });
//     }
//   };
//   // const savece = (categoryData) => {
//   //   const formData = {
//   //     plan_id: planData[0]?.id,
//   //     company_id: planData[0]?.is_custom ? planData[0]?.company_name : null,
//   //     is_custom: planData[0]?.is_custom,
//   //     country_code: categoryData?.country_code,
//   //     country: categoryData?.country,
//   //     category: categoryData?.category?.toLocaleLowerCase(),
//   //     price: messageAmount,
//   //   };
//   //   // console.log(formData, "categoryData");
//   // };
//   useEffect(() => {
//     let fData = moneyDeductionlistData?.filter((item) => {
//       return (
//         item?.pricing?.country
//           ?.toLocaleLowerCase()
//           ?.match(searchableData?.toLocaleLowerCase()) ||
//         item?.pricing?.country_code?.match(searchableData)
//       //   item?.category
//       //     ?.toLocaleLowerCase()
//       //     ?.match(searchableData?.toLocaleLowerCase())
//       );
//     });
//     setFilteredData(fData);
//   }, [searchableData]);
//   useEffect(() => {
//     moneyDeductionList();
//   }, []);
//   const [selectedPlanId, setSelectedPlanId] = useState(null);

//   // console.log(moneyDeductionlistData, "list");
//   // console.log('filteredData?.data', filteredData);
//   const handlePlanClick = (planId) => {
//     const selectedPlan = filteredData?.data?.filter(
//       (item) => item._id === planId
//     );
//     // console.log('selected',selectedPlan);
//     if (selectedPlan) {
//       setSelectedPricing(selectedPlan[0].pricing);
//       setSelectedPlanId(planId);
//     } else {
//       setSelectedPricing(null);
//     }

//   };

//   // const handlePlanClick = (planId) => {
//   //   setSelectedPlanId(planId);
//   // };

//   return (
//     <div
//       style={{
//         width: `${openDialog ? "auto" : "100%"}`,
//         padding: "10px",
//       }}
//     >
//       <Toaster position="top-center" reverseOrder={false} />
//       <div className="headingComponents">Message Amount Setting </div>
//       <div>
//         {filteredData?.data?.map((listData, index) => (
//           <button

//             key={index}
//             onClick={() => handlePlanClick(listData._id)}
//             style={{
//               cursor: 'pointer',
//               color: 'white',
//               backgroundColor: selectedPlanId === listData._id ? '#27d727' : '#1877f2',
//               marginRight: '10px',
//               padding: '10px',
//               border: 'none',
//               borderRadius: '5px',
//             }}
//           >
//             {listData?.plan?.plan_name}
//           </button>
//         ))}

//       </div>
//       <AddAmount
//         openDialog={openDialog}
//         planListData={planListData}
//         editedData={editedData}
//         setEditedData={setEditedData}
//         setOpenDialog={setOpenDialog}
//         config={config}
//         setmoneyDeductionListData={setmoneyDeductionListData}
//         moneyDeductionlistData={moneyDeductionlistData}
//       />
//       <div
//         style={{
//           marginTop: "10px",
//         }}
//         className="customer_table_container"
//       >
//         <div className="plan-button-container">
//           <input
//             className="plan-input-field"
//             type="search"
//             placeholder="Search..."
//             onChange={(e) => {
//               const regex = /^[A-Za-z0-9]*$/;
//               if (regex.test(e.target.value)) {
//                 setSearchableData(e.target.value);
//               }
//             }}
//           />
//           <button
//             className="plan-submit-button"
//             onClick={() => {
//               setOpenDialog(true);
//             }}
//           >
//             Set Amount
//           </button>
//         </div>
//         <div
//           style={{
//             width: "100%",
//             maxHeight: "60vh",
//             overflow: "auto",
//             position: "relative",
//           }}
//           className="customer_table"
//         >
//          <table>
//   <thead style={{ position: "sticky", top: 0 }}>
//     <tr>
//       <th style={{ textAlign: "center" }}>#</th>
//       <th style={{ textAlign: "center" }}>Country Name</th>
//       <th style={{ textAlign: "center" }}>Country Code</th>
//       <th style={{ textAlign: "center" }}>Marketing</th>
//       <th style={{ textAlign: "center" }}>Services</th>
//       <th style={{ textAlign: "center" }}>Authentication</th>
//       <th style={{ textAlign: "center" }}>Utility</th>
//       <th style={{ textAlign: "center" }}>Action</th>
//     </tr>
//   </thead>
//   <tbody>
//               {selectedPricing?.length > 0 ? (
//                 selectedPricing?.map((item, i) => (
//                   <tr key={i}>
//                     <td style={{ textAlign: "center" }}>{i + 1}</td>
//                     <td style={{ textAlign: "center" }}>
//                       <span>{item.country}</span>
//                     </td>
//                     <td style={{ textAlign: "center" }}>{item.country_code}</td>
//                     <td style={{ textAlign: "center" }}>&#8377; {item.marketing}</td>
//                     <td style={{ textAlign: "center" }}>&#8377; {item.service}</td>
//                     <td style={{ textAlign: "center" }}>&#8377; {item.authentication}</td>
//                     <td style={{ textAlign: "center" }}>&#8377; {item.utility}</td>
//                     <td style={{ textAlign: "center" }}>
//                       <button
//                         style={{
//                           fontSize: "18px",
//                           color: "#1877F2",
//                           cursor: "pointer",
//                           background: "none",
//                           border: "none",
//                         }}
//                         onClick={() => {
//                           setEditedData(item);
//                           setOpenDialog(true);
//                         }}
//                       >
//                         <MdModeEdit />
//                       </button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td
//                     colSpan={8}
//                     style={{
//                       fontWeight: "600",
//                       color: "#595959",
//                       fontSize: "14px",
//                       textAlign: "center",
//                     }}
//                   >
//                     Please select the plan from above to show its message pricing data!
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };
// import React, { useEffect, useState } from "react";
// import { MdModeEdit } from "react-icons/md";
// import axios from "axios";
// import AddAmount from "./addAmount";
// import { Toaster } from "react-hot-toast";

const PlanSetting = () => {
  var token;
  if (localStorage.getItem("saToken2")) token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [selectedPlanForSearch, setSelectedPlanForSearch] = useState("");
  const [wait, setWait] = useState("Submit");
  const [openDialog, setOpenDialog] = useState(false);
  const [moneyDeductionlistData, setmoneyDeductionListData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [messageCategory, setMessageCategory] = useState([
    "Service",
    "Marketing",
    "Authentication",
    "Utility",
  ]);
  const [templateAmountData, setTemplateAmountData] = useState({
    category: "",
    price: "",
  });
  const [messageAmount, setMessageAmount] = useState(0);
  const [editInstance, setEditInstance] = useState("");
  const [errors, setErrors] = useState("");
  const [searchableData, setSearchableData] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [planListData, setPlanListData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [planId, setPlanId] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const handleChange = (e) => {
    setErrors("");
    const { name, value } = e.target;
    setTemplateAmountData({
      ...templateAmountData,
      [name]: value,
    });
  };
  
  const moneyDeductionList = () => {
    axios
      .get(`${baseUrl}message/price/fetch-via-superadmin`, config)
      .then((respo) => {
        if (respo?.status) {
          setmoneyDeductionListData(respo?.data);
          setFilteredData(respo?.data);
        }
      })
      .catch((err) => { });
  };

  const planList = () => {
    axios
      .get(`${baseUrl}subscription/super-admin/fetch-card`, config)
      .then((respo) => {
        setPlanListData(respo?.data?.fetchingCards);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    planList();
  }, []);

  useEffect(() => {
    let filteredPlanData = planListData?.filter((item) => item?._id === planId);
    setPlanData(filteredPlanData);
  }, [planId]);
  const [open, setOpen] = useState(false);
  //10/9/2024-saif--------------- Delete,update & search module
  const handleClickOpen = () =>{
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletePlan = () =>{
    const token = localStorage.getItem("saToken2");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    // console.log("delete........",selectedPlanId,filteredData._id, moneyDeductionlistData?.data[0]?._id);
    const id = selectedPlanId ? selectedPlanId : moneyDeductionlistData?.data[0]?._id;
    axios
        .delete(`${baseUrl}message/price/${id}/delete`, config)
        .then((respo) => {
            // console.log("respo in delete.....", respo);
            toast.success("Template deleted successfully!");
            moneyDeductionList();
            setOpen(false);
        })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }
  // useEffect(() => {
  //   console.log("dataaaaaaaaaa..",selectedPlanForSearch,searchableData)
  //   let fData = selectedPlanForSearch && selectedPlanForSearch?.filter((item) => {
  //     return (
  //       item?.pricing?.country
  //         ?.toLocaleLowerCase()
  //         ?.match(searchableData?.toLocaleLowerCase()) ||
  //       item?.pricing?.country_code?.match(searchableData)
  //     );
  //   });
  //   console.log("fData..",fData)
  //   setFilteredData(fData);
  // }, [searchableData]);

  useEffect(() => {
    moneyDeductionList();
  }, []);

  useEffect(() => {
    // console.log("filterd data..........",filteredData)
    if (filteredData?.data?.length > 0) {
      const firstPlan = filteredData?.data[0];
      setSelectedPricing(firstPlan?.pricing);
      setSelectedPlanId(firstPlan?._id);
    }
  }, [filteredData]);

  const handlePlanClick = (planId) => {
    const selectedPlan = filteredData?.data?.filter((item) => item._id === planId);
    if (selectedPlan) {
      console.log("selected Plan.........",selectedPlan)
      setSelectedPricing(selectedPlan[0].pricing);
      setSelectedPlanId(planId);
      setSelectedPlanForSearch(selectedPlan);
    } else {
      setSelectedPricing(null);
    }
  };

  return (
    <div
      style={{
        width: `${openDialog ? "auto" : "100%"}`,
        padding: "10px",
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />
      <div className="headingComponentsForPlan">
        Message Amount Setting
        <button
          className="plan-submit-button"
          style={{
            backgroundColor: "#7bd7a4",
            flexDirection: "column",
            alignItems: "end"
          }}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Set Amount
        </button>
      </div>
      {/* <div style={{
          display:"flex",
          flexDirection: "column",
          alignItems: "flex-end",
          // marginTop: "10px",
        }}>
        
         </div> */}
      <div>
        {filteredData?.data?.map((listData, index) => (
          <button
            key={index}
            onClick={() => handlePlanClick(listData._id)}
            style={{
              cursor: 'pointer',
              color: 'white',
              backgroundColor: selectedPlanId === listData._id ? '#27d727' : '#1877f2',
              marginTop: '10px',
              marginRight: '10px',
              padding: '10px',
              border: 'none',
              borderRadius: '5px',
            }}
          >
            {listData?.plan?.plan_name}
          </button>
        ))}
      </div>
      <AddAmount
        openDialog={openDialog}
        moneyDeductionList = {moneyDeductionList}
        planListData={planListData}
        editedData={editedData}
        setEditedData={setEditedData}
        setOpenDialog={setOpenDialog}
        config={config}
        setmoneyDeductionListData={setmoneyDeductionListData}
        moneyDeductionlistData={moneyDeductionlistData}
      />

      <div
        style={{
          marginTop: "10px",
        }}
        className="customer_table_container"
      >
        <div className="plan-button-container">
          <input
            className="plan-input-field"
            type="search"
            placeholder="Search..."
            onChange={(e) => {
              const regex = /^[A-Za-z0-9]*$/;
              if (regex.test(e.target.value)) {
                setSearchableData(e.target.value);
              }
            }}
          />
          {/* <button
            className="plan-submit-button"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            Set Amount
          </button> */}
          <span
            className="plan-button-container"
          >
            <button
              className="plan-submit-button"

              style={{
                marginRight: "20px",
                backgroundColor: "#7bd7a4"
              }}
              onClick={() => {
                handleClickOpen();
              }}
            >
              Delete Amount
            </button>
            {/* <button
              className="plan-submit-button"

              style={{
                backgroundColor: "#7bd7a4"
              }}
              onClick={() => {
                // setEditedData(item);
                setOpenDialog(true);
              }}
            >
              Edit Amount
            </button> */}
          </span>

        </div>
        <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you really want to delete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will delete your chosen message amount plan.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>Cancel</MuiButton>
            <MuiButton onClick={handleDeletePlan} autoFocus>
              Delete
            </MuiButton>
          </DialogActions>
        </Dialog>
      </div>
        <div
          style={{
            width: "100%",
            maxHeight: "60vh",
            overflow: "auto",
            position: "relative",
          }}
          className="customer_table"
        >

          <table>
            <thead style={{ position: "sticky", top: 0 }}>
              <tr>
                <th style={{ textAlign: "center" }}>#</th>
                <th style={{ textAlign: "center" }}>Country Name</th>
                <th style={{ textAlign: "center" }}>Country Code</th>
                <th style={{ textAlign: "center" }}>Marketing</th>
                <th style={{ textAlign: "center" }}>Services</th>
                <th style={{ textAlign: "center" }}>Authentication</th>
                <th style={{ textAlign: "center" }}>Utility</th>
                {/* <th style={{ textAlign: "center" }}>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {selectedPricing?.length > 0 ? (
                selectedPricing?.map((item, i) => (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      <span>{item.country}</span>
                    </td>
                    <td style={{ textAlign: "center" }}>{item.country_code}</td>
                    <td style={{ textAlign: "center" }}>&#8377; {item.marketing}</td>
                    <td style={{ textAlign: "center" }}>&#8377; {item.service}</td>
                    <td style={{ textAlign: "center" }}>&#8377; {item.authentication}</td>
                    <td style={{ textAlign: "center" }}>&#8377; {item.utility}</td>
                    <td style={{ textAlign: "center" }}>
                      {/* <button
                        style={{
                          fontSize: "18px",
                          color: "#1877F2",
                          cursor: "pointer",
                          background: "none",
                          border: "none",
                        }}
                        onClick={() => {
                          setEditedData(item);
                          setOpenDialog(true);
                        }}
                      >
                        <MdModeEdit />
                      </button> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={8}
                    style={{
                      fontWeight: "600",
                      color: "#595959",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    No data available!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};




export default PlanSetting;

//           {/* <div className="pegination">
//                   <div className='peginationinner'>
//                     <MdKeyboardArrowLeft className='arrowPreNext' onClick={() => {
//                       allCompanyData?.links?.previous != null && getCompany(allCompanyData?.links?.previous)
//                     }} />
//                     <span>{allCompanyData?.page} out of {Math.ceil((allCompanyData?.total) / (allCompanyData?.page_size))}</span>
//                     <MdKeyboardArrowRight className='arrowPreNext' onClick={() => {
//                       allCompanyData?.links?.next != null && getCompany(allCompanyData?.links?.next)
//                     }} />
//                   </div>
//                 </div> */}

//   ): (
//     <tr>
//       <td
//         colSpan={8}
//         style={{
//           fontWeight: "600",
//           color: "#595959",
//           fontSize: "14px",
//           textAlign: "center",
//         }}
//       >
//         No data available!
//       </td>
//     </tr>
//   );
// };
