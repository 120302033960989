import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { baseUrl } from "../../BaseUrl";
import ForgotPassword from "./ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import { handleForgotPasswordDialog } from "../../ReduxSlices/MainSlice";
import toast, { Toaster } from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Login = () => {
  const forgotReduxActionData = useSelector(
    (store) => store?.main?.forgotPasswordDialog
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wait, setWait] = useState("Login");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  var token, userType;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  userType = localStorage.getItem("userType");

  const getUserIp = () => {
    // console.log("get API");
    axios.get("https://ipapi.co/json/").then((res) => {
      getUserIpDB(res?.data);
    });
  };

  const getUserIpDB = (data) => {
    axios
      .post(`${baseUrl}super-admin/ipdata`, {
        json_data: data,
        source: window?.location?.href,
      
      })
      // .then((res) => {
      //   // console.log(res)
      // });
  };

  useEffect(() => {
    getUserIp();
  }, []);

  useEffect(() => {
    if (!token && userType !== "true") {
      navigate("/");
    } else if (token && userType === "true") {
      navigate("/dashboard");
    }
  }, []);
  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };
  const submitLogin = (e) => {
    e.preventDefault();
    setWait("Please wait...");
    let data = {
      super_admin_email: loginData.email,
      password: loginData.password
    }
    axios
      .post(`${baseUrl}super-admin/login`, data)
      .then((respo) => {
        if (respo.status === 200) {
          // console.log('response',respo?.data);
          localStorage.setItem("saToken2", respo?.data?.token_ids?.access_token);
          if (respo?.data?.admin_type === "superadmin") {
            localStorage.setItem("permissions", respo?.data?.admin_type);
          } else {
            localStorage.setItem(
              "permissions",
              JSON.stringify(respo?.data?.data)
            );
          }
          setWait("Logged In");
          toast?.success(respo.data.message);
          // console.log('resppose2',respo?.data?.data);
          // console.log('resppose3',respo?.data?.data?.permission);
          setTimeout(() => {
            if (respo?.data?.admin_type === "superadmin") {
              navigate("/dashboard");
            } else if (
              JSON.stringify(respo?.data?.data?.permission).includes("Sales") &&
              JSON.stringify(respo?.data?.data?.permission).includes("Leads") &&
              JSON.stringify(respo?.data?.data?.permission).includes("Company")
            ) {
              navigate("/customers");
            } else if (
              JSON.stringify(respo?.data?.data?.permission).includes("Leads")
            ) {
              navigate("/leads");
            } else if (
              JSON.stringify(respo?.data?.data?.permission).includes("Company")
            ) {
              navigate("/customers");
            } else if (
              JSON.stringify(respo?.data?.data?.permission).includes("Sales")
            ) {
              navigate("/sales_invoice");
            } else if (
              JSON.stringify(respo?.data?.data?.permission).includes("Ticket")
            ) {
              navigate("/tickets");
            }
          }, 2000);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404 || 401) {
          console.log(err.response,"Login page");
          toast.error(err?.response?.data?.message);
          setWait("Please fill correct email and password!");
          setTimeout(() => {
            setWait("Please try again!");
          }, 2000);
        } else {
          // console.log(err.message);
          setWait(err?.message);
          setTimeout(() => {
            setWait("Please try again!");
          }, 2000);
        }
      });
  };
  const [isPassword, setIsPassword] = useState(true);
  const togglePassword = () => {
    setIsPassword(!isPassword);
  };

  return (
    <div className="login-page">
      <Toaster position="top-center" reverseOrder={false} />
      {/* <button onClick={handleSendMessage}>send</button> */}
      <div className="container">
        <div className="agent_details">
          <h1>Login</h1>
          <form>
            <div className="col_1">
              <div className="line_1">
                <label>Username</label>
                <div className="form-control">
                  <i className="fa fa-user icon"></i>
                  <input
                    type="text"
                    placeholder="Type your Username"
                    required
                    name="email"
                    value={loginData.email}
                    onChange={(e) => handleChangeLogin(e)}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Password</label>
                <div className="form-control">
                  <i className="fa fa-lock icon"></i>
                  <input
                    type={isPassword ? "password" : "text"}
                    placeholder="Type your password"
                    required
                    name="password"
                    value={loginData.password}
                    onChange={(e) => handleChangeLogin(e)}
                  />
                  <div>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }} onClick={togglePassword}>
                      {
                        isPassword ? (
                          <FaEye />
                        ) : (
                          <FaEyeSlash />
                        )
                      }
                    </div>
                  </div>
                </div>
                <div
                  className="forget"
                  onClick={() => dispatch(handleForgotPasswordDialog(true))}
                >
                  Forgot Password
                </div>
              </div>
              <div className="end_section">
                <button
                  onClick={(e) => {
                    // navigate('/')
                    submitLogin(e);
                  }}
                >
                  {wait}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {forgotReduxActionData && <ForgotPassword />}
    </div>
  );
};

export default Login;
