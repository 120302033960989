import React, { useState } from 'react'
import { RxTextAlignLeft } from 'react-icons/rx'
import {MdLogout, MdOutlineNotificationsNone} from 'react-icons/md'
import profile from '../../Assets/profile.jpeg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Navbar = (profileData) => {
  const profileDataOfSuperAdmin = useSelector((store)=> store?.main?.userData)

  const navigate = useNavigate();
  const sideToggle = () => {
    var sideBarToggle = document.getElementById('sideMain')
    var sideTextToggle1 = document.getElementById('menuTxt1')
    var sideTextToggle2 = document.getElementById('menuTxt2')
    var sideTextToggle3 = document.getElementById('menuTxt3')
    var sideTextToggle4 = document.getElementById('menuTxt4')
    var sideTextToggle6 = document.getElementById('menuTxt6')
    var sideTextToggle8 = document.getElementById('menuTxt8')
    var sideTextToggle9 = document.getElementById('menuTxt9')
    var sideTextToggle10 = document.getElementById('menuTxt10')
    var sideTextToggle11 = document.getElementById('menuTxt11')
    var sideTextToggle12 = document.getElementById('menuTxt12')
    var sideTextToggle13 = document.getElementById('menuTxt13')
    var sideTextToggle14 = document.getElementById('menuTxt14')
    var sideTextToggle15 = document.getElementById('menuTxt15')
    var sideTextToggle16 = document.getElementById('menuTxt16')
    var sideTextToggle18 = document.getElementById('menuTxt18')

    sideBarToggle.classList.toggle('hideSidebar')
    sideTextToggle1.classList.toggle('menu_txt_hide')
    sideTextToggle2.classList.toggle('menu_txt_hide')
    sideTextToggle3.classList.toggle('menu_txt_hide')
    sideTextToggle4.classList.toggle('menu_txt_hide')
    sideTextToggle6.classList.toggle('menu_txt_hide')
    sideTextToggle8.classList.toggle('menu_txt_hide')
    sideTextToggle9.classList.toggle('menu_txt_hide')
    sideTextToggle10.classList.toggle('menu_txt_hide')
    sideTextToggle11.classList.toggle('menu_txt_hide')
    sideTextToggle12.classList.toggle('menu_txt_hide')
    sideTextToggle13.classList.toggle('menu_txt_hide')
    sideTextToggle14.classList.toggle('menu_txt_hide')
    sideTextToggle15.classList.toggle('menu_txt_hide')
    sideTextToggle16.classList.toggle('menu_txt_hide')
    sideTextToggle18.classList.toggle('menu_txt_hide')
  }
  const profileEnable =() => {
    navigate('/setting')
  }
  return (
    <div className='navbar_main'>
      <div className='nav_inner'>
        <div className='nav_left'>
          <div className='hamburger'>
          <span onClick={() => {
              sideToggle()
              }}>
            <RxTextAlignLeft/></span>
          </div>
        </div>
        <div className='nav_right'>
          <div className='custmer_area'>
            {profileDataOfSuperAdmin ? profileDataOfSuperAdmin?.name  : 'Unknown'}
          </div>
          <div className='profile_container' onClick={() => profileEnable()}>
            <img src={profile} alt='profile_picture' className='profile_picture'/>
          </div>
          <div className='profile_container'>
            <MdLogout onClick={() => {
              localStorage.clear('saToken')
              navigate('/login')
            }}/>
            {/* <span className='notification_counter'>9</span> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
