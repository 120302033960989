import React, { useEffect, useState } from "react";
import "../Customers/Customer.css";
import {
  MdDeleteOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineListAlt,
  MdOutlineSummarize,
} from "react-icons/md";
import { BsReply } from "react-icons/bs";
import {
  HiOutlineFilter,
  HiOutlineLocationMarker,
  HiOutlinePencil,
} from "react-icons/hi";
import {
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../../ReduxSlices/MainSlice";
import { baseUrl } from "../../../BaseUrl";
import { RxCross2 } from "react-icons/rx";
// const baseUrl = process.env.REACT_APP_API_URL;
const IPDetails = () => {
  const [ipDetailsData, setIpDetailsData] = useState([]);
  const [searchableData, setSearchableData] = useState('');
  const [reminderProfilePopup, setOpenReminderProfilePopup] = useState("");
  const [selectedReminderData, setSelectedReminderData] = useState(null);
  const [wait, setWait] = useState(false);

  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };

  const getCompany = (page) => {
    setWait(true);
    axios
      .get(`${baseUrl}super-admin/fetch_ipdata/?page=${page}&search=${searchableData}`)
      .then((respo) => {
        setWait(false);
        setIpDetailsData(respo?.data);
        // setFilteredIP(respo?.data);
      })
      .catch((err) => {
        setWait(false);
        // console.log(err, "error")
      });
  };

  useEffect(() => {
    getCompany(1);
  }, [searchableData]);
  const handlePopupClose = () => {
    setOpenReminderProfilePopup("");
  };
  const handleOpenDialog = (data) => {
    setOpenReminderProfilePopup("ongoing_popup");
    setSelectedReminderData(data);

    // console.log(data, "data for ");
  };
  let jsonDataDetails =
    selectedReminderData && selectedReminderData?.json_data;
  // console.log(jsonDataDetails);
  return (
    <div className="customers_page">
      <div
        className={reminderProfilePopup + " " + "ongoing_popup_hide"}
        disabled
      >
        {/* popup code open */}
        <div className="popup-main">
          <div className="popup-card">
            <div
              style={{
                width: "100%",
                padding: "0 10px",
                fontSize: "1rem",
                color: "#595959",
                fontWeight: "600",
              }}
            >
              IP Details
            </div>
            <RxCross2 onClick={handlePopupClose} className="cross-icons" />
            <>
              <div
                style={{
                  fontSize: "0.8rem",
                  color: "#393939",
                }}
                className="task-details-main"
              >
                <div className="flex-class">
                  <span>ASN Number</span>
                  <span>:</span>
                  <span>{jsonDataDetails?.asn}</span>
                </div>
                <div className="flex-class">
                  <span>Network</span>
                  <span>:</span>
                  <span>{jsonDataDetails?.network}</span>
                </div>
                <div className="flex-class">
                  <span>City</span>
                  <span>:</span>
                  <span>
                    {jsonDataDetails?.city}, ({jsonDataDetails?.postal})
                  </span>
                </div>
                <div className="flex-class">
                  <span>Country</span>
                  <span>:</span>
                  <span>
                    {jsonDataDetails?.country_name}, ({jsonDataDetails?.country}
                    )
                  </span>
                </div>

                <div className="flex-class">
                  <span>Country Capital</span>
                  <span>:</span>
                  <span>{jsonDataDetails?.country_capital}</span>
                </div>
                <div className="flex-class">
                  <span>Continent Code</span>
                  <span>:</span>
                  <span>{jsonDataDetails?.continent_code}</span>
                </div>
                <div className="flex-class">
                  <span>Currency</span>
                  <span>:</span>
                  <span>
                    {jsonDataDetails?.currency_name}, (
                    {jsonDataDetails?.currency})
                  </span>
                </div>
                <div className="flex-class">
                  <span>Latitude and Longitude</span>
                  <span>:</span>
                  <span>
                    {jsonDataDetails?.latitude}, {jsonDataDetails?.longitude}
                  </span>
                </div>
                <div className="flex-class">
                  <span>Timezone</span>
                  <span>:</span>
                  <span>
                    {jsonDataDetails?.timezone}, ({jsonDataDetails?.utc_offset})
                  </span>
                </div>
                <div className="flex-class">
                  <span>Version</span>
                  <span>:</span>
                  <span>{jsonDataDetails?.version}</span>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className="customers_inner">
        <>
          <div className="customer_table_container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="customer_table_up"
            >
              <div className="customer_summery">
                <div className="summery_icon">
                  <HiOutlineLocationMarker />
                </div>
                <div className="summer_heading">
                  IP Details
                  <span
                    style={{
                      backgroundColor: "#1877F239",
                      color: "#1877F2",
                      padding: "3px 5px",
                      borderRadius: "20px",
                      marginLeft: "20px",
                      fontSize: "12px",
                    }}
                  >
                    Total Count: {ipDetailsData?.total_IpDatas ? ipDetailsData?.total_IpDatas : 0}
                  </span>
                </div>
              </div>
              <input
                className="plan-input-field"
                type="search"
                placeholder="Search..."
                value={searchableData}
                // onChange={(e) => {
                //   setSearchableData(e?.target?.value);
                // }}
                onChange={(e) => {
                  // const regex = /^[A-Za-z0-9]*$/;
                  // if (regex.test(e.target.value)) {
                    setSearchableData(e.target.value);
                  // }
                }}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
              className="customer_table"
            >
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ASN Number</th>
                    <th>Network</th>
                    <th>Postal Code</th>
                    <th>City</th>
                    <th>Country</th>
                    <th>Lattitude, Langitude</th>
                    <th>Date & Time</th>
                    <th>Source</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {!wait ? (
                    ipDetailsData?.data?.map((item, index) => {
                      let date = new Date(item?.createdAt);
                      let convertedDate = date.toLocaleDateString();
                      let convertedTime = date.toLocaleTimeString("en-US");
                      let jsonData = item?.json_data
                      return (
                        <>
                          <tr key={index + 1}>
                            <td>{index +
                            (ipDetailsData?.current_page * ipDetailsData?.page_size -
                              (ipDetailsData?.page_size - 1))}</td>
                            <td>{jsonData?.asn}</td>
                            <td>{jsonData?.network}</td>
                            <td>{jsonData?.postal}</td>
                            <td>{jsonData?.city}</td>
                            <td>{jsonData?.country_name} ({jsonData?.country})</td>
                            <td>
                              {jsonData?.latitude + ", " + jsonData?.longitude}
                            </td>
                            <td>
                              {convertedDate}, {convertedTime}
                            </td>
                            <td>{item?.source ? item?.source : "---"}</td>
                            <td>
                              <AiOutlineEye
                                className="action_icon_view reminder-eye-icon"
                                onClick={() => {
                                  handleOpenDialog(item);
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={10}
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#797979",
                          padding: "20px",
                          textAlign: "center",
                        }}
                      >
                        Please wait...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* {
                console.log(ipDetailsData?.length, "ipDetail")
              } */}
              {(ipDetailsData?.length === 0 || ipDetailsData?.data?.length <= 0) && (
                <div
                  style={{
                    textAlign: "center",
                    padding: "40px",
                    backgroundColor: "#c9c9c9",
                    fontSize: "30px",
                    fontWeight: "600",
                    color: "#595959",
                  }}
                >
                  No Data Available!
                </div>
              )}
              {ipDetailsData?.data?.length > 0 && (
                <div className="pegination">
                  <div className="peginationinner">
                    <MdKeyboardArrowLeft
                      className="arrowPreNext"
                      onClick={() => {
                        ipDetailsData?.previous_page != null &&
                          getCompany(ipDetailsData?.previous_page);
                      }}
                    />
                    <span>
                      {ipDetailsData?.current_page} out of{" "}
                      {Math.ceil(ipDetailsData?.pages)}
                    </span>
                    <MdKeyboardArrowRight
                      className="arrowPreNext"
                      onClick={() => {
                        ipDetailsData?.next_page != null &&
                          getCompany(ipDetailsData?.next_page);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default IPDetails;
