import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { baseUrl } from "../../../../BaseUrl";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const WhatsAppList = () => {
    var token;
    if (localStorage.getItem("saToken2"))
      token = localStorage.getItem("saToken2");
    var config = { headers: { Authorization: `Bearer ${token}` } };
    const [whatsAppListData, setWhatsAppListData] = useState([]);
    const companyId = sessionStorage?.getItem("c_id");
    const activityLog = () => {
      axios
        .get(`${baseUrl}super_admin_lead/whatsapp_message_list/${companyId}/`, config)
        .then((respo) => {
          // console.log(respo?.data);
          setWhatsAppListData(respo?.data);
        })
        .catch((err) => {
          // console.log(err);
        });
    };
    useEffect(() => {
      activityLog();
    }, []);
    return (
      <div className="customer_proposal_page">
        <div className="leads-table">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Message</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {whatsAppListData?.length > 0 ? (
                whatsAppListData?.map((item, index) => {
                  // let planData = JSON?.parse(item?.plan_description);
                  let newDate = new Date(item?.created_at);
                  let dateString = newDate?.toDateString();
                  let timeString = newDate?.toLocaleTimeString();
                  
                  return (
                    <tr key={index}>
                      <td>{index +1}</td>
                      <td style={{
                        whiteSpace: "pre-wrap"
                      }}>
                        {item?.message}
                      </td>
                      <td>
                        {dateString}, {timeString}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img width={300} src="./images1/noLogin.png" />
                      <p
                        style={{
                          color: "#595959",
                          fontWeight: "600",
                        }}
                      >
                        No message sent yet on WhatsApp
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* {loginData?.results?.length > 0 && (
          <div className="pegination">
            <div className="peginationinner">
              <MdKeyboardArrowLeft
                className="arrowPreNext"
                onClick={() => {
                  loginData?.links?.previous != null &&
                    activityLog(loginData?.links?.previous);
                }}
              />
              <span>
                {loginData?.page} out of{" "}
                {Math.ceil(loginData?.total / loginData?.page_size)}
              </span>
              <MdKeyboardArrowRight
                className="arrowPreNext"
                onClick={() => {
                  loginData?.links?.next != null &&
                    activityLog(loginData?.links?.next);
                }}
              />
            </div>
          </div>
        )} */}
        </div>
      </div>
    );
}

export default WhatsAppList