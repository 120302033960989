import { configureStore } from "@reduxjs/toolkit";
import MainSlice from "./ReduxSlices/MainSlice";


export const store = configureStore({
    reducer: {
        main : MainSlice
    },
    devTools: true
})
