import { FaQq } from "react-icons/fa";
import { SiQantas } from "react-icons/si";

export const convertDate = (date) => {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    date = new Date(date)
    const day = date.getDate();
    const monthAbbreviation = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${monthAbbreviation}-${year}`;
}

export const convertTime = (date) => {
    date = new Date(date)
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return `${hour}:${minute}:${second}`}