import React, { useEffect, useState } from 'react'
import { getSubCompanyList } from '../../../../APIs/CompanyAPIs'
import { convertDate, convertTime } from '../../../webutils';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { handleIsCompanyProfileActive } from '../../../../ReduxSlices/MainSlice';

const SubCompanyGrid = ({ config }) => {
    const dispatch = useDispatch();
    const [subCompanyData, setSubCompanyData] = useState([]);
    const getList = () => getSubCompanyList(
        sessionStorage.getItem('c_id'),
        config,
        respo => {
            // console.log(respo.data)
            setSubCompanyData(respo.data.data)
        },
        err => {
            // console.log(err)
        }
    )
    const subCompaySelector = (item) => {
        sessionStorage.setItem('s_id', item._id);
        dispatch(handleIsCompanyProfileActive(false));
    }
    useEffect(() => {
        getList()
    }, [])
    return (
        <div style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            padding: "10px",
        }}>
            {
                subCompanyData.length > 0 ?
                    subCompanyData.map((item, index) => {
                        const dateFormated = convertDate(item?.createdAt);
                        const timeFormated = convertTime(item?.createdAt);
                        return (
                            <div style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                boxShadow: "0px 0px 10px #ccc",
                                width: "300px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                backgroundColor: "#fff",
                                color: "#000",
                                cursor: "pointer",
                                boxShadow: "0px 0px 10px #ccc",
                            }} key={index}>
                                <h2 style={{
                                    color: "#1877F2",
                                    fontSize: "20px",
                                }}>{item.sub_company_name}</h2>
                                <p style={{
                                    color: "#595959"
                                }}>{dateFormated} | {timeFormated}</p>

                                <div style={{
                                    display: "flex",
                                    gap: "10px",
                                    flexDirection: "column",
                                    marginTop: "10px"
                                }}>
                                    <div>
                                        TopUp Amount : &#8377; {(+item?.sub_company_storage_topup).toFixed(2)}
                                    </div>
                                    {
                                        item?.sub_company_attached_number.length > 0 ?
                                            <div style={{
                                                color: "#393939"
                                            }}>
                                                Attached Phone Number : ---
                                            </div> :
                                            <div style={{
                                                fontSize: "18px",
                                                fontWeight: "600",
                                                color: "#797979"
                                            }}>No phone number attached!</div>
                                    }
                                </div>
                                <Button
                                    onClick={() => subCompaySelector(item)}
                                    style={{
                                        backgroundColor: "#1877F2",
                                        color: "#FFFFFF",
                                        marginTop: "10px"
                                    }}>View Details</Button>
                            </div>
                        )
                    }) :
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                        No Sub Company Data Here! <br />
                        Add New Sub Company!
                    </div>
            }
        </div>
    )
}

export default SubCompanyGrid