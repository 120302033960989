import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import Button from "../../reusableComponent/Button";
import InputField from "../../reusableComponent/InputField";
import CreateInvoice from "../CreateInvoice/createInvoice";
import { handleLeadData, handleLeadProposalDetails } from "../../../ReduxSlices/MainSlice";
import { useDispatch } from "react-redux";
// const baseUrl = process.env.REACT_APP_API_URL;

const Proposals = ({ editSelectedLead }) => {
  // const baseUrl = "http://192.168.114.239:8000/";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cid = sessionStorage.getItem("c_id");
  const [selectedSection, setSelectedSection] = useState("proposaltable");
  const [proposalData, setProposalData] = useState();
  // console.log(editSelectedLead?.id)
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  // token ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkyNjg1OTU5LCJpYXQiOjE2OTI2ODIzNTksImp0aSI6IjRmYTc2MjE1NmUxNzRiNTBhMzVjN2E0NWRmZDNmZGNhIiwidXNlcl9pZCI6MSwidXNlcm5hbWUiOiJyIn0.z3WoK5rud0yBLE0NuYhcY09ecJLO77OPH5Z9sqcFrP8";
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const handleCreateProposal = () => {
    // sessionStorage.setItem("c_id", editSelectedLead?.id)
    {
      sessionStorage.getItem("c_id") !== editSelectedLead?.id &&
        sessionStorage.setItem("c_id", editSelectedLead?.id);
    }
    navigate("/lead-proposals");
  };
  const [filteredData, setFilteredData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const getProposalList = () => {
    axios
      .get(`${baseUrl}leads/${editSelectedLead?._id}/fetch-proposal/`, config)
      .then((respo) => {
        // if (respo.status === 200) {
        // console.log("respo", respo?.data?.data);
        // setProposalData(respo?.data);
        setFilteredData(respo?.data);
        // }
      });
  };
  useEffect(() => {
    getProposalList();
  }, []);
  useEffect(() => {
    // var fData = proposalData?.filter((item) => {
    //   return item?.subject?.toLowerCase()?.match(searchData?.toLowerCase());
    // });
    setFilteredData();
  }, [searchData]);
  // console.log(filteredData);
  return (
    <>
      {selectedSection === "proposaltable" && (
        <div className="customer_proposal_page">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              className={"simpleBtn proposalBtn"}
              text={"New Proposal"}
              onClick={handleCreateProposal}
              style={{ background: "#1976D2", color: "#ffffff" }}
            />
            <InputField
              placeholder="Search..."
              endIcon={<AiOutlineSearch />}
              className="searchInput"
              onChange={(e) => setSearchData(e?.target?.value)}
            />
          </div>
          <div className="leads-table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Subject</th>
                  <th>Plans</th>
                  <th>Proposed Amount</th>
                  <th>Proposal Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.data?.length > 0 ? (
                  filteredData?.data?.map((res, index) => {
                    let date = new Date(res?.createdAt);
                    let convertedDate = date.toLocaleDateString();
                    let convertedTime = date.toLocaleTimeString();
                    let planData;
                    // console.log(planData, "planData");
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{res?.subject ? res?.subject : "---"}</td>
                        <td>
                          <div>
                            {res?.planType}

                            {/* {planData?.map((planItem, i) => {
                              return (
                                <span
                                  title={res?.planDescription}
                                  style={{
                                    display: "block",
                                    paddingBottom: "3px",
                                  }}
                                  key={i}
                                >
                                  {planItem?.planType && i + 1 + "."}{" "}
                                  {planItem?.planType}
                                </span>
                              );
                            })} */}
                          </div>
                        </td>
                        <td>
                          {res?.totalAmount}
                          {/* {planData
                            ?.reduce(
                              (sum, planItem) => sum + +planItem?.totalAmount,
                              0
                            )
                            ?.toFixed(2)} */}
                        </td>
                        <td>{`${convertedDate}`}</td>
                        <td>
                          <AiOutlineEye
                            className="action_icon_view reminder-eye-icon"
                            onClick={() => {
                              sessionStorage.setItem("lp_id", res?.id);
                              dispatch(handleLeadProposalDetails(res));
                              navigate("/view-invoice/" + "lead_proposal");
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td style={{
                      textAlign: "center",
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: "#595959"
                    }} colSpan={6}>No Proposal Found Yet!</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {selectedSection === "createProposal" && (
        <CreateInvoice />
      )}
    </>
  );
};

export default Proposals;
