import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import "./ProfileCard.css";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import { updateUserData } from "../../../../ReduxSlices/MainSlice";
import toast from "react-hot-toast";

const ProfileCard = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((store) => store?.main?.userData);
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [wait, setWait] = useState("Update");
  const [editToggle, setEditToggle] = useState({
    lableDisplay: "block",
    inputDisplay: "none",
    updateButton: "none",
    cancelButton: "none",
  });
  // console.log(profileData)
  const [adminAllData, setAdminAllData] = useState({
    name: "",
    mobile_number: "",
    email: "",
    gender: "",
    state: "",
    address: "",
    pincode: "",
    country: "",
    designation:"",
    admin_type:""
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminAllData({
      ...adminAllData,
      [name]: value,
    });
  };
  const editProfileSubmit = () => {
    setWait("Please wait...");
    axios
      .patch(`${baseUrl}super-admin/profile-update`, adminAllData, config)
      .then((respo) => {
        if (respo.status === 200) {
          // console.log('adminnnnnnnnnnnnn',respo?.data,adminAllData);
          toast.success(respo.data.message);
          dispatch(updateUserData(adminAllData));
          setWait("Data Updated");
          setTimeout(() => {
            setEditToggle({
              lableDisplay: "block",
              inputDisplay: "none",
              updateButton: "none",
              cancelButton: "none",
            });
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setWait("Try Again");
        // console.log(err)
      });
  };
  useEffect(() => {
    setAdminAllData({
      name: profileData?.name ? profileData?.name : "",
      mobile_number: profileData?.mobile_number
        ? profileData?.mobile_number
        : "",
      email: profileData?.email ? profileData?.email : "",
      gender: profileData?.gender ? profileData?.gender : "",
      state: profileData?.state ? profileData?.state : "",
      address: profileData?.address ? profileData?.address : "",
      pincode: profileData?.pincode ? profileData?.pincode : "",
      country: profileData?.country ? profileData?.country : "",
      designation: profileData?.designation ? profileData?.designation : "",
      admin_type: profileData?.admin_type ? profileData?.admin_type : "",

    });
  }, [profileData]);
  // console.log("admin data",adminAllData);
  return (
    <>
      <div class="profileDetailsSetting">
        <div className="headingComponents">
          Profile Setting{" "}
          <span style={{ color: "#1877F2", cursor: "pointer" }}>
            <FaRegEdit
              onClick={() => {
                setEditToggle({
                  lableDisplay: "none",
                  inputDisplay: "block",
                  updateButton: "block",
                  cancelButton: "block",
                });
              }}
            />
          </span>
        </div>
        <div className="subHeading">Personal Details </div>
        <div className="editProfile">
          <div className="subTitle">Name</div>
          <div>:</div>
          <div className="subText">
            <div style={{ display: editToggle?.lableDisplay }}>
              {profileData?.name ? profileData?.name : "---"}
            </div>
            <div
              style={{ display: editToggle?.inputDisplay }}
              className="editableField"
            >
              <input
                name="name"
                value={adminAllData?.name}
                onChange={(e) => handleChange(e)}
                type={"text"}
                // defaultValue={profileData?.name ? profileData?.name : 'Unknown ---
              />
            </div>
          </div>
          <div className="subTitle">Mobile Number</div>
          <div>:</div>
          <div className="subText">
            <div style={{ display: editToggle?.lableDisplay }}>
              {profileData?.mobile_number ? profileData?.mobile_number : "---"}
            </div>
            <div
              style={{ display: editToggle?.inputDisplay }}
              className="editableField"
            >
              <input
                maxLength={10}
                name="mobile_number"
                value={adminAllData?.mobile_number}
                onChange={(e) => handleChange(e)}
                type={"text"}
                // defaultValue={profileData?.mobile_number ? profileData?.mobile_number : '---number'}
              />
            </div>
          </div>
          <div className="subTitle">Email Id</div>
          <div>:</div>
          <div className="subText">
            <div>{profileData?.email ? profileData?.email : "---"}</div>
            <div className="editableField">
              {/* <input
                                name='email'
                                value={adminAllData?.email}
                                onChange={(e) => handleChange(e)}
                                type={'text'}
                            // defaultValue={profileData?.email ? profileData?.email : 'Unknown ---d'}
                            /> */}
            </div>
          </div>
          <div className="subTitle">Gender</div>
          <div>:</div>
          <div className="subText">
            <div style={{ display: editToggle?.lableDisplay }}>
              {profileData?.gender ? profileData?.gender : "---"}
            </div>
            <div
              style={{ display: editToggle?.inputDisplay }}
              className="editableField"
            >
              <input
                name="gender"
                value={adminAllData?.gender}
                onChange={(e) => handleChange(e)}
                type={"text"}
                // defaultValue={profileData?.gender ? profileData?.gender : 'Unknown ---}
              />
            </div>
          </div>
        </div>
        {/* Designation */}

        {profileData?.admin_type === "admin" && adminAllData?.admin_type==="admin" && (
          <>
            <div className="subTitle">Designation</div>
            <div>:</div>
            <div className="subText">
              <div style={{ display: editToggle?.lableDisplay }}>
                {profileData?.designation ? profileData?.designation : "---"}
              </div>
              <div
                style={{ display: editToggle?.inputDisplay }}
                className="editableField"
              >
                <input
                  name="designation"
                  value={adminAllData?.designation}
                  onChange={(e) => handleChange(e)}
                  type="text"
                />
              </div>
            </div>
          </>
        )}

        <div className="subHeading">Address Details</div>
        <div className="editProfile">
          <div className="subTitle">Address</div>
          <div>:</div>
          <div className="subText">
            <div style={{ display: editToggle?.lableDisplay }}>
              {profileData?.address ? profileData?.address : "--- "}
            </div>
            <div
              style={{ display: editToggle?.inputDisplay }}
              className="editableField"
            >
              <input
                name="address"
                value={adminAllData?.address}
                onChange={(e) => handleChange(e)}
                type={"text"}
                // defaultValue={profileData?.address ? profileData?.address : '--- '}
              />
            </div>
          </div>
          <div className="subTitle">State</div>
          <div>:</div>
          <div className="subText">
            <div style={{ display: editToggle?.lableDisplay }}>
              {profileData?.state ? profileData?.state : "--- "}
            </div>
            <div
              style={{ display: editToggle?.inputDisplay }}
              className="editableField"
            >
              <input
                name="state"
                value={adminAllData?.state}
                onChange={(e) => handleChange(e)}
                type={"text"}
                // defaultValue={profileData?.state ? profileData?.state : ' ---
              />
            </div>
          </div>
          <div className="subTitle">Pin Code</div>
          <div>:</div>
          <div className="subText">
            <div style={{ display: editToggle?.lableDisplay }}>
              {profileData?.pincode ? profileData?.pincode : "---"}
            </div>
            <div
              style={{ display: editToggle?.inputDisplay }}
              className="editableField"
            >
              <input
                name="pincode"
                value={adminAllData?.pincode}
                onChange={(e) => handleChange(e)}
                type={"text"}
                // defaultValue={profileData?.pincode ? profileData?.pincode : '---'}
              />
            </div>
          </div>
          <div className="subTitle">Country</div>
          <div>:</div>
          <div className="subText">
            <div style={{ display: editToggle?.lableDisplay }}>
              {profileData?.country ? profileData?.country : "---"}
            </div>
            <div
              style={{ display: editToggle?.inputDisplay }}
              className="editableField"
            >
              <input
                name="country"
                value={adminAllData?.country}
                onChange={(e) => handleChange(e)}
                type={"text"}
                // defaultValue={profileData?.country ? profileData?.country : '---'}
              />
            </div>
          </div>
        </div>
        <div className="edotButtonSection">
          <button
            style={{ display: editToggle?.updateButton }}
            onClick={() => {
              editProfileSubmit();
            }}
          >
            {wait}
          </button>
          <button
            style={{ display: editToggle?.cancelButton }}
            onClick={() => {
              setEditToggle({
                lableDisplay: "block",
                inputDisplay: "none",
                updateButton: "none",
                cancelButton: "none",
              });
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
