import React, { useEffect, useState } from "react";
import "../Customer.css";
import {
  MdOutlineAutoGraph,
  MdOutlineSummarize,
  MdVerified,
} from "react-icons/md";
import { HiOutlineFilter, HiOutlineTemplate } from "react-icons/hi";
import { FiActivity, FiUser } from "react-icons/fi";
// import { GoGraph } from "react-icons/go";
import { RiFileList3Line } from "react-icons/ri";
import "./CustomerProfile.css";

import { useNavigate, useParams } from "react-router-dom";
import CustomerDetails from "./CustomerDetails";
import Statement from "./Statement";
import Payment from "./Payment";
import CustomerInvoice from "../../CustomerInvoice/customerInvoice";
import CustomerProposal from "./Proposal/CustomerProposal";
import SubscriptionCustomer from "./Subscrption/SubscriptionCustomer";
// import { useSelector } from "react-redux";
import WhatsAppGreenTick from "./WhatsAppGreenTick";
import CompanyLogs from "./CompnyLogs";
import WhatsAppList from "./WhatsAppList";
import { useSelector } from "react-redux";
import CompanyProfile from "./CompanyProfile";

const CustomerProfile = ({ editSelectedItem, setOpenEditCompany }) => {
  // const [profileComponent, setProfileComponent] = useState(<CustomerDetails />);
  const { isCompanyProfileActive } = useSelector((store) => store.main)
  const [activeBtn, setActiveBtn] = useState("profile");
  const navigate = useNavigate();
  var activeComponent = sessionStorage?.getItem("activeComponent");
  const handleBtnClick = (activate) => {
    setActiveBtn(activate);
    sessionStorage?.setItem("activeComponent", activate);
  };

  useEffect(() => {
    setActiveBtn(activeComponent);
  }, [activeComponent]);
  return (
    <div className="customers_page">
      <div className="customers_inner">
        <div className="customers_headeing">
          <div className="heading_left">
            <div className="form_title">
              Company Profile ({sessionStorage?.getItem("companyName")})
            </div>
          </div>
          <div className="heading_right">
            <div
              className="back_btn"
              onClick={() => {
                // setOpenEditCompany('dashboard');
                sessionStorage?.setItem("activeComponent", "profile");
                navigate("/customers");
              }}
            >
              <span>
                <HiOutlineFilter />
              </span>
              <span>Back</span>
            </div>
          </div>
        </div>
        <div className="customer_table_container">
          {
            isCompanyProfileActive ?
              <div>
                <CompanyProfile/>
              </div> :
              <>
                <div className="customer_profile_up">
                  <div className="profile_options">
                    <div
                      className={`profile_option ${activeBtn === "profile" && `profile_option_active`
                        }`}
                      onClick={() => handleBtnClick("profile")}
                    >
                      <FiUser />
                      <div className="option_txt">Profile</div>
                    </div>
                    {/* <div
                className={`profile_option ${activeBtn === "statement" && `profile_option_active`
                  }`}
                onClick={() => handleBtnClick("statement")}
              >
                <GoGraph />
                <div className="option_txt">Statement</div>
              </div> */}
                    <div
                      className={`profile_option ${activeBtn === "invoice" && `profile_option_active`
                        }`}
                      onClick={() => handleBtnClick("invoice")}
                    >
                      <MdOutlineSummarize />
                      <div className="option_txt">Invoice</div>
                    </div>
                    <div
                      className={`profile_option ${activeBtn === "payment" && `profile_option_active`
                        }`}
                      onClick={() => handleBtnClick("payment")}
                    >
                      <MdOutlineAutoGraph />
                      <div className="option_txt">Payments</div>
                    </div>
                    <div
                      className={`profile_option ${activeBtn === "proposal" && `profile_option_active`
                        }`}
                      onClick={() => handleBtnClick("proposal")}
                    >
                      <RiFileList3Line />
                      <div className="option_txt">Proposals</div>
                    </div>
                    <div
                      className={`profile_option ${activeBtn === "subscription" && `profile_option_active`
                        }`}
                      onClick={() => handleBtnClick("subscription")}
                    >
                      <HiOutlineTemplate />
                      <div className="option_txt">Subscriptions</div>
                    </div>
                    <div
                      className={`profile_option ${activeBtn === "wa_green_tick" && `profile_option_active`
                        }`}
                      onClick={() => handleBtnClick("wa_green_tick")}
                    >
                      <MdVerified />
                      <div className="option_txt">WhatsApp Green Tick</div>
                    </div>
                    <div
                      className={`profile_option ${activeBtn === "company_log" && `profile_option_active`
                        }`}
                      onClick={() => handleBtnClick("company_log")}
                    >
                      <FiActivity />
                      <div className="option_txt">Company Logs</div>
                    </div>
                    <div
                      className={`profile_option ${activeBtn === "whatsApp_list" && `profile_option_active`
                        }`}
                      onClick={() => handleBtnClick("whatsApp_list")}
                    >
                      <FiActivity />
                      <div className="option_txt">Sent WhatsApp</div>
                    </div>
                  </div>
                </div>
                {/* {profileComponent} */}
                {activeBtn === "profile" && (
                  <CustomerDetails editSelectedItem={editSelectedItem} />
                )}
                {/* {activeBtn === "statement" && <Statement />} */}
                {activeBtn === "invoice" && (
                  <CustomerInvoice editSelectedItem={editSelectedItem} />
                )}
                {activeBtn === "payment" && <Payment/>}
                {activeBtn === "proposal" && <CustomerProposal />}
                {activeBtn === "subscription" && <SubscriptionCustomer />}
                {activeBtn === "wa_green_tick" && <WhatsAppGreenTick />}
                {activeBtn === "company_log" && <CompanyLogs />}
                {activeBtn === "whatsApp_list" && <WhatsAppList />}
              </>
          }

        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
