import "./leads.css";
import InputField from "../../reusableComponent/InputField";
import Button from "../../reusableComponent/Button";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import TextArea from "../../reusableComponent/TextArea";
import toast from "react-hot-toast";
// const baseUrl = process.env.REACT_APP_API_URL;
// const CreateLead = ({ editSelectedLead, setOpenEditLeads, setAllLeadData }) => {
//   const navigate = useNavigate();
//   // console.log("editSelected.............",editSelectedLead);
//   const [leadFormData, setLeadFormData] = useState({
//     category: editSelectedLead ? editSelectedLead?.category : null,
//     company: editSelectedLead ? editSelectedLead?.company : null,
//     name: editSelectedLead ? editSelectedLead?.name : null,
//     email: editSelectedLead ? editSelectedLead?.email : null,
//     mobile: editSelectedLead ? editSelectedLead?.mobile : null,
//     phone: editSelectedLead ? editSelectedLead?.phone : null,
//     area: editSelectedLead ? editSelectedLead?.area : null,
//     city: editSelectedLead ? editSelectedLead?.city : null,
//     state: editSelectedLead ? editSelectedLead?.state : null,
//     country: editSelectedLead ? editSelectedLead?.country : null,
//     pincode: editSelectedLead ? editSelectedLead?.pincode : null,
//     description: editSelectedLead ? editSelectedLead?.description : null,
//     status: editSelectedLead ? editSelectedLead?.status : 'New',
//     dealValue: editSelectedLead ? editSelectedLead?.dealValue : null,
//     expected_dealValue: editSelectedLead ? editSelectedLead?.expected_dealValue : null,
//   });

//   // Disable deal done prevention 
//   const isDealDone = editSelectedLead?.status === "Deal Done";
//   console.log(isDealDone,".......Deal ")
//   const [errors, setErrors] = useState({});
//   const [companyErr, setcompanyErr] = useState("");
//   const [companyEmailErr, setCompanyEmailErr] = useState("");
//   const [companyMobileErr, setCompanyMobileErr] = useState("");
//   const createLeadAPI = `${baseUrl}leads/`;
//   // const createLeadAPI = `http://192.168.114.239:8000/lead/`;
//   var token
//   if (localStorage.getItem("saToken2"))
//     token = localStorage.getItem("saToken2");
//   var config = { headers: { Authorization: `Bearer ${token}` } };
//   // console.log(editSelectedLead);
//   const leadFormHandler = (e) => {
//     const { value, name } = e.target;
//     setLeadFormData({
//       ...leadFormData,
//       [name]: value,
//     });
//     setErrors({
//             ...errors,
//             [name]: '',
//           });
//   };

//   const validateForm = () => {
//         const newErrors = {};

//         // Required fields validation
//         if (!leadFormData.company) newErrors.company = "Please enter company name!";
//         if (!leadFormData.name) newErrors.name = "Please enter lead name!";
//         if (!leadFormData.email) newErrors.email = "Please enter email id!";
//         if (!leadFormData.mobile) newErrors.mobile = "Please enter mobile number!";

//         // Email format validation
//         const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if (leadFormData.email && !emailPattern.test(leadFormData.email)) {
//           newErrors.email = "Please enter a valid email address!";
//         }

//         // Mobile number validation (should be numeric and 10 digits)
//         const mobilePattern = /^\d{10}$/;
//         if (leadFormData.mobile && !mobilePattern.test(leadFormData.mobile)) {
//           newErrors.mobile = "Mobile number must be a 10-digit number!";
//         }

//         // Numeric validation for deal values
//         if (leadFormData.dealValue && isNaN(leadFormData.dealValue)) {
//           newErrors.dealValue = "Deal Value must be a number!";
//         }
//         if (leadFormData.expected_dealValue && isNaN(leadFormData.expected_dealValue)) {
//           newErrors.expected_dealValue = "Expected Deal Value must be a number!";
//         }

//         // Pincode validation (if provided, must be numeric and 6 digits)
//         const pincodePattern = /^\d{6}$/;
//         if (leadFormData.pincode && !pincodePattern.test(leadFormData.pincode)) {
//           newErrors.pincode = "Pincode must be a 6-digit number!";
//         }

//         setErrors(newErrors);
//         return Object.keys(newErrors).length === 0; // Returns true if no errors
//       };


//   let formData = {
//     company: leadFormData.company,
//     mobile: leadFormData.mobile,
//     email: leadFormData.email,
//     name: leadFormData.name,
//     phone: leadFormData.phone,
//     category: leadFormData.category,
//     status: leadFormData?.status,
//     description: leadFormData.description,
//     area: leadFormData.area,
//     city: leadFormData.city,
//     state: leadFormData.state,
//     pincode: leadFormData.pincode,
//     country: leadFormData?.country,
//     dealValue: leadFormData?.dealValue,
//     expected_dealValue: leadFormData?.expected_dealValue,
//   };
//   console.log('form lead.........',formData);
//   const updateLeadDetails = (e) => {
//     e.preventDefault();
//     axios
//       .put(`${createLeadAPI}${editSelectedLead?._id}/update-lead-details-superadmin`, formData, config)
//       .then((res) => {
//         // console.log(res)
//         toast.success("Lead updated successfully!");
//         setAllLeadData((old) => {
//           return old?.results?.map((obj) => {
//             if (obj?.id == editSelectedLead.id) {
//               return {
//                 ...obj,
//                 category: leadFormData?.category,
//                 company: leadFormData?.company,
//                 name: leadFormData?.name,
//                 email: leadFormData?.email,
//                 mobile: leadFormData?.mobile,
//                 phone: leadFormData?.phone,
//                 area: leadFormData?.area,
//                 city: leadFormData?.city,
//                 state: leadFormData?.state,
//                 country: leadFormData?.country,
//                 pincode: leadFormData?.pincode,
//                 description: leadFormData?.description,
//               };
//             }
//             return obj;
//           });
//         });
//         setOpenEditLeads("lead_table");
//       })
//       .catch((err) => {
//         // console.log(err)
//         toast.error(err?.response?.data?.message);

//       });
//   };
//   // console.log(leadFormData, "data");
//   const submitCreateLead = (e) => {
//     e.preventDefault();
//     if (leadFormData?.company === "") {
//       setcompanyErr("Please enter company name!");
//     } else if (leadFormData?.email === "") {
//       setCompanyEmailErr("Please enter email id!");
//     } else if (leadFormData?.mobile === "") {
//       setCompanyMobileErr("Please enter mobile number!");
//     } else {
//       axios
//         .post(`${baseUrl}leads/create-lead-superadmin`, formData, config)
//         .then((res) => {
//           navigate("/leads");
//                     if (res?.status === 200) {
//                                   toast.success(res?.data?.message);

//           }
//         })
//         .catch((err) => {
//           toast.error(err?.response?.data?.message);

//           // console.log(err);
//         });
//     }
//   };
//   const companyErr = () => {
//     setcompanyErr("");
//   };
//   const emailErr = () => {
//     setCompanyEmailErr("");
//   };
//   const mobileErr = () => {
//     setCompanyMobileErr("");
//   };
//   return (
//     <div className="create-leads">
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           padding: "10px 10px",
//         }}
//         className="leadTitle"
//       >
//         <div className="create-leads-title">
//           {editSelectedLead ? "Update Lead details" : "Create New Lead"}
//         </div>
//         {!editSelectedLead && (
//           <Button
//             text={"Back"}
//             className="simpleBtn "
//             onClick={() => navigate("/leads")}
//           />
//         )}
//       </div>
//       <div className="lead-form-boundary">
//         <form className="create-leads-inner">
//           <div className="create-leads-body">
//             <div className="inner-left-container">
//               <div className="inner-form-title">
//                 <div>General Details</div>
//               </div>
//               <div className="general-info-container">
//                 <InputField
//                   inputLabel={"Lead Name"}
//                   type="text"
//                   name={"name"}
//                   value={leadFormData.name}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                 />
//                 <InputField
//                   inputLabel={"Company Name"}
//                   type="text"
//                   name={"company"}
//                   value={leadFormData.company}
//                   onChange={leadFormHandler}
//                   errorResponse={companyErr}
//                   onKeyUp={companyErr}
//                 />

//                 <InputField
//                   inputLabel={"Email Id"}
//                   type="email"
//                   name={"email"}
//                   value={leadFormData.email}
//                   onChange={leadFormHandler}
//                   errorResponse={companyEmailErr}
//                   onKeyUp={emailErr}
//                 />
//                 <InputField
//                   inputLabel={"Mobile Number"}
//                   type="text"
//                   maxLength={10}
//                   name={"mobile"}
//                   value={leadFormData.mobile}
//                   onChange={leadFormHandler}
//                   errorResponse={companyMobileErr}
//                   onKeyUp={mobileErr}
//                 />
//                 <InputField
//                   inputLabel={"Category"}
//                   type="text"
//                   name={"category"}
//                   value={leadFormData.category}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                 />
//                 <InputField
//                   inputLabel={"Phone Number"}
//                   type="text"
//                   name={"phone"}
//                   value={leadFormData.phone}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                 />
//               </div>
//               <TextArea
//                 inputLabel={"Description"}
//                 type="text"
//                 name={"description"}
//                 value={leadFormData.description}
//                 onChange={leadFormHandler}
//                 errorResponse={``}
//               />
//             </div>

//             {/* area Details From Here */}
//             <div className="inner-right-container">
//               <div className="inner-form-title">
//                 <div>Address Details</div>
//               </div>
//               <TextArea
//                 inputLabel={"Address"}
//                 type="text"
//                 name={"area"}
//                 value={leadFormData.area}
//                 onChange={leadFormHandler}
//                 errorResponse={``}
//               />

//               <div className="general-info-container">
//                 {/* <div
//                 style={{
//                   display: "grid",
//                   justifyContent: "space-between",
//                   gridTemplateColumns: "45% 45%",
//                   gap: "10px",
//                 }}
//               > */}
//                 <InputField
//                   inputLabel={"City"}
//                   type="text"
//                   name={"city"}
//                   value={leadFormData.city}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                 />
//                 <InputField
//                   inputLabel={"Pin Code"}
//                   type="text"
//                   name={"pincode"}
//                   value={leadFormData.pincode}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                 />
//                 {/* </div> */}
//                 <InputField
//                   inputLabel={"State"}
//                   type="text"
//                   name={"state"}
//                   value={leadFormData.state}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                 />

//                 <InputField
//                   inputLabel={"Country"}
//                   type="text"
//                   name={"country"}
//                   value={leadFormData.country}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="other-info-container">
//             <div className="other-info-inner">
//               <div className="inner-form-title">
//                 <div>Other Details</div>
//               </div>
//               <div className="inner-other-info-container">
//                 {/* <InputField
//                   inputLabel={"Number of user"}
//                   type="text"
//                   name={"straingth"}
//                   value={leadFormData.straingth}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                 /> */}
//                 <InputField
//                   inputLabel={"Expected Deal Value"}
//                   type="text"
//                   name={"expected_dealValue"}
//                   value={leadFormData?.expected_dealValue}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                   disabled={editSelectedLead?.status === "Deal Done"} // Disable if status is "Deal Done"
//                 />
//                 <InputField
//                   inputLabel={"Deal Value"}
//                   type="text"
//                   name={"dealValue"}
//                   value={leadFormData?.dealValue}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                   disabled={isDealDone} // Disable if status is "Deal Done"
//                 />
//                 <InputField
//                   inputLabel={"Change Status"}
//                   type="text"
//                   name={"status"}
//                   value={leadFormData.status}
//                   onChange={leadFormHandler}
//                   errorResponse={``}
//                   disabled={true} // Disable if status is "Deal Done"
//                 />
//               </div>
//             </div>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               gap: "10px",
//               padding: "10px",
//               justifyContent: "end",
//             }}
//           >
//             {editSelectedLead && (
//               <Button
//                 text={"Back"}
//                 className="red-back"
//                 // color={"red"}
//                 onClick={(e) => {
//                   e.preventDefault();
//                   setOpenEditLeads("lead_table");
//                 }}
//               />
//             )}
//             {!editSelectedLead && (
//               <Button
//                 text={"Save"}
//                 className="leadSubmitBtn "
//                 onClick={submitCreateLead}
//               />
//             )}
//             {editSelectedLead && (
//               <Button
//                 text={"Update"}
//                 className="leadSubmitBtn"
//                 onClick={updateLeadDetails}
//               />
//             )}
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };


const CreateLead = ({ editSelectedLead, setOpenEditLeads, setAllLeadData }) => {
  const navigate = useNavigate();
  // console.log("create lead...............",  editSelectedLead);
  const [leadFormData, setLeadFormData] = useState({
    category: editSelectedLead?.category || '',
    company: editSelectedLead?.company || '',
    name: editSelectedLead?.name || '',
    email: editSelectedLead?.email || '',
    mobile: editSelectedLead?.mobile || '',
    phone: editSelectedLead?.phone || '',
    area: editSelectedLead?.area || '',
    city: editSelectedLead?.city || '',
    state: editSelectedLead?.state || '',
    country: editSelectedLead?.country || '',
    pincode: editSelectedLead?.pincode || '',
    description: editSelectedLead?.description || '',
    status: editSelectedLead?.status || 'New',
    dealValue: editSelectedLead?.dealValue || '',
    expected_dealValue: editSelectedLead?.expected_dealValue || '',
  });

  const [errors, setErrors] = useState({});
  const createLeadAPI = `${baseUrl}leads/`;
  const token = localStorage.getItem("saToken2");
  const config = { headers: { Authorization: `Bearer ${token}` } };

  const leadFormHandler = (e) => {
    const { value, name } = e.target;
    setLeadFormData({
      ...leadFormData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateForm = () => {
    const newErrors = {};

    // Required fields validation
    if (!leadFormData.company) newErrors.company = "Please enter company name!";
    // if (!leadFormData.email) newErrors.email = "Please enter email id!";
    if (!leadFormData.mobile) newErrors.mobile = "Please enter mobile number!";
    // if (!leadFormData.state) newErrors.state = "Please enter state!";

    // State and Country validation (must not contain numbers)
    const textPattern = /^[A-Za-z\s]+$/; // Only letters and spaces
    if (leadFormData.state && !textPattern.test(leadFormData.state)) {
      // console.log("eadFormData.country",leadFormData.state)
      newErrors.state = "State must contain only letters!";
    }
    // console.log("leadFormData.country",leadFormData.country)
    if (leadFormData.country && !textPattern.test(leadFormData.country)) {
      // console.log("eadFormData.country",leadFormData.country)
      newErrors.country = "Country must contain only letters!";
    }

    // Email format validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (leadFormData.email && !emailPattern.test(leadFormData.email)) {
      newErrors.email = "Please enter a valid email address!";
    }

    // Mobile number validation (must be numeric and exactly 10 digits)
    const mobilePattern = /^\d{10}$/;
    if (leadFormData.mobile && !mobilePattern.test(leadFormData.mobile)) {
      newErrors.mobile = "Mobile number must be a 10-digit number!";
    }


    // Numeric validation 
    if (leadFormData.phone && isNaN(leadFormData.phone)) {
      newErrors.phone = "Phone number must be a number!";
    }
    if (leadFormData.dealValue && isNaN(leadFormData.dealValue)) {
      newErrors.dealValue = "Deal Value must be a number!";
    }
    if (leadFormData.expected_dealValue && isNaN(leadFormData.expected_dealValue)) {
      newErrors.expected_dealValue = "Expected Deal Value must be a number!";
    }

    // Pincode validation (if provided, must be numeric and 6 digits)
    const pincodePattern = /^\d{6}$/;
    if (leadFormData.pincode && !pincodePattern.test(leadFormData.pincode)) {
      newErrors.pincode = "Pincode must be a 6-digit number!";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const submitCreateLead = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const res = await axios.post(`${baseUrl}leads/create-lead-superadmin`, leadFormData, config);
        if (res?.status === 200) {
          toast.success(res?.data?.message);
          navigate("/leads");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    }
  };
  let formData = {
    company: leadFormData.company,
    mobile: leadFormData.mobile,
    email: leadFormData.email,
    name: leadFormData.name,
    phone: leadFormData.phone,
    category: leadFormData.category,
    status: leadFormData?.status,
    description: leadFormData.description,
    area: leadFormData.area,
    city: leadFormData.city,
    state: leadFormData.state,
    pincode: leadFormData.pincode,
    country: leadFormData?.country,
    dealValue: leadFormData?.dealValue,
    expected_dealValue: leadFormData?.expected_dealValue,
  };
  const updateLeadDetails = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const res = await axios.put(`${createLeadAPI}${editSelectedLead?._id}/update-lead-details-superadmin`, formData, config);
        toast.success("Lead updated successfully!");
        setAllLeadData((old) => old?.results?.map((obj) => {
          if (obj?.id === editSelectedLead.id) {
            return { ...obj, ...leadFormData }; // Ensure original keys are maintained
          }
          return obj;
        }));
        setOpenEditLeads("lead_table");
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  return (
    <div className="create-leads">
      <div className="leadTitle">
        <div className="create-leads-title">
          {editSelectedLead ? "Update Lead details" : "Create New Lead"}
        </div>
        {!editSelectedLead && (
          <Button text={"Back"} className="simpleBtn" onClick={() => navigate("/leads")} />
        )}
      </div>
      <div className="lead-form-boundary">
        <form className="create-leads-inner">
          <div className="create-leads-body">
            <div className="inner-left-container">
              <div className="inner-form-title">
                <div>General Details</div>
              </div>
              <div className="general-info-container">
                <InputField
                  inputLabel={"Lead Name"}
                  type="text"
                  name={"name"}
                  value={leadFormData.name}
                  onChange={leadFormHandler}
                  errorResponse={errors.name}
                />
                <InputField
                  inputLabel={"Company Name"}
                  type="text"
                  name={"company"}
                  value={leadFormData.company}
                  onChange={leadFormHandler}
                  errorResponse={errors.company}
                />
                <InputField
                  inputLabel={"Email Id"}
                  type="email"
                  name={"email"}
                  value={leadFormData.email}
                  onChange={leadFormHandler}
                  errorResponse={errors.email}
                />
                <InputField
                  inputLabel={"Mobile Number"}
                  type="text"
                  maxLength={10}
                  name={"mobile"}
                  value={leadFormData.mobile}
                  onChange={leadFormHandler}
                  errorResponse={errors.mobile}
                />
                <InputField
                  inputLabel={"Category"}
                  type="text"
                  name={"category"}
                  value={leadFormData.category}
                  onChange={leadFormHandler}
                />
                <InputField
                  inputLabel={"Phone Number"}
                  type="text"
                  name={"phone"}
                  value={leadFormData.phone}
                  onChange={leadFormHandler}
                />
              </div>
              <TextArea
                inputLabel={"Description"}
                name={"description"}
                value={leadFormData.description}
                onChange={leadFormHandler}
              />
            </div>
            <div className="inner-right-container">
              <div className="inner-form-title">
                <div>Address Details</div>
              </div>
              <TextArea
                inputLabel={"Address"}
                name={"area"}
                value={leadFormData.area}
                onChange={leadFormHandler}
              />
              <div className="general-info-container">
                <InputField
                  inputLabel={"City"}
                  type="text"
                  name={"city"}
                  value={leadFormData.city}
                  onChange={leadFormHandler}
                />
                <InputField
                  inputLabel={"Pin Code"}
                  type="text"
                  name={"pincode"}
                  value={leadFormData.pincode}
                  onChange={leadFormHandler}
                  errorResponse={errors.pincode}
                />
                <InputField
                  inputLabel={"State"}
                  type="text"
                  name={"state"}
                  value={leadFormData.state}
                  onChange={leadFormHandler}
                  errorResponse={errors.state}
                />
                <InputField
                  inputLabel={"Country"}
                  type="text"
                  name={"country"}
                  value={leadFormData.country}
                  onChange={leadFormHandler}
                />
              </div>
            </div>
          </div>
          <div className="other-info-container">
            <div className="other-info-inner">
              <div className="inner-form-title">
                <div>Other Details</div>
              </div>
              <div className="inner-other-info-container">
                <InputField
                  inputLabel={"Expected Deal Value"}
                  type="text"
                  name={"expected_dealValue"}
                  value={leadFormData.expected_dealValue}
                  onChange={leadFormHandler}
                  errorResponse={errors.expected_dealValue}
                  disabled={editSelectedLead?.status === "Deal Done"}
                />
                <InputField
                  inputLabel={"Deal Value"}
                  type="text"
                  name={"dealValue"}
                  value={leadFormData.dealValue}
                  onChange={leadFormHandler}
                  errorResponse={errors.dealValue}
                  disabled={editSelectedLead?.status === "Deal Done"}
                />
                <InputField
                  inputLabel={"Change Status"}
                  type="text"
                  name={"status"}
                  value={leadFormData.status}
                  onChange={leadFormHandler}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              padding: "10px",
              justifyContent: "end",
            }}
          >
            {editSelectedLead && (
              <Button
                text={"Back"}
                className="red-back"
                // color={"red"}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditLeads("lead_table");
                }}
              />
            )}
            {!editSelectedLead && (
              <Button
                text={"Save"}
                className="leadSubmitBtn "
                onClick={submitCreateLead}
              />
            )}
            {editSelectedLead && (
              <Button
                text={"Update"}
                className="leadSubmitBtn"
                onClick={updateLeadDetails}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateLead;








