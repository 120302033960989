import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../BaseUrl";
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { UpdateDialog } from "../Leads/leadTemplateUpdate";
import toast from "react-hot-toast";
import "./TemplateUpdate.css";
import { RiAddLine } from "react-icons/ri";
import "../Leads/leads.css";
import InputField from "../../reusableComponent/InputField";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import MuiButton from "@mui/material/Button";
import TextArea from "../../reusableComponent/TextArea";
// const baseUrl = process.env.REACT_APP_API_URL

const TemplateUpdate = () => {
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedModule, setSelectedModule] = useState('');
    const [whatsAppTemplates, setWhatsappTemplate] = useState('');
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState(null);
    const [updatedContent, setUpdatedContent] = useState({ heading: '', body: '', footer: '' });
    const [updatedModule, setUpdatedModule] = useState('');
    const [selectedValue, setSelectedValue] = useState('leads');

    const getWhatsappTemplate = () => {
        var token;
        if (localStorage.getItem("saToken2"))
            token = localStorage.getItem("saToken2");
        var config = { headers: { Authorization: `Bearer ${token}` } };

        // console.log("selected......", selectedValue)
        axios
            .get(`${baseUrl}double-tick/template/fetch?module_name=${selectedValue}`, config)
            .then((respo) => {
                // handleOpenTemplate(true)
                // console.log("respo", respo?.data?.fetch_list_of_templates);
                setWhatsappTemplate(respo?.data);
            });
    }

    useEffect(() => {
        getWhatsappTemplate();
    }, []);

    const updateWhatsAppMessage = async (template_unique_key, leadFormData) => {
        const token = localStorage.getItem("saToken2");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const formData = {
            heading: leadFormData.heading,
            body: leadFormData.body,
            footer: leadFormData.footer,
            module_name: updatedModule
        };
        // console.log("unique key......", template_unique_key,formData);
        try {
            const res = await axios.post(`${baseUrl}double-tick/template/add?uniqueKey=${template_unique_key}`, formData, config);
            toast.success("Template updated successfullly!")
        } catch (error) {
            console.error("Error updating template:", error);

        }
    };

    const handleUpdateSubmit = async () => {
        try {
            await updateWhatsAppMessage(editingTemplate.template_unique_key, updatedContent);
            setOpenUpdateDialog(false);
            getWhatsappTemplate();
        } catch (error) {
            console.error("Error updating template:", error);
        }
    };

    const handleUpdateClick = (template) => {
        // console.log("tempalteeeeeeeeeeeeee",template);
        setEditingTemplate(template);
        setOpenUpdateDialog(true);
        setUpdatedModule(template.template_module_name);
        setUpdatedContent({
            heading: template.template_heading,
            body: template.template_body,
            footer: template.template_footer,
        });
    };

    //delete template
    const deleteWhatsAppMessage = async (template_unique_key) => {
        const token = localStorage.getItem("saToken2");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        axios
            .delete(`${baseUrl}double-tick/template/${template_unique_key}/delete`, config)
            .then((respo) => {
                // console.log("respo in delete.....", respo);
                toast.success("Template deleted successfully!");
                getWhatsappTemplate();
            })
    }

    // template saif dropdown 16/10/2024
    const handleChangeDropdown = (e) => {
        const value = e.target.value;
        setSelectedValue(value);
    }

    useEffect(() => {
        getWhatsappTemplate();
    }, [selectedValue]);

    //template create saif--10/09/2024
    const handleCreateTemplate = async (e) => {
        const formData = {
            heading: leadFormData.template_heading,
            body: leadFormData.template_body,
            footer: leadFormData.template_footer,
            module_name: selectedModule
        }
        // console.log("formData for create........", formData);
        const token = localStorage.getItem("saToken2");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        e.preventDefault();
        const res = await axios.post(`${baseUrl}double-tick/template/add`, formData, config)
            .then((respo) => {
                // console.log(respo, "respo of template add");
                getWhatsappTemplate();
                setOpenDialog(false);
                setSelectedCompany("");
                setSelectedModule("");
                setLeadFormData({
                    template_heading: "",
                    template_body: "",
                    template_footer: ""
                });
                toast.success("Template created successfully!");
            })
    }
    const [openDialog, setOpenDialog] = useState(false);
    const [leadFormData, setLeadFormData] = useState({
        template_heading: "",
        template_body: "",
        template_footer: ""
    })
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedCompany("");
        setSelectedModule("");
        setLeadFormData({
            template_heading: "",
            template_body: "",
            template_footer: ""
        });
    }
    const handleOpenDialog = () => {
        // console.log("handle...........")
        setOpenDialog(true);
    }

    const handleCompanySelect = (e) => {
        // setLeadFormData({ ...leadFormData, customer_name: e.target.value });
        setSelectedCompany(e.target.value);
    }

    return (
        <>

            <div className="whatsapp-lis" style={{
                // width:'100%'
            }}
            >   <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <div >
                    {/* <FormControl fullWidth variant="outlined"> */}
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="dropdown-label">Select a module</InputLabel>
                        <Select
                            labelId="dropdown-label"
                            id="demo-simple-select"
                            label="Select a module"
                            value={selectedValue}
                            onChange={handleChangeDropdown}
                        >
                            <MenuItem value={'company_templates'}>Company</MenuItem>
                            <MenuItem value={'leads'}>Leads</MenuItem>
                            <MenuItem value={'leads2'}>Leads2</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="customers_headeing">
                    <div className="heading_left">
                        <div
                            // variant="contained"
                            className="create_btn"
                            onClick={() => handleOpenDialog()}
                        >
                            <RiAddLine />
                            <span>Template</span>
                        </div>
                    </div>
                </div>
                </div>
                {whatsAppTemplates?.fetch_list_of_templates?.map((template, index) => {
                    return (

                        <div className="whatsapp-list-item-containe" key={index}>
                            <div className="template-header">
                                {template?.template_heading}
                            </div>
                            <div>
                                {template?.template_body}
                            </div>
                            <div className="template-footer">
                                {template?.template_footer}
                            </div>

                            <div className="whatsapp-btn-container">
                                <div className="button-group">
                                    <button
                                        data-title={
                                            "Delete " + template?.template_heading
                                        }
                                        className="whatsapp-button"
                                        onClick={() =>
                                            deleteWhatsAppMessage(
                                                template.template_unique_key,
                                            )
                                        }
                                    >
                                        <DeleteIcon />
                                    </button>

                                    <button
                                        data-title={
                                            "Update " + template?.template_heading
                                        }
                                        className="whatsapp-button"
                                        onClick={() => handleUpdateClick(template)}
                                    >
                                        <UpdateIcon />
                                    </button>
                                </div>
                            </div>
                        </div>

                    );
                })}
                <UpdateDialog
                    open={openUpdateDialog}
                    handleClose={() => setOpenUpdateDialog(false)}
                    selectedModule={updatedModule}
                    setSelectedModule={setUpdatedModule}
                    updatedContent={updatedContent}
                    setUpdatedContent={setUpdatedContent}
                    handleUpdateSubmit={handleUpdateSubmit}
                />
            </div>
            {/* } */}
            <div>
             
                <div>
                    {/* <div className="customer_table_container"> */}
                    <Dialog open={openDialog} onClose={handleCloseDialog}
                        PaperProps={{ style: { width: '60%', maxWidth: '500px' } }}
                    >
                        <DialogTitle>{"Create Template"}</DialogTitle>
                        <DialogContent>
                            <form onSubmit={handleCreateTemplate}>
                                {/* <div className="inner-form-title">
                                    <label>Select Customer Name</label>
                                    <select value={selectedCompany} onChange={handleCompanySelect}
                                    >
                                        <option value="" disabled>Select Customer Name</option>
                                        <option value="customer_name">customer_name</option>
                                    </select>
                                </div>
                                <div className="inner-form-title">
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="module-select-label">Select Module</InputLabel>
                            <Select
                                labelId="module-select-label"
                                id="module-select"
                                value={selectedModule}
                                onChange={(e) => setSelectedModule(e.target.value)}
                            >
                                <MenuItem value="Company">Company</MenuItem>
                                <MenuItem value="Leads">Leads</MenuItem>
                                <MenuItem value="Leads2">Leads2</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                                    <FormControl variant="outlined" style={{ flex: 1, marginRight: '8px' }}>
                                        <InputLabel id="customer-select-label">Select Customer Name</InputLabel>
                                        <Select
                                            labelId="customer-select-label"
                                            id="customer-select"
                                            value={selectedCompany}
                                            onChange={handleCompanySelect}
                                            label="Select Customer Name"
                                        >
                                            <MenuItem value="" disabled>Select Customer Name</MenuItem>
                                            <MenuItem value="customer_name">customer_name</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" style={{ flex: 1 }}>
                                        <InputLabel id="module-select-label">Select Module</InputLabel>
                                        <Select
                                            labelId="module-select-label"
                                            id="module-select"
                                            value={selectedModule}
                                            onChange={(e) => setSelectedModule(e.target.value)}
                                            label="Select Module"
                                        >
                                            <MenuItem value="company_templates">Company</MenuItem>
                                            <MenuItem value="leads">Leads</MenuItem>
                                            <MenuItem value="leads2">Leads2</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>              <div className="inner-form-title">
                                    <InputField
                                        inputLabel={"Template Heading"}
                                        type="text"
                                        name={"template_heading"}
                                        value={leadFormData.template_heading}
                                        onChange={(e) => setLeadFormData({ ...leadFormData, template_heading: e.target.value })}
                                    />
                                </div>
                                <div className="inner-form-title">
                                    <TextArea
                                        fullWidth
                                        inputLabel={"Template Body"}
                                        type="text"
                                        name={"template_body"}
                                        value={leadFormData.template_body}
                                        onChange={(e) => setLeadFormData({ ...leadFormData, template_body: e.target.value })}
                                        style={{ height: '200px', width: '100%' }}
                                    />
                                </div>
                                <div className="inner-form-title">
                                    <InputField
                                        inputLabel={"Template Footer"}
                                        type="text"
                                        name={"template_footer"}
                                        value={leadFormData.template_footer}
                                        onChange={(e) => setLeadFormData({ ...leadFormData, template_footer: e.target.value })}
                                    />
                                </div>
                                {/* Buttons to insert customer name placeholder */}
                                {selectedCompany && (
                                    <div className="insert-customer-name-buttons">
                                        <MuiButton
                                            onClick={() => {
                                                const customerName = "customer_name";
                                                if (customerName) {
                                                    const placeholder = `{{${customerName}}}`;
                                                    const templateHeading = leadFormData?.template_heading;

                                                    // Check if template_heading is empty or undefined
                                                    if (!templateHeading) {
                                                        setLeadFormData({
                                                            ...leadFormData,
                                                            template_heading: placeholder, // Set only customer_name if heading is empty
                                                        });
                                                    } else {
                                                        setLeadFormData({
                                                            ...leadFormData,
                                                            template_heading: `${templateHeading} ${placeholder}`, // Append if not empty
                                                        });
                                                    }
                                                }
                                            }}
                                        >
                                            Add to Heading
                                        </MuiButton>
                                        <MuiButton
                                            onClick={() => {
                                                const customerName = "customer_name";
                                                if (customerName) {
                                                    const placeholder = `{{${customerName}}}`;
                                                    const templateBody = leadFormData?.template_body;

                                                    // Check if template_body is empty or undefined
                                                    if (!templateBody) {
                                                        setLeadFormData({
                                                            ...leadFormData,
                                                            template_body: placeholder, // Set only customer_name if body is empty
                                                        });
                                                    } else {
                                                        setLeadFormData({
                                                            ...leadFormData,
                                                            template_body: `${templateBody} ${placeholder}`, // Append if not empty
                                                        });
                                                    }
                                                }
                                            }}
                                        >
                                            Add to Body
                                        </MuiButton>
                                        <MuiButton
                                            onClick={() => {
                                                const customerName = "customer_name";
                                                if (customerName) {
                                                    const placeholder = `{{${customerName}}}`;
                                                    const templateFooter = leadFormData?.template_footer;

                                                    // Check if template_footer is empty or undefined
                                                    if (!templateFooter) {
                                                        setLeadFormData({
                                                            ...leadFormData,
                                                            template_footer: placeholder, // Set only customer_name if footer is empty
                                                        });
                                                    } else {
                                                        setLeadFormData({
                                                            ...leadFormData,
                                                            template_footer: `${templateFooter} ${placeholder}`, // Append if not empty
                                                        });
                                                    }
                                                }
                                            }}
                                        >
                                            Add to Footer
                                        </MuiButton>
                                    </div>
                                )}


                            </form>
                        </DialogContent>
                        <DialogActions>
                            <MuiButton
                                className="templateSubmitBtn"
                                color='primary'
                                autoFocus
                                onClick={handleCreateTemplate}
                            >
                                Save
                            </MuiButton>
                            <MuiButton onClick={handleCloseDialog}>Cancel</MuiButton>
                        </DialogActions>
                    </Dialog>

                </div>
            </div>
        </>
    )
}



export default TemplateUpdate;