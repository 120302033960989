import "./subscription.css";
import { RiAddLine } from "react-icons/ri";
import InputField from "../../reusableComponent/InputField";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineEye, HiOutlinePencil } from "react-icons/hi";
import { useEffect, useState } from "react";
import axios from "axios";
import { HiStar } from "react-icons/hi";
import MuiButton from "@mui/material/Button";
import { baseUrl } from "../../../BaseUrl";
import EditIcon from "@mui/icons-material/Edit";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import Button from "../../reusableComponent/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSubscriptionDelete,
  handleSubscriptionList,
} from "../../../ReduxSlices/MainSlice";
import toast, { Toaster } from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
// const baseUrl = process.env.REACT_APP_API_URL;
const Subscription = () => {
  const [modulePermission, setModulePermission] = useState([]);
  const [popupToggle, setPopUpToggle] = useState("");
  let [plan_features_list, set_plan_features_list] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({
    id: "",
    name: "",
    plan_type: "",
    plan_duration: "",
    number_of_months: 0,
    number_of_days: 0,
    number_of_minutes: 0,
    package_type: "",
    price: 0,
    discount: 0,
    description: "",
    features: [],
    member_count: 0,
    workflow_count: 0,
    mkt_count: 0,
    interactive_count: 0,
    storage_count: 0,
    thunderbolt_count: 0,
    thunderbolt_unit_price: 0,
    total_interactive_price: 0,
    total_member_price: 0,
    total_mkt_price: 0,
    total_storage_price: 0,
    total_thunderbolt_price: 0,
    total_workflow_price: 0,
    module_permission: [],
    selectedCompany: "",
    is_Custom: false,
    is_OneTimeUse: false,
    unit_member_price: 0,
    storage_unit_price: 0,
    interactive_unit_price: 0,
    workflow_unit_price: 0,
  });

  const [personName, setPersonName] = useState([]);
  const [allSubsData, setAllSubsData] = useState([]);
  const [planType, setPlanType] = useState([]);
  const [planDurationData, setPlanDurationData] = useState([]);
  const subsDataAPI = `${baseUrl}subscription/super-admin/fetch-card`;
  const subsDataDeleteAPI = `${baseUrl}subscription/delete-card/`;
  // const planDuration = `${baseUrl}plan-duration/`;
  const [selectedHandler, setSelectedHandler] = useState("");
  const [is_Custom, setIsCustom] = useState(false);
  const [isOneTime, setIsOneTime] = useState(false);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [selectedPlanDuration, setSelectedPlanDuration] = useState(null);
  //  console.log("selected..........",selectedPlanDuration);
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const handleChange = (e) => {
    setPopUpToggle("ongoing_popup");
    setSelectedHandler("Create");
    setIsCustom(false);
    setIsOneTime(false);
    setSubscriptionData({
      id: "",

      planPermission: [],
 
      name: "",
      plan_type: "",
      plan_duration: "",
      number_of_months: 0,
      number_of_days: 0,
      number_of_minutes: 0,
      package_type: "",
      price: 0,
      discount: 0,
      description: "",
      features: [],
      member_count: 0,
      workflow_count: 0,
      mkt_count: 0,
      interactive_count: 0,
      storage_count: 0,
      thunderbolt_count: 0,
      thunderbolt_unit_price: 0,
      total_interactive_price: 0,
      total_member_price: 0,
      total_mkt_price: 0,
      total_storage_price: 0,
      total_thunderbolt_price: 0,
      total_workflow_price: 0,
      module_permission: [],
      selectedCompany: "",
      is_Custom: false,
      is_OneTimeUse: false,
      unit_member_price: 0,
      storage_unit_price: 0,
      interactive_unit_price: 0,
      workflow_unit_price: 0,
    });
  };
  const [addingFeature, setAddingFeature] = useState({
    feature_name: "",
    feature_description: "",
  });

  // const navigate = useNavigate();
  // const handleChangeVal = (e) => {
  //   const { name, value } = e.target;
  //   // console.log(value, "value");
  //   setSubscriptionData({
  //     ...subscriptionData,
  //     [name]: value,
  //   });
  // };
  const planDurations = ["Monthly", "Yearly"];

  const handleChangeVal = (e) => {
    const { name, value } = e.target;
    let updatedValues = { [name]: value };

    if (name === "plan_duration") {
      switch (value) {
        case "Days":
          updatedValues = {
            ...updatedValues,
            number_of_months: 0,
            number_of_days: 7,
            number_of_minutes: 10080,
          };
          break;
        case "Monthly":
          updatedValues = {
            ...updatedValues,
            number_of_months: 1,
            number_of_days: 30,
            number_of_minutes: 43200,
          };
          break;
        case "Yearly":
          updatedValues = {
            ...updatedValues,
            number_of_months: 12,
            number_of_days: 365,
            number_of_minutes: 525600,
          };
          break;
        default:
          break;
      }
    }

    setSubscriptionData((prev) => ({ ...prev, ...updatedValues }));
  };
  // If Is Custom then price is automatic calculated by max member X price per user
  useEffect(() => {
    // if (isCustom) {
    setSubscriptionData({
      ...subscriptionData,
      total_member_price:
        +subscriptionData?.member_count * +subscriptionData?.unit_member_price,
      total_storage_price:
        +subscriptionData?.storage_count *
        +subscriptionData?.storage_unit_price,
      total_thunderbolt_price:
        +subscriptionData?.thunderbolt_count *
        +subscriptionData?.thunderbolt_unit_price,
      total_interactive_price:
        +subscriptionData?.interactive_count *
        +subscriptionData?.interactive_unit_price,
      total_workflow_price:
        +subscriptionData?.workflow_count *
        subscriptionData?.workflow_unit_price,
    });
    // }
  }, [
    +subscriptionData?.member_count,
    +subscriptionData?.unit_member_price,
    +subscriptionData?.storage_count,
    +subscriptionData?.storage_unit_price,
    +subscriptionData?.thunderbolt_count,
    +subscriptionData?.thunderbolt_unit_price,
    +subscriptionData?.interactive_count,
    +subscriptionData?.interactive_unit_price,
    +subscriptionData?.workflow_count,
    +subscriptionData?.workflow_unit_price,

  ]);
  //storage priv
  // useEffect(() => {
  //   setSubscriptionData({
  //     ...subscriptionData,

  //   });
  // }, [nsubscriptionData?.storage_cout, +subscriptionData?.storage_unit_price]);
  const subsItemDelete = useSelector(
    (store) => store?.main?.selectedSubscriptionDelete
  );
  const allSubsItemRedux = useSelector(
    (store) => store?.main?.selectedSubscriptionDelete
  );

  const dispatch = useDispatch();

  const featureHandler = (e) => {
    const { name, value } = e.target;
    setAddingFeature({ ...addingFeature, [name]: value });
  };
  const handleFeaturesList = (value) => {
    set_plan_features_list([...plan_features_list, value?.feature_name]);
    setAddingFeature({
      feature_name: "",
      feature_description: "",
    });
  };
  const submitSubscription = (e) => {
    e.preventDefault();
    // console.log("subscription 2 data", subscriptionData);
    let data = {
      name: subscriptionData?.name || "",
      plan_type: subscriptionData?.plan_type || "",
      plan_duration: subscriptionData?.plan_duration || "",
      number_of_months: subscriptionData?.number_of_months || 0,
      number_of_days: subscriptionData?.number_of_days || 0,
      number_of_minutes: subscriptionData?.number_of_minutes || 0,
      package_type: subscriptionData?.package_type || "",
      price: subscriptionData?.price || 0,
      discount: subscriptionData?.discount || 0,
      description: subscriptionData?.description || "",
      features: plan_features_list || [],
      member_count: subscriptionData?.member_count || 0,
      workflow_count: subscriptionData?.workflow_count || 0,
      mkt_count: subscriptionData?.mkt_count || 0,
      interactive_count: subscriptionData?.interactive_count || 0,
      storage_count: subscriptionData?.storage_count || 0,
      thunderbolt_count: subscriptionData?.thunderbolt_count || 0,
      thunderbolt_unit_price: subscriptionData?.thunderbolt_unit_price || 0,
      total_interactive_price: subscriptionData?.total_interactive_price || 0,
      total_member_price: subscriptionData?.total_member_price || 0,
      total_mkt_price: subscriptionData?.total_mkt_price || 0,
      total_storage_price: subscriptionData?.total_storage_price || 0,
      total_thunderbolt_price: subscriptionData?.total_thunderbolt_price || 0,
      total_workflow_price: subscriptionData?.total_workflow_price || 0,
      module_permission: personName,
      selectedCompany: subscriptionData?.selectedCompany || "",
      is_Custom: is_Custom || false,
      is_OneTimeUse: isOneTime || false,
      unit_member_price: subscriptionData?.unit_member_price || 0,
      storage_unit_price: subscriptionData?.storage_unit_price || 0,
      interactive_unit_price: subscriptionData?.interactive_unit_price || 0,
      workflow_unit_price: subscriptionData?.workflow_unit_price || 0,
    };
    // console.log("data of subscription-----", data);
    axios
      .post(`${baseUrl}subscription/create-card`, data, config)
      .then((res) => {
        // console.log(".......//./.....hjmg inpost", res);
        clearAllData();
        setAllSubsData([...allSubsData, subscriptionData]);
        getSubscriptionPlan();
        toast.success(res?.data?.message);

      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  // const getPlanDuration = () => {
  //   axios
  // .get(planDuration, config)
  //     .then((respo) => {
  //       setPlanDurationData(respo?.data);
  //       // console.log(respo?.data, "planDuration");
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //     });
  // };
  const [searchData, setSearchData] = useState("");
  const getSubscriptionPlan = (search = "") => {
    axios
      .get(`${subsDataAPI}/?search=${search}`, config)
      .then((res) => {
        // console.log(res?.data?.fetchingCards, "get all subs cdata");
        setAllSubsData(res?.data?.fetchingCards);

        dispatch(handleSubscriptionList(res?.data?.fetchingCards));
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getAllCompanyLists = () => {
    axios.get(`${baseUrl}super-admin/fetch-companies-for-subscription/`, config).then((res) => {
      // setAllCompanyData(res?.data?.data);
      // console.log('company list::',res?.data);
    });
  };
  const clearAllData = () => {
    setSubscriptionData({
      id: "",
      // name: "",
      // plan_type: "",
      // package_type: "",
      // price: "",
      // description: "",
      // planPermission: planType,
      // discount: "",
      // member_count: "",
      selectedCompany: "",
    });
    // setIsCustom(false);
    // setIsOneTime(false);
    setPersonName([]);
    set_plan_features_list([]);
    setPopUpToggle("");
  };

  useEffect(() => {
    getSubscriptionPlan();
    getAllCompanyLists();
    // getPlanDuration();
  }, []);
  useEffect(() => {
    getSubscriptionPlan(searchData);
  }, [searchData]);
 
  const handleEditSubs = (value) => {
    // console.log(value, "value");
    setSelectedHandler("Update");
    setPopUpToggle("ongoing_popup");

    setSubscriptionData({
      _id: value?._id ? value?._id : "",
      name: value?.plan_name || "",
      plan_type: value?.plan_type || "",
      plan_duration: value?.plan_duration || "",
      number_of_months: value?.number_of_months || 0,
      number_of_days: value?.number_of_days || 0,
      number_of_minutes: value?.number_of_minutes || 0,
      package_type: value?.package_type || "",
      price: value?.price || 0,
      discount: value?.discount || 0,
      description: value?.description || "",
      features: value?.plan_features || [],
      member_count: value?.member_count || 0,
      workflow_count: value?.workflow_count || 0,
      mkt_count: value?.mkt_count || 0,
      interactive_count: value?.interactive_count || 0,
      storage_count: value?.storage_count || 0,
      thunderbolt_count: value?.thunderbolt_count || 0,
      thunderbolt_unit_price: value?.thunderbolt_unit_price || 0,
      total_interactive_price: value?.total_interactive_price || 0,
      total_member_price: value?.total_member_price || 0,
      total_mkt_price: value?.total_mkt_price || 0,
      total_storage_price: value?.total_storage_price || 0,
      total_thunderbolt_price: value?.total_thunderbolt_price || 0,
      total_workflow_price: value?.total_workflow_price || 0,
      module_permission: value?.module_permission || [],
      selectedCompany: value?.company_name || "",
      is_Custom: value?.is_Custom || false,
      is_OneTimeUse: value?.is_OneTimeUse || false,
      unit_member_price: value.unit_member_price || 0,
      storage_unit_price: value?.storage_unit_price || 0,
      interactive_unit_price: value?.interactive_unit_price || 0,
      workflow_unit_price: value?.workflow_unit_price || 0,
    });

    setIsCustom(value?.is_Custom || false);
    setIsOneTime(value?.is_OneTimeUse || false);
    setPersonName(value?.module_permission || []);
    set_plan_features_list(value?.plan_features);
    // console.log(value, "setSubscriptionData./././././../././././");

  };


  const handleDelete = () => {
    setSelectedHandler("Delete");
    fetch(`${subsDataDeleteAPI}${subsItemDelete?._id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // body: item?.id,
    })
      .then((res) => {
        // console.log(res);
        if (res.status == "200") {
          toast.success("Subscription Card deleted successfully!");
          setOpen(false);
          dispatch(handleSubscriptionDelete(null));
          setAllSubsData(
            allSubsData.filter(
              (filterItem) => filterItem._id !== subsItemDelete?._id
            )
          );
        }
        return res.json();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);

        // console.log(Err);
      });
  };
  // console.log(subscriptionData, "puttttttttttttttttttttttttttttttt");
  const subscriptionUpdator = (e) => {
    e.preventDefault();
    setSelectedHandler("Update");
    let data = {
      _id: subscriptionData?._id,
      name: subscriptionData?.name || "",
      plan_type: subscriptionData?.plan_type || "",
      plan_duration: subscriptionData?.plan_duration || "",
      number_of_months: subscriptionData?.number_of_months || 0,
      number_of_days: subscriptionData?.number_of_days || 0,
      number_of_minutes: subscriptionData?.number_of_minutes || 0,
      package_type: subscriptionData?.package_type || "",
      price: subscriptionData?.price || 0,
      discount: subscriptionData?.discount || 0,
      description: subscriptionData?.description || "",
      features: plan_features_list || [],
      member_count: subscriptionData?.member_count || 0,
      workflow_count: subscriptionData?.workflow_count || 0,
      mkt_count: subscriptionData?.mkt_count || 0,
      interactive_count: subscriptionData?.interactive_count || 0,
      storage_count: subscriptionData?.storage_count || 0,
      thunderbolt_count: subscriptionData?.thunderbolt_count || 0,
      thunderbolt_unit_price: subscriptionData?.thunderbolt_unit_price || 0,
      total_interactive_price: subscriptionData?.total_interactive_price || 0,
      total_member_price: subscriptionData?.total_member_price || 0,
      total_mkt_price: subscriptionData?.total_mkt_price || 0,
      total_storage_price: subscriptionData?.total_storage_price || 0,
      total_thunderbolt_price: subscriptionData?.total_thunderbolt_price || 0,
      total_workflow_price: subscriptionData?.total_workflow_price || 0,
      module_permission: personName,
      selectedCompany: subscriptionData?.selectedCompany || "",
      is_Custom: is_Custom || false,
      is_OneTimeUse: isOneTime || false,
      unit_member_price: subscriptionData?.unit_member_price || 0,
      storage_unit_price: subscriptionData?.storage_unit_price || 0,
      interactive_unit_price: subscriptionData?.interactive_unit_price || 0,
      workflow_unit_price: subscriptionData?.workflow_unit_price || 0,
    };
    // console.log(data, "putttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt");
    axios
      .patch(`${baseUrl}subscription/update-card`, data, config)
      .then((res) => {
        // console.log(res);
        if (res?.status === 200) {
          toast?.success("Subscription updated successfully!");
          getSubscriptionPlan();
          setAllSubsData((old) => {
            return old?.map((obj) => {
              if (obj?._id == subscriptionData?._id) {
                return {
                  ...obj,
                  name: subscriptionData?.name,
                  plan_type: subscriptionData?.plan_type,
                  package_type: subscriptionData?.package_type,
                  price: subscriptionData?.price,
                  modulePermission: subscriptionData?.module_permission,
                  description: subscriptionData?.description,
                  planPermission: subscriptionData?.planPermission,
                  discount: subscriptionData?.discount,
                };
              }
              return obj;
            });
          });
          clearAllData();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast?.error("Some thing went wrong!");
      });
  };

  // ------------------------------ Multi-Select Dropdown -------------------------

  //   const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  //   const MenuProps = {
  //     PaperProps: {
  //       style: {
  //         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //         width: 250,
  //       },
  //     },
  //   };
  const a2p_modules = [
    "Members",
    "A2P",
    "A2P CSV",
    "Move Partners",
    "B&D",
    "K&B",
    "Analytics",
    "Reporting",
    "Notification",
    "Thunderbolt",
    "Media",
    "Interactive",
  ];
  const cst_modules = [
    "Members",
    "B&D",
    "K&B",
    "Analytics",
    "Reporting",
    "Notification",
    "Thunderbolt",
    "Media",
    "Interactive",
  ];
  const mkt_modules = [
    "Members",
    "B&D",
    "K&B",
    "Analytics",
    "Reporting",
    "Notification",
    "Thunderbolt",
    "Media",
    "Interactive",
  ];




  const handleMultiSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    // if (subscriptionData.plan_type) {
    setModulePermission(cst_modules);
    // }
    //  else if (subscriptionData.plan_type === "A2P") {
    //   setModulePermission(a2p_modules);
    // } else if (subscriptionData?.plan_type === "MKT") {
    //   setModulePermission(mkt_modules);
    // } else {
    //   setModulePermission([]);
    // }
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(handleSubscriptionDelete(null));
  };

  useEffect(() => {
    dispatch(handleSubscriptionList(allSubsData));
  }, [allSubsData]);
  // console.log(isCustom, subscriptionData, "isCustom");
  // console.log(plan_features_list, "jhgsdjgsdjgsdjgjsdgjhsdgjhsdg");
  const [featureOpen, setOpenFeature] = useState({
    open: false,
    type: "",
  });
  const [planDetailsData, setPlanDetailsData] = useState(null);
  const [featureObject, setFetureObject] = useState({
    featureIndex: "",
    featureName: "",
    // featureDescription: "",
  });
  // const [featureName,setFeatureName] =useState("");
  const handleOpenView = (planDetails) => {
    // console.log("plan details : ", planDetails);
    setPlanDetailsData(planDetails);
    setOpenFeature({
      open: true,
      type: "planDetails",
    });
  };

  const handleEditFeature = (feature, index2) => {
    setFetureObject({
      featureIndex: index2,
      featureName: feature?.feature_name || "",
      // featureDescription: feature?.feature_description || "",
    });
    setOpenFeature({
      open: true,
      type: "feature",
    });
  };
  // const handleFeatureChange = (e) => {
  //   const { value } = e.target;
  //   setFetureObject({ ...featureObject, [name]: value });
  // };
  const handleFeatureChange = (e) => {
    const { value } = e.target;
        setFetureObject({ ...featureObject,  value });
  };
  const updateFeature = () => {
    let data = {
      feature_name: featureObject?.featureName,
      // feature_description: featureObject?.featureDescription,
    };
    // console.log('plannnnnn in update', plan_features_list);
    let updated_arr = plan_features_list.map((i, index) => {
      if (index === featureObject?.featureIndex) {
        return data?.feature_name;
      }
      return i;
    });
    set_plan_features_list(updated_arr);
    setOpenFeature({
      open: false,
      type: "",
    });
  };
  useEffect(() => {
    const planDurationName = planDurationData?.filter((item) => {
      if (item?._id === subscriptionData?.plan_duration) {
        return item;
      }
    });
    setSelectedPlanDuration(planDurationName[0]);
  }, [subscriptionData?.plan_duration]);
  // console.log("plan bhai : ", planDetailsData);
  return (
    <div className="subs-main">
      <Toaster position="top-center" reverseOrder={false} />
      <div
        style={{
          position: "absolute",
          zIndex: "9999",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "#00000049",
          display: featureOpen?.open ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: "0 10px",
                  fontSize: "1rem",
                  color: "#595959",
                  fontWeight: "600",
                }}
              >
                {featureOpen?.type === "feature"
                  ? "Update Feature"
                  : "Plan Details"}
              </div>
              <div>
                {" "}
                <RxCross2
                  onClick={() =>
                    setOpenFeature({
                      open: false,
                      type: "",
                    })
                  }
                  className="cross-subs"
                />
              </div>
            </div>
            {featureOpen?.type === "feature" ? (
              <>
                <input
                  className="plan-input-field"
                  type="text"
                  placeholder="feature name"
                  name="featureName"
                  value={featureObject?.featureName}
                  onChange={(e) => handleFeatureChange(e)}
                />
                {/* <textarea
                  className="plan-input-field"
                  placeholder="feature description"
                  name="featureDescription"
                  value={featureObject?.featureDescription}
                  onChange={(e) => handleFeatureChange(e)}
                  style={{
                    maxWidth: "250px",
                  }}
                /> */}
                <button
                  style={{
                    border: "none",
                    borderRadius: "5px",
                    backgroundColor: "#1877F2",
                    color: "#FFFFFF",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                  onClick={updateFeature}
                >
                  Update
                </button>
              </>
            ) : (
              <>
                <div
                  style={{
                    fontSize: "0.8rem",
                    color: "#393939",
                  }}
                  className="task-details-main"
                >
                  <div className="flex-class">
                    <span>Custom</span>
                    <span>:</span>
                    <span
                      style={{
                        fontWeight: "600",
                        color: "#1877F2",
                      }}
                    >
                      {planDetailsData?.is_Custom ? "YES" : "NO"}
                    </span>
                  </div>
                  <div className="flex-class">
                    <span>One Time Use</span>
                    <span>:</span>
                    <span
                      style={{
                        fontWeight: "600",
                        color: "#1877F2",
                      }}
                    >
                      {planDetailsData?.is_OneTimeUse ? "YES" : "NO"}
                    </span>
                  </div>
                  <div className="flex-class">
                    <span>Subscription Name</span>
                    <span>:</span>
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      {planDetailsData?.plan_name}
                    </span>
                  </div>
                  <div className="flex-class">
                    <span>Subscription Type</span>
                    <span>:</span>
                    <span>{planDetailsData?.package_type}</span>
                  </div>
                  <div className="flex-class">
                    <span>Allowed Member</span>
                    <span>:</span>
                    <span>{planDetailsData?.member_count}</span>
                  </div>
                  <div className="flex-class">
                    <span>Plan Duration</span>
                    <span>:</span>
                    <span>{planDetailsData?.plan_duration} days</span>
                  </div>

                  <div className="flex-class">
                    <span>Price</span>
                    <span>:</span>
                    <span>
                      &#8377; {(+planDetailsData?.plan_price)?.toFixed(2)}/Month
                    </span>
                  </div>
                  <div className="flex-class">
                    <span>Chargeable Amount</span>
                    <span>:</span>
                    <span>
                      &#8377;{" "}
                      {planDetailsData?.plan_price > 0
                        ? (
                          +planDetailsData.number_of_months *
                          +planDetailsData?.plan_price
                        )?.toFixed(2)
                        : 0}{" "}
                      + GST
                    </span>
                  </div>
                  <div className="flex-class-2">
                    <span className="heading-permission-feature">
                      Permissions
                    </span>
                    <span className="heading-permission-feature">Features</span>
                    <div>
                      {planDetailsData?.module_permission &&
                        planDetailsData?.module_permission?.map((item, i) => {
                          return (
                            <div>
                              <span>
                                {i + 1}. {item}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                    <div>
                      {planDetailsData?.plan_features &&
                        planDetailsData.plan_features.map((item, i) => {
                          // const feature = JSON.parse(item); // Parse each feature item if it's stored as a JSON string
                          // console.log('feater',item);
                          return (
                            <div key={i}>
                              <span>
                                {/* {i + 1}. {feature.feature_name}:{" "}
                                {feature.feature_description} */}
                                {i + 1}. {item}:{" "}
                                {/* {item.feature_description} */}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you really want to delete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will delete your chosen subscription plan.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>Cancel</MuiButton>
            <MuiButton onClick={handleDelete} autoFocus>
              Delete
            </MuiButton>
          </DialogActions>
        </Dialog>
      </div>
      <div className={popupToggle + " " + "ongoing_popup_hide"} disabled>
        {/* popup code open */}
        <form className="popup-main">
          <div className="popup-card">
            <p className="create-subs-title">{selectedHandler} Subscription</p>
            <div className="dual-item-wrapper">
              <div
                style={{
                  border: "1px solid #c9c9c9",
                  width: "230px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <FormControlLabel
                  value="end"
                  control={<Checkbox />}
                  label="Is Custom"
                  labelPlacement="end"
                  onChange={(e) => setIsCustom(e?.target?.checked)}
                  margin="normal"
                  size="small"
                  autoFocus
                  fullWidth
                  checked={is_Custom}
                />
              </div>
              <div
                style={{
                  display: `${is_Custom ? "block" : "none"}`,
                }}
                className="fields-wrapper"
              >
                <FormControl sx={{ m: 1, width: 200 }} size="small">
                  <InputLabel id="demo-multiple-checkbox-label">
                    Select Company
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    defaultValue={subscriptionData?.selectedCompany}
                    value={subscriptionData?.selectedCompany}
                    name="selectedCompany"
                    onChange={(e) => handleChangeVal(e)}
                    input={<OutlinedInput label={`Select Company`} />}
                  >
                    {allCompanyData?.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item?._id}>
                          {item?.company_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="discount"
                  label="Discount (in %)"
                  name={"discount"}
                  autoFocus
                  value={subscriptionData?.discount}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
            </div>
            <div className="dual-item-wrapper">
              <div className="fields-wrapper">
                <div
                  style={{
                    border: "1px solid #c9c9c9",
                    width: "230px",
                    textAlign: "center",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <FormControlLabel
                    value="end"
                    control={<Checkbox />}
                    label="One Time"
                    labelPlacement="end"
                    onChange={(e) => setIsOneTime(e?.target?.checked)}
                    margin="normal"
                    size="small"
                    autoFocus
                    fullWidth
                    checked={isOneTime}
                  />
                </div>
              </div>
              <div className="fields-wrapper">
                <FormControl sx={{ m: 1, width: 200 }} size="small">
                  <InputLabel id="plan-duration-label">
                    Plan Duration
                  </InputLabel>
                  <Select
                    labelId="plan-duration-label"
                    id="plan-duration"
                    value={subscriptionData.plan_duration}
                    name="plan_duration"
                    onChange={handleChangeVal}
                    input={<OutlinedInput label="Plan Duration" />}
                  >
                    {planDurations.map((duration) => (
                      <MenuItem key={duration} value={duration}>
                        {duration}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="fields-wrapper">
                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                  <InputLabel id="package-type">Package Type</InputLabel>
                  <Select
                    labelId="package-type"
                    id="package-type"
                    value={subscriptionData?.package_type}
                    onChange={(e) => handleChangeVal(e)}
                    name="package_type"
                    input={<OutlinedInput label={`Package Type`} />}
                  >
                    {is_Custom === false ? (
                      ["Free", "Basic", "Advance", "Premium"].map(
                        (arrayItem) => {
                          return (
                            <MenuItem value={arrayItem}>{arrayItem}</MenuItem>
                          );
                        }
                      )
                    ) : (
                      <MenuItem value={"Premium"}>Premium</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="dual-item-wrapper">
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Subscription Name"
                  name={"name"}
                  autoFocus
                  value={subscriptionData?.name}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
              {
                // console.log('personName', personName)
              }
              <div className="fields-wrapper">
                <FormControl sx={{ m: 1, width: 200 }} size="small">
                  <InputLabel id="package-type">Module Permission</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={personName}
                    onChange={handleMultiSelectChange}
                    input={
                      <OutlinedInput
                        label={`${subscriptionData?.plan_type} permission`}
                      />
                    }
                    renderValue={(selected) => selected.join(", ")}
                  // MenuProps={MenuProps}
                  >
                    {modulePermission?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="dual-item-wrapper">
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="member_count"
                  label="Max Number of Member"
                  name={"member_count"}
                  autoFocus
                  value={subscriptionData.member_count}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="unit_member_price"
                  label="Price Per Member"
                  name={"unit_member_price"}
                  autoFocus
                  value={subscriptionData?.unit_member_price || 0}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>

              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="total_member_price"
                  label="Member Price"
                  name={"total_member_price"}
                  autoFocus
                  value={subscriptionData?.total_member_price || 0}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
            </div>
            {/* storage */}
            <div className="dual-item-wrapper">
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="storage_count"
                  label="Max Number of Storage"
                  name={"storage_count"}
                  autoFocus
                  value={subscriptionData.storage_count}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="storage_unit_price"
                  label="Price Per Storage"
                  name={"storage_unit_price"}
                  autoFocus
                  value={subscriptionData?.storage_unit_price}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>

              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="total_storage_price"
                  label="Storage Price"
                  name={"total_storage_price"}
                  autoFocus
                  value={subscriptionData?.total_storage_price}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
            </div>

            {/* thunderbolt */}
            <div className="dual-item-wrapper">
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="thunderbolt_count"
                  label="Max Number of Thunderbolt"
                  name={"thunderbolt_count"}
                  autoFocus
                  value={subscriptionData.thunderbolt_count}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="thunderbolt_unit_price"
                  label="Price Per Thunderbolt"
                  name={"thunderbolt_unit_price"}
                  autoFocus
                  value={subscriptionData?.thunderbolt_unit_price}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>

              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="total_thunderbolt_price"
                  label="Thunderbolt Price"
                  name={"total_thunderbolt_price"}
                  autoFocus
                  value={subscriptionData?.total_thunderbolt_price}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
            </div>

            {/* interactive */}
            <div className="dual-item-wrapper">
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="interactive_count"
                  label="Max Number of Interactive"
                  name={"interactive_count"}
                  autoFocus
                  value={subscriptionData.interactive_count}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="interactive_unit_price"
                  label="Price Per Interactive"
                  name={"interactive_unit_price"}
                  autoFocus
                  value={subscriptionData?.interactive_unit_price}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>

              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="total_interactive_price"
                  label="Total Interactive Price"
                  name={"total_interactive_price"}
                  autoFocus
                  value={subscriptionData?.total_interactive_price}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
            </div>

            {/* workflow */}
            <div className="dual-item-wrapper">
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="workflow_count"
                  label="Max Number of Workflow"
                  name={"workflow_count"}
                  autoFocus
                  value={subscriptionData.workflow_count}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="workflow_unit_price"
                  label="Price Per Workflow"
                  name={"workflow_unit_price"}
                  autoFocus
                  value={subscriptionData?.workflow_unit_price}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>

              <div className="fields-wrapper">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="total_workflow_price"
                  label="Total Workflow Price"
                  name={"total_workflow_price"}
                  autoFocus
                  value={subscriptionData?.total_workflow_price}
                  onChange={(e) => handleChangeVal(e)}
                  size="small"
                />
              </div>
            </div>

            {subscriptionData?.total_member_price > 0 && (
              <div
                style={{
                  backgroundColor: "#1877F219",
                  width: "100%",
                  marginBottom: "10px",
                  padding: "5px 10px",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#1877F2",
                  borderRadius: "5px",
                }}
              >
                Your are creating{" "}
                <span style={{ fontWeight: "600" }}>
                  {selectedPlanDuration?.name} plan
                </span>
                , which have chargeable amount will be{" "}
                <span style={{ fontWeight: "600" }}>
                  &#8377;{" "}
                  {(
                    (+subscriptionData?.price / 31) *
                    +selectedPlanDuration?.days
                  ).toFixed(2)}{" "}
                  + GST
                </span>
              </div>
            )}
            <div className="dual-item-wrapper  features-box">
              <div
                style={{
                  gap: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="fields-wrapper">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="feature_name"
                    label="Add Features"
                    name={"feature_name"}
                    autoFocus
                    value={addingFeature.feature_name}
                    onChange={(e) => featureHandler(e)}
                    size="small"
                  />
                </div>

                {/* <div className="fields-wrapper">
                  <TextField
                    label="Feature Description"
                    placeholder="Feature Description"
                    required
                    multiline
                    rows={1}
                    // maxRows={10}
                    name="feature_description"
                    onChange={featureHandler}
                    value={
                      addingFeature?.feature_description !== null
                        ? addingFeature?.feature_description
                        : ""
                    }
                    maxLength={"250"}
                    style={{ width: "100%" }}
                    size="small"
                  />
                </div> */}
                <Button
                  style={{ margin: "20px" }}
                  onClick={() => handleFeaturesList(addingFeature)}
                  text="Add"
                />
              </div>
            </div>
            <div className="dual-item-wrapper">
              <div className="fields-wrapper">
                <TextField
                  label="Plan Description"
                  placeholder="Plan Description"
                  required
                  multiline
                  rows={2}
                  // maxRows={10}
                  name="description"
                  onChange={handleChangeVal}
                  value={subscriptionData?.description}
                  maxLength={"250"}
                  style={{ width: "100%" }}
                  size="small"
                />
              </div>
            </div>
            <div className="dual-item-wrapper features-row">
              <div className="fields-wrapper">
                <div className="feature-items">
                  <p className="feature-column">Selected Features</p>
                  <div className="features-list-bottom">
                    {plan_features_list.length >= 1 ? (
                      plan_features_list?.map((feature, i) => (
                        <>
                          <HiStar className="start-icon" />
                          <span style={{ fontSize: "11px" }}>
                            {feature}
                          </span>
                          <div>
                            <EditIcon
                              onClick={() => handleEditFeature(feature, i)}
                              className="features-pencil"
                            />
                          </div>
                        </>
                      ))
                    ) : (
                      <span className="not-selected-p">
                        Select any subscription type
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="fields-wrapper"
                style={{ alignItems: "flex-start" }}
              >
                <div className="feature-items">
                  <p className="feature-column">Selected Permission</p>
                  {personName?.length >= 1 ? (
                    personName?.map((feature) => (
                      <span
                        style={{
                          fontSize: "11px",
                        }}
                      >
                        <HiStar className="start-icon" />
                        {feature}
                      </span>
                    ))
                  ) : (
                    <span className="not-selected-p">
                      Select any subscription type
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="btn-wrapper">
              {selectedHandler === "Create" && (
                <Button
                  text={"Save"}
                  className="saveBtn"
                  onClick={(e) => submitSubscription(e)}
                />
              )}
              {selectedHandler === "Update" && (
                <Button
                  text={"Update"}
                  className="saveBtn"
                  onClick={(e) => subscriptionUpdator(e)}
                />
              )}
              <div className="cancelBtn" onClick={clearAllData}>
                Cancel
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* popup code close */}

      {/* ###############SUBSCRIPTION TABLE#################### */}

      <Button
        text={"New Subscription"}
        className="new-subs-btn"
        startIcon={<RiAddLine />}
        onClick={() => {
          handleChange();
        }}
      />
      <div className="subs-inner">
        <div className="ttl_flex">
          <div className="tag-title">Subscription</div>
          <InputField
            onChange={(e) => setSearchData(e?.target?.value)}
            placeholder={"search..."}
            endIcon={<AiOutlineSearch />}
          />
        </div>
        <div
          style={{
            maxHeight: "71vh",
            overflowY: "auto",
          }}
          className="subs-table"
        >
          <table>
            <thead
              style={{
                position: "sticky",
                top: 0,
              }}
            >
              <tr>
                <th>#</th>
                <th>Subscription Name</th>
                <th>Subscription Type & Duration</th>
                <th>Subscription Price</th>
                <th>Subscription Features</th>
                <th>Discount</th>
                <th>Module Permission</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allSubsData &&
                allSubsData?.map((subsItem, index) => {
                  // console.log(subsItem, "jhgsjhgjhsdgjhsdfgjh");
                  return (
                    <tr
                      style={{
                        // backgroundColor: subsItem?.is_Custom && "#1877f229",
                        color: subsItem?.is_Custom && "#1877F2",
                      }}
                      key={index}
                    >
                      <td
                        style={{
                          // backgroundColor: subsItem?.is_Custom && "#1877f229",
                          color: subsItem?.is_Custom && "#1877F2",
                        }}
                        className="subs-title"
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          // backgroundColor: subsItem?.is_Custom && "#1877f229",
                          color: subsItem?.is_Custom && "#1877F2",
                        }}
                      >
                        {subsItem?.plan_name}
                      </td>
                      <td
                        style={{
                          // backgroundColor: subsItem?.is_Custom && "#1877f229",
                          color: subsItem?.is_Custom && "#1877F2",
                        }}
                      >
                        {subsItem?.package_type} - {subsItem?.plan_duration}
                      </td>
                      <td
                        style={{
                          // backgroundColor: subsItem?.is_Custom && "#1877f229",
                          color: subsItem?.is_Custom && "#1877F2",
                        }}
                      >
                        &#8377;{" "}
                        {subsItem?.plan_price
                          ? (+subsItem?.plan_price)?.toFixed(2)
                          : 0.0}{" "}
                        - {subsItem?.member_count} members
                      </td>
                      <td
                        style={{
                          // backgroundColor: subsItem?.is_Custom && "#1877f229",
                          color: subsItem?.is_Custom && "#1877F2",
                        }}
                      >
                        {subsItem?.plan_features !== undefined && (subsItem?.plan_features?.length > 0) &&
                          subsItem?.plan_features.map((f1, index) => {
                            return (
                              <p
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  padding: "2px 0",
                                  fontSize: "11px",
                                }}
                              >
                                <span>{`${index + 1}.`}</span>
                                <span
                                  style={{
                                    color: subsItem?.is_Custom
                                      ? "#1877F2"
                                      : "#595959",
                                  }}
                                >
                                  {f1}
                                </span>
                              </p>
                            );
                          })}
                      </td>
                      <td
                        style={{
                          // backgroundColor: subsItem?.is_Custom && "#1877f229",
                          color: subsItem?.is_Custom && "#1877F2",
                        }}
                      >
                        {subsItem?.discount ? subsItem?.discount : 0} %
                      </td>
                      <td
                        style={{
                          // backgroundColor: subsItem?.is_Custom && "#1877f229",
                          color: subsItem?.is_Custom && "#1877F2",
                        }}
                      >
                        {subsItem?.module_permission !== undefined &&
                          subsItem?.module_permission?.map((mp, index) => {
                            return (
                              <p
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  padding: "2px 0",
                                  fontSize: "11px",
                                }}
                              >
                                <span>{index + 1}.</span>
                                <span
                                  style={{
                                    color: subsItem?.is_Custom
                                      ? "#1877F2"
                                      : "#595959",
                                  }}
                                >
                                  {mp}
                                </span>
                              </p>
                            );
                          })}
                      </td>
                      <td>
                        <div className="action_btns">
                          <HiOutlineEye
                            className="action_icon_view"
                            onClick={() => handleOpenView(subsItem)}
                          />
                          <HiOutlinePencil
                            className="action_icon_edit"
                            onClick={() => handleEditSubs(subsItem)}
                          />
                          <MdDeleteOutline
                            className="action_icon_delete"
                            onClick={() => {
                              handleClickOpen();
                              dispatch(handleSubscriptionDelete(subsItem));
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {allSubsData === 0 && (
            <div
              style={{
                textAlign: "center",
                padding: "40px",
                backgroundColor: "#c9c9c9",
                fontSize: "30px",
                fontWeight: "600",
                color: "#595959",
              }}
            >
              No Data Available!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
