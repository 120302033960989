import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "./Home.css";
import ProfileCard from "../Outlet/Setting/ProfileCard/ProfileCard";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../ReduxSlices/MainSlice";
import { baseUrl } from "../../BaseUrl";
import toast, { Toaster } from "react-hot-toast";
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var token, userType;
  if (localStorage.getItem("saToken2")) token = localStorage.getItem("saToken2");
  userType = localStorage.getItem("userType");
  const [profileData, setProfileDate] = useState();
  const profileAPI = () => {
      axios.get(`${baseUrl}super-admin/profile`, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      }).then((respo) => {
          if (respo.status === 200) {
              setProfileDate(respo.data)
              dispatch(updateUserData(respo.data.superAdmin))
          }
      }).catch((err) => {
        if (err?.response.status === 401) {
          navigate("/")
          localStorage.removeItem("saToken2");
          toast.error("Session Expired, Please log in again!")
        }
        // console.log(err)
      })
  }
  useEffect(() => {
    // console.log(token);
    if (!token && userType !== "true") {
      navigate("/");
    }else
    profileAPI()
  }, []);
  return (
    <div className="home_page">
    <Toaster position="top-center" reverseOrder={false}/>
      <Sidebar />
      <div className="nav_outlet">
        <Navbar profileData={profileData} />
        <Outlet />
      </div>
      <div className="profile_card_none" id="profileCard">
        <span id="profileContainer" className="card_none">
          <ProfileCard profileData={profileData}/>
        </span>
      </div>
    </div>
  );
};

export default Home;
