import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ProfileCard from './ProfileCard/ProfileCard'
import ResetPassword from './ResetPassword'
import './Setting.css'
import favPng from "../../../Assets/logo-fav.png"
import TemplateUpdate from './TemplateUpdate'
import TemplateAutomation from './TemplateAutomation'

const Setting = () => {
  const userName = useSelector((store) => store?.main?.userData?.name);
  const permission = localStorage.getItem("permissions");
  const [components, setComponents] = useState(<ProfileCard />)
  const [settingC, setSettingC] = useState(1)
  const settingComponents = (setting) => {
    setSettingC(setting)
    if (setting === 1) {
      setComponents(<ProfileCard />)
    } else if (setting === 2) {
      setComponents(<ResetPassword />)
    } else if (setting === 3) {
      setComponents(<TemplateUpdate />)
    } else if (setting === 4) {
      setComponents(<TemplateAutomation />)
    } else {
      setComponents(null)
    }
  }
  return (
    <div className='setting'>
      <div className='settingContainer'>
        <div className='settingBannerContainer'>
          <div className='setHeading'>Setting</div>
        </div>
        <div className='accountBox'>
          <div className='accountContainer'>
            <div className='settingMenuSide'>
              <div className='imenuSide'>
                <div className='settingImage'>
                  <img width={'100%'} src={favPng} alt="Albert Einstein" />
                </div>
                <div className='userName'>{userName}</div>
              </div>
              <div className='smenusContainer'>
                <div className={`menusSetting ${settingC === 1 && 'activeMenuSetting'}`} onClick={() => settingComponents(1)}>Profile Setting</div>
                <div className={`menusSetting ${settingC === 2 && 'activeMenuSetting'}`} onClick={() => settingComponents(2)}>Reset Password</div>
                {permission === "superadmin" &&
                  <div className={`menusSetting ${settingC === 3 && 'activeMenuSetting'}`} onClick={() => settingComponents(3)}>Template</div>
                }
                {permission==="superadmin" &&
                <div className={`menusSetting ${settingC === 4 && 'activeMenuSetting'}`} onClick={() => settingComponents(4)}>Select Template Automation</div>
                }
                {/* <div className={`menusSetting ${settingC === 3 && 'activeMenuSetting'}`} onClick={() => settingComponents(3)}>Message Amount Setting</div> */}
                {/* <div className={`menusSetting ${settingC === 4 && 'activeMenuSetting'}`} onClick={() => settingComponents(4)}>Notification</div> */}
              </div>
            </div>

            {components}

          </div>
        </div>
      </div>

    </div>
  )
}

export default Setting
