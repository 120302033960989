import React, { useEffect, useState } from "react";
import "./SalesInvoice.css";
import { RiAddLine } from "react-icons/ri";
import InputField from "../../../../reusableComponent/InputField";
import Button from "../../../../reusableComponent/Button";
import InvoiceCard from "../../../../reusableComponent/invoiceCard";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlinePencil } from "react-icons/hi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineClose } from "react-icons/md";
import axios from "axios";
import { baseUrl } from "../../../../../BaseUrl";
// const baseUrl = process.env.REACT_APP_API_URL
const SalesInvoice = () => {
  const navigate = useNavigate();
  const [paymentPopup, setPaymentPopup] = useState("none");
  const [payData, setPayData] = useState({
    payTransaction: "",
    payMode: "",
    payDate: "",
    invNumber: "INV-001",
    payAmount: "10982.91",
  });
  const [createdInvoiceData, setCreatedInvoiceData] = useState([]);
  const createBtn = () => {
    navigate("/create-invoice");
  };
  const acceptPaymentPop = (item) => {
    setPaymentPopup("block");
  };
  const handleChangeVal = (e) => {
    const { name, value } = e.target;
    setPayData({
      ...payData,
      [name]: value,
    });
  };
  const handlePayForm = (e) => {
    e.preventDefault();
    // console.log(payData);
  };

  const getinvoicedataAPI = `${baseUrl}Invoice_for_company/`;

  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const companyInvoiceData = (page) => {
    axios
      .get(`${getinvoicedataAPI}?page=${page}`, config)
      .then((res) => {
        setCreatedInvoiceData(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    companyInvoiceData(1);
  }, []);
// console.log(createdInvoiceData, "invData");
  return (
    <div className="customer-invoice">
      <div className="buttons-wrapper">
        <div className="section-title">Invoices</div>
        {/* <Button
          text={"Create New Invoice"}
          startIcon={<RiAddLine />}
          onClick={createBtn}
        /> */}
      </div>
      <div className="invoice-inner">
        {/* <div className="invoice-card-wrapper">
          <InvoiceCard
            CardHeading={"Outstanding Invoices"}
            CardPrice={"$2,200.00"}
            color={"yellowColor"}
          />
          <InvoiceCard
            CardHeading={"Past Due Invoices"}
            CardPrice={"$0.00"}
            color={"purpleColor"}
          />
          <InvoiceCard
            CardHeading={"Paid Invoices"}
            CardPrice={"$10,374.00"}
            color={"blueColor"}
          />
        </div> */}
        <div className="invoice-table">
          <InputField
            placeholder="Search..."
            endIcon={<AiOutlineSearch />}
            className="searchField"
          />
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Invoice Number</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
                <th>Accept Payment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {createdInvoiceData &&
                createdInvoiceData?.results?.map((item, index) => {
                  let date = new Date(item?.create_at);
                  let convertedDate = date.toLocaleDateString();
                  // let convertedTime = date.toLocaleTimeString();
                  // console.log(convertedDate, convertedTime)
                  return (
                    <tr key={index + 1}>
                      <td>{index + ((createdInvoiceData?.page * 5) - 4)}</td>
                      <td className="invoice-title-section">
                        <Link className="invoice-title">
                          {item?.invoice_number}
                        </Link>
                      </td>
                      <td>&#8377; {item?.amount}</td>
                      <td>{convertedDate}</td>
                      <td className="payBtn">
                        <Button
                          className={"simpleBtn warnBtn"}
                          text={item?.status}
                        />
                      </td>
                      <td>
                        <button
                          className="accept_pmnt"
                          onClick={() => acceptPaymentPop(item)}
                        >
                          Accept Payment
                        </button>
                      </td>
                      <td className="action-btns">
                        <Link to={"/view-invoice/" + "sales"} onClick={() => {
                          sessionStorage.setItem('iv_id', item?.id)
                        }}>
                          <AiOutlineEye
                            className="icons"
                          />
                        </Link>
                        {/* <Link to="/create-invoice">
                          <HiOutlinePencil
                            className="icons"
                            onClick={() => navigate("/view-subscription")}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {
            createdInvoiceData?.results?.length === 0 &&
            <div style={{
              textAlign: "center",
              padding: "40px",
              backgroundColor: "#c9c9c9",
              fontSize: "30px",
              fontWeight: "600",
              color: "#595959"
            }}>No Data Available!</div>
          }
          {
            createdInvoiceData?.results?.length > 0 &&
            <div className="pegination">
              <div className='peginationinner'>
                <MdKeyboardArrowLeft className='arrowPreNext' onClick={() => {
                  createdInvoiceData?.links?.previous != null && companyInvoiceData(createdInvoiceData?.links?.previous)
                }} />
                <span>{createdInvoiceData?.page} out of {Math.ceil((createdInvoiceData?.total) / (createdInvoiceData?.page_size))}</span>
                <MdKeyboardArrowRight className='arrowPreNext' onClick={() => {
                  createdInvoiceData?.links?.next != null && companyInvoiceData(createdInvoiceData?.links?.next)
                }} />
              </div>
            </div>

          }
        </div>
      </div>
      {/* Accept Payment Popup Form....................................... */}
      <div className="payment_popup" style={{ display: paymentPopup }}>
        <div className="paymentInner">
          <div className="company_addressDetails">
            <div className="closeBtn" onClick={() => setPaymentPopup("none")}>
              <MdOutlineClose />
            </div>
            <div className="pay_head">
              <h3>Accept Payment</h3>
            </div>
            <div className="detailSection">
              <div className="paymentFor">
                <h4>Payment For</h4>
                <div className="compName">Company Name Showing Here</div>
                <div className="compAddr">
                  <span>Address one</span>
                  <span>Address two</span>
                  <span>Address three</span>
                </div>
                <div className="gstNumber">
                  <span>GST :</span>
                  <span>09ADF78561F9HJ</span>
                </div>
              </div>
              <hr></hr>
              <form className="payDetails">
                <div className="lableSide">
                  <div className="paylabel">Invoice Number</div>
                  <div className="payInput">INV-001</div>
                </div>
                <div className="lableSide">
                  <div className="paylabel">Amount</div>
                  <div className="payInput">&#8377; 10982.91</div>
                </div>
                <div className="lableSide">
                  <div className="paylabel">Transaction Id</div>
                  <div className="payInput">
                    <input
                      type={"text"}
                      className="inputPay"
                      name="payTransaction"
                      value={payData?.payTransaction}
                      onChange={(e) => handleChangeVal(e)}
                      placeholder="Ex. fgf67645489897boi89"
                    />
                  </div>
                </div>
                <div className="lableSide">
                  <div className="paylabel">Payment Mode</div>
                  <div className="payInput">
                    <select
                      className="inputPay"
                      name="payMode"
                      value={payData?.payMode}
                      onChange={(e) => handleChangeVal(e)}
                    >
                      <option value={"NB"}>Net Banking</option>
                      <option value={"CC"}>Credit Card</option>
                      <option value={"DC"}>Debit Card</option>
                      <option value={"UPI"}>UPI</option>
                    </select>
                  </div>
                </div>
                <div className="lableSide">
                  <div className="paylabel">Credited Date</div>
                  <div className="payInput">
                    <input
                      type={"date"}
                      className="inputPay"
                      name="payDate"
                      value={payData?.payDate}
                      onChange={(e) => handleChangeVal(e)}
                      placeholder="Ex. fgf67645489897boi89"
                    />
                  </div>
                </div>
                <div className="payBtns">
                  <button onClick={handlePayForm}>Accept Payment</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesInvoice;
