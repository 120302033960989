import React from 'react'
import { FcSerialTasks } from 'react-icons/fc'
import "../Outlet/Dashboard/Dashboard.css"

const DashboardCard = ({
    dashCounts,
    navigate,
    counterClassName,
    titleClassName,
    titleText,
    iconClassName,
    dashIcon,
}) => {
    return (
        <div className='card_main' onClick={() => navigate} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
                <div className='left_card'>
                    <div className={counterClassName || 'counter'}>{dashCounts}</div>
                    <div className={titleClassName || 'title_text'}>{titleText || 'Title here'}</div>
                </div>
                <div className='right_card'>
                    <span className='icon_circle'>
                        {dashIcon || <FcSerialTasks className={iconClassName}/>}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default DashboardCard