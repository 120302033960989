import "../../../Subscription/subscription.css";
import InputField from "../../../../reusableComponent/InputField";
import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineEye } from "react-icons/hi";
import { useEffect, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "axios";
import { baseUrl } from "../../../../../BaseUrl";
// const baseUrl = process.env.REACT_APP_API_URL;
const SubscriptionCustomer = () => {
  const s_id = sessionStorage.getItem("s_id");
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  // const [popupToggle, setPopUpToggle] = useState("");
  // const handleChange = (e) => {
  //     setPopUpToggle("ongoing_popup");
  // };
  // const navigate = useNavigate();
  const [allSubsData, setAllSubsData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [activeView, setActiveView] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const getPlans = () => {
    axios.get(`${baseUrl}super-admin/fetch-purchased-plan/${s_id}/`, config).then((respo) => {
      if (respo.status === 200) {
        // console.log(respo)
        setAllSubsData(respo.data.data);
        setFilteredData(respo.data.data);
      }
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  useEffect(() => {
    var fData = allSubsData?.filter((item) => {
      return (
        item?.plan_name
          ?.toLocaleLowerCase()
          ?.match(searchData?.toLocaleLowerCase()) ||
        item?.plan_duration_details?.plan_package_type
          ?.toLocaleLowerCase()
          ?.match(searchData?.toLocaleLowerCase())
      );
    });
    setFilteredData(fData);
  }, [searchData]);
  const viewSubscription = (data) => {
    setSubscriptionData(data);
    setActiveView(true);
    
  };
  const deActivate = () => {
    setActiveView(false);
    setSubscriptionData();
  };
  // console.log(subscriptionData);
  let startViewDate = new Date(subscriptionData?.plan_startdate)?.toDateString();
  let expiryViewDate = new Date(subscriptionData?.plan_enddate)?.toDateString();
  let feturesList = subscriptionData?.plan_type_details?.plan_features
    ? subscriptionData?.plan_type_details?.plan_features
    : [];
  let permissionList = subscriptionData?.plan_type_details?.module_permission
    ? subscriptionData?.plan_type_details?.module_permission
    : [];
  return (
    <div className="subs-main">
      {/* Subscription View in pop-up */}
      <div
        style={activeView ? { display: "block" } : { display: "none" }}
        className="notes-overlay"
      >
        <div className="notes-overlay-inner">
          <div style={{
            display: "block",
          }} className="notes-form-container">
            <div className="title-note">
              <div style={{ color: "#1877F2" }}>Subscription Details</div>
              <div onClick={deActivate} className="close-notes">
                <CloseOutlinedIcon />
              </div>
            </div>
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto 10px auto",
                      gap: "10px",
                      fontSize: "12px",
                      color: "#39339",
                    }}
                  >
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Name
                    </span>
                    <span>:</span>
                    <span>{subscriptionData?.plan_name}</span>
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Plan Type
                    </span>
                    <span>:</span>
                    <span
                      style={{
                        color: "#1877F2",
                      }}
                    >
                      {subscriptionData?.plan_type_details?.is_Custom
                        ? "Custom"
                        : "Regular"}
                    </span>
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Price
                    </span>
                    <span>:</span>
                    <span>
                      &#8377;{" "}
                      {(+subscriptionData?.plan_type_details?.plan_price)?.toFixed(2)}
                    </span>
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Max Member
                    </span>
                    <span>:</span>
                    <span>{subscriptionData?.plan_type_details?.member_count}</span>
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Unit Price
                    </span>
                    <span>:</span>
                    <span>
                      &#8377;{" "}
                      {subscriptionData?.plan_type_details?.unit_member_price
                        ? (+subscriptionData?.plan_type_details?.unit_member_price)?.toFixed(2)
                        : (
                            +subscriptionData?.plan_type_details?.plan_price /
                            +subscriptionData?.plan_type_details?.member_count
                          )?.toFixed(2)}
                    </span>
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Subscription/ Renewal Date
                    </span>
                    <span>:</span>
                    <span>{startViewDate}</span>
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Expiry Date
                    </span>
                    <span>:</span>
                    <span>{expiryViewDate}</span>
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Plan Duration
                    </span>
                    <span>:</span>
                    <span>{subscriptionData?.plan_duration}</span>
                    <span
                      style={{
                        color: "#595959",
                      }}
                    >
                      Status
                    </span>
                    <span>:</span>
                    <span
                      style={{
                        color: `${subscriptionData?.is_expired ? "red" : "green"}`,
                      }}
                    >
                      {subscriptionData?.is_expired ? "Expired" : "Active"}
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <h3
                      style={{
                        fontSize: "14px",
                        color: "#595959",
                        marginBottom: "10px",
                      }}
                    >
                      Features
                    </h3>
                    {feturesList?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          <span>{i + 1}. </span>
                          <span>{item}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <h3
                      style={{
                        fontSize: "14px",
                        color: "#595959",
                        margin: "10px 0",
                      }}
                    >
                      Permissions
                    </h3>
                    {permissionList?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          <span>{i + 1}. </span>
                          <span>{item}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subs_inner">
        <div className="ttl_flex">
          <div className="tag-title">Subscription</div>
          <InputField
            onChange={(e) => setSearchData(e?.target?.value)}
            placeholder={"search..."}
            endIcon={<AiOutlineSearch />}
          />
        </div>
        <div
          style={{
            maxHeight: "55vh",
            overflowY: "auto",
            position: "relative",
          }}
          className="subs-table"
        >
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
              }}
            >
              <tr>
                <th>#</th>
                <th>Subscription Name</th>
                <th>Subscription Type</th>
                <th>Max Member</th>
                <th>Subscription Price</th>
                <th>Subscription Features</th>
                <th>Yearly Discount</th>
                <th>Expired In</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.length > 0 ? (
                filteredData?.map((subsItem, index) => {
                  let startDate = new Date(subsItem?.plan_startdate);
                  let lastDate = new Date(subsItem?.plan_enddate);
                  let curentDate = new Date();
                  let remainDays = Math.ceil(
                    (lastDate - curentDate) / (1000 * 60 * 60 * 24)
                  );
                  return (
                    <tr key={index}>
                      <td className="subs-title">{index + 1}</td>
                      <td>{subsItem?.plan_name}</td>
                      <td>{subsItem?.plan_duration_details?.plan_package_type}</td>
                      <td>{subsItem?.plan_type_details?.member_count}</td>
                      <td>
                        &#8377;{" "}
                        {subsItem?.plan_type_details?.plan_price
                          ? (+subsItem?.plan_type_details?.plan_price)?.toFixed(2)
                          : 0.0}
                      </td>
                      <td>
                        {subsItem.plan_type_details?.plan_features !== undefined &&
                          subsItem?.plan_type_details?.plan_features.map(
                            (f1, index) => {
                              return (
                                <p
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "start",
                                  }}
                                >
                                  <span>{index + 1}. </span>
                                  <span>{f1}</span>
                                </p>
                              );
                            }
                          )}
                      </td>
                      <td>
                        {subsItem?.plan_type_details?.discount
                          ? subsItem?.plan_type_details?.discount
                          : 0}
                        %
                      </td>
                      <td>{remainDays} days</td>
                      <td>
                        {!subsItem?.is_expired ? (
                          <span style={{ color: "green" }}>Current Plan</span>
                        ) : (
                          <span style={{ color: "red" }}>Plan Expired</span>
                        )}
                      </td>
                      <td>
                        <div className="action_btns">
                          <HiOutlineEye
                            className="action_icon_view"
                            onClick={() => viewSubscription(subsItem)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div
                      style={{
                        textAlign: "center",
                        // fontSize: "30px",
                        fontWeight: "600",
                        color: "#595959",
                      }}
                    >
                      No Data Available!
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCustomer;
