import './reusable.css'

const TextArea = ({
  type,
  placeholder,
  inputLabel,
  name,
  className,
  onChange,
  value,
  isDisable,
  required,
  maxLength,
  style,
  endIcon,
  iconClass,
  errorResponse,
  onKeyUp

}) => {
  return (
    <div>
      <div className='input-main'>
        {inputLabel && <span>{inputLabel}</span>}
        {inputLabel && required && <span><b style={{ color: "red" }}>* </b>{inputLabel}</span>}
        <textarea
          type={type}
          placeholder={placeholder}
          name={name}
          className={className}
          onChange={onChange}
          value={value}
          disabled={isDisable}
          maxLength={maxLength}
          style={style}
          onKeyUp={onKeyUp}
        />
        {endIcon && <div className={`endIcon ${iconClass}`}>{endIcon}</div>}
      </div>
      {errorResponse && <div style={{
        fontSize: "11px",
        color: "red",
        padding: "5px"
      }}>{errorResponse}</div>}
    </div>
  )
}

export default TextArea;