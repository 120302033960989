import React, { useEffect, useState } from "react";
import "./customerInvoice.css";
import { RiAddLine } from "react-icons/ri";
import InputField from "../../reusableComponent/InputField";
import Button from "../../reusableComponent/Button";
import InvoiceCard from "../../reusableComponent/invoiceCard";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlinePencil } from "react-icons/hi";
import axios from "axios";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { baseUrl } from "../../../BaseUrl";
import { companyInvoiceViewDataHandler } from "../../../ReduxSlices/MainSlice";
import { useDispatch } from "react-redux";
// const baseUrl = process.env.REACT_APP_API_URL;
// impo {base}
const CustomerInvoice = () => {
  const cid = sessionStorage.getItem("c_id");
  const dispatch = useDispatch();
  const sid = sessionStorage.getItem("s_id");
  const getInvoicedataAPI = `${baseUrl}super-admin/invoice/${cid}/${sid}/company-fetch`;
  const navigate = useNavigate();
  const createBtn = () => {
    navigate("/create-invoice");
  };
  const [allInvoiceData, setAllInvoiceData] = useState([]);
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const companyInvoiceData = (page) => {
    axios
      .get(`${getInvoicedataAPI}`, config)
      .then((res) => {
        // console.log("resssssssss",res?.data?.data)
        setAllInvoiceData(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    companyInvoiceData(1);
  }, []);

  return (
    <div className="customer-invoice">
      <div className="invoice_inner">
        <div className="invoice_table">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              text={"Create New Invoice"}
              startIcon={<RiAddLine />}
              onClick={createBtn}
              className={"simpleBtn proposalBtn"}
              style={{ background: "#1976D2", color: "#ffffff" }}
            />
            <InputField
              // type='search'
              placeholder="Search..."
              endIcon={<AiOutlineSearch />}
              className="searchInput"
            />
          </div>
          <table style={{ marginTop: "0px !important" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Invoice Number</th>
                <th>Plans</th>
                <th>Total Amount</th>
                <th>Invoice Date</th>
                <th>Payment Status</th>
                {/* <th>Payment Link</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allInvoiceData?.data?.length > 0 ? (
                allInvoiceData?.data?.map((item, index) => {
                  let date = new Date(item?.createdAt);
                  let convertedDate = date.toLocaleDateString();
                  let lastDate = new Date(item?.last_date).toLocaleDateString();
                  let planData = JSON?.parse(item?.plan_description);
                  // console.log(planData, "planData");
                  return (
                    <tr key={index + 1}>
                      {/* <td className="invoice-title-section">
                        <Link style={{fontSize: '14px'}} className="invoice-title">
                          {item?.invoice_number}
                        </Link>
                      </td> */}
                      <td>{index + 1}</td>
                      <td>{item?._id}</td>
                      {/* <td>{item?.invoice_number}</td> */}
                      <td>
                        <div>
                          {planData?.map((planItem, i) => {
                            return (
                              <span
                                title={planItem?.planDescription}
                                style={{
                                  display: "block",
                                  paddingBottom: "3px",
                                }}
                                key={i}
                              >
                                {planItem?.planType && i + 1 + "."}{" "}
                                {planItem?.planType}
                              </span>
                            );
                          })}
                        </div>
                      </td>
                      <td>
                        &#8377;{" "}
                        {planData
                          ?.reduce(
                            (sum, planItem) => sum + +planItem?.totalAmount,
                            0
                          )
                          ?.toFixed(2)}
                      </td>
                      <td>{convertedDate}</td>
                      <td>
                        {item?.payment_status
                          ? item?.payment_status
                          : "Pending"}
                      </td>
                      {/* <td className="pay_Btn">
                        {" "}
                        <a href={item?.payment_link} target="blank">
                          <Button
                            className={"simpleBtn warnBtn"}
                            text={"Pay Now"}
                          />
                        </a>
                      </td> */}
                      <td>
                        <span className="action_btns">
                          <Link
                            to={"/view-invoice/" + "company"}
                            onClick={() => {
                              sessionStorage.setItem("lp_id", item?._id);
                              dispatch(companyInvoiceViewDataHandler(item));
                            }}
                          >
                            <AiOutlineEye className="action_icon_view" />
                          </Link>
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={9}
                    style={{
                      textAlign: "center",
                      color: "#595959",
                      fontSize: "14px",
                      fontWeight: "600"
                    }}
                  >
                    Data Not Available!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <div className="pegination">
            <div className='peginationinner'>
              <MdKeyboardArrowLeft className='arrowPreNext' onClick={() => {
                allInvoiceData?.links?.previous != null && companyInvoiceData(allInvoiceData?.links?.previous)
              }} />
              <span>{allInvoiceData?.page} out of {Math.ceil((allInvoiceData?.total) / (allInvoiceData?.page_size))}</span>
              <MdKeyboardArrowRight className='arrowPreNext' onClick={() => {
                allInvoiceData?.links?.next != null && companyInvoiceData(allInvoiceData?.links?.next)
              }} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CustomerInvoice;
