import "./reusable.css";

const Button = ({
  className,
  text,
  onClick,
  type,
  color,
  disabled,
  startIcon,
  endIcon,
  btntype,
  style
}) => {
  return (
    <div>
      <button
        className={`button-wrap ${className} ${btntype}`}
        style={style}
        onClick={onClick}
        type={type}
        color={color}
        disabled={disabled}
      >
        {startIcon}
        {text}
        {endIcon}
      </button>
    </div>
  );
};

export default Button;
