import React, { useEffect, useState } from 'react'
import { AiOutlineMessage, AiOutlineUser } from 'react-icons/ai'
import { MdOutlineChat, MdSupportAgent } from 'react-icons/md'
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2'
import { FaUsers } from 'react-icons/fa'
import {
  FcAdvertising,
  FcButtingIn,
  FcConferenceCall,
  FcCustomerSupport,
  FcFlowChart,
  FcManager,
  FcPortraitMode,
  FcSerialTasks,
  FcVoicePresentation
} from 'react-icons/fc'
import './Dashboard.css'
import { baseUrl } from '../../../BaseUrl'
import axios from 'axios'
import { HiOutlinePhoneIncoming } from 'react-icons/hi'
import { IoTicket } from "react-icons/io5";
import { useNavigate } from 'react-router-dom'
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
const Dashboard = () => {
  const navigate = useNavigate();
  const [dashCounts, setDashCounts] = useState()
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const dashCounter = () => {
    axios.get(`${baseUrl}super-admin/dashboard-count/`, config).then((respo) => {
      if (respo.status === 200) {
        setDashCounts(respo?.data?.data);
      }
    }).catch((err) => {
      // console.log(err);
    })
  }
  // console.log('.........',respo?.data?.data);
  // console.log(dashCounts)
  useEffect(() => {
    dashCounter();
  }, [])
  // const [interval, setInterval] = useState("");
  // const [intervalId ,setIntervalId] = useState("");
  // const [selectedTime,setSelectedTime] = useState("");
  // const [selectedInterval, setSelectedInterval] = useState(null);
  // const [timeoutId, setTimeoutId] = useState(null);

  // const handleTimeChange = (event) => {
  //   console.log("inside the handelchange");
  //   setSelectedTime(event.target.value);
  // };


  // const handleSave = () => {
  //   console.log("handleSave called");
  //   console.log(selectedTime, "selectedTime--------------");

  //   if (selectedTime === 0) {
  //     console.log("Selected time is 0. No API calls will be made.");
  //     return;
  //   }

  //   console.log("Setting interval for", selectedTime, "seconds");

  //   if (intervalId) {
  //     clearInterval(intervalId);
  //     console.log("Cleared previous interval:", intervalId);
  //   }


  //   const id = setInterval(() => {
  //     console.log("Interval running");

  //   }, 5000);

  //   console.log("Interval ID:", id);
  //   setIntervalId(id);
  // };

  // const handleStop = () => {
  //   if (intervalId) {
  //     clearInterval(intervalId);
  //     console.log("Interval cleared on stop:", intervalId);
  //     setIntervalId(null);
  //   }
  // };

  return (
    
    <div className='superDash'>
       {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 120 }}
          >
            <InputLabel id="time-interval-label">
              Select Time Interval
            </InputLabel>
            <Select
              labelId="time-interval-label"
              value={selectedTime}
              onChange={handleTimeChange}
              label="Select Time Interval"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem  value={5}>5 seconds</MenuItem>
              <MenuItem value={15}>15 seconds</MenuItem>
              <MenuItem value={20}>20 seconds</MenuItem>
              <MenuItem value={25}>25 seconds</MenuItem>
              <MenuItem value={30}>30 seconds</MenuItem>
              <MenuItem value={35}>35 seconds</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "16px" }}
            onClick={handleSave}
          >
            Save
          </Button>
          <Typography
            variant="h6"
            style={{ marginLeft: "16px", color: "#333" }}
          >
            Your Selected Interval:{" "}
            {selectedTime ? `${selectedTime} seconds` : "None"}
          </Typography>
        </div> */}
      <div className='dash_cards'>
        <div className='dash_grid'>
          <div className='card_main' onClick={() => navigate('/customers')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Company.totalCompanies}</div>
                <div className='title_text'>All Companies</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle'>
                  <FcManager />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/customers')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Company.activeCompanies}</div>
                <div className='title_text'>Active Companies</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle'>
                  <FcManager />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/customers')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Company.deActivatedCompanies}</div>
                <div className='title_text'>Deactivated Companies</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle'>
                  <FcManager />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/customers')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Company.newCompanies}</div>
                <div className='title_text'>New Companies</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle'>
                  <FcManager />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/customers')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Company.contactedCompanies}</div>
                <div className='title_text'>Contacted Companies</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle'>
                  <FcManager />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/customers')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Company.dealDoneCompanies}</div>
                <div className='title_text'>Deal Done Companies</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle'>
                  <FcManager />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/customers')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Company.unqualifiedCompanies}</div>
                <div className='title_text'>Unqualified Companies</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle'>
                  <FcManager />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/leads')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Leads?.totalLeads ? dashCounts?.Leads?.totalLeads : 0}</div>
                <div className='title_text'>All Leads</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle'>
                  <FcSerialTasks />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/leads')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.a2p_lead ? dashCounts?.a2p_lead : 0}</div>
                <div className='title_text'>A2P Leads</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle chat_icon'>
                  <FcFlowChart />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/leads')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.cst_lead ? dashCounts?.cst_lead : 0}</div>
                <div className='title_text'>CST Leads</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle chat_icon'>
                  <FcCustomerSupport />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/leads')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.mkt_lead ? dashCounts?.mkt_lead : 0}</div>
                <div className='title_text'>MKT Leads</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle chat_icon'>
                  <FcAdvertising />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/employee')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Employee.totalEmployee}</div>
                <div className='title_text'>All Employee</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle chat_icon'>
                  <FcPortraitMode />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/employee')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Employee.activeEmployee}</div>
                <div className='title_text'>Active Employee</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle chat_icon'>
                  <FcVoicePresentation />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/employee')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Employee.deActivatedEmployee}</div>
                <div className='title_text'>Deactivated Employee</div>
              </div>
              <div className='right_card'>
                <span className='icon_circle chat_icon'>
                  <FcButtingIn />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/tickets')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Tickets.totalTickets}</div>
                <div className='title_text'>All Tickets</div>
              </div>
              <div className='right_card'>
                <span style={{ color: "#72d985" }} className='icon_circle chat_icon'>
                  <IoTicket />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/tickets')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Tickets.newTickets}</div>
                <div className='title_text'>New Ticket</div>
              </div>
              <div className='right_card'>
                <span style={{ color: "#72d985" }} className='icon_circle chat_icon'>
                  <IoTicket />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/tickets')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Tickets.pendingTickets}</div>
                <div className='title_text'>Pending Ticket</div>
              </div>
              <div className='right_card'>
                <span style={{ color: "#fbbc28" }} className='icon_circle chat_icon'>
                  <IoTicket />
                </span>
              </div>
            </div>
          </div>
          <div className='card_main' onClick={() => navigate('/tickets')} style={{ cursor: 'pointer' }}>
            <div className='card_inner'>
              <div className='left_card'>
                <div className='counter'>{dashCounts?.Tickets.closedTickets}</div>
                <div className='title_text'>Closed Ticket</div>
              </div>
              <div className='right_card'>
                <span style={{ color: "#ff5a48" }} className='icon_circle chat_icon'>
                  <IoTicket />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
