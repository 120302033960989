import React, { useEffect, useState } from "react";
import forgotPass from "../../Assets/forgotPass.png";
import { Alert, Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import { baseUrl } from "../../BaseUrl";
import { useDispatch } from "react-redux";
import { handleForgotPasswordDialog } from "../../ReduxSlices/MainSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [activeForgotDialog, setActiveForgotDialog] = useState("mailDialog");
  const [sendOTPErr, setSendOTPErr] = useState(null);
  const [emailId, setEmailId] = useState("");
  const [delay, setDelay] = useState("89");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [validPwdMsg, setValidPwdMsg] = useState(null);
  const [strongPassword, setStrongPassword] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [otpSuccess, setOtpSuccess] = useState(null);
  const [blankEmail, setBlankEmail] = useState(false);
  const [otpObject, setOtpObject] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [newPasswordData, setNewPasswordData] = useState(null);
  const handleChangeSendOTP = (e) => {
    setEmailId(e.target.value);
  };
  const sendData = {
    email: emailId,
  };
  const sendOtp = (e) => {
    e.preventDefault();
    if (emailId !== "") {
      axios
        .post(`${baseUrl}super-admin/forget-password`, sendData)
        .then((res) => {
          // sessionStorage.setItem("oid", true)
          setActiveForgotDialog("otpDialog");
          setDelay("89");
          setOtpSuccess(res?.data?.data, {
            duration: 5000,
          });
          setTimeout(() => {
            setOtpSuccess(null);
          }, 3000);
        })
        .catch((err) => {
          setOtpError(err?.response?.data?.error);
          setTimeout(() => {
            setOtpError(false);
          }, 3000);
        });
    } else {
      setBlankEmail(true);
    }
  };

  // OTP ENter

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  // multi-field box design
  const handleOtpChange = (event) => {
    const { name, value } = event.target;
    setOtpObject({
      ...otpObject,
      [name]: value,
    });
  };
  const handleStepTwo = (e) => {
    // clearAllErrors();
    setNewPasswordData((old) => {
      return { ...old, [e.target?.name]: e.target?.value };
    });
  };
  useEffect(() => {
    if (activeForgotDialog === "otpDialog") {
      const timer = setInterval(() => {
        // startTransition(() => {
        setDelay(delay - 1);
        setMinutes(Math.floor(delay / 60));
        setSeconds(Math.floor(delay % 60));
        // });
      }, 1000);

      if (delay === 0) {
        clearInterval(timer);
        // sessionStorage.removeItem("oid");
      }

      return () => {
        clearInterval(timer);
      };
    }
  });
  // Forgot password and email validation
  const checkPasswordValidation = (value) => {
    setStrongPassword(false);
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(value)) {
      value.length !== 0 &&
        setValidPwdMsg("Password must not contain Whitespaces.");
      return "Password must not contain Whitespaces.";
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(value)) {
      setValidPwdMsg("Password must have at least one Uppercase Character.");
      return "Password must have at least one Uppercase Character.";
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(value)) {
      setValidPwdMsg("Password must have at least one Lowercase Character.");
      return "Password must have at least one Lowercase Character.";
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(value)) {
      setValidPwdMsg("Password must contain at least one Digit.");
      return "Password must contain at least one Digit.";
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    if (!isContainsSymbol.test(value)) {
      setValidPwdMsg("Password must contain at least one Special Symbol.");
      return "Password must contain at least one Special Symbol.";
    }

    if (value.length < 8) {
      setValidPwdMsg("Password must be 8-16 Characters Long.");
    } else {
      setValidPwdMsg("Strong Password");
      setStrongPassword(true);
    }
  };
  useEffect(() => {
    checkPasswordValidation(newPasswordData?.password);
  }, [newPasswordData?.password, newPasswordData, handleStepTwo]);

  useEffect(() => {
    setNewPasswordData((old) => {
      return { ...old, otpFieldValue: Object.values(otpObject).join("") };
    });
  }, [otpObject]);
  // console.log(newPasswordData, "newPass");

  const changePassword = (e) => {
    e.preventDefault();
    const data = {
      email: emailId,
      otp: newPasswordData?.otpFieldValue,
      password: newPasswordData?.password,
      password2: newPasswordData?.password2,
    };
    if (newPasswordData?.password === newPasswordData?.password2) {
      axios
        .put(`${baseUrl}super-admin/reset-password/`, data)
        .then((res) => {
          // handleForgotClose();
          toast.success("Password reset successfully");
          // sessionStorage.removeItem("oid");

          dispatch(handleForgotPasswordDialog(false));
        })
        .catch((err) => {
          setOtpError(err?.response?.data?.data);
        });
    } else {
      setOtpError("Please enter correct password");
    }
  };
  return (
    <div className="forgot-dialog-container">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="forgot-dialog-box">
        <div style={{ position: "relative" }} className="forgot-dialog">
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              backgroundColor: "#ff6060",
              cursor: "pointer",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "2px",
              color: "#FFFFFF",
            }}
            onClick={() => {
              dispatch(handleForgotPasswordDialog(false));
            }}
          >
            <CloseIcon />
          </div>
          <div className="forgot-icon">
            <img width={"100px"} src={forgotPass} alt="something here" />
            <div className="forgot-heading">Forgot Password</div>
            <div className="forgot-quote">
              Locked out of your account? Let's get you back in with a new
              password!
            </div>
            <div className="forgot-steps">
              <span>
                {activeForgotDialog === "mailDialog" &&
                  "Step 1: Enter Registered Email Address"}
                {activeForgotDialog === "otpDialog" &&
                  "Step 2: Enter the OTP sent on your mail and new password"}
              </span>
            </div>
          </div>
          {otpError && (
            <div style={{ padding: "10px 20px" }}>
              <Alert severity="error">{otpError}</Alert>
            </div>
          )}
          {otpSuccess && (
            <div style={{ padding: "10px 20px" }}>
              <Alert severity="success">{otpSuccess}</Alert>
            </div>
          )}
          {activeForgotDialog === "mailDialog" && (
            <>
              <form>
                <div style={{ padding: "0 20px" }} class="line_1">
                  <div class="form-control">
                    <i class="fa fa-user icon"></i>
                    <input
                      type="text"
                      placeholder="example@somthing.com"
                      required
                      name="email"
                      value={emailId}
                      onChange={(e) => handleChangeSendOTP(e)}
                    />
                  </div>
                </div>
                {sendOTPErr && (
                  <div style={{ padding: "10px 20px" }}>
                    <Alert severity="error">{sendOTPErr}</Alert>
                  </div>
                )}
                {blankEmail && (
                  <div style={{ padding: "10px 20px" }}>
                    <Alert severity="error">Please enter email id</Alert>
                  </div>
                )}
                <div className="plan-button-container">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      padding: "0 10px",
                    }}
                  >
                    <button
                      className="plan-submit-button"
                      onClick={(e) => sendOtp(e)}
                    >
                      Send OTP
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
          {activeForgotDialog === "otpDialog" && (
            <>
              <form>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <div className="otpContainer">
                    <div>
                      <input
                        name="otp1"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otpObject.otp1}
                        onChange={handleOtpChange}
                        tabIndex="1"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        name="otp2"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otpObject.otp2}
                        onChange={handleOtpChange}
                        tabIndex="2"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        name="otp3"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otpObject.otp3}
                        onChange={handleOtpChange}
                        tabIndex="3"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        name="otp4"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otpObject.otp4}
                        onChange={handleOtpChange}
                        tabIndex="4"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                      />

                      <input
                        name="otp5"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otpObject.otp5}
                        onChange={handleOtpChange}
                        tabIndex="5"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        name="otp6"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otpObject.otp6}
                        onChange={handleOtpChange}
                        tabIndex="6"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                    </div>
                    {/* <Button style={{textTransform: "capitalize"}} onClick={(e) => forgetStepOne(e, "resend")}>Resend</Button> */}
                    <Button
                      style={{ textTransform: "capitalize" }}
                      onClick={sendOtp}
                    >
                      Resend
                    </Button>
                  </div>
                  <p style={{ color: "red" }}>
                    {(minutes !== 0 && seconds > 1) ||
                    (minutes === 0 && seconds > 1)
                      ? `OTP valid for ${minutes} : ${seconds}`
                      : `OTP Expired`}
                  </p>

                  <TextField
                    margin="dense"
                    name="password"
                    label="New password"
                    variant="outlined"
                    onChange={handleStepTwo}
                    required
                    fullWidth
                  />

                  {validPwdMsg !== null && (
                    <Alert severity={strongPassword ? "success" : "warning"}>
                      {validPwdMsg}
                    </Alert>
                  )}
                  <TextField
                    margin="dense"
                    name="password2"
                    label="Confirm new password"
                    variant="outlined"
                    onChange={handleStepTwo}
                    required
                    fullWidth
                  />
                </div>
                <div className="plan-button-container">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      padding: "0 10px",
                    }}
                  >
                    <button
                      className="plan-submit-button"
                      onClick={(e) => changePassword(e)}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
