import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './ProfileCard/ProfileCard.css'
import { Alert, Button } from '@mui/material'
import axios from 'axios'
import { baseUrl } from "../../../BaseUrl";
import toast, { Toaster } from "react-hot-toast";
import { DotLoader } from "react-spinners";
import e from 'cors'

const ResetPassword = () => {
    // const profileData = useSelector((store) => store?.main?.userData);
    const [strongPassword, setStrongPassword] = useState(false);
    const [validPwdMsg, setValidPwdMsg] = useState(null);
    // const [openResetModule, setOpenResetModule] = useState(false);
    const [adding, setAdding] = useState(false);
    const [resetPassword, setResetPassword] = useState({
        current_password: null,
        new_password: null,
        confirm_password: null,
    });
    const [resetResponse, setResetResponse] = useState(null);
    const [resetPwdError, setResetPwdError] = useState(null);
    const handleCloseResetPassword = () => {
        // console.log('e',e);
        // e.preventDefault();
        // setOpenResetModule(false);
        // setAdding(false);
        setResetPassword({
            current_password: "",
            new_password: "",
            confirm_password: "",
        });
        setResetPwdError(null);
    };
    // console.log(resetPassword);
    // Forgot password validation
    const checkPasswordValidation = (value) => {
        setStrongPassword(false);
        const isWhitespace = /^(?=.*\s)/;
        if (isWhitespace.test(value)) {
            value.length !== 0 &&
                setValidPwdMsg("Password must not contain Whitespaces.");
            return "Password must not contain Whitespaces.";
        }

        const isContainsUppercase = /^(?=.*[A-Z])/;
        if (!isContainsUppercase.test(value)) {
            setValidPwdMsg("Password must have at least one Uppercase Character.");
            return "Password must have at least one Uppercase Character.";
        }

        const isContainsLowercase = /^(?=.*[a-z])/;
        if (!isContainsLowercase.test(value)) {
            setValidPwdMsg("Password must have at least one Lowercase Character.");
            return "Password must have at least one Lowercase Character.";
        }

        const isContainsNumber = /^(?=.*[0-9])/;
        if (!isContainsNumber.test(value)) {
            setValidPwdMsg("Password must contain at least one Digit.");
            return "Password must contain at least one Digit.";
        }

        const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
        if (!isContainsSymbol.test(value)) {
            setValidPwdMsg("Password must contain at least one Special Symbol.");
            return "Password must contain at least one Special Symbol.";
        }

        if (value.length < 8) {
            setValidPwdMsg("Password must be 8-16 Characters Long.");
        } else {
            setValidPwdMsg("Strong Password");
            setStrongPassword(true);
        }
    };

    // #####################################################################
    const handleResetInput = (e) => {
        const value = e.target.value;
        setResetPassword({
            ...resetPassword,
            [e.target.name]: value,
        });
    };
    const resetPasswordApi = () => {
        // console.log("submitted", localStorage.getItem("saToken2"));
        let token;
        if (localStorage.getItem("saToken2") !== null)
            token = localStorage.getItem("saToken2");
        // let api = `${BASE_URL}/Reset_password/`;
        let api = `${baseUrl}super-admin/change-password`;
        let resetPwdData = {
            current_password: resetPassword.current_password,
            new_password: resetPassword.new_password,
            confirm_password: resetPassword.confirm_password,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        // axios
        //     .post(api, resetPwdData, config)
        //     .then((res) => {
        //         setAdding(false);
                // setOpenResetModule(false);
        //         toast.success("Successfully password Reset!");
        //     })
        //     .catch((err) => {
        //         setAdding(false);
        //         setResetPwdError(err.response?.data?.status);
        //     });
        if (
            resetPwdData !== null &&
            resetPassword.new_password === resetPassword.confirm_password
        ) {
            axios
                .post(api, resetPwdData, config)
                .then((res) => {
                    setAdding(false);
                    // setOpenResetModule(false);
                    toast.success("Successfully password Reset!");
                    setTimeout(() => {
                        handleCloseResetPassword()
                    }, 2000);
                })
                .catch((err) => {
                    setAdding(false);
                    toast.error(err.response.data.message);
                    setResetPwdError(err.response?.data?.status);
                });
        } else {
            // setAdding(false);
            setResetPwdError("Confirm Password does not match");
            setTimeout(() => {
                setResetPwdError(null);
            }, 3000);
        }
    };
    useEffect(() => {
        checkPasswordValidation(resetPassword?.new_password);
    }, [resetPassword?.new_password, resetPasswordApi, adding]);
    return (
        <div class="profileDetailsSetting">
            <Toaster position="top-center" reverseOrder={false} />
            <div className='headingComponents'>Password Setting </div>
            {resetPwdError && (
                <Alert severity="error">{resetPwdError}</Alert>
            )}
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    resetPasswordApi();
                }}
                className="reset-pwd-form"
            >
                <div className="content-wrapper">
                    <div className="reset-input-wrapper">
                        <label className='label'>Old Password</label>
                        <input
                            className="question-input"
                            placeholder="old password"
                            required
                            autoFocus
                            name="current_password"
                            id="current_password"
                            type="text"
                            value={resetPassword?.current_password}
                            onChange={handleResetInput}
                        />
                    </div>
                    <div className="reset-input-wrapper">
                        <label className='label'>New Password</label>
                        <input
                            className="question-input"
                            placeholder="new password"
                            required
                            name="new_password"
                            id="new_password"
                            type="text"
                            value={resetPassword?.new_password}
                            onChange={handleResetInput}
                        />
                    </div>
                    {resetPassword?.new_password !== null &&
                        validPwdMsg !== null && (
                            <Alert
                                severity={strongPassword ? "success" : "warning"}
                                style={{ padding: "0 5px", width: "100%" }}
                            >
                                {validPwdMsg}
                            </Alert>
                        )}
                    <div className="reset-input-wrapper">
                        <label className='label'>Confirm Password</label>
                        <input
                            className="question-input"
                            placeholder="confirm Password"
                            required
                            name="confirm_password"
                            id="confirm_password"
                            value={resetPassword?.confirm_password}
                            onChange={handleResetInput}
                            type="text"
                        />
                    </div>
                </div>
                <div className="reset-actionbtns-wrapper">
                    {/* <Button
                        variant="outlined"
                        onClick={handleCloseResetPassword}
                        className="actionBtns"
                        style={{ borderRadius: "7px" }}
                    >
                        Cancel
                    </Button> */}
                    {adding ? (
                        <DotLoader color="blue" loading={true} size={20} />
                    ) : (
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!strongPassword}
                            className="actionBtns"
                        >
                            {adding ? <>Loading...</> : <>Reset Password</>}
                        </Button>
                    )}
                </div>
            </form>

        </div>
    )
}

export default ResetPassword
