import React from 'react'
import "./Employee.css"
import DealValueCard from '../../reusableComponent/DealValueCard';
import AnalyticBar from '../../reusableComponent/AnalyticBar';
import DashboardCard from '../../reusableComponent/DashboardCard';
import { FcApproval, FcBusinessman, FcCalendar, FcCallTransfer, FcPlanner, FcViewDetails } from 'react-icons/fc';
import { useSelector } from 'react-redux';

const EmployeeDashboard = () => {
    const employeeView = JSON?.parse(localStorage.getItem("employee_profile"));
    const employeeAnalyticData = useSelector((store) => store?.main?.employeeAnalytics)
    // console.log(employeeAnalyticData, "datatttttttttttttttttttttt");
    // console.log("employeee-----",employeeView)
    return (
        <div className="profile_section">
            <div style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
            }} className='profile_inner'>
                <div className='employee-profile-left'>
                    <div className='profile-data-grid'>
                        <div
                            style={{
                                fontSize: "0.8rem",
                                color: "#FFFFFF",
                            }}
                            className="task-details-main"
                        >
                            <div className="flex-class">
                                <span>Name</span>
                                <span>:</span>
                                <span>{employeeView?.name}</span>
                            </div>
                            <div className="flex-class">
                                <span>Email</span>
                                <span>:</span>
                                <span>{employeeView?.email}</span>
                            </div>
                            <div className="flex-class">
                                <span>Mobile</span>
                                <span>:</span>
                                <span>{employeeView?.mobile}</span>
                            </div>
                            <div className="flex-class">
                                <span>Gender</span>
                                <span>:</span>
                                <span>{employeeView?.gender}</span>
                            </div>
                        </div>
                    </div>
                    <div className='profile-data-grid'>
                        <div
                            style={{
                                fontSize: "0.8rem",
                                color: "#FFFFFF",
                            }}
                            className="task-details-main"
                        >
                            <div className="flex-class">
                                <span>Designation</span>
                                <span>:</span>
                                <span>{employeeView?.designation}</span>
                            </div>
                            <div className="flex-class">
                                <span>Permissions</span>
                                <span>:</span>
                                <div>
                                    {employeeView?.permission &&
                                        employeeView?.permission?.map((item, i) => {
                                            return (
                                                <div>
                                                    <span>
                                                        {i + 1}. {item}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='profile-data-grid'>
                        <div
                            style={{
                                fontSize: "0.8rem",
                                color: "#FFFFFF",
                            }}
                            className="task-details-main"
                        >
                            <div className="flex-class">
                                <span>Address</span>
                                <span>:</span>
                                <span>
                                    {employeeView?.address ? employeeView?.address : "---"},{" "}
                                    {employeeView?.city ? employeeView?.city : "---"},{" "}
                                    {employeeView?.state ? employeeView?.state : "---"},{" "}
                                    {employeeView?.pincode ? employeeView?.pinCode : "---"}
                                </span>
                            </div>
                            <div className="flex-class">
                                <span>Status</span>
                                <span>:</span>
                                <span style={{
                                    color: `${employeeView?.is_deactivated ?  "#FF5733": "#71fb71" }`,
                                }}>
                                    {employeeView?.is_deactivated ? "Deactivated": "Active"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {/* Deal Values */}
                    <h5>Deal Value</h5>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                            padding: "10px 0",
                        }}>
                            {/* {console.log(employeeAnalyticData)} */}
                        <DealValueCard
                            titleText={"Total Expected Deal"}
                            amount={employeeAnalyticData?.total_expected_deal_value}
                            borderColor={"orange"}
                        />
                        <DealValueCard
                            titleText={"Total Deal Value"}
                            amount={employeeAnalyticData?.todays_deal_value}
                            borderColor={"green"}
                        />
                        <DealValueCard
                            titleText={"Today's Total Expected Deal"}
                            amount={employeeAnalyticData?.todays_expected_deal_value}
                        />
                        <DealValueCard
                            titleText={"Today's Total Deal Value"}
                            amount={employeeAnalyticData?.todays_deal_value}
                        />
                    </div>
                    {/* Deal Analysis */}
                    <div style={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                    }}>
                        <AnalyticBar
                            dealvalue={employeeAnalyticData?.todays_deal_value}
                            expectedDealvalue={employeeAnalyticData?.total_expected_deal_value}
                            title={"Total Deal Analysis"}
                            titleColor={"#1877F2"}
                        />
                        <AnalyticBar
                            dealvalue={employeeAnalyticData?.todays_deal_value
                            }
                            expectedDealvalue={employeeAnalyticData?.todays_expected_deal_value}
                            title={"Today's Deal Analysis"}
                            titleColor={"#1877F2"}
                        />
                    </div>
                    {/* Counts of Leads & Tasks */}
                    <div>
                        <h5>Counts Record</h5>
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                flexWrap: "wrap",
                                padding: "10px 0",
                            }}>
                            <DashboardCard
                              dashCounts={employeeAnalyticData?.status_count?.new_lead || 0}
                              titleText={"New Leads"}
                            />
                            <DashboardCard
                              dashCounts={employeeAnalyticData?.status_count?.contacted_lead || 0}
                              titleText={"Contacted Leads"}
                              dashIcon={<FcCallTransfer />}
                            />
                            <DashboardCard
                              dashCounts={employeeAnalyticData?.status_count?.qualified_lead || 0}
                              titleText={"Qualified Leads"}
                              dashIcon={<FcApproval />}
                            />
                            <DashboardCard
                              dashCounts={employeeAnalyticData?.status_count?.deal_done_lead
                                || 0}
                              titleText={"Deal Done Leads"}
                              dashIcon={<FcBusinessman />}
                            />
                            <DashboardCard
                              dashCounts={employeeAnalyticData?.status_count?.task_pending
                                || 0}
                              titleText={"Total pending Tasks"}
                              dashIcon={<FcCalendar />}
                            />
                            <DashboardCard
                              dashCounts={employeeAnalyticData?.status_count?.today_task_lead || 0}
                              titleText={"Today's pending Tasks"}
                              dashIcon={<FcPlanner />}
                            />
                            <DashboardCard
                              dashCounts={employeeAnalyticData?.status_count?.note_pending || 0}
                              titleText={"Total Pending Notes"}
                              dashIcon={<FcViewDetails />}
                            />                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EmployeeDashboard