import axios from "axios"
import { baseUrl } from "../BaseUrl"

export const toggleCompanyActivation = (
    data,
    config,
    successHandler,
    errorHandler
) => {
    let api = `${baseUrl}super-admin/toggle-deactivation/${data.id}?is_deactivated=${!data.is_deactivated}`

    axios.post(api, "", config).then((respo) => {
        successHandler(respo)
    }).catch((err) => {
        errorHandler(err)
    })
}

// Sub Company list

export const getSubCompanyList = (
    id,
    config,
    successHandler,
    errorHandler
) => {
    axios
        .get(`${baseUrl}super-admin/fetch-subcompany/${id}`, config)
        .then((respo) => {
            successHandler(respo)
        })
        .catch((err) => {
            errorHandler(err)
        })
}

// get Sub company details

export const getSubCompanyDetails = (
    id,
    config,
    successHandler,
    errorHandler
) => {
    axios
        .get(`${baseUrl}super-admin/fetch-subcompany-profile/${id}`, config)
        .then((respo) => {
            successHandler(respo)
        })
        .catch((err) => {
            errorHandler(err)
        })
}