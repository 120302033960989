import React, { useEffect, useState } from "react";
import "../Customers/Customer.css";
import {
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdOutlineSummarize,
} from "react-icons/md";
import { HiOutlinePencil } from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineClose, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CreateForm from "../Customers/CreateForm";
import CustomerProfile from "../Customers/CustomerProfile/CustomerProfile";
import { useDispatch } from "react-redux";
import {
    handleCompanyData,
    handleIsCompanyProfileActive,
    updateCompanyData,
} from "../../../ReduxSlices/MainSlice";
import { baseUrl } from "../../../BaseUrl";
import { Toaster, toast } from "react-hot-toast";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Dialog } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { DotLoader } from "react-spinners";
import { convertDate, convertTime } from "../../webutils";
import { toggleCompanyActivation } from "../../../APIs/CompanyAPIs";

const EmployeeCompany = () => {
    const employeeAllData = JSON?.parse(localStorage.getItem("employee_profile"));
    const [page, setPage] = useState(parseInt(localStorage.getItem('currentPage')) || 1);
    const [allCompanyData, setAllCompanyData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [allPendingPaymentData, setAllPendingPaymentData] = useState([]);
    const [openEditCompany, setOpenEditCompany] = useState("dashboard");
    const [companyView, setCompanyView] = useState("companies_list");
    const [editSelectedItem, setEditSelectedItem] = useState([]);
    const [openPaymentRequest, setOpenPaymentRequest] = useState(false);
    const [popupPaymentDetails, setPopupPaymentDetails] = useState(null);
    const [paymentRequestError, setPaymentRequestError] = useState({});
    const [searchableData, setSearchableData] = useState("");
    const [whatsAppMessage, setWhatsAppMessage] = useState(null);
    const [templateMessage, setTemplateMessage] = useState(null);
    const [noteType, setNoteType] = useState("");
    const [activeNotes, setActiveNotes] = useState(false);
    const [noteTaskData, setNoteTaskData] = useState(null);
    const [recentList, setRecentList] = useState([]);
    const [mouseActive, setMouseActive] = useState();
    const [handleExpendComment, setHandleExpendComment] = useState({
        id: null,
        display: false,
    });
    const [commentsList, setCommentsList] = useState([]);
    const [commentValue, setCommentValue] = useState("");
    const [errorComment, setErrorComment] = useState("none");
    const [companyName, setCompanyName] = useState();
    const [activeDeal, setActiveDeal] = useState(null);
    const [expectedDealValue, setExpectedDealValue] = useState(null);
    const [expecteDealClosingDate, setExpecteDealClosingDate] = useState(null);
    const [dealVal, setDealValue] = useState("");
    const [sendBtnWait, setSendBtnWait] = useState(<SendIcon />);
    const [selectedMessage, setSelectedMessage] = useState({
        isTrue: false,
        messageId: "",
    });
    const expendCommentHandler = (item) => {
        // console.log("itemmm", item)
        setHandleExpendComment({
            id: item._id,
            display: !handleExpendComment.display,
        });
    };
    const navigate = useNavigate();

    //Drop down for company
    const [leadStatus, setLeadStatus] = useState([
        "New",
        "Viewed",
        "Contacted",
        "Deal Done",
        "Unqualified",
    ]);
    const [activeLeadStatus, setActiveLeadStatus] = useState(null);
    const activeStatusDropDown = (item) => {
        if (activeLeadStatus === null) {
            setActiveLeadStatus(item?._id);
        } else {
            setActiveLeadStatus(null);
        }
    };
    //Assign company
    const [wait, setWait] = useState("Assign");
    const [employeeId, setEmployeeId] = useState("");
    useEffect(() => {
        getEmployeeList();
    }, []);
    // Assign button
    const [employeeData, setEmployeeData] = useState();
    const getEmployeeList = () => {
        axios
            .get(`${baseUrl}add-admin/profile-list-super-admin`, config)
            .then((respo) => {
                if (respo.status === 200) {
                    setEmployeeData(respo?.data?.data);
                    // console.log("tick", respo?.data);
                }
            })
            .catch((err) => {
                // console.log(err)
            });
    };
    const [toggleAssign, setToggleAssign] = useState({
        classN: "assignPopup",
        company_id: "",
        company_name: "",
        category: "",
        status: "",
        date: "",
        time: "",
        description: "",
    });
    const formData = {
        company_id: toggleAssign?.company_id,
        admin_user: employeeId,
    };
    const assignAdminUser = () => {
        // console.log(formData,"lead form")
        axios
            .post(`${baseUrl}super-admin/company-assigned`, formData, config)
            .then((respo) => {
                if (respo.status === 200 || 201) {
                    toast.success(respo?.data?.message);
                    setWait("Assigned");
                    // console.log("ticktick assign", respo?.data?.message);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
                setWait("Err");
                // console.log(err)
            });
    };

    const [paymentReqData, setPaymentReqData] = useState({
        company_name: popupPaymentDetails?.company_id?.id,
        transaction_id: popupPaymentDetails?.transaction_id,
        category: popupPaymentDetails?.category,
        amount: "",
        status: popupPaymentDetails?.status ? popupPaymentDetails?.status : "",
        payment_mode: "Offline",
        amount_in_word: "",
        isChecked: false,
    });

    const pendingBorder = {
        border: "1px solid #ff7700",
        padding: "4px 10px",
        backgroundColor: "#fff",
        color: "#ff5f00",
        borderRadius: "4px",
        outline: "none",
    };
    const rejectedBorder = {
        border: "1px solid #e31c1c",
        padding: "4px 10px",
        backgroundColor: "#fff",
        color: "#e31c1c",
        borderRadius: "4px",
        outline: "none",
    };
    const successBorder = {
        border: "1px solid #3b7212",
        padding: "4px 10px",
        backgroundColor: "#e4f1e4",
        color: "#3b7212",
        borderRadius: "4px",
        outline: "none",
    };
    var token;
    if (localStorage.getItem("saToken2"))
        token = localStorage.getItem("saToken2");
    var config = { headers: { Authorization: `Bearer ${token}` } };

    const getCompanyDataAPI = `${baseUrl}employyee/assign/fetch/company/${employeeAllData?._id}`;
    const [companyType, setCompanyType] = useState("all");
    const [isDateTypeSearch, setIsDateTypeSearch] = useState(false);
    // console.log(storedDataCompanyList, "data")
    const dispatch = useDispatch();
    const getCompany = (page, search, startDate, endDate) => {
        axios
            .get(
                `${getCompanyDataAPI}?page=${page}&queue=${search}&active_list=${companyType}&${startDate ? "startDate=" + startDate : ""}&${endDate ? "endDate=" + endDate : ""}`,
                config
            )
            .then((res) => {
                setAllCompanyData(res?.data);
                dispatch(handleCompanyData(res?.data));
                  })
            .catch((err) => {
                // console.error(err);
            });
    };

    // SAIF- 29/OCTOBER/2024
    const handlePageChange = (newPage) => {
        localStorage.setItem("currentPage", newPage);
        setPage(newPage);
    }

    const renderPageNumber = () => {
        const totalPages = Math.ceil(allCompanyData?.pages);
        const maxPageNumbersShowAtATime = 10;
        const pageNumbersToShow = [];

        //Calulate pages range
        let startPage = Math.max(1, page - 4); //4 Pages before current page
        let endPage = Math.min(totalPages, page + 5);

        if (page < 6) {
            endPage = Math.min(maxPageNumbersShowAtATime, totalPages);
        }

        if (totalPages - page < 5) {
            startPage = Math.max(1, totalPages - maxPageNumbersShowAtATime + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            const buttonStyle = {
                padding: '6px 10px',
                margin: '0 2px',
                cursor: 'pointer',
                backgroundColor: i === page ? '#007bff' : '#f8f9fa',
                color: i === page ? '#ffffff' : '#000000',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontWeight: i === page ? 'bold' : 'normal',
            };
            pageNumbersToShow.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    style={buttonStyle}
                    type="button"
                >
                    {i}
                </button>
            );
        }

        return pageNumbersToShow;
    }


    const getPendingPaymentRequest = () => {
        axios
            .get(`${baseUrl}offline-topup/`, config)
            .then((res) => {
                // console.log(res.data);
                setAllPendingPaymentData(res?.data);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const validatePaymentRequest = () => {
        let arr = {};
        var z1 = /^[0-9]*$/;
        if (!paymentReqData?.amount) {
            arr.amount = "Enter payment amount!";
        }
        if (!z1.test(paymentReqData?.amount)) {
            arr.amount = "Amount must be in number!";
        }
        if (!paymentReqData?.amount_in_word) {
            arr.word_amount = "Enter amount in word!";
        }
        if (!paymentReqData?.isChecked) {
            arr.isChecked = "Please checked the box!";
        }
        setPaymentRequestError(arr);
        return Object.keys(arr)?.length === 0;
    };

    const submitPaymentRequest = () => {
        let data = {
            company_name: popupPaymentDetails?.company_id?.id,
            transaction_id: popupPaymentDetails?.transaction_id,
            category: popupPaymentDetails?.category === "topup" ? "Top-up" : null,
            amount: paymentReqData?.amount,
            status: paymentReqData?.status,
            payment_mode: "Offline",
        };
        let val = validatePaymentRequest();
        val &&
            axios
                .post(`${baseUrl}create-offline-topup/`, data, config)
                .then((res) => {
                    // console.log(res?.data);
                    setOpenPaymentRequest(false);
                    toast.success("Payment successfully accepted!");
                    getPendingPaymentRequest();
                });
    };

    // console.log(paymentRequestError);

    const activateDeactivateCompany = (companyData) => {
        toggleCompanyActivation(
            companyData,
            config,
            (respo) => {
                // console.log(respo, "activate");
                toast.success(`${respo?.data?.message}`);
                getCompany(1, searchableData);
            },
            (err) => {
                toast.error(`${err?.response?.data?.message}`);
            }
        );
    };

    const handleClosePendingPayment = () => {
        setOpenPaymentRequest(false);
        setPopupPaymentDetails(null);
        setPaymentReqData({});
        setPaymentRequestError({});
    };
    const handleSearchType = () => {
        setIsDateTypeSearch(!isDateTypeSearch);
    };
    // console.log(popupPaymentDetails, "p_details");
    const handleSearchChange = (e) => {
        const regex = /^[A-Za-z0-9]*$/;
        if (regex.test(e.target.value)) {
            setSearchableData(e.target.value);
        }
    };
    const handleDateChange = (e, dateType) => {
        const value = e.target.value;
        if (dateType === 'startDate') {
            setStartDate(value);
        } else if (dateType === 'endDate') {
            setEndDate(value);
        }
    };
    useEffect(() => {
        getCompany(page, "", "", "")
    }, [page])
    useEffect(() => {
        getCompany(1, searchableData, startDate, endDate);
    }, [openEditCompany, searchableData, companyType, startDate, endDate]);
    useEffect(() => {
        if (companyView === "payment_lists") {
            getPendingPaymentRequest();
        }
    }, [companyView]);
    const handleCompanyUpdate = (item) => {
        setEditSelectedItem(item);
        setOpenEditCompany("update");
        setAllCompanyData((old) => {
            return old?.results?.paginated_queryset?.map((obj) => {
                if (obj.id == item.id) {
                    return {
                        ...obj,
                        company_name: item ? item?.company_name : "",
                        gst_number: item ? item?.gst : "",
                        owner_name: item ? item?.owner_name : "",
                        phone: item ? item?.mobile_no : "",
                        email: item ? item?.email : "",
                        website: item ? item?.website : "",
                        businessCategory: item ? item?.bussiness_category : "",
                    };
                }
                return obj;
            });
        });
    };

    const handleCompanyProfile = (item) => {
        sessionStorage?.setItem("companyName", item?.company_name);
        // sessionStorage?.setItem("activeComponent", "profile");
        dispatch(updateCompanyData(item));
        dispatch(handleIsCompanyProfileActive(true));
        navigate("/customer_profile");

        sessionStorage.setItem("c_id", item?._id);
    };

    //fetch whatsapp message
    const [whatsAppTemplates, setWhatsappTemplate] = useState('');

    const getWhatsappTemplate = () => {
        axios
            .get(`${baseUrl}double-tick/template/fetch?module_name=company_templates`, config)
            .then((respo) => {
                // console.log("respo......in company",respo);
                handleOpenTemplate(true);
                setWhatsappTemplate(respo?.data)

            });
    }
    const [openTemplate, setOpenTemplate] = React.useState(false);

    const handleOpenTemplate = () => {
        setOpenTemplate(true);
    };
    useEffect(() => {
        getWhatsappTemplate()
    }, []);

    const sendWhatsAppMessage = (template_unique_key, leadtitem) => {
        setSendBtnWait(<DotLoader color="#f57842" loading={true} size={20} />);
        // console.log("data............",leadtitem)
        let formattedNumber = leadtitem.contact_number;
        // Check if the number is Indian and adjust accordingly
        if (formattedNumber.length === 10 && /^[789]\d{9}$/.test(formattedNumber)) {
            formattedNumber = '91' + formattedNumber; // Prepend '91' for 10-digit Indian numbers
        }

        const formData = {
            number: formattedNumber,
            unique_key: template_unique_key,
        };
        // console.log("data for send", formData)
        // console.log("config",config)
        axios
            .get(`${baseUrl}double-tick/send/text?number=${formData.number}&unique_key=${formData.unique_key}&name=${leadtitem.company_name}`, config)
            .then((respo) => {
                // console.log("respo", respo);
                toast?.success("Template sent successfully!");
                setTimeout(() => {
                    setSendBtnWait(<SendIcon />);
                    setOpenPaymentRequest(false);
                    setWhatsAppMessage(null);
                }, 500);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    // Note & Task

    // Note and task
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let myData = {
            ...noteTaskData,
            [name]: value,
        };

        // console.log(myData,"myyyyyyyyy");
        setNoteTaskData(myData);
    };
    const formDataForNoteTask = {
        company_id: mouseActive?._id,
        description: noteTaskData?.noteDescription,
        note_type: noteType,
        datetime: noteType === "Note" ? null : noteTaskData?.taskDateTime,
    };
    // console.log('form data',formDataForNoteTask);
    // console.log('form data dussra',noteTaskData);
    const submitNoteNTask = () => {
        axios
            .post(
                `${baseUrl}super-admin/create/${formDataForNoteTask.note_type}/${formDataForNoteTask.company_id}/`,
                formDataForNoteTask,
                config
            )
            .then((respo) => {
                // console.log(respo);
                setActiveNotes(false);
                setNoteTaskData(null);
                toast?.success(
                    `${noteType} for ${companyName} is submitted successfully!`
                );
            })
            .catch((err) => {
                toast?.failed("failed");
            });
    };
    const handleSaveComment = (item) => {
        // console.log("item in save",item)
        const commentData = {
            comment: commentValue,
        };
        // console.log("comment", commentData)
        if (commentData.comment !== null && commentData.comment !== "") {
            setErrorComment("none");
            axios
                .post(`${baseUrl}super-admin/company/${item._id}/create-comment`, commentData, config)
                .then((respo) => {
                    // console.log(respo);
                    getComments(item?._id);
                    setCommentValue("");
                    toast?.success("Comment is saved successfully!");
                })
                .catch((err) => {
                    // console.log(err);
                });
        } else {
            setErrorComment("block");
        }
    };
    const getRecentList = (item, note) => {
        axios
            .get(`${baseUrl}super-admin/fetch-recent/${item?._id}/${note}`, config)
            .then((respo) => {
                let recentData = respo?.data;
                setRecentList(recentData);
                // console.log(respo?.data, "recentData");
            });
    };
    const getComments = (item) => {
        // console.log("item........",item._id);
        axios.get(`${baseUrl}super-admin/company/${item}/fetch-comment`, config).then((respo) => {
            // console.log(respo);
            setCommentsList(respo?.data);
        });
    };
    useEffect(() => {
        if (handleExpendComment?.display) {
            getComments(handleExpendComment?.id);
        }
    }, [handleExpendComment]);

    // Notes open and close command
    const openNotes = (item, note) => {
        setMouseActive(item);
        setCompanyName(item?.company_name);
        setActiveNotes(true);
        setNoteType(note);
        if (note === "Note" || note === "Task") {
            getRecentList(item, note);
        }
    };
    const closeNotes = () => {
        setActiveNotes(false);
        setMouseActive(null);
        setNoteType("");
        setNoteTaskData(null);
    };

    const currentStatusFunc = (keys, value, companyItem, page) => {
        const data =
            keys === "status"
                ? { status: value }
                : keys === "dealValue"
                    ? {
                        dealValue: value,
                        dealClosingDate: new Date(),
                        // uuid: companyItem?._id,
                    }
                    : {
                        // uuid: companyItem?._id,
                        expected_dealValue: value,
                        expected_dealClosingDate: expecteDealClosingDate,
                    };
        // setStatusValue(value);
        // setIsCurrentLead(companyItem?._id);

        axios
            .patch(
                `${baseUrl}super-admin/${companyItem?._id}/update-company-status`,
                data,
                config
            )
            .then((respo) => {
                toast.success(
                    `Successfully updated ${keys === "dealValue" ? "deal value" : "expected deal value"
                    } for ${companyItem?.company_name}.`
                );
                // console.log(respo, "updateLead");
                getCompany(page, "", "", "");
                closeDealDialogue();
                setDealValue("");
                setExpectedDealValue("");
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
                // console.log(err);
            });
    };

    const dealValue = (item, dealType) => {
        // console.log(item, dealType, "dealValue556");
        setActiveDeal({
            dealType: dealType,
            id: item?._id,
        });
        if (dealType === "dealValue") {
            setDealValue(item?.dealValue);
        } else {
            setExpectedDealValue(item?.expected_dealValue);
            setExpecteDealClosingDate(item?.expected_dealClosingDate);
        }
    };
    const closeDealDialogue = () => {
        setActiveDeal(null);
    };
    return (
        <div className="customers_page">
            {/* Assign empployee Portal */}
            <div style={{}} className={toggleAssign?.classN} id="assignEmployee">
                <div className="assignPopupInner">
                    <div className="containerTicket">
                        <div
                            className="cutAssign"
                            onClick={() => {
                                setToggleAssign({
                                    classN: "assignPopup",
                                    company_id: "",
                                    company_name: "",
                                    category: "",
                                    status: "",
                                    date: "",
                                    time: "",
                                    description: "",
                                });
                            }}
                        >
                            <AiOutlineClose />
                        </div>
                        <div className="assign_heading" style={{ textAlign: "center" }}>
                            Assign Employee For Company
                        </div>
                        <div className="ticketDetailsContainer">
                            <div className="rightTicket">
                                <div className="ticketTitle">Company-Details</div>
                                <div className="deatilsGridForTicket">
                                    <div className="ticketTite">Company Name</div>
                                    <div>:</div>
                                    <div className="ticketNames">
                                        {toggleAssign?.company_name}
                                    </div>
                                    <div className="ticketTite">Category</div>
                                    <div>:</div>
                                    <div className="ticketNames">
                                        {toggleAssign?.bussiness_category}
                                    </div>
                                    <div className="ticketTite">Status</div>
                                    <div>:</div>
                                    <div className="ticketNames">{toggleAssign?.status}</div>
                                </div>
                                <div className="subjectTicket">Description</div>
                                <div className="ticketNames">{toggleAssign?.description}</div>
                            </div>
                            <div
                                className="chooseEmployeeSection"
                                style={{ padding: "10px" }}
                            >
                                <div className="assign_heading">
                                    Select Employee To Assign This Company
                                </div>
                                <select
                                    className="selectEmployee"
                                    onChange={(e) => setEmployeeId(e.target.value)}
                                >
                                    <option value={""}>Choose Employee</option>
                                    {employeeData?.map((data, i) => {
                                        return (
                                            <option key={i} value={data?._id}>
                                                {data?.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="assignBtn">
                            <button
                                onClick={() => {
                                    assignAdminUser();
                                    setTimeout(() => {
                                        getCompany(1, "");
                                    }, 1000);
                                    setTimeout(() => {
                                        setToggleAssign({
                                            classN: "assignPopup",
                                            lead_id: "",
                                            company_name: "",
                                            category: "",
                                            status: "",
                                            date: "",
                                            time: "",
                                            description: "",
                                        });
                                    }, 2000);
                                }}
                            >
                                {wait}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Toaster position="top-center" reverseOrder={false} />
            {/* Notes Popup */}
            <div
                style={activeNotes ? { display: "block" } : { display: "none" }}
                className="notes-overlay"
            >
                {(noteType === "Note" || noteType === "Task") && (
                    <div className="notes-overlay-inner">
                        <div className="notes-form-container">
                            <div className="note-list">
                                <div
                                    style={{
                                        textAlign: "center",
                                        fontSize: "20px",
                                        color: "#1877F2",
                                    }}
                                >
                                    Recent {noteType}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        maxHeight: "400px",
                                        overflow: "auto",
                                    }}
                                >
                                    {recentList?.data?.map((item, index) => {
                                        let date = new Date(
                                            item?.createdAt
                                        );
                                        let dateOfNote = date?.toLocaleDateString();
                                        let timeOfNote = date?.toLocaleTimeString();
                                        return (
                                            <div key={index}>
                                                <div className="recent-note-list">
                                                    <div>
                                                        <div
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "#393939",
                                                            }}
                                                        >
                                                            {item?.description}
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: "10px",
                                                                color: "#1877F2",
                                                            }}
                                                        >
                                                            {dateOfNote},{timeOfNote}
                                                        </div>
                                                    </div>
                                                    <div
                                                        onClick={() => expendCommentHandler(item)}
                                                        className="comment-btn-container"
                                                    >
                                                        <div
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <InsertCommentOutlinedIcon
                                                                style={{
                                                                    fontSize: "18px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="comment-btn">
                                                            Comment{" "}
                                                            <div
                                                                style={{
                                                                    rotate: "-90deg",
                                                                }}
                                                            >
                                                                <ArrowBackIosNewOutlinedIcon
                                                                    style={{
                                                                        fontSize: "18px",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display:
                                                            handleExpendComment?.display &&
                                                                handleExpendComment?.id === item?._id
                                                                ? "block"
                                                                : "none",
                                                    }}
                                                    className="comments-container"
                                                >
                                                    {commentsList?.data?.map((comment, index) => {
                                                        let commentDate = new Date(comment?.createdAt);
                                                        let commentDateOfNote =
                                                            commentDate?.toLocaleDateString();
                                                        let commentTimeOfNote =
                                                            commentDate?.toLocaleTimeString();
                                                        return (
                                                            <div className="comments-list-row">
                                                                <div style={{ fontSize: "14px" }}>
                                                                    {comment?.comment}
                                                                </div>
                                                                <div
                                                                    style={{ color: "#1877F2", fontSize: "10px" }}
                                                                >
                                                                    {commentDateOfNote}, {commentTimeOfNote}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    <div className="comment-input-container">
                                                        <div className="comment-input">
                                                            <textarea
                                                                placeholder="Write comments"
                                                                className="comment-input-area"
                                                                value={commentValue}
                                                                onChange={(e) =>
                                                                    setCommentValue(e.target.value)
                                                                }
                                                            />
                                                            <span
                                                                style={{
                                                                    display: errorComment,
                                                                    color: "red",
                                                                    fontSize: "10px",
                                                                }}
                                                            >
                                                                Please enter comment!
                                                            </span>
                                                        </div>
                                                        <div className="comment-action-btns">
                                                            <button
                                                                className="note-save-btn"
                                                                onClick={() => handleSaveComment(item)}
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className="title-note">
                                    <div style={{ color: "#1877F2" }}>Add {noteType}</div>
                                    <div onClick={closeNotes} className="close-notes">
                                        <CloseOutlinedIcon />
                                    </div>
                                </div>
                                <div className="notes-form">
                                    <div className="note-input-box">
                                        <label className="note-label">Write Your {noteType}</label>
                                        <textarea
                                            style={{
                                                maxWidth: "300px",
                                            }}
                                            className="note-inchar note-text"
                                            name="noteDescription"
                                            value={noteTaskData?.noteDescription}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="note-input-box">
                                        <label className="note-label">Pick Date and Time</label>
                                        <input
                                            className="note-inchar"
                                            type="datetime-local"
                                            name="taskDateTime"
                                            value={noteTaskData?.taskDateTime}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                    <button
                                        onClick={() => submitNoteNTask()}
                                        className="note-save-btn"
                                    >
                                        Save {noteType}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* Notes Popup End */}
            <Dialog
                onClose={() => handleClosePendingPayment()}
                open={openPaymentRequest}
            >
                {whatsAppMessage ? (
                    <div className="whatsapp-form-container">
                        <div className="main-container-for-whatsapp">
                            <div className="title-note">
                                <div style={{ color: "#1877F2" }}>
                                    Send WhatsApp Message to{" "}
                                    <b>{whatsAppMessage?.company_name}</b>
                                </div>
                                <div
                                    onClick={() => setOpenPaymentRequest(false)}
                                    className="close-whatsapp"
                                >
                                    <CloseOutlinedIcon />
                                </div>
                            </div>
                            {/* <div className="whatsapp-list">
              {whatsAppTemplates?.fetch_list_of_templates?.map((template, index) => {
                    return (
                      <div key={index} className="whatsapp-list-item-container">
                        <div
                          style={{
                            display:
                              selectedMessage?.messageId === template?.id &&
                              selectedMessage?.isTrue &&
                              "none",
                          }}
                          className="whatsapp-message-to-send"
                        >
                          <div
                            style={{
                              overflow: "auto",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {template?.message}
                          </div>
                          <div
                            onClick={() =>
                              setSelectedMessage({
                                isTrue: true,
                                messageId: template?.id,
                              })
                            }
                            className="select-btn"
                          >
                            Select to edit message & send
                          </div>
                        </div>
                        <div
                          style={{
                            display:
                              selectedMessage?.messageId === template?.id &&
                              selectedMessage?.isTrue
                                ? "flex"
                                : "none",
                          }}
                          className="whatsapp-list-item-focus-container"
                        >
                          <textarea
                            className="WhatsAppArea"
                            defaultValue={template?.message}
                            onChange={(e) =>
                              setTemplateMessage(e?.target?.value)
                            }
                          />
                          <div className="whatsapp-btn-container">
                            <button
                              style={{
                                backgroundColor: "#1877F2",
                                borderRadius: "5px",
                                padding: "5px",
                                border: "none",
                                color: "#FFFFFF",
                                fontSize: "14px",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5px",
                              }}
                              onClick={() =>
                                sendWhatsAppMessage(
                                  templateMessage
                                    ? templateMessage
                                    : template?.message
                                )
                              }
                            >
                              {sendBtnWait}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div> */}
                            <div className="whatsapp-list">
                                {whatsAppTemplates?.fetch_list_of_templates?.map((template, index) => {
                                    return (
                                        <div className="whatsapp-list-item-container" key={index}>
                                            <div className="template-header">
                                                {template?.template_heading}
                                            </div>
                                            <div>
                                                {template?.template_body}
                                            </div>
                                            <div className="template-footer">
                                                {template?.template_footer}
                                            </div>

                                            <div className="whatsapp-btn-container">
                                                <button
                                                    data-title={
                                                        "Send " + template?.template_heading
                                                    }
                                                    className="whatsapp-button"
                                                    onClick={() =>
                                                        sendWhatsAppMessage(
                                                            template.template_unique_key,
                                                            whatsAppMessage
                                                        )
                                                    }
                                                >
                                                    <SendIcon />
                                                </button>
                                            </div>
                                        </div>

                                    );
                                })}

                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            padding: "4%",
                            minWidth: "350px",
                        }}
                    >
                        <div
                            style={{
                                display: "grid",
                                gap: "10px",
                                gridTemplateColumns: "65% 35%",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        padding: " 0  0 10px 0",
                                        fontSize: "20px",
                                        color: "#1877f2",
                                    }}
                                >
                                    Payment Request
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Company Name : </div>
                                        <div>{popupPaymentDetails?.company_id?.company_name}</div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Category : </div>
                                        <div>{popupPaymentDetails?.category}</div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Transaction ID : </div>
                                        <div>{popupPaymentDetails?.transaction_id}</div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Payment Mode : </div>
                                        <div>Offline</div>
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>Amount : </div>
                                            <input
                                                value={paymentReqData?.amount}
                                                required
                                                onChange={(e) =>
                                                    setPaymentReqData({
                                                        ...paymentReqData,
                                                        amount: e.target.value,
                                                    })
                                                }
                                                type="text"
                                                style={{
                                                    height: "28px",
                                                    outline: "none",
                                                    border: "1px solid gray",
                                                    padding: "0 5px",
                                                    borderRadius: "7px",
                                                    letterSpacing: "1px",
                                                }}
                                            />
                                        </div>
                                        {paymentRequestError?.amount && (
                                            <span
                                                style={{ justifyContent: "flex-end" }}
                                                className="cs-custom-error-msg"
                                            >
                                                <ErrorOutlineOutlinedIcon />
                                                {paymentRequestError?.amount}
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>Amount (in words) : </div>
                                            <textarea
                                                value={paymentReqData?.amount_in_word}
                                                onChange={(e) =>
                                                    setPaymentReqData({
                                                        ...paymentReqData,
                                                        amount_in_word: e.target.value,
                                                    })
                                                }
                                                required
                                                style={{
                                                    width: "auto",
                                                    minHeight: "50px",
                                                    outline: "none",
                                                    border: "1px solid gray",
                                                    padding: "3px 5px",
                                                    borderRadius: "7px",
                                                    letterSpacing: "1px",
                                                }}
                                            />
                                        </div>
                                        {paymentRequestError?.word_amount && (
                                            <span
                                                style={{ justifyContent: "flex-end" }}
                                                className="cs-custom-error-msg"
                                            >
                                                <ErrorOutlineOutlinedIcon />
                                                {paymentRequestError?.word_amount}
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Status : </div>
                                        <select
                                            onChange={(e) =>
                                                setPaymentReqData({
                                                    ...paymentReqData,
                                                    status: e.target.value,
                                                })
                                            }
                                            defaultValue={popupPaymentDetails?.status}
                                            style={
                                                paymentReqData?.status === "Pending"
                                                    ? pendingBorder
                                                    : paymentReqData?.status === "Reject"
                                                        ? rejectedBorder
                                                        : paymentReqData?.status === "Success"
                                                            ? successBorder
                                                            : pendingBorder
                                            }
                                        >
                                            <option value={"Success"}>Success</option>
                                            <option value={"Pending"}>Pending</option>
                                            <option value={"Reject"}>Reject</option>
                                        </select>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Request Date : </div>
                                        <div>{popupPaymentDetails?.create_at}</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: "35px",
                                    minWidth: "fit-content",
                                }}
                            >
                                <div
                                    style={{
                                        maxWidth: "250px",
                                    }}
                                >
                                    <div>Payment Screenshot:</div>
                                    <a href={popupPaymentDetails?.media_file} target="blank">
                                        <img
                                            style={{ width: "100%", marginTop: "10px" }}
                                            src={popupPaymentDetails?.media_file}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "10px 0" }}>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "baseline",
                                    // padding: "10px 0",
                                    color: "rgb(70 70 70)",
                                    fontSize: "12px",
                                }}
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={paymentReqData?.isChecked}
                                    onChange={(e) =>
                                        setPaymentReqData({
                                            ...paymentReqData,
                                            isChecked: e.target.checked,
                                        })
                                    }
                                    style={{ outline: "none", border: "1px solid green" }}
                                />
                                I hereby declare that i read all the details carefully and i'm
                                authorized to perform this action.
                            </div>
                            {paymentRequestError?.isChecked && (
                                <span className="cs-custom-error-msg">
                                    <ErrorOutlineOutlinedIcon />
                                    {paymentRequestError?.isChecked}
                                </span>
                            )}
                        </div>
                        <div style={{ display: "flex", gap: "30px" }}>
                            <button
                                className="create_btn"
                                style={{
                                    background: "#fff",
                                    color: "#1877f2",
                                    border: "1px solid #1877f2",
                                    outline: "none",
                                }}
                                onClick={handleClosePendingPayment}
                            >
                                Cancel
                            </button>
                            <button
                                className="create_btn"
                                style={{ outline: "none" }}
                                onClick={submitPaymentRequest}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}
            </Dialog>
            <div className="customers_inner">
                {openEditCompany === "dashboard" && (
                    <>
                    
                        <div className="customer_table_container">
                            <div className="customer_table_up">
                                <div
                                    className="customer_summery"
                                    style={{ justifyContent: "space-between" }}
                                >
                                    <div className="customer_summery">
                                        <div className="summery_icon">
                                            <MdOutlineSummarize />
                                        </div>
                                        <div className="summer_heading">Company Details</div>
                                    </div>
                                    {/* Buttons and search field */}
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                        }}
                                    >
                                        {companyView === "companies_list" ? (
                                            <>
                                                <button
                                                    className="plan-input-field"
                                                    style={{
                                                        width: "fit-content",
                                                        backgroundColor: "#1877F259",
                                                        border: "none",
                                                        outline: "none",
                                                        color: "#1877F2",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => handleSearchType()}
                                                >
                                                    Search by {isDateTypeSearch ? "other" : "date"}
                                                </button>
                                                {/* Search By Date From & To */}
                                                {isDateTypeSearch ? (
                                                    <>
                                                        <div>
                                                            <input
                                                                style={{ width: "120px" }}
                                                                className="plan-input-field"
                                                                type="date"
                                                                placeholder="Start Date"
                                                                onChange={(e) =>
                                                                    handleDateChange(e, "startDate")
                                                                }
                                                            />
                                                        </div>
                                                        <div>-</div>
                                                        <div>
                                                            <input
                                                                style={{ width: "120px" }}
                                                                className="plan-input-field"
                                                                type="date"
                                                                placeholder="End Date"
                                                                onChange={(e) => handleDateChange(e, "endDate")}
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>
                                                            <input
                                                                className="plan-input-field"
                                                                type="search"
                                                                placeholder="Search by email, name, mobile"
                                                                onChange={(e) => {
                                                                    handleSearchChange(e);
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <div>
                                                <input
                                                    className="plan-input-field"
                                                    type="search"
                                                    placeholder="Search by name, transaction id"
                                                    onChange={(e) => {
                                                        handleSearchChange(e);
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {companyView === "companies_list" ? (
                                            <div
                                                className="create_btn"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    color: "#1877f2",
                                                    userSelect: "none",
                                                }}
                                                onClick={() => setCompanyView("payment_lists")}
                                            >
                                                Payment Requests
                                            </div>
                                        ) : (
                                            <div
                                                className="create_btn"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    color: "#1877f2",
                                                    userSelect: "none",
                                                }}
                                                onClick={() => setCompanyView("companies_list")}
                                            >
                                                Companies List
                                            </div>
                                        )}
                                        {companyView !== "payment_lists" && (
                                            <>
                                                <div
                                                    className="create_btn"
                                                    style={{
                                                        backgroundColor: `${companyType === true ? "#1877F2" : "#fff"
                                                            }`,
                                                        color: `${companyType === true ? "#FFFFFF" : "#1877F2"
                                                            }`,
                                                        userSelect: "none",
                                                    }}
                                                    onClick={() => setCompanyType(true)}
                                                >
                                                    Active Companies
                                                </div>
                                                <div
                                                    className="create_btn"
                                                    style={{
                                                        backgroundColor: `${companyType !== true ? "#1877F2" : "#fff"
                                                            }`,
                                                        color: `${companyType !== true ? "#FFFFFF" : "#1877F2"
                                                            }`,
                                                        userSelect: "none",
                                                    }}
                                                    onClick={() => setCompanyType("all")}
                                                >
                                                    All Companies
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="summery_container"
                                    style={{ display: "flex", justifyContent: "space-between" }}
                                >
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <div className="summery_card">
                                            <div className="summery_card_inner" onClick={() => setSearchableData("")}>
                                                <span>{allCompanyData?.status_count?.total_company_count || 0}</span>
                                                <span>Total Companies</span>
                                            </div>
                                        </div>
                                        <div className="summery_card">
                                            <div className="summery_card_inner" onClick={() => setSearchableData("New")}>
                                                <span>
                                                    {allCompanyData?.status_count?.new_company || 0}
                                                </span>
                                                <span style={{ color: "orange" }}>New</span>
                                            </div>
                                        </div>
                                        <div className="summery_card">
                                            <div className="summery_card_inner" onClick={() => setSearchableData("Viewed")}>
                                                <span>
                                                    {allCompanyData?.status_count?.viewed_company || 0}
                                                </span>
                                                <span style={{ color: "#1877F2" }}>Viewed</span>
                                            </div>
                                        </div>
                                        <div className="summery_card">
                                            <div className="summery_card_inner" style={{ color: "#1877F2" }} onClick={() => setSearchableData("Contacted")}>
                                                <span>
                                                    {allCompanyData?.status_count?.contacted_company || 0}
                                                </span>
                                                <span style={{ color: "#1877F2" }}>Contacted</span>
                                            </div>
                                        </div>
                                        <div className="summery_card">
                                            <div className="summery_card_inner" onClick={() => setSearchableData("Deal_done")}>
                                                <span>
                                                    {allCompanyData?.status_count?.deal_done_company || 0}
                                                </span>
                                                <span style={{ color: "green" }}>Deal Done</span>
                                            </div>
                                        </div>
                                        <div className="summery_card">
                                            <div className="summery_card_inner" onClick={() => setSearchableData("Unqualified")}>
                                                <span>
                                                    {allCompanyData?.status_count?.unqualified_company ||
                                                        0}
                                                </span>
                                                <span style={{ color: "red" }}>Unqualified</span>
                                            </div>
                                        </div>
                                        <div className="summery_card">
                                            <div className="summery_card_inner" onClick={() => setCompanyType(true)}>
                                                <span>
                                                    {allCompanyData?.status_count?.active_company || 0}
                                                </span>
                                                <span style={{ color: "green" }}>Active</span>
                                            </div>
                                        </div>
                                        <div className="summery_card">
                                            <div className="summery_card_inner" onClick={() => setCompanyType(false)}>
                                                <span>
                                                    {allCompanyData?.status_count?.deactivated_company ||
                                                        0}
                                                </span>
                                                <span style={{ color: "red" }}>De-Activated</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {companyView === "companies_list" ? (
                                <div className="customer_table">
                                    <table>
                                        <thead>
                                            <tr
                                                style={{
                                                    zIndex: "1299",
                                                }}
                                            >
                                                <th>#</th>
                                                <th>Company</th>
                                                <th></th>
                                                <th>Contact Number</th>
                                                <th>Email Id</th>
                                                <th>Expected Deal Value</th>
                                                <th>Deal Value</th>
                                                <th>Date & Time</th>
                                                <th
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Active
                                                </th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allCompanyData?.data !== undefined &&
                                                allCompanyData?.data?.map((companyItem, index) => {
                                                    const date = new Date(companyItem?.createdAt);
                                                    const convertedDate = convertDate(date);
                                                    const convertedTime = convertTime(date);
                                                    return (
                                                        <tr
                                                            style={{
                                                                backgroundColor: `${companyItem?.active_status
                                                                    ? "#1877F229"
                                                                    : ""
                                                                    }`,
                                                            }}
                                                            key={index + 1}
                                                        >
                                                            <td>
                                                                {index +
                                                                    (+allCompanyData?.current_page *
                                                                        +allCompanyData?.page_size -
                                                                        (+allCompanyData?.page_size - 1))}
                                                            </td>
                                                            <td>{companyItem?.company_name}</td>
                                                            <td>
                                                                <div id="btnContainer">
                                                                    <div className="contact-action-container">
                                                                        <div className="contact-action-inner">
                                                                            <div
                                                                                data-title={
                                                                                    "Add Notes for " +
                                                                                    companyItem?.company_name
                                                                                }
                                                                                className="action-btn-container"
                                                                                onClick={() =>
                                                                                    openNotes(companyItem, "Note")
                                                                                }
                                                                            >
                                                                                <NoteAddOutlinedIcon fontSize="small" />
                                                                            </div>
                                                                            <div
                                                                                data-title={
                                                                                    "Add Task for " +
                                                                                    companyItem?.company_name
                                                                                }
                                                                                className="action-btn-container"
                                                                                onClick={() =>
                                                                                    openNotes(companyItem, "Task")
                                                                                }
                                                                            >
                                                                                <AddTaskOutlinedIcon fontSize="small" />
                                                                            </div>

                                                                            <div
                                                                                data-title={
                                                                                    "Call to " +
                                                                                    companyItem?.company_name
                                                                                }
                                                                                className="action-btn-container"
                                                                            >
                                                                                <CallOutlinedIcon fontSize="small" />
                                                                            </div>
                                                                            <a
                                                                                href={
                                                                                    "mailto:" +
                                                                                    companyItem?.company_email
                                                                                }
                                                                            >
                                                                                <div
                                                                                    data-title={
                                                                                        "Send email to " +
                                                                                        companyItem?.company_name
                                                                                    }
                                                                                    className="action-btn-container"
                                                                                >
                                                                                    <MailOutlineIcon fontSize="small" />
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{companyItem?.contact_number}</td>
                                                            <td>{companyItem?.company_email}</td>
                                                            <td
                                                                style={{
                                                                    fontWeight: "500",
                                                                    minWidth: "100px",
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        activeDeal?.id === companyItem?._id &&
                                                                            activeDeal?.dealType ===
                                                                            "expectedDealValue"
                                                                            ? "dealValue"
                                                                            : ""
                                                                    }
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        position: "relative",
                                                                    }}
                                                                >
                                                                    <span
                                                                        onClick={() =>
                                                                            dealValue(
                                                                                companyItem,
                                                                                "expectedDealValue"
                                                                            )
                                                                        }
                                                                    >
                                                                        &#8377;{" "}
                                                                        {companyItem?.expected_dealValue
                                                                            ? companyItem?.expected_dealValue
                                                                            : "----"}
                                                                    </span>
                                                                    {activeDeal?.id === companyItem?._id &&
                                                                        activeDeal?.dealType ===
                                                                        "expectedDealValue" ? (
                                                                        <div
                                                                            style={{
                                                                                color: "#1877F2",
                                                                                position: "absolute",
                                                                                left: "-20%",
                                                                                top: "-2px",
                                                                                zIndex: "9999999",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    gap: "10px",
                                                                                    backgroundColor: "#FFFFFF",
                                                                                    boxShadow: "0px 4px 12px #00000069",
                                                                                    padding: "5px",
                                                                                    borderRadius: "5px",
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    type="text"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                        border: "none",
                                                                                        outline: "1px solid #1877F2",
                                                                                        borderRadius: "5px",
                                                                                        padding: "5px",
                                                                                    }}
                                                                                    defaultValue={companyItem?.expected_DealValue}
                                                                                    name="expectedDealInputValue"
                                                                                    onChange={(e) =>
                                                                                        setExpectedDealValue(
                                                                                            e?.target?.value
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <input
                                                                                    type="datetime-local"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                        border: "none",
                                                                                        outline: "1px solid #1877F2",
                                                                                        borderRadius: "5px",
                                                                                        padding: "5px",
                                                                                    }}
                                                                                    defaultValue={
                                                                                        expecteDealClosingDate
                                                                                    }
                                                                                    name="expectedClosingDate"
                                                                                    onChange={(e) => setExpecteDealClosingDate(e?.target?.value)}
                                                                                />
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        gap: "5px",
                                                                                        width: "100%",
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        style={{
                                                                                            padding: "5px",
                                                                                            border: "none",
                                                                                            backgroundColor: "#f34747",
                                                                                            color: "#FFFFFF",
                                                                                            borderRadius: "5px",
                                                                                            cursor: "pointer",
                                                                                            width: "100%",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            closeDealDialogue()
                                                                                        }
                                                                                    >
                                                                                        Cancel
                                                                                    </button>
                                                                                    <button
                                                                                        style={{
                                                                                            padding: "5px",
                                                                                            border: "none",
                                                                                            backgroundColor: "#1877F2",
                                                                                            color: "#FFFFFF",
                                                                                            borderRadius: "5px",
                                                                                            cursor: "pointer",
                                                                                            width: "100%",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            currentStatusFunc(
                                                                                                "expectedDealValue",
                                                                                                expectedDealValue,
                                                                                                companyItem,
                                                                                                allCompanyData?.current_page
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Save
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontWeight: "500",
                                                                    minWidth: "100px",
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        activeDeal?.id === companyItem?._id &&
                                                                            activeDeal?.dealType === "dealValue"
                                                                            ? "dealValue"
                                                                            : ""
                                                                    }
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        position: "relative",
                                                                    }}
                                                                >
                                                                    <span
                                                                        onClick={() =>
                                                                            dealValue(companyItem, "dealValue")
                                                                        }
                                                                    >
                                                                        &#8377;{" "}
                                                                        {companyItem?.dealValue
                                                                            ? companyItem?.dealValue
                                                                            : "----"}
                                                                    </span>

                                                                    {activeDeal?.id === companyItem?._id &&
                                                                        activeDeal?.dealType === "dealValue" ? (
                                                                        <div
                                                                            style={{
                                                                                color: "#1877F2",
                                                                                position: "absolute",
                                                                                left: "-20%",
                                                                                top: "-2px",
                                                                                zIndex: "9999999",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    gap: "10px",
                                                                                    backgroundColor: "#FFFFFF",
                                                                                    boxShadow: "0px 4px 12px #00000069",
                                                                                    padding: "3px",
                                                                                    borderRadius: "5px",
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                        border: "none",
                                                                                        outline: "1px solid #1877F2",
                                                                                        borderRadius: "5px",
                                                                                        padding: "5px",
                                                                                    }}
                                                                                    defaultValue={
                                                                                        companyItem?.dealValue
                                                                                    }
                                                                                    name="dealInputValue"
                                                                                    onChange={(e) =>
                                                                                        setDealValue(e?.target?.value)
                                                                                    }
                                                                                />
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        gap: "5px",
                                                                                        width: "100%",
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        style={{
                                                                                            padding: "5px",
                                                                                            border: "none",
                                                                                            backgroundColor: "#f34747",
                                                                                            color: "#FFFFFF",
                                                                                            borderRadius: "5px",
                                                                                            cursor: "pointer",
                                                                                            width: "100%",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            closeDealDialogue()
                                                                                        }
                                                                                    >
                                                                                        Cancel
                                                                                    </button>
                                                                                    <button
                                                                                        style={{
                                                                                            padding: "0 5px",
                                                                                            border: "none",
                                                                                            backgroundColor: "#1877F2",
                                                                                            color: "#FFFFFF",
                                                                                            borderRadius: "5px",
                                                                                            cursor: "pointer",
                                                                                            padding: "5px",
                                                                                            width: "100%",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            currentStatusFunc(
                                                                                                "dealValue",
                                                                                                dealVal,
                                                                                                companyItem,
                                                                                                allCompanyData?.current_page
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Save
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {convertedDate}, {convertedTime}
                                                            </td>
                                                            <td>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        id={companyItem._id}
                                                                        checked={!companyItem?.is_deactivated}
                                                                        onChange={(e) => {
                                                                            let data = {
                                                                                id: companyItem._id,
                                                                                is_deactivated: e.target.checked,
                                                                            };
                                                                            activateDeactivateCompany(data);
                                                                        }}
                                                                    />
                                                                    <label htmlFor={companyItem._id}>
                                                                        Genuine
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td onClick={() => setActiveDeal(null)}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        cursor: "pointer",
                                                                        alignItems: "center",
                                                                        gap: "10px",
                                                                        minWidth: "50px",
                                                                        position: "relative",
                                                                    }}
                                                                    onClick={() => activeStatusDropDown(companyItem)}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            color: `${companyItem?.status === "New"
                                                                                ? "#228B22"
                                                                                : companyItem?.status === "Viewed"
                                                                                    ? "#1877F2"
                                                                                    : companyItem?.status === "Contacted"
                                                                                        ? "#FF4500"
                                                                                        : companyItem?.status === "Deal Done"
                                                                                            ? "#228B22"
                                                                                            : companyItem?.status === "Unqualified"
                                                                                                ? "#8B0000"
                                                                                                : "#228B22"
                                                                                }`,
                                                                            backgroundColor: `${companyItem?.status === "New"
                                                                                ? "#228B2245"
                                                                                : companyItem?.status === "Viewed"
                                                                                    ? "#1877F245"
                                                                                    : companyItem?.status === "Contacted"
                                                                                        ? "#FF450045"
                                                                                        : companyItem?.status === "Deal Done"
                                                                                            ? "#228B2245"
                                                                                            : companyItem?.status === "Unqualified"
                                                                                                ? "#8B000045"
                                                                                                : "#228B2245"
                                                                                }`,
                                                                            padding: "5px",
                                                                            borderRadius: "3px",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {companyItem?.status}
                                                                    </div>
                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                color: "#393939",
                                                                            }}
                                                                            className={
                                                                                activeLeadStatus === companyItem?._id
                                                                                    ? "rightCloseArrow"
                                                                                    : "downCloseArrow"
                                                                            }
                                                                        >
                                                                            &#9662;
                                                                        </div>
                                                                    </div>
                                                                    {activeLeadStatus === companyItem?._id && (
                                                                        <div className="dropdown-container">
                                                                            <div className="dropdown-inner">
                                                                                <div className="list-container">
                                                                                    {leadStatus?.map((item, index) => (
                                                                                        <div
                                                                                            onClick={() =>
                                                                                                currentStatusFunc(
                                                                                                    "status",
                                                                                                    item,
                                                                                                    companyItem,
                                                                                                    allCompanyData?.current_page
                                                                                                )
                                                                                            }
                                                                                            className="status-iem"
                                                                                            key={index}
                                                                                        >
                                                                                            {item}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="action_btns">
                                                                    <AiOutlineEye
                                                                        className="action_icon_view"
                                                                        onClick={() => {
                                                                            // navigate("/customer_profile");
                                                                            handleCompanyProfile(companyItem);
                                                                        }}
                                                                    />
                                                                    <FaWhatsapp
                                                                        className="action_icon_edit"
                                                                        onClick={() => {
                                                                            setWhatsAppMessage(companyItem);
                                                                            setOpenPaymentRequest(true);
                                                                        }}
                                                                    />{" "}
                                                                    {companyItem?.assign_to ? (
                                                                        <div style={{ position: "relative" }}>
                                                                            <span>
                                                                                {companyItem?.assign_to?.name}
                                                                            </span>
                                                                            <button
                                                                                className={`assignTickets reAssign`}
                                                                                onClick={() => {
                                                                                    // console.log('lead click',companyItem?._id);
                                                                                    setToggleAssign({
                                                                                        classN: "assignPopupShow",
                                                                                        company_id: companyItem?._id,
                                                                                        company_name:
                                                                                            companyItem?.company_name,
                                                                                        category:
                                                                                            companyItem?.bussiness_category,
                                                                                        status: companyItem?.status,
                                                                                        date: convertedDate,
                                                                                        time: convertedTime,
                                                                                        description:
                                                                                            companyItem?.description,
                                                                                    });
                                                                                }}
                                                                            >
                                                                                Re-Assign
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <button
                                                                            className="assignTickets"
                                                                            onClick={() => {
                                                                                setToggleAssign({
                                                                                    classN: "assignPopupShow",
                                                                                    company_id: companyItem?._id,
                                                                                    company_name:
                                                                                        companyItem?.company_name,
                                                                                    category:
                                                                                        companyItem?.bussiness_category,
                                                                                    status: companyItem?.status,
                                                                                    date: convertedDate,
                                                                                    time: convertedTime,
                                                                                    description:
                                                                                        companyItem?.description,
                                                                                });
                                                                            }}
                                                                        >
                                                                            Assign
                                                                        </button>
                                                                    )}
                                                                    {localStorage?.getItem("permissions") ===
                                                                        "Super_user" && (
                                                                            <HiOutlinePencil
                                                                                className="action_icon_edit"
                                                                                onClick={() =>
                                                                                    handleCompanyUpdate(companyItem)
                                                                                }
                                                                            />
                                                                        )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                // : storedDataCompanyList?.results?.paginated_queryset?.map((companyItem, index) => {
                                                //   let date = new Date(companyItem?.create_at);
                                                //   let convertedDate = date.toLocaleDateString();
                                                //   let convertedTime = date.toLocaleTimeString();
                                                //   // console.log(companyItem, "c_item")
                                                //   return (
                                                //     <tr style={{
                                                //       backgroundColor: `${companyItem?.company_type === "Genuin" && "#99f59952"}`
                                                //     }} key={index + 1}>
                                                //       <td>{index + (storedDataCompanyList?.page * 5 - 4)}</td>
                                                //       <td>{companyItem?.company_name}</td>
                                                //       <td>{companyItem?.owner_name}</td>
                                                //       <td>{companyItem?.mobile_no}</td>
                                                //       <td>{companyItem?.email}</td>
                                                //       {/* <td>
                                                //       <input type="checkbox" id="1" />
                                                //       <label htmlFor="1">Toggle</label>
                                                //     </td> */}
                                                //       {/* <td>goup name</td> */}
                                                //       <td>
                                                //         {convertedDate}, {convertedTime}
                                                //       </td>
                                                //       <td>
                                                //         <div className="action_btns">
                                                //           <AiOutlineEye
                                                //             className="action_icon_view"
                                                //             onClick={() => {
                                                //               // navigate("/customer_profile");
                                                //               handleCompanyProfile(companyItem);
                                                //             }}
                                                //           />
                                                //           {localStorage?.getItem("permissions") ===
                                                //             "Super_user" && (
                                                //               <HiOutlinePencil
                                                //                 className="action_icon_edit"
                                                //                 onClick={() =>
                                                //                   handleCompanyUpdate(companyItem)
                                                //                 }
                                                //               />
                                                //             )}
                                                //           {/* <MdDeleteOutline className="action_icon_delete" /> */}
                                                //         </div>
                                                //       </td>
                                                //     </tr>
                                                //   );
                                                // })
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        // console.log(allCompanyData?.next_page, "pagenation")
                                    }
                                    {allCompanyData?.data?.length === 0 && (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                padding: "40px",
                                                backgroundColor: "#c9c9c9",
                                                fontSize: "30px",
                                                fontWeight: "600",
                                                color: "#595959",
                                            }}
                                        >
                                            No Data Available!
                                        </div>
                                    )}
                                    {allCompanyData?.data?.length > 0 && (
                                        <div className="pegination">
                                            <div
                                                style={{
                                                    userSelect: "none",
                                                }}
                                                className="peginationinner"
                                            >
                                                <MdKeyboardArrowLeft
                                                    // style={{
                                                    //   color: `${
                                                    //     !allCompanyData?.previous_page  && "#999999"
                                                    //   }`,
                                                    //   cursor: `${
                                                    //     !allCompanyData?.previous_page  && "no-drop"
                                                    //   }`,
                                                    // }}
                                                    className="arrowPreNext"
                                                    onClick={() => {
                                                        allCompanyData?.previous_page &&
                                                            handlePageChange(allCompanyData?.previous_page);
                                                    }}
                                                />
                                                {renderPageNumber()}
                                                <MdKeyboardArrowRight
                                                    // style={{
                                                    //   color: `${
                                                    //     !allCompanyData?.next_page && "#999999"
                                                    //   }`,
                                                    //   cursor: `${
                                                    //     !allCompanyData?.next_page && "no-drop"
                                                    //   }`,
                                                    // }}
                                                    className="arrowPreNext"
                                                    onClick={() => {
                                                        allCompanyData?.next_page &&
                                                            handlePageChange(allCompanyData?.next_page);
                                                    }}
                                                />
                                                <span>
                                                    {allCompanyData?.current_page} out of{" "}
                                                    {Math.ceil(allCompanyData?.pages)}
                                                </span>
                                            </div>
                                        </div>)}
                                </div>
                            ) : companyView === "payment_lists" ? (
                                <div className="customer_table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Company</th>
                                                <th>Category</th>
                                                <th>Transaction ID</th>
                                                <th>Payment Receipt</th>
                                                <th>Date & Time</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allPendingPaymentData?.length > 0 ? (
                                                allPendingPaymentData?.map((companyItem, index) => {
                                                    // let date = new Date(companyItem?.create_at);
                                                    // let convertedDate = date.toLocaleDateString();
                                                    // let convertedTime = date.toLocaleTimeString();
                                                    // console.log(allPendingPaymentData, "c_item");
                                                    return (
                                                        <tr key={index + 1}>
                                                            <td>{index + 1}</td>
                                                            <td>{companyItem?.company_id?.company_name}</td>
                                                            <td>{companyItem?.category}</td>
                                                            <td>{companyItem?.transaction_id}</td>
                                                            <td>--</td>
                                                            <td>{companyItem?.createdAt}</td>
                                                            <td>{companyItem?.status}</td>
                                                            <td>
                                                                <div className="action_btns">
                                                                    <AiOutlineEye
                                                                        className="action_icon_view"
                                                                        onClick={() => {
                                                                            setPopupPaymentDetails(companyItem);
                                                                            setPaymentReqData({
                                                                                ...paymentReqData,
                                                                                status: companyItem?.status,
                                                                            });
                                                                            setOpenPaymentRequest(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan={8}
                                                        style={{
                                                            fontWeight: "600",
                                                            color: "#595959",
                                                            fontSize: "14px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        No data available!
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
                {openEditCompany === "update" && (
                    <CreateForm
                        editSelectedItem={editSelectedItem}
                        setOpenEditCompany={setOpenEditCompany}
                        allCompanyData={allCompanyData}
                        setAllCompanyData={setAllCompanyData}
                    />
                )}
                {openEditCompany === "profile" && (
                    <CustomerProfile
                        editSelectedItem={editSelectedItem}
                        setOpenEditCompany={setOpenEditCompany}
                    />
                )}
            </div>
        </div>
    );
};

export default EmployeeCompany;
