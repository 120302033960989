import React, { useState } from 'react'
import { FiUser } from 'react-icons/fi';
import { MdOutlineLocalShipping, MdOutlineReceiptLong, MdOutlineSummarize } from 'react-icons/md';
import CompanyLogs from './CompnyLogs';
import SubCompanyGrid from './SubCompanyGrid';
import { useSelector } from 'react-redux';
import { convertDate, convertTime } from '../../../webutils';
import DealValueCard from '../../../reusableComponent/DealValueCard';
import { HiOutlineHome } from 'react-icons/hi';

const CompanyProfile = () => {
    var token;
    if (localStorage.getItem("saToken2"))
        token = localStorage.getItem("saToken2");
    var config = { headers: { Authorization: `Bearer ${token}` } };
    const { companyData } = useSelector((store) => store.main);
    const [activeAddress, setActiveAddress] = useState("permanent");
    const [activeBtn, setActiveBtn] = useState("subCompany");
    const handleBtnClick = (name) => {
        setActiveBtn(name);
    }
    // console.log(companyData, "companyData")
    const handleAddressClick = (btnValue) => {
        setActiveAddress(btnValue);
      };
    return (
        <div>
            <div style={{
                display: 'flex',
                padding: '10px',
                gap: '5px',
            }}>
                <div style={{
                    width: '380px',
                    height: '80vh',
                    backgroundColor: '#F5F5F5',
                    borderRadius: '5px',
                    display: 'flex',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        padding: '5px',
                    }}>
                        <div className="cust_title">Company Details</div>
                        <div style={{
                            display: "grid",
                            gap: '10px',
                            gridTemplateColumns: "auto 16px auto"
                        }}>
                            <span>Company Name</span>
                            <span>:</span>
                            <span>{companyData?.company_name}</span>
                            <span>Company Email</span>
                            <span>:</span>
                            <span>{companyData?.company_email}</span>
                            <span>Phone Number</span>
                            <span>:</span>
                            <span>{companyData?.contact_number}</span>
                            <span>Company GST</span>
                            <span>:</span>
                            <span>{companyData?.gst_number}</span>
                            <span>Registered Date</span>
                            <span>:</span>
                            <span>{convertDate(companyData?.createdAt)} | {convertTime(companyData?.createdAt)}</span>
                            <span>Active Status</span>
                            <span>:</span>
                            {
                                !companyData?.is_deactivated ?
                                    <span style={{
                                        color: '#04b504'
                                    }}>{"Active"}</span> :
                                    <span style={{
                                        color: '#fb0505'
                                    }}>{"De-activated"}</span>
                            }
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                            justifyContent: "center",
                            padding: "10px",
                        }}>
                            <DealValueCard
                                titleText={"Expected Deal"}
                                amount={companyData?.expected_dealValue || 0.00}
                                dealDate={convertDate(companyData?.expected_dealClosingDate)}
                                dealTime={convertTime(companyData?.expected_dealClosingDate)}
                                borderColor={"orange"}
                            />
                            <DealValueCard
                                titleText={"Deal Value"}
                                amount={companyData?.dealValue || 0.00}
                                dealDate={convertDate(companyData?.dealClosingDate)}
                                dealTime={convertTime(companyData?.dealClosingDate)}
                                borderColor={"orange"}
                            />
                        </div>
                        {/* Address */}
                        <div>
                            <div className="cust_title">Company Address</div>
                            <div className="profile_options">
                                <div
                                    className={`profile_option ${activeAddress === "permanent" && `profile_option_active`
                                        }`}
                                    onClick={() => handleAddressClick("permanent")}
                                >
                                    <HiOutlineHome />
                                    <div className="option_txt">Permanent</div>
                                </div>
                                <div
                                    className={`profile_option ${activeAddress === "shipping" && `profile_option_active`
                                        }`}
                                    onClick={() => handleAddressClick("shipping")}
                                >
                                    <MdOutlineLocalShipping />
                                    <div className="option_txt">Shipping</div>
                                </div>
                                <div
                                    className={`profile_option ${activeAddress === "billing" && `profile_option_active`
                                        }`}
                                    onClick={() => handleAddressClick("billing")}
                                >
                                    <MdOutlineReceiptLong />
                                    <div className="option_txt">Billing</div>
                                </div>
                            </div>
                            {/* Permanent Address */}
                            {activeAddress === "permanent" && (
                                <div className="personal_details">
                                    <div className="naming_title">Street</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.company_details?.company_address
                                            ? companyData?.company_details?.company_address
                                            : "---"}
                                    </div>
                                    <div className="naming_title">City</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.company_details?.city
                                            ? companyData?.company_details?.city
                                            : "---"}
                                    </div>
                                    <div className="naming_title">State</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.company_details?.state
                                            ? companyData?.company_details?.state
                                            : "---"}
                                    </div>
                                    <div className="naming_title">Pin Code</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.company_details?.area_pin_code
                                            ? companyData?.company_details?.area_pin_code
                                            : "---"}
                                    </div>
                                    <div className="naming_title">Country</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.company_details?.country
                                            ? companyData?.company_details?.country
                                            : "---"}
                                    </div>
                                </div>
                            )}
                            {/* Shipping Address */}
                            {activeAddress === "shipping" && (
                                <div className="personal_details">
                                    <div className="naming_title">Street</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.shipping_details?.shipping_address
                                            ? companyData?.shipping_details?.shipping_address
                                            : "---"}
                                    </div>
                                    <div className="naming_title">City</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.shipping_details?.city
                                            ? companyData?.shipping_details?.city
                                            : "---"}
                                    </div>
                                    <div className="naming_title">State</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.shipping_details?.state
                                            ? companyData?.shipping_details?.state
                                            : "---"}
                                    </div>
                                    <div className="naming_title">Pin Code</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.shipping_details?.shipping_area_pin_code
                                            ? companyData?.shipping_details?.shipping_area_pin_code
                                            : "---"}
                                    </div>
                                    <div className="naming_title">Country</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.shipping_details?.country
                                            ? companyData?.shipping_details?.country
                                            : "---"}
                                    </div>
                                </div>
                            )}

                            {/* Billing Address */}
                            {activeAddress === "billing" && (
                                <div className="personal_details">
                                    <div className="naming_title">Street</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.billing_details?.billing_address
                                            ? companyData?.billing_details?.billing_address
                                            : "---"}
                                    </div>
                                    <div className="naming_title">City</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.billing_details?.city
                                            ? companyData?.billing_details?.city
                                            : "---"}
                                    </div>
                                    <div className="naming_title">State</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.billing_details?.state
                                            ? companyData?.billing_details?.state
                                            : "---"}
                                    </div>
                                    <div className="naming_title">Pin Code</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.billing_details?.billing_area_pin_code
                                            ? companyData?.billing_details?.billing_area_pin_code
                                            : "---"}
                                    </div>
                                    <div className="naming_title">Country</div>
                                    <div className="colon">:</div>
                                    <div className="naming_txt">
                                        {" "}
                                        {companyData?.billing_details?.country
                                            ? companyData?.billing_details?.country
                                            : "---"}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{
                    width: "70%"
                }}>
                    <div className="customer_profile_up">
                        <div className="profile_options">
                            <div
                                className={`profile_option ${activeBtn === "subCompany" && `profile_option_active`
                                    }`}
                                onClick={() => handleBtnClick("subCompany")}
                            >
                                <FiUser />
                                <div className="option_txt">Sub Companies</div>
                            </div>
                            <div
                                className={`profile_option ${activeBtn === "logs" && `profile_option_active`
                                    }`}
                                onClick={() => handleBtnClick("logs")}
                            >
                                <MdOutlineSummarize />
                                <div className="option_txt">Login Activity</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {activeBtn === "subCompany" && (
                            <div>
                                <SubCompanyGrid
                                    config={config}
                                />
                            </div>
                        )}
                        {activeBtn === "logs" && (
                            <div>
                                <CompanyLogs />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyProfile