import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { companyProposalViewDataHandler } from "../../../../../ReduxSlices/MainSlice";
import Button from "../../../../reusableComponent/Button";
import InputField from "../../../../reusableComponent/InputField";
import { baseUrl } from "../../../../../BaseUrl";

const CustomerProposal = () => {
  const navigate = useNavigate();
  const [proposalData, setProposalData] = useState([]);
  const dispatch = useDispatch();
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const cid = sessionStorage?.getItem("c_id");
  const sid = sessionStorage?.getItem("s_id");
  useEffect(() => {
    axios
      .get(`${baseUrl}leads/${cid}/${sid}/fetch/company-proposal`, config)
      .then((res) => {
        setProposalData(res?.data);
        // console.log(res?.data?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);
  return (
    <div className="customer_proposal_page">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          // disabled
          className={"simpleBtn proposalBtn"}
          text={"New Proposal"}
          onClick={() => navigate("/proposals")}
          style={{ background: "#1976D2", color: "#ffffff" }}
        />
        <InputField
          placeholder="Search..."
          endIcon={<AiOutlineSearch />}
          className="searchInput"
        />
      </div>
      <div className="leads-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Subject</th>
              <th>Plans</th>
              <th>Proposal Amount</th>
              <th>Proposal Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {proposalData?.data?.length > 0 ? (
              proposalData?.data?.map((item, index) => {
                // let planData = JSON?.parse(item?.plan_description);
                let newDate = new Date(item?.createdAt);
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.subject}</td>
                    <td>
                      <div>
                        {item?.planType}
                        {/* {planData?.map((planItem, i) => {
                          return (
                            <span
                              title={planItem?.planDescription}
                              style={{
                                display: "block",
                                paddingBottom: "3px",
                              }}
                              key={i}
                            >
                              {planItem?.planType && i + 1 + "."}{" "}
                              {planItem?.planType}
                            </span>
                          );
                        })} */}
                      </div>
                    </td>
                    <td>
                      &#8377;{" "}
                      {item?.totalAmount}
                      {/* {planData
                        ?.reduce(
                          (sum, planItem) => sum + +planItem?.totalAmount,
                          0
                        )
                        ?.toFixed(2)} */}
                    </td>
                    <td>{newDate?.toLocaleDateString()}</td>
                    <td>
                      <AiOutlineEye
                        className="action_icon_view reminder-eye-icon"
                        onClick={() => {
                          sessionStorage.setItem("lp_id", item?._id);
                          navigate("/view-invoice/" + "company_proposal");
                          dispatch(companyProposalViewDataHandler(item));
                        }}
                      />
                    </td>{" "}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={6}
                  style={{
                    fontWeight: "600",
                    color: "#595959",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  No data available!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerProposal;
