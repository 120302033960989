import "./createInvoice.css";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../Assets/logo.png";
import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { baseUrl } from "../../../BaseUrl";
import { useSelector } from "react-redux";

const CreateInvoice = () => {
  const editSelectedLead = useSelector((store) => store?.main?.leadData);
  // console.log("proposals........12",  Lead)

  // const baseUrl = "http://192.168.114.239:8000/";
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  // token =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkzMTMzNDU1LCJpYXQiOjE2OTMwNDcwNTUsImp0aSI6IjdiMWYxODY4NzkzODRiOThiMmFmNTdkMTA0OTFkN2FmIiwidXNlcl9pZCI6MSwidXNlcm5hbWUiOiJyIn0.OqPlHszfn9T-MpsGPTk__DxZTnFZgl7lN5jkLy-KqBQ";
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [pageTitle, setPageTitle] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [subject, setSubject] = useState("");
  // const [longDescription, setLongDescription] = useState("");
  const [detailsData, setDetailsData] = useState();
  const cid = sessionStorage.getItem("c_id");
  const sid = sessionStorage.getItem("s_id");
  const [planPriceData, setPlanPriceData] = useState();
  // Invoice for company
  const [companyData, setCompanyData] = useState();
  const [companyPlan, setCompanyPlan] = useState();
  const [wait, setWait] = useState("Submit");
  const [selectedPlanDetails, setSelectedPlanDetails] = useState();
  var totalAmountIncGST;
  const getCompanyDetails = () => {
    axios
      .get(`${baseUrl}Invoice_for_company_details/${cid}/`, config)
      .then((respo) => {
        if (respo.status === 200) {
          // console.log(respo);
          setCompanyData(respo.data);
          if (location?.pathname("/create-invoice")) {
            // getPlan(respo.data?.Plan_details?.plan_type);
          }
          // setTimeout(() => {

          // }, 1000);
        }
      });
  };
  // console.log(companyData )

  useEffect(() => {
    // const getPlanData = () => {
    // axios.get(`${baseUrl}subscriptions/${id}/`, config)
    axios
      .get(`${baseUrl}subscription/super-admin/fetch-card`, config)
      .then((respo) => {
        // if (respo.status === 200) {
        console.log("respo plan types", respo?.data?.fetchingCards);
        // setPlanPriceData(respo.data.results[0]?.price);
        setCompanyPlan(respo?.data?.fetchingCards);

        // }
      });
  }, []);

  const [invoiceData, setInvoiceData] = useState({
    planType: "",
    planDescription: "",
    teamLength: "",
    unitPrice: "",
  });
  const handleInvDataChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData({
      ...invoiceData,
      [name]: value,
    });
  };
  const [paymentWait, setPaymentWait] = useState("Generate Payment Link");
  const [paymentLink, setPaymentLink] = useState(null);
  const [planID, setPlanID] = useState();
  const [selectedPlan, setSelectedPlan] = useState("");
  const invoiceFormData = {
    planType: invoiceData?.planTpe,
    planDescription: invoiceData?.planDescription,
    teamLength: (+invoiceData?.teamLength)?.toFixed(2),
    unitPrice: (+invoiceData?.unitPrice)?.toFixed(2),
    amount: (+invoiceData?.teamLength * +invoiceData?.unitPrice)?.toFixed(2),
    totalAmount: (
      +invoiceData?.teamLength * +invoiceData?.unitPrice +
      (+invoiceData?.teamLength * +invoiceData?.unitPrice * 18) / 100
    )?.toFixed(2),
  };
  // console.log(invoiceFormData)

  // console.log(invoiceData.planPrice)
  // Proposals for lead
  const getLeadData = () => {
    axios
      .get(
        baseUrl +
        (location.pathname === "/proposals"
          ? "proposal-company/"
          : "super_admin_lead/lead/") +
        cid +
        "/",
        config
      )
      .then((respo) => {
        console.log("respo", respo)
        if (respo.status === 200) {
          setDetailsData(respo.data);
        }
      });
  };
  useEffect(() => {
    if (location.pathname === "/lead-proposals") {
      setPageTitle("Create Proposal");
      getLeadData();
    } else if (location.pathname == "/create-invoice") {
      setPageTitle("Create Invoice");
      getCompanyDetails();
    } else if (location.pathname == "/proposals") {
      setPageTitle("Create Proposal");
      getCompanyDetails();
    }
  }, [location.pathname]);
  useEffect(() => {
    setInvoiceData({
      planDetails: "",
      planType: "",
      planPrice: companyData?.Plan_details?.plan_price,
      billDetails: "",
      billType: "",
      chatCount: "",
      pricePerChat: "",
    });
  }, [companyData]);
  // Dynamic inputs
  const [invoicingForm, setInvoicingForm] = useState([
    {
      planType: "",
      planDescription: "",
      teamLength: "",
      unitPrice: "",
      amount: "",
      totalAmount: "",
    },
  ]);
  const handleChangeVal = (index, e, planValue) => {
    // console.log(planValue, "plaaaaaaaaaa");
    // console.log("handleeeeeeee",planValue);
    setPlanID(planValue);
    const { name, value } = e.target;
    let subs_price, total_amount;
    let newFormValues = [...invoicingForm];
    console.log("newFormValuesssssss", newFormValues);
    newFormValues[index][e.target.name] = e.target.value;
    let t_length = newFormValues[index]["teamLength"];
    let unit_price = newFormValues[index]["unitPrice"];
    if (name === "teamLength" || name === "unitPrice") {
      subs_price = t_length * unit_price;
      total_amount =
        t_length * unit_price + ((t_length * unit_price) / 100) * 18;
      // console.log(subs_price, total_amount, "aaaaa");
    }
    newFormValues[index]["amount"] = planValue ? planValue?.plan_price : subs_price;
    newFormValues[index]["totalAmount"] = planValue ? +planValue?.plan_price + (+planValue?.plan_price * 18) / 100 : total_amount;
    newFormValues[index]["planType"] = planValue ? planValue?.plan_name : newFormValues[index]["planType"];
    newFormValues[index]["unitPrice"] = planValue ? planValue?.unit_member_price : newFormValues[index]["unitPrice"];
    newFormValues[index]["teamLength"] = planValue ? planValue?.member_count : newFormValues[index]["teamLength"];
    setInvoicingForm(newFormValues);
  };
  const addField = () => {
    // setPlanID(null);
    setInvoicingForm([
      ...invoicingForm,
      {
        planType: "",
        planDescription: "",
        teamLength: "",
        unitPrice: "",
        amount: "",
        totalAmount: "",
      },
    ]);
  };
  // Sum of all amount including GST

  var totalAmountIncGST =
    invoicingForm &&
    (
      invoicingForm
        ?.reduce((sum, planItem) => sum + +planItem?.totalAmount, 0)
        ?.toFixed(2) * 100
    )?.toFixed(0);
  var allAmount = ((+planID?.plan_price + (+planID?.plan_price * 18) / 100)?.toFixed(2)) * 100;
  const paymentData = {
    amount: allAmount,
    currency: "inr",
    company_id: companyData?.company_detials[0]?.id,
    plan_id: planID?._id,
    plan_duration: "Monthly",
    plan_category: "CST",
  };
  // console.log(paymentData, "jhgfjgjfgjg");
  // console.log(invoicingForm, "selectedPlanDetails");
  const generatePaymentLink = () => {
    setPaymentLink(null);
    setPaymentWait("Generating...");
    axios
      ?.post(`${baseUrl}strpay/create_payment_link/`, paymentData, config)
      .then((respo) => {
        setPaymentWait("Payment Link Generated");
        // console.log(respo?.data, "link");
        setTimeout(() => {
          setPaymentLink(respo?.data?.payment_link);
          setPaymentWait("Regenerate Payment Link");
        }, 1000);
        setPaymentLink(respo?.data?.payment_link);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  console.log("location.pathname...................", location.pathname)
  let dataSend = {
    subject: subject,
    lead_id: location.pathname === "/lead-proposals" ? editSelectedLead?._id : null,
    company_id: location.pathname === "/proposals" ? cid : null,
    sub_company_id: location.pathname === "/proposals" ? sid : null,
    // description: longDescription,
    plan_description: JSON.stringify(invoicingForm),
    amount: location.pathname === "/lead-proposals" ? allAmount : allAmount,
    totalAmount: location.pathname === "/lead-proposals" ? totalAmountIncGST : totalAmountIncGST,
    email: location.pathname === "/lead-proposals" ? editSelectedLead.email : null
  };
  const invoicedData = {
    comapny_id: location.pathname === "/create-invoice" ? cid : null,
    sub_company_id: location.pathname === "/create-invoice" ? sid : null,
    plan_description: JSON.stringify(invoicingForm),
    payment_link: paymentLink,
    pathname: location.pathname
  };
  // console.log(invoicedData);
  // Send invoice data ############################################
  const createCompInvoice = () => {
    // console.log(invoiceFormData)
    setWait("Please wait...");
    axios
      .post(`${baseUrl}super-admin/invoice/company-create`, invoicedData, config)
      .then((respo) => {
        // if (respo.status === 200) {
        setWait("Data Submitted Successfully");
        // console.log(respo?.data);
        setTimeout(() => {
          setPaymentLink(null);
          navigate("/customer_profile");
        }, 1000);
        // }
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  const submitInvoice = (e) => {
    e.preventDefault();
    setWait("Please wait...");
    if (location.pathname === "/lead-proposals") {
      // console.log("data in proposal", dataSend);
      axios
        .post(baseUrl + "leads/create-proposal/", dataSend, config)
        .then((respo) => {
          setWait("Data Submitted Successfully");
          setTimeout(() => {
            if (location.pathname === "/create-invoice") {
              navigate("/customer_profile");
            } else if (location.pathname === "/proposals") {
              navigate("/customer_profile");
            } else if (location.pathname === "/lead-proposals") {
              navigate("/leads");
              sessionStorage.setItem("navigate", "Proposals");
            }
          }, 1000);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else if (location.pathname === "/create-invoice") {
      createCompInvoice();
    } else if (location.pathname === "/proposals") {
      axios
        .post(baseUrl + "leads/create-proposal/", dataSend, config)
        .then((respo) => {
          setWait("Data Submitted Successfully");
          // console.log(respo);
          setTimeout(() => {
            navigate("/customer_profile");
          }, 2000);
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };
  const removeFormFields = (i) => {
    let newFormValues = [...invoicingForm];
    newFormValues.splice(i, 1);
    setInvoicingForm(newFormValues);
  };
  // console.log(invoicingForm[0]?.totalAmount, "totalAmountIncGST");
  return (
    <div className="create-invoice-main">
      <div className="view_heading">
        <div className="heading_left">
          <div className="form_title">{pageTitle}</div>
        </div>
        <div className="heading_right">
          <div
            className="back_btn"
            onClick={() => {
              if (location.pathname === "/create-invoice") {
                navigate("/customer_profile");
              } else if (location.pathname === "/proposals") {
                navigate("/customer_profile");
              } else if (location.pathname === "/lead-proposals") {
                navigate("/leads");
              }
            }}
          >
            <span>Back</span>
          </div>
        </div>
      </div>
      <div className="create-invoice-inner">
        <form className="invoice_proposal">
          {/* uper input feilds */}
          <div className="formTitle"></div>
          <div className="addrDetailsIP">
            <div className="recipt_payment">
              <div className="container">
                <div className="main_payment">
                  <div
                    className="first_side"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <div className="left_side">
                      <div className="cmpny_name">
                        <div style={{ color: "#323232", fontSize: "14px" }}>
                          From
                        </div>
                        <div className="logo_chatsense">
                          <img src={logo} alt="" />
                        </div>
                      </div>
                      <div className="company_name">
                        <h3
                          style={{
                            fontSize: "14px",
                            color: "#393939",
                          }}
                        >
                          Brimming Grace Capital Pvt. Ltd.
                        </h3>
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#393939",
                        }}
                        className="company_details"
                      >
                        <p>108, phase-1</p>
                        <p>Udyog Vihar, Sector - 20</p>
                        <p>Gurugram, Haryana</p>
                        <p>GST Number : 07AAICB6042L1ZI</p>
                      </div>
                    </div>
                    <div className="right_side">
                      <div style={{ color: "#323232", fontSize: "14px" }}>
                        {location.pathname === "/proposals" ||
                          location.pathname === "/lead-proposals"
                          ? "To,"
                          : "Bill To,"}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#393939",
                        }}
                        className="cmpny_details"
                      >
                        {/* {console.log(companyData)} */}
                        <h3 style={{ fontSize: "14px", color: "#393939" }}>
                          {editSelectedLead?.company
                            ? editSelectedLead?.company
                            : "-----"}
                        </h3>
                        <p>
                          {editSelectedLead?.area
                            ? editSelectedLead?.area

                            : "--------"}
                        </p>
                        <p>
                          {editSelectedLead?.city
                            ? `${editSelectedLead?.city}, ${editSelectedLead?.pincode}`
                            : companyData?.company_detials[0]?.city
                              ? `${companyData?.company_detials[0]?.city}, ${companyData?.company_detials[0]?.state}, ${companyData?.company_detials[0]?.country} - ${companyData?.company_detials[0]?.pincode}`
                              : "------------"}
                        </p>
                        <p>
                          GST Number:{" "}
                          {editSelectedLead?.gst
                            ? editSelectedLead?.gst
                            : companyData?.company_detials[0].gst
                              ? companyData?.company_detials[0]?.gst
                              : "-------"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="invoiceProposalGrid">
            <div className="invoicingTitle">
              {location.pathname === "/proposals" ||
                location.pathname === "/lead-proposals"
                ? "Proposal"
                : " Invoice"}{" "}
              Details
            </div>
            {location.pathname === "/proposals" ||
              location.pathname === "/lead-proposals" ? (
              <>
                <div className="subNdescription">
                  <label>Subject</label>
                  <input
                    type={"text"}
                    className="inputsubject"
                    name="subject"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                    placeholder="Subject"
                    maxLength={70}
                  />
                </div>
                {/* <div className="subNdescription">
                  <label>Brief Description</label>
                  <textarea
                    type={"text"}
                    className="inputdesc"
                    name="long_desc"
                    onChange={(e) => setLongDescription(e.target.value)}
                    value={longDescription}
                    placeholder="Long Description"
                  />
                </div> */}
              </>
            ) : (
              ""
            )}
            {/* <div className="gridTable">
              <div className="attributes">Plan Type</div>
              <div className="attributes">Plan Description</div>
              <div className="attributes">Team Length</div>
              <div className="attributes">Price Per Member</div>
              <div className="attributes">Subscription Price(in rupees)</div>
              <div className="attributes">Total Amount (include 18% GST)</div>
              {
                location.pathname === "/proposals" ||
                  location.pathname === "/lead-proposals" ||
                  location?.pathname === "/create-invoice"
                  ? invoicingForm?.map((element, index) => {
                    console.log(element, invoicingForm,"invoicing form");
                    return (
                      <React.Fragment key={index}>
                        <div className="payInput">
                          {location.pathname === "/proposals" || location.pathname === "/lead-proposals" ? (
                            <input
                              maxLength={30}
                              type="text"
                              className="inputPay"
                              name="planType"
                              value={element?.planType}
                              onChange={(e) => handleChangeVal(index, e, planID)}
                              placeholder="Enter plan type"
                            />
                          ) : (
                            <select
                              className="inputPay"
                              name="planType"
                              value={element?.planType}
                              onChange={(e) => {
                                const selectedPlanId = e.target.value;
                                const selectedPlan = companyPlan.find(plan => plan._id === selectedPlanId);
                                handleChangeVal(index, e, selectedPlan);
                              }}
                            >
                              <option value="" disabled>Select a plan</option>
                              {companyPlan?.map(item => (
                                <option key={item._id} value={item._id}>
                                  {item.plan_name}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        <div className="payInput">
                          <textarea
                            maxLength={250}
                            className="textPay"
                            name="planDescription"
                            value={element?.planDescription}
                            onChange={(e) => handleChangeVal(index, e, planID)}
                            placeholder="Enter plan description"
                          />
                        </div>
                        <div className="payInput">
                          <input
                            maxLength={4}
                            type="text"
                            className="inputPay"
                            name="teamLength"
                            value={element?.teamLength}
                            onChange={(e) => handleChangeVal(index, e, planID)}
                            placeholder="Number of users"
                          />
                        </div>
                        <div className="payInput">
                          <input
                            maxLength={4}
                            type="text"
                            className="inputPay"
                            name="unitPrice"
                            value={element?.unitPrice}
                            onChange={(e) => handleChangeVal(index, e, planID)}
                            placeholder="Unit price"
                          />
                        </div>
                        <div className="payInput">
                          <input
                            disabled
                            type="text"
                            className="inputPay"
                            name="amount"
                            value={(+element?.teamLength * +element?.unitPrice)?.toFixed(2)}
                            placeholder="Total amount"
                          />
                        </div>
                        <div className="payInput">
                          <input
                            disabled
                            type="text"
                            className="inputPay"
                            name="totalAmount"
                            value={((+element?.teamLength * +element?.unitPrice) * 1.18)?.toFixed(2)}
                            placeholder="Total Amount (incl. GST)"
                          />
                          {index ? (
                            <>
                              <FiMinusCircle
                                className="button remove"
                                onClick={() => removeFormFields(index)}
                              />
                            </>
                          ) : null}
                        </div>
                      </>
                    );
                  })
                  : ""
                // <>
                //   <>
                //     <div className="payInput">
                //       <input
                //         type={"text"}
                //         className="inputPay"
                //         name="planDetails"
                //         value={invoiceData?.planDetails}
                //         onChange={(e) => handleInvDataChange(e)}
                //         placeholder="Enter details heare..."
                //       />
                //     </div>
                //     <div className="payInput">
                //       <select
                //         className="inputPay"
                //         name="planType"
                //         value={invoiceData?.planType}
                //         onChange={(e) => {
                //           handleInvDataChange(e);
                //           getPlan(e.target.value);
                //         }}
                //       >
                //         <option value={companyData?.Plan_details?.plan_type}>
                //           {companyData?.Plan_details?.plan_name}
                //         </option>
                //         {
                //           // companyData?.subscription_plans ?
                //           companyData?.subscription_plans?.map((item, i) => {
                //             return (
                //               <option key={i} value={item?.id}>
                //                 {item?.name}
                //               </option>
                //             );
                //           })
                //         }
                //       </select>
                //     </div>
                //     <div className="payInput">
                //       <input
                //         disabled
                //         type={"text"}
                //         className={"inputPay"}
                //         placeholder="-------"
                //       />
                //     </div>
                //     <div className="payInput">
                //       <input
                //         type={"text"}
                //         className="inputPay"
                //         name="planPrice"
                //         value={invoiceData?.planPrice}
                //         onChange={(e) => handleInvDataChange(e)}
                //         placeholder="Plan Price"
                //       />
                //     </div>
                //     <div className="payInput">
                //       <input
                //         type={"text"}
                //         className="inputPay"
                //         disabled
                //         // name="planAmount"
                //         value={(+invoiceData?.planPrice)?.toFixed(2)}
                //         // onChange={(e) => handleInvDataChange(e)}
                //         placeholder="Amount"
                //       />
                //     </div>
                //   </>
                //   <>
                //     <div className="payInput">
                //       <input
                //         type={"text"}
                //         className="inputPay"
                //         name="billDetails"
                //         value={invoiceData?.billDetails}
                //         onChange={(e) => handleInvDataChange(e)}
                //         placeholder="Enter details heare..."
                //       />
                //     </div>
                //     <div className="payInput">
                //       <select
                //         className="inputPay"
                //         name="billType"
                //         value={invoiceData?.billType}
                //         onChange={(e) => handleInvDataChange(e)}
                //       >
                //         <option>Select Bill Type</option>
                //         <option value={"monthly"}>Monthly</option>
                //         <option value={"yearly"}>Yearly</option>
                //       </select>
                //     </div>
                //     <div className="payInput">
                //       <input
                //         // disabled={invoiceData?.invoiceType === 'Subscription' ? true : false}
                //         type={"text"}
                //         className={"inputPay"}
                //         name="chatCount"
                //         value={invoiceData?.chatCount}
                //         onChange={(e) => handleInvDataChange(e)}
                //         placeholder="Chat Count"
                //       />
                //     </div>
                //     <div className="payInput">
                //       <input
                //         type={"text"}
                //         className="inputPay"
                //         name="pricePerChat"
                //         value={invoiceData?.pricePerChat}
                //         onChange={(e) => handleInvDataChange(e)}
                //         placeholder="Price in rupees"
                //       />
                //     </div>
                //     <div className="payInput">
                //       <input
                //         type={"text"}
                //         className="inputPay"
                //         disabled
                //         // name="amount"
                //         value={(
                //           invoiceData?.chatCount * invoiceData?.pricePerChat
                //         )?.toFixed(2)}
                //         // onChange={(e) => handleInvDataChange(e)}
                //         placeholder="Amount"
                //       />
                //     </div>
                //   </>
                // </>
              }
            </div> */}
            <div className="gridTable">
              <div className="attributes">Plan Type</div>
              <div className="attributes">Plan Description</div>
              <div className="attributes">Team Length</div>
              <div className="attributes">Price Per Member</div>
              <div className="attributes">Subscription Price(in rupees)</div>
              <div className="attributes">Total Amount (include 18% GST)</div>
              {location.pathname === "/proposals" ||
                location.pathname === "/lead-proposals" ||
                location.pathname === "/create-invoice" ?
                invoicingForm?.map((element, index) => {
                  // console.log(element, invoicingForm, "invoicing form");
                  return (
                    <React.Fragment key={index}>
                      <div className="payInput">
                        {location.pathname === "/proposals" || location.pathname === "/lead-proposals" ? (
                          <input
                            maxLength={30}
                            type="text"
                            className="inputPay"
                            name="planType"
                            value={element?.planType}
                            onChange={(e) => handleChangeVal(index, e, planID)}
                            placeholder="Enter plan type"
                          />
                        ) : (
                          <select
                            className="inputPay"
                            name="planType"
                            value={element?.plan_name}
                            onChange={(e) => {
                              const selectedPlanId = e.target.value;
                              const selectedPlanData = companyPlan.find(plan => plan._id === selectedPlanId);
                              setSelectedPlan(selectedPlanData);
                              handleChangeVal(index, e, selectedPlanData);

                            }}
                          >
                            <option value="" disabled>Select a plan</option>
                            {companyPlan?.map(item => (
                              <option key={item._id} value={item._id}>
                                {item.plan_name}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                      <div className="payInput">
                        <textarea
                          maxLength={250}
                          className="textPay"
                          name="planDescription"
                          value={element?.planDescription}
                          onChange={(e) => handleChangeVal(index, e, planID)}
                          placeholder="Enter plan description"
                        />
                      </div>
                      <div className="payInput">
                        <input
                          maxLength={4}
                          type="text"
                          className="inputPay"
                          name="teamLength"
                          value={element?.teamLength}
                          onChange={(e) => handleChangeVal(index, e, planID)}
                          placeholder="Number of users"
                        />
                      </div>
                      <div className="payInput">
                        <input
                          maxLength={4}
                          type="text"
                          className="inputPay"
                          name="unitPrice"
                          value={element?.unitPrice}
                          onChange={(e) => handleChangeVal(index, e, planID)}
                          placeholder="Unit price"
                        />
                      </div>
                      <div className="payInput">
                        <input
                          disabled
                          type="text"
                          className="inputPay"
                          name="amount"
                          value={(+element?.teamLength * +element?.unitPrice)?.toFixed(2)}
                          placeholder="Total amount"
                        />
                      </div>
                      <div className="payInput">
                        <input
                          disabled
                          type="text"
                          className="inputPay"
                          name="totalAmount"
                          value={((+element?.teamLength * +element?.unitPrice) * 1.18)?.toFixed(2)}
                          placeholder="Total Amount (incl. GST)"
                        />
                        {index ? (
                          <FiMinusCircle
                            className="button remove"
                            onClick={() => removeFormFields(index)}
                          />
                        ) : null}
                      </div>
                    </React.Fragment>
                  );
                }) : ""
              }
            </div>

            {location?.pathname === "/create-invoice" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "10px",
                }}
              >
                {planID?.price > 0 && (
                  <div>
                    {paymentLink ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            // backgroundColor: "#1877F2",
                            outline: "1px solid green",
                            color: "#1877F2",
                            fontSize: "0.8rem",
                            padding: "10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <a href={paymentLink} target="blank">
                            Open Link
                          </a>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#1877F2",
                            color: "#FFFFFF",
                            fontSize: "0.8rem",
                            padding: "10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => generatePaymentLink()}
                        >
                          {paymentWait}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#1877F2",
                          color: "#FFFFFF",
                          fontSize: "0.8rem",
                          padding: "10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => generatePaymentLink()}
                      >
                        {paymentWait}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="plusContainer">
            <button
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => submitInvoice(e)}
            >
              {wait}
            </button>
            {location.pathname === "/proposals" ||
              location.pathname === "/lead-proposals" ||
              (location?.pathname === "/create-invoice" && (
                <FiPlusCircle className="plus" onClick={() => addField()} />
              ))}
          </div>
          <div className="ipSubmit"></div>
        </form>
      </div>
    </div>
  );
};
export default CreateInvoice;
