import React, { useEffect, useState } from "react";
import "./viewSubs.css";
import './style.css'
import Logo from "../../../Assets/logo.png";
import axios from "axios";
import {BiBadgeCheck} from 'react-icons/bi'

const ViewSubs = () => {
  const [subscriptionData, setSubscriptionData] = useState()

  const getSubscribe = () => {
    axios.get('http://192.168.172.18:8000/myapp/Plan_create/').then((respo) => {
      setSubscriptionData(respo.data)
    })
  }
  // console.log(subscriptionData)
  useEffect(() => {
    getSubscribe();
  }, [])
  return (
    // <div className="full-invoice">
    //   {/* <!-- invoice sheet start here=====>>>> --> */}
    //   <div className="invoice_sheet">
    //     <div className="container">
    //       <div className="invoice_header">
    //         <div className="user_details">
    //           <div className="com-logo">
    //             <img src={Logo} alt="Comapny logo" />
    //           </div>
    //           <div className="user_bill">
    //             <h4>Bill To,</h4>
    //           </div>
    //           <div className="group">
    //             <p>Paytm Group</p>
    //           </div>
    //           <div className="user_add">
    //             <p>261 Group Paytm</p>
    //             <p>Sehstradhara</p>
    //             <p> Sec-51 Dehradun</p>
    //           </div>
    //           <div className="invoice_date">
    //             <h5>
    //               Subscription :- <span>2022-10-05</span>
    //             </h5>
    //           </div>
    //           <div className="Due_date">
    //             <h5>
    //               {" "}
    //               Due Date :- <span>2025-05-10</span>
    //             </h5>
    //           </div>
    //           <div className="agent">
    //             <h5>
    //               First Billing Date :- <span>2025-05-10</span>
    //             </h5>
    //           </div>
    //         </div>
    //         <div className="user_invoice_number">
    //           <div className="user_invoice">
    //             <h3>
    //               INVOICE - <span>0001</span>
    //             </h3>
    //           </div>
    //           <div className="com_prefex">
    //             <h5>Prefex INC</h5>
    //           </div>
    //           <div className="com_details">
    //             <p>Building number 108</p>
    //             <p>Go Work</p>
    //             <p> Sec-20 Gurugram</p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   {/* <!-- invoice details start here===========>>>>>>>>> --> */}
    //   <div className="Invoice_details">
    //     <div className="container">
    //       <div className="grid-container">
    //         <div className="Series">#</div>
    //         <div className="item">Item</div>
    //         <div className="qty">Qty</div>
    //         <div className="rate">Rate</div>
    //         <div className="tax">Tax</div>
    //         <div className="ammount">Ammount</div>
    //         <div className="Series">1</div>
    //         <div className="item">
    //           <h5>Marketing Services</h5>
    //           <p>
    //             {" "}
    //             Nam earum necessitatibus est sunt eligendi. Possimus officiis
    //             velit rem rerum.
    //           </p>
    //         </div>
    //         <div className="qty">2</div>
    //         <div className="rate">360</div>
    //         <div className="tax">
    //           <h5>TAX1</h5>
    //           18.00%
    //         </div>
    //         <div className="ammount">600.00</div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="total_ammount">
    //     <div className="container">
    //       <div className="sub_total">
    //         <div className="total_amm">
    //           {" "}
    //           <span className="first_span">Sub Total</span>{" "}
    //           <span className="second_span">$600.00</span>
    //         </div>
    //       </div>
    //       <div className="sub_total">
    //         <div className="total_amm">
    //           {" "}
    //           <span className="first_span">TAX1 (18.00%)</span>{" "}
    //           <span className="second_span">$108.00</span>
    //         </div>
    //       </div>
    //       <div className="sub_total">
    //         <div className="total_amm">
    //           {" "}
    //           <span className="first_span">Total</span>{" "}
    //           <span className="second_span">$708.00</span>
    //         </div>
    //       </div>
    //       <div className="sub_total">
    //         <div className="total_amm">
    //           {" "}
    //           <span className="first_span">Total Paid</span>{" "}
    //           <span className="second_span">-$708.00</span>
    //         </div>
    //       </div>
    //       <div className="sub_total">
    //         <div className="total_amm">
    //           {" "}
    //           <span className="first_span">Amount Due </span>{" "}
    //           <span className="second_span">$0.0</span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   {/* <!-- terms and condition start here====>>>>>>>>> --> */}
    //   <div className="terms_condition">
    //     <div className="container">
    //       <div className="terms">
    //         <div className="condition_heading">
    //           <h5>Description</h5>
    //         </div>
    //         <div className="conidtion_para">
    //           <p>
    //             Qui distinctio rerum repellendus at et voluptates et. Itaque
    //             nisi sint tempore dolorem ipsa autem. Hic iure est perferendis
    //             quia id dolorum dolores nihil.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="sub_start">
      <div className="container">
        <div className="sub_part">
          <div className="sub_heading">
            <h2>Pricing Plans</h2>
          </div>
          <div className="subs_all_plan">
            {
              subscriptionData?.map((item) => {
                return (
                  <div className="sub_plan">
                    <div className="box"></div> 
                    <div className="first_headings">
                      <div className="name_head">
                        <div className="name_ab">{item?.name}</div>
                      </div>
                    </div>
                    <div className="payment_type">
                      <div className="first_pay">
                        <h1>&#8377; {item?.price}</h1>
                      </div>
                      <div className="mnth">
                        <p>Per Week</p>
                      </div>
                    </div>
                    <div className="para">
                      <div className="second_line">
                        <div className="plan_type">
                          <span>{item?.plan_type}</span>
                        </div>
                      </div>
                      <div className="second_line">
                        <div className="right_img">
                          <BiBadgeCheck/>
                        </div>
                        <div className="second_content">
                          <span>{item?.feature}</span>
                        </div>
                      </div>
                      <div className="second_line">
                        <div className="description">
                          <p>{item?.discription}</p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="select">
                      <div className="last_select">
                        <button>SELECT</button>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSubs;
