import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCompanyData } from "../../../../ReduxSlices/MainSlice";
import "./CustomerProfile.css";
import { baseUrl } from "../../../../BaseUrl";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { HiOutlineHome } from "react-icons/hi";
import { MdOutlineLocalShipping, MdOutlineReceiptLong } from "react-icons/md";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import AnalyticBar from "../../../reusableComponent/AnalyticBar";
import toast from "react-hot-toast";
import { convertDate, convertTime } from "../../../webutils";
import { getSubCompanyDetails } from "../../../../APIs/CompanyAPIs";
ChartJS.register(ArcElement, Tooltip, Legend);
// const baseUrl = process.env.REACT_APP_API_URL;
const CustomerDetails = () => {
  // {sessionStorage.getItem("c_id") !== editSelectedItem?.id && sessionStorage.setItem('c_id', editSelectedItem?.id)}

  const [shippingAddrData, setShippingAddrData] = useState([]);
  const [billingAddrData, setBillingAddrData] = useState([]);
  const [editSelectedItem, setEditSelectedItem] = useState();
  const [activeBtn, setActiveBtn] = useState("permanent");
  const dispatch = useDispatch();
  const sid = sessionStorage.getItem("s_id");
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  let date = new Date(editSelectedItem?.create_at);
  let createdDate = convertDate(date);
  let createdTime = date.toLocaleTimeString();

  const getSubCompany = () => {
    getSubCompanyDetails(
      sid,
      config,
      respo => {
        // console.log(respo.data)
        setEditSelectedItem(respo.data)
      },
      err => {
        console.log(err)
      }
    )
  }
  // Accordion here
  const [blockClass0, setBlockClass0] = useState("");
  const [blockClass1, setBlockClass1] = useState("");
  const [blockClass2, setBlockClass2] = useState("");
  const [blockClass3, setBlockClass3] = useState("");
  const [blockClass4, setBlockClass4] = useState("");
  const [steper, setSteper] = useState([]);
  const [memberCount, setMemberCount] = useState();
  const statusVal = ["New", "Contacted", "Deal Done", "Unqualified"]
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
        },
      },
    },
  };
  const handleAccordion = (i) => {
    if (i === 0) {
      blockClass0 === ""
        ? setBlockClass0("accordion_description_expand")
        : setBlockClass0("");
    } else if (i === 1) {
      blockClass1 === ""
        ? setBlockClass1("accordion_description_expand")
        : setBlockClass1("");
    } else if (i === 2) {
      blockClass2 === ""
        ? setBlockClass2("accordion_description_expand")
        : setBlockClass2("");
    } else if (i === 3) {
      blockClass3 === ""
        ? setBlockClass3("accordion_description_expand")
        : setBlockClass3("");
    } else if (i === 4) {
      blockClass4 === ""
        ? setBlockClass4("accordion_description_expand")
        : setBlockClass4("");
    }
  };
  const handleBtnClick = (btnValue) => {
    setActiveBtn(btnValue);
  };
  // const getCompany = () => {
  //   axios
  //     .get(`${baseUrl}Invoice_for_company_details/${cid}/`, config)
  //     .then((res) => {
  //       setEditSelectedItem(res.data.company_detials[0]);
  //     })
  //     .then((err) => {
  //       // console.log(err);
  //     });
  // };
  // const updateComanyData = (companyData) => {
  //   axios
  //     .patch(`${baseUrl}super-admin/fetch-companies`, companyData, config).then((respo) => {
  //       toast.success(`Status updated`)
  //     }).catch((err) => {
  //       console.log(err)
  //     })
  // }
  // const getBillingAddress = () => {
  //   axios
  //     .get(`${baseUrl}billing-address/${cid}/`, config)
  //     .then((res) => {
  //       // console.log(res);
  //       setShippingAddrData(res.data[0]);
  //     })
  //     .then((err) => {
  //       // console.log(err);
  //     });
  // };
  // const getShippingAddress = () => {
  //   axios
  //     .get(
  //       `${baseUrl}shipping-address/${editSelectedItem?.id ? editSelectedItem?.id : cid
  //       }/`,
  //       config
  //     )
  //     .then((res) => {
  //       // console.log(res)
  //       setBillingAddrData(res.data[0]);
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //     });
  // };
  const getStepCounter = () => {
    axios
      ?.get(
        `${baseUrl}super-admin/fetch-subcompany-onboarding/${sid}/`,
        config
      )
      ?.then((respo) => {
        // console.log(respo?.data);
        setSteper(respo?.data?.data);
        // setMemberCount(respo?.data);
      });
  };
  // console.log(memberCount, "memberCount");
  // const cstMemberCount = {
  //   labels: ["Current Member", "Remain Sheets"],
  //   datasets: [
  //     {
  //       label: "Count",
  //       data: [
  //         memberCount?.CST?.current_member,
  //         memberCount?.CST?.max_member - memberCount?.CST?.current_member,
  //       ],
  //       backgroundColor: ["#ff8000", "#00ff80"],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };
  // const a2pMemberCount = {
  //   labels: ["Current Member", "Remain Sheets"],
  //   datasets: [
  //     {
  //       label: "Count",
  //       data: [
  //         memberCount?.A2P?.current_member,
  //         memberCount?.A2P?.max_member - memberCount?.A2P?.current_member,
  //       ],
  //       backgroundColor: ["#ff8000", "#00ff80"],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };
  // const mediaStorage = {
  //   labels: ["Used Storage (MB)", "Remain Storage (MB)"],
  //   datasets: [
  //     {
  //       label: "Storage in MB",
  //       data: [
  //         +memberCount?.media_storage?.storage,
  //         +memberCount?.media_storage?.total_storage - +memberCount?.media_storage?.storage,
  //       ],
  //       backgroundColor: ["#ff8000", "#00ff80"],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };
  // console.log(editSelectedItem);
  // console.log(memberCount?.CST?.max_member, "jhgjdgjfgjg");
  useEffect(() => {
    getSubCompany()
    getStepCounter();
  }, []);
  return (
    <div className="profile_section">
      <div className="profile_inner">
        {/* <div className="customer_details_sec"> */}
        <div>
          <div className="profile">
            <div className="cust_title">Company Details</div>
            {editSelectedItem ? (
              <>

                {/* Stepper Primary Journey*/}
                <div className="profile_img">
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginLeft: "150px",
                      gap: "10px",
                    }}
                  >
                    <div className="topup-box">
                      <span>Top-Up Amount</span>
                      <span>&#8377; {editSelectedItem?.subCompanyData?.sub_company_storage_topup}</span>
                    </div>
                    <div className="company-tracker">
                      <span
                        style={{
                          backgroundColor: `${steper?.waba_journey_cretedAt ? "#2fbf71" : "white"
                            }`,
                          color: `${steper?.waba_journey_cretedAt ? "white" : "#393939"
                            }`,
                        }}
                      >
                        1
                      </span>
                      <span>Sub Company Created At</span>
                      {steper?.sub_company_createdAt && (
                        <span>{convertDate(steper?.sub_company_createdAt)} | {convertTime(steper?.sub_company_createdAt)}</span>
                      )}

                    </div>
                    <div className="company-tracker">
                      <span
                        style={{
                          backgroundColor: `${steper?.sub_company_createdAt ? "#2fbf71" : "white"
                            }`,
                          color: `${steper?.sub_company_createdAt ? "white" : "#393939"
                            }`,
                        }}
                      >
                        2
                      </span>
                      <span>Start Journey</span>
                      {steper?.waba_journey_cretedAt && (
                        <span>{convertDate(steper?.waba_journey_cretedAt)} | {convertTime(steper?.waba_journey_cretedAt)}</span>
                      )}
                    </div>
                    <div className="company-tracker">
                      <span
                        style={{
                          backgroundColor: `${steper?.first_sub_company_purchasedPlan_ceatedAt ? "#2fbf71" : "white"
                            }`,
                          color: `${steper?.first_sub_company_purchasedPlan_ceatedAt ? "white" : "#393939"
                            }`,
                        }}
                      >
                        3
                      </span>
                      <span>Buy Plan</span>
                      {steper?.first_sub_company_purchasedPlan_ceatedAt && (
                        <span>{convertDate(steper?.first_sub_company_purchasedPlan_ceatedAt)} | {convertTime?.first_sub_company_purchasedPlan_ceatedAt}</span>
                      )}
                    </div>
                  </div>
                </div>
                {/* Graphs */}
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap"
                  }}
                >
                  {+editSelectedItem?.total_member > 0 && (
                    <>
                      <AnalyticBar
                        expectedDealvalue={editSelectedItem?.total_member}
                        dealvalue={editSelectedItem?.member_used}
                        postText={" Member"}
                        title={"Member Count"}
                        barPathColor={"#2fbf71"}
                        valueColor={"#ef2d56"}
                      />
                    </>

                  )

                  }
                  {+editSelectedItem?.total_member > 0 && (
                    <>
                      <AnalyticBar
                        expectedDealvalue={editSelectedItem?.total_storage}
                        dealvalue={editSelectedItem?.storage_used}
                        postText={" MB"}
                        title={"Storage"}
                        barPathColor={"#2fbf71"}
                        valueColor={"#ef2d56"}
                      />
                    </>
                  )}
                  {+editSelectedItem?.total_thunderbolt > 0 && (
                    <AnalyticBar
                      expectedDealvalue={+editSelectedItem?.total_thunderbolt}
                      dealvalue={+editSelectedItem?.thunderbolt_used}
                      title={"Thunderbolt Count"}
                      postText={" Thunderbolt"}
                      barPathColor={"#2fbf71"}
                      valueColor={"#ef2d56"}
                    />
                  )}
                  {+editSelectedItem?.total_interactive > 0 && (
                    <AnalyticBar
                      expectedDealvalue={+editSelectedItem?.total_interactive}
                      dealvalue={+editSelectedItem?.interactive_used}
                      title={"Interactive Count"}
                      postText={" Interactive"}
                      barPathColor={"#2fbf71"}
                      valueColor={"#ef2d56"}
                    />
                  )}
                </div>
                <div style={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  marginTop: "10px"
                }}>
                  <div>
                    <div>Sub Company Details</div>
                    <div style={{
                      display: "grid",
                      gap: "10px",
                      gridTemplateColumns: "auto 15px auto",
                    }}>
                      <span>Name</span>
                      <span>:</span>
                      <span>{editSelectedItem?.subCompanyData?.sub_company_name}</span>
                      <span>Attached Phone Number</span>
                      <span>:</span>
                      <span>{editSelectedItem?.subCompanyData?.sub_company_attached_number.length > 0 &&
                        editSelectedItem?.subCompanyData?.sub_company_attached_number[0]?.display_name}</span>
                      <span>Plan Features</span>
                      <span>:</span>
                      <div>{
                        editSelectedItem?.subscriptionID?.plan_features.map((item, i) => 
                        <div>{i+1}. {item}</div>
                        )
                      }</div>
                    </div>
                  </div>
                  <div>
                    <div>Active Plan Details</div>
                    <div style={{
                      display: "grid",
                      gap: "10px",
                      gridTemplateColumns: "auto 15px auto",
                    }}>
                      <span>Name</span>
                      <span>:</span>
                      <div>
                        <div>{editSelectedItem?.subscriptionID?.plan_name}</div>
                        <div style={{
                          fontSize: "12px",
                          color: "#595959"
                        }}>{editSelectedItem?.subscriptionID?.package_type}</div>
                      </div>
                      <span>Plan Price</span>
                      <span>:</span>
                      <span>{editSelectedItem?.subscriptionID?.plan_price}</span>
                      <span>Plan Duration</span>
                      <span>:</span>
                      <span>{editSelectedItem?.subscriptionID?.plan_duration} ({editSelectedItem?.subscriptionID?.number_of_days} days)</span>
                      <span>Permissions</span>
                      <span>:</span>
                      <div>{
                        editSelectedItem?.subscriptionID?.module_permission.map((item, i) =>
                          <div>{i + 1}. {item}</div>
                        )
                      }</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#595959",
                }}
              >
                Data not loaded Yet <br />
                Please wait... or Refresh
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
