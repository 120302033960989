import React, { useEffect, useState } from "react";
import "../Customers/Customer.css";
import {
    MdDeleteOutline,
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdOutlineListAlt,
    MdOutlineSummarize,
} from "react-icons/md";
import { BsReply } from 'react-icons/bs'
import { HiOutlineFilter, HiOutlinePencil } from "react-icons/hi";
import { AiOutlineClose, AiOutlineEye, AiOutlineWhatsApp } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../../ReduxSlices/MainSlice";
import { baseUrl } from '../../../BaseUrl'
// const baseUrl = process.env.REACT_APP_API_URL;
const WABADetails = () => {
    const [wait, setWait] = useState('Assign')
    const [toggleAssign, setToggleAssign] = useState({
        classN: 'assignPopup',
        ticket_id: '',
        ticket_no: '',
        category: '',
        priority: '',
        date: '',
        time: '',
        subject: ''
    })
    const [employeeId, setEmployeeId] = useState('');
    const [ticketData, setTicketData] = useState([]);
    const [openEditCompany, setOpenEditCompany] = useState("dashboard");
    const [editSelectedItem, setEditSelectedItem] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getCompanyDataAPI = `${baseUrl}ticket-api/${(localStorage.getItem('permissions')) === 'Super_user' ? 'ticket' : 'admin-ticket'}/`;
    var token;
    if (localStorage.getItem("saToken2"))
        token = localStorage.getItem("saToken2");
    var config = { headers: { Authorization: `Bearer ${token}` } };
    const getFilteredTicket = (filter) => {
        axios.get(`${baseUrl}ticket-api/filter-ticket/?status=${filter}&page=1`, config).then((respo) => {
            if (respo.status === 200) {
                // console.log(respo)
            }
        })
    }
    const [employeeData, setEmployeeData] = useState();
    const getEmployeeList = () => {
        axios.get(`${baseUrl}ticket-api/assign-id/`, config).then((respo) => {
            if (respo.status === 200) {
                setEmployeeData(respo.data)
            }
        }).catch((err) => {
            // console.log(err)
        })
    }
    const getCompany = (page) => {
        axios
            .get(`${getCompanyDataAPI}?page=${page}`, config)
            .then((res) => {
                setTicketData(res?.data);
            })
            .catch((err) => {
                // console.log(err)
            });
    };
    const formData = {
        ticket_id: toggleAssign?.ticket_id,
        admin_user: employeeId,
    }
    const assignAdminUser = () => {
        // console.log(formData)
        axios.post(`${baseUrl}ticket-api/assign/`, formData, config).then((respo) => {
            if (respo.status === 200) {
                setWait('Assigned')
            }
        }).catch((err) => {
            setWait('Err')
            // console.log(err)
        })
    }
    useEffect(() => {
        getCompany(1);
        getFilteredTicket('New');
        getEmployeeList();
    }, []);
    return (
        <div className="customers_page">

            {/* Assign empployee Portal */}
            {/* <div className={toggleAssign?.classN} id="assignEmployee">
                <div className="assignPopupInner">
                    <div className="containerTicket">
                        <div className="cutAssign" onClick={() => {
                            setToggleAssign({
                                classN: 'assignPopup',
                                ticket_id: '',
                                ticket_no: '',
                                category: '',
                                priority: '',
                                date: '',
                                time: '',
                                subject: ''
                            })
                        }}><AiOutlineClose /></div>
                        <div className="assign_heading" style={{ textAlign: 'center' }}>Assign Employee For Ticket</div>
                        <div className="ticketDetailsContainer">
                            <div className='rightTicket'>
                                <div className='ticketTitle'>Ticket-Details</div>
                                <div className='deatilsGridForTicket'>
                                    <div className='ticketTite'>Ticket Number</div><div>:</div><div className='ticketNames'>{toggleAssign?.ticket_no}</div>
                                    <div className='ticketTite'>Category</div><div>:</div><div className='ticketNames'>{toggleAssign?.category}</div>
                                    <div className='ticketTite'>Priority</div><div>:</div><div className='ticketNames'>{toggleAssign?.priority}</div>
                                </div>
                                <div className='subjectTicket'>Subject</div>
                                <div className='ticketNames'>{toggleAssign?.subject}</div>
                            </div>
                            <div className="chooseEmployeeSection" style={{ padding: '10px' }}>
                                <div className="assign_heading">Select Employee To Assign This Ticket</div>
                                <select
                                    className="selectEmployee"
                                    onChange={(e) => setEmployeeId(e.target.value)}
                                >
                                    <option value={''}>Choose Employee</option>
                                    {
                                        employeeData?.map((data, i) => {
                                            return (
                                                <option key={i} value={data?.id}>{data?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="assignBtn">
                            <button onClick={() => {
                                assignAdminUser()
                                setTimeout(() => {
                                    getCompany(1)
                                }, 1000);
                                setTimeout(() => {
                                    setToggleAssign({
                                        classN: 'assignPopup',
                                        ticket_id: '',
                                        ticket_no: '',
                                        category: '',
                                        priority: '',
                                        date: '',
                                        time: '',
                                        subject: ''
                                    })
                                }, 2000);
                            }}>{wait}</button>
                        </div>
                    </div>

                </div>
            </div> */}

            <div className="customers_inner">
                    <>
                        <div className="customer_table_container">
                            <div className="customer_table_up">
                                <div className="customer_summery">
                                    <div className="summery_icon">
                                        <AiOutlineWhatsApp />
                                    </div>
                                    <div className="summer_heading">WABA Details</div>
                                </div>
                                {/* <div style={{ justifyContent: 'space-between' }} className="summery_container">
                                    <div className="summery_card">
                                        <div className="summery_card_inner">
                                            <span>{ticketData?.total ? ticketData?.total : 0}</span>
                                            <span>Total Tickets</span>
                                        </div>
                                    </div>
                                    <div className="summery_card">
                                        <div className="summery_card_inner">
                                            <span>{ticketData?.new ? ticketData?.new : 0}</span>
                                            <span style={{ color: "green" }}>New Tickets</span>
                                        </div>
                                    </div>
                                    <div className="summery_card">
                                        <div className="summery_card_inner">
                                            <span>{ticketData?.pending ? ticketData?.pending : 0}</span>
                                            <span style={{ color: "orange" }}>Ongoing Tickets</span>
                                        </div>
                                    </div>
                                    <div className="summery_card">
                                        <div className="summery_card_inner">
                                            <span>{ticketData?.close ? ticketData?.close : 0}</span>
                                            <span style={{ color: "red" }}>Cosed Tickets</span>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div style={{
                                marginTop: "10px"
                            }} className="customer_table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Ticket Number</th>
                                            <th>Subject</th>
                                            <th>Company Name</th>
                                            <th>Category</th>
                                            <th>Priority</th>
                                            <th>Status</th>
                                            <th>Date & Time</th>
                                            <th>Assign To</th>
                                            <th>Reply</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {console.log(ticketData)} */}
                                        {
                                            ticketData?.results?.map((item, index) => {
                                                let date = new Date(item?.created_at);
                                                let convertedDate = date.toLocaleDateString();
                                                let convertedTime = date.toLocaleTimeString('en-US');
                                                return (
                                                    <>
                                                        <tr key={index + 1} >
                                                            <td>{index + ((ticketData?.page * 10) - 9)}</td>
                                                            {/* <td>{index +1}</td> */}
                                                            <td>{item?.ticket_no}</td>
                                                            <td>{item?.subject}</td>
                                                            <td>{item?.company_id?.company_name}</td>
                                                            <td>{item?.category}</td>
                                                            <td>{item?.priority}</td>
                                                            {
                                                                item?.status === 'New' ?
                                                                    <td style={{ color: 'green' }}>{item?.status}</td> :
                                                                    item?.status === 'Pending' ?
                                                                        <td style={{ color: 'orange' }}>{item?.status}</td> :
                                                                        <td style={{ color: 'red' }}>{item?.status}</td>
                                                            }
                                                            <td>{convertedDate}, {convertedTime}</td>
                                                            <td>
                                                                {
                                                                    item?.assign_to ?
                                                                        <div style={{ position: 'relative' }}>
                                                                            <span>{item?.assign_to?.name}</span>
                                                                            <button className={`assignTickets reAssign`} onClick={() => {
                                                                                setToggleAssign({
                                                                                    classN: 'assignPopupShow',
                                                                                    ticket_id: item?.id,
                                                                                    ticket_no: item?.ticket_no,
                                                                                    category: item?.category,
                                                                                    priority: item?.priority,
                                                                                    date: convertedDate,
                                                                                    time: convertedTime,
                                                                                    subject: item?.subject

                                                                                })
                                                                            }}>Re-Assign</button>
                                                                        </div> :
                                                                        <button className="assignTickets" onClick={() => {
                                                                            setToggleAssign({
                                                                                classN: 'assignPopupShow',
                                                                                ticket_id: item?.id,
                                                                                ticket_no: item?.ticket_no,
                                                                                category: item?.category,
                                                                                priority: item?.priority,
                                                                                date: convertedDate,
                                                                                time: convertedTime,
                                                                                subject: item?.subject

                                                                            })
                                                                        }}>Assign</button>
                                                                }
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <div className="action_btns" >
                                                                    {
                                                                        item?.status === 'New' && localStorage.getItem('permissions') === 'Super_user' ? '' :
                                                                            <BsReply
                                                                                className="action_icon_view"

                                                                                onClick={() => {
                                                                                    navigate("/ticket_reply");
                                                                                    dispatch(updateUserData(item))
                                                                                    sessionStorage.setItem('ticket', item?.id)
                                                                                    //   handleCompanyProfile(item);
                                                                                }}
                                                                            />
                                                                    }

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: 'none' }}>
                                                            <td colSpan={2}>Image of {index + 1}</td>
                                                            <td colSpan={7}> {item?.description}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                    </tbody>
                                </table>
                                {
                                    ticketData?.results?.length === 0 &&
                                    <div style={{
                                        textAlign: "center",
                                        padding: "40px",
                                        backgroundColor: "#c9c9c9",
                                        fontSize: "30px",
                                        fontWeight: "600",
                                        color: "#595959"
                                    }}>No Data Available!</div>
                                }
                                {
                                    ticketData?.results?.length > 0 &&
                                    <div className="pegination">
                                        <div className='peginationinner'>
                                            <MdKeyboardArrowLeft className='arrowPreNext' onClick={() => {
                                                ticketData?.links?.previous != null && getCompany(ticketData?.links?.previous)
                                            }} />
                                            <span>{ticketData?.page} out of {Math.ceil((ticketData?.total) / (ticketData?.page_size))}</span>
                                            <MdKeyboardArrowRight className='arrowPreNext' onClick={() => {
                                                ticketData?.links?.next != null && getCompany(ticketData?.links?.next)
                                            }} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
            </div>
        </div>
    );
};

export default WABADetails;
