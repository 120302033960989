import React, { useEffect, useState } from "react";
import Button from "../../reusableComponent/Button";
import { RiAddLine } from "react-icons/ri";
import "../Leads/leads.css";
import { RxCross2 } from "react-icons/rx";
import InputField from "../../reusableComponent/InputField";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import whatsApptemplates from "../Leads/whatsApptemplates.json";
import SendIcon from "@mui/icons-material/Send";
import {
  MdDeleteOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineDelete,
} from "react-icons/md";
import axios from "axios";
import CreateLead from "../Leads/createLead";
import ViewLeads from "../Leads/ViewLead";
import { HiBell } from "react-icons/hi";
import { SiSourceforge } from "react-icons/si";
import { IoChevronDownSharp } from "react-icons/io5";
import { FaTasks } from "react-icons/fa";
import { baseUrl } from "../../../BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLeadData,
  leadDeleteHandler,
} from "../../../ReduxSlices/MainSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MuiButton from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import { Toaster, toast } from "react-hot-toast";
import { type } from "@testing-library/user-event/dist/type";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { convertDate, convertTime } from "../../webutils";

// const baseUrl = process.env.REACT_APP_API_URL
const EmployeeLeads = () => {

  let nav = sessionStorage.getItem("navigate");
  const dispatch = useDispatch();
  const [searchFilterToggle, setSearchFilterToggle] = useState(null);
  const navigate = useNavigate();
  const [allLeadData, setAllLeadData] = useState([]);
  const [allReminderData, setAllReminderData] = useState([]);
  const [openEditLeads, setOpenEditLeads] = useState("lead_table");
  const [editSelectedLead, setEditSelectedLead] = useState([]);
  const reminderAllDataAPI = `${baseUrl}super_admin_lead/note/`;
  const [reminderProfilePopup, setOpenReminderProfilePopup] = useState("");
  const [selectedReminderData, setSelectedReminderData] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState("task");
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [reminderSearchInputValue, setReminderSearchInputValue] =
    useState(null);
  const [searchElement, setSearchElement] = useState("");
  const [filteredNote, setFilteredNote] = useState([]);
  const [filterToggle, setFilterToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const employeeAllData = JSON?.parse(localStorage.getItem("employee_profile"));
  // const filterReminderApi = `${baseUrl}super_admin_lead/note`;
  const selectedLeadDelete = useSelector((store) => store?.main);
  const [leadSearch, setLeadSearch] = useState("");
  useEffect(() => {
    {
      nav === "Proposals" && setOpenEditLeads("lead_profile");
    }
  }, [nav]);
  const [noteTaskData, setNoteTaskData] = useState(null);
  const [mouseActive, setMouseActive] = useState(null);
  const [activeNotes, setActiveNotes] = useState(false);
  const [activeDeal, setActiveDeal] = useState(null);
  const [defaultStatus, setDefaultStatus] = useState();
  const [activePopUpForSomeActions, setActivePopUpForSomeActions] = useState();
  const [templateMessage, setTemplateMessage] = useState(null);
  const [leadStatus, setLeadStatus] = useState([
    "New",
    "Viewed",
    "Contacted",
    "Deal Done",
    "Unqualified",
  ]);
  const [statusValue, setStatusValue] = useState("New");
  const [activeLeadStatus, setActiveLeadStatus] = useState(null);
  const [isCurrentLead, setIsCurrentLead] = useState(null);
  const [noteType, setNoteType] = useState("");
  const [dealVal, setDealValue] = useState("");
  const [expectedDealvalue, setExpectedDealValue] = useState({
    expectedDealInputValue: "",
    expectedClosingDate: "",
  });
  const [expectedDealValue1, setExpectedDealValue1] = useState(null);
  const [expecteDealClosingDate, setExpecteDealClosingDate] = useState(null);
  const [recentList, setRecentList] = useState([]);
  const [handleExpendComment, setHandleExpendComment] = useState({
    id: null,
    display: false,
  });
  const [commentsList, setCommentsList] = useState([]);
  const [commentValue, setCommentValue] = useState(null);
  const [errorComment, setErrorComment] = useState("none");
  const expendCommentHandler = (item) => {
    setHandleExpendComment({
      id: item.id,
      display: !handleExpendComment.display,
    });
  };
  const [leadName, setLeadName] = useState("");
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const getLeadData = (page) => {
    axios
      .get(
        `${baseUrl}leads/employee/fetch-leademployee-list-via-superadmin/${employeeAllData?._id}?page=${page}&filterdata=${leadSearch}`,
        config
      )
      .then((res) => {
        setAllLeadData(res?.data);
        // console.log(res?.data, "leadLead");
      })
      .then((err) => {
        // console.log(err, "error");
      });
  };

  useEffect(() => {
    getLeadData(1);
  }, [leadSearch]);

  // Note and task
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let myData = {
      ...noteTaskData,
      [name]: value,
    };

    // console.log(myData)
    setNoteTaskData(myData);
  };
  const formDataForNoteTask = {
    lead_id: mouseActive?.id,
    description: noteTaskData?.noteDescription,
    note_type: noteType,
    datetime: noteType === "Note" ? null : noteTaskData?.taskDateTime,
  };
  const submitNoteNTask = () => {
    // console.log("execute 1")
    axios
      .post(`${baseUrl}super_admin_lead/note/`, formDataForNoteTask, config)
      .then((respo) => {
        // console.log(respo);
        setActiveNotes(false);
        setNoteTaskData(null);
        toast?.success(
          `${noteType} for ${leadName} is submitted successfully!`
        );
      })
      .catch((err) => {
        toast?.failed("failed");
      });
  };
  const handleSaveComment = (item) => {
    const commentData = {
      note: item?.id,
      comment: commentValue,
    };
    if (commentData.comment !== null && commentData.comment !== "") {
      setErrorComment("none");
      axios
        .post(`${baseUrl}super_admin_lead/comment-save/`, commentData, config)
        .then((respo) => {
          // console.log(respo);
          getComments(item?.id);
          setCommentValue("");
          toast?.success("Comment is saved successfully!");
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      setErrorComment("block");
    }
  };
  const getComments = (item) => {
    axios
      .get(`${baseUrl}super_admin_lead/comment-save/${item}/`, config)
      .then((respo) => {
        // console.log(respo);
        setCommentsList(respo?.data);
      });
  };
  useEffect(() => {
    // console.log(handleExpendComment, "jkhjgjgjgjhgjhgj")
    if (handleExpendComment?.display) {
      getComments(handleExpendComment?.id);
    }
  }, [handleExpendComment]);
  const reminderAllData = () => {
    setFilteredNote([]);
    axios
      .get(`${reminderAllDataAPI}${openEditLeads}/`, config)
      .then((res) => {
        setAllReminderData(res?.data);
        setFilteredNote(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    var fData = allReminderData?.filter((item) => {
      return (
        item?.status?.toLowerCase()?.match(searchElement?.toLowerCase()) ||
        item?.description?.toLowerCase()?.match(searchElement?.toLowerCase())
      );
    });
    setFilteredNote(fData);
  }, [searchElement]);
  useEffect(() => {
    {
      openEditLeads === "lead_table" && getLeadData(1);
    }
    {
      openEditLeads === "Note" && reminderAllData();
    }
    {
      openEditLeads === "Task" && reminderAllData();
    }
  }, [openEditLeads]);
  const handleLeadUpdate = (item) => {
    setEditSelectedLead(item);
    setOpenEditLeads("update_lead");
  };
  const handleLeadView = (item) => {
    sessionStorage?.setItem("c_id", item?.id);
    sessionStorage?.setItem("navigate", "Profile");
    dispatch(handleLeadData(item));
    setOpenEditLeads("lead_profile");
  };
  const handleReminderView = (data) => {
    setOpenReminderProfilePopup("ongoing_popup");
    setSelectedReminderData(data);
  };

  const handleReminderDelete = () => {
    let id = localStorage?.getItem("noteTaskId");
    axios
      ?.delete(`${baseUrl}super_admin_lead/note/${id}/`, config)
      .then((respo) => {
        handleClose();
        toast?.success(`Your ${openEditLeads} is deleted successfully!`);
        reminderAllData();
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleToggleComponent = (activeClass) => {
    setSelectedComponent(activeClass);
  };
  const handlePopupClose = () => {
    setOpenReminderProfilePopup("");
    handleToggleComponent("task");
    setDefaultStatus();
  };

  const taskDataCompanyWise = () => {
    // console.log(selectedReminderData?.lead_id?.id, "lid");
    axios
      .get(
        `${baseUrl}super_admin_lead/lead/${selectedReminderData?.lead_id?.id}/`,
        config
      )
      .then((res) => {
        setSelectedCompanyData(res.data);
        // console.log(res.data);
      });
  };
  const handleFilterChange = (value) => {
    setSearchElement(value);
    setSearchFilterToggle();
  };
  const filterReminder = () => {
    axios
      .get(
        `${baseUrl}super_admin_lead/note/${searchQuery}${searchQuery && "/"}`,
        config
      )
      .then((res) => {
        // console.log(res.data);
        setAllReminderData(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleLeadDelete = () => {
    axios
      .delete(
        `${baseUrl}lead/${selectedLeadDelete?.selectedLeadDelete?.id}/`,
        config
      )
      .then((respo) => {
        if (respo.status === 200) {
          getLeadData(1);
          setOpen(false);
        }
      });
  };

  useEffect(() => {
    filterReminder();
  }, [searchQuery, reminderSearchInputValue]);

  useEffect(() => {
    reminderProfilePopup === "ongoing_popup" && taskDataCompanyWise();
  }, [reminderProfilePopup]);

  const [open, setOpen] = useState({
    open: false,
    type: null,
  });

  const handleClose = () => {
    setOpen(false);
    dispatch(leadDeleteHandler(null));
  };
  // Hover showContactActionButtons ############### Lead Deal ######################

  const activeStatusDropDown = (item) => {
    if (activeLeadStatus === null) {
      setActiveLeadStatus(item?.id);
    } else {
      setActiveLeadStatus(null);
    }
  };
  const handleChangeExpectedValues = (e) => {
    const { name, value } = e?.target;
    setExpectedDealValue({ ...expectedDealvalue, [name]: value });
  };
  const currentStatusFunc = (keys, value, leadItem, page) => {
    // console.log(value, keys, "value Expected");
    const data =
      keys === "status"
        ? { status: value }
        : keys === "dealValue"
        ? {
            dealValue: dealVal,
            dealClosingDate: new Date(),
          }
        : {
            expected_dealValue: expectedDealValue1,
            expected_dealClosingDate: expecteDealClosingDate,
          };
    // setStatusValue(value);
    setIsCurrentLead(leadItem?._id);

    axios
      .put(
        `${baseUrl}leads/${leadItem?._id}/update-lead-status-superadmin`,
        data,
        config
      )
      .then((respo) => {
        // console.log(respo, "updateLead");
        getLeadData(page);
        setActiveDeal(null);
        setDealValue("");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getRecentList = (item, note) => {
    axios
      .get(
        `${baseUrl}super_admin_lead/leadid_and_notetype/?leadID=${item?.id}&note_type=${note}`
      )
      .then((respo) => {
        let recentData = respo?.data?.slice(0, 5);
        setRecentList(recentData);
        // console.log(respo?.data)
      });
  };
  const sendWhatsAppMessage = (template, leadtitem) => {
    const formData = {
      mobile: leadtitem.mobile
,
      message: template,
    };
    axios
      .post(`${baseUrl}super_admin_lead/whatsapp-temp/`, formData, config)
      .then((respo) => {
        // console.log(respo?.data, "respo");
        toast?.success(respo?.data?.message);
        setTimeout(() => {
          closeNotes();
        }, 500);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  // Notes open and close command
  const openNotes = (item, note) => {
    setMouseActive(item);
    setLeadName(item?.company);
    setActiveNotes(true);
    setNoteType(note);
    if (note === "Note" || note === "Task") {
      getRecentList(item, note);
    }
  };
  const closeNotes = () => {
    setActiveNotes(false);
    setMouseActive(null);
    setNoteType("");
    setNoteTaskData(null);
  };

  const dealValue = (item, dealType) => {
    // console.log(dealType, "dealValue");
    setActiveDeal({
      dealType: dealType,
      id: item?.id,
    });
    if (dealType === "dealValue") {
      setDealValue(item?.dealValue);
    } else {
      setExpectedDealValue1(item?.expected_dealValue);
      setExpecteDealClosingDate(item?.expected_dealClosingDate);
    }
  };

  let taskDateFormate = new Date(
    selectedReminderData?.datetime
      ? selectedReminderData?.datetime
      : selectedReminderData?.created_at
  );
  let taskDate = taskDateFormate?.toLocaleDateString();
  let taskTime = taskDateFormate?.toLocaleTimeString();
  const updateStatusNote = (id, changeStatus) => {
    const formDataForNote = {
      status: changeStatus,
    };
    axios
      ?.put(`${baseUrl}super_admin_lead/note/${id}/`, formDataForNote, config)
      .then((respo) => {
        toast?.success(`${openEditLeads} updated Successfully!`);
        reminderAllData();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className="leads-main">
      <Toaster position="top-center" reverseOrder={false} />
      {/* Notes Popup */}
      <div
        style={activeNotes ? { display: "block" } : { display: "none" }}
        className="notes-overlay"
      >
        {noteType === "WhatsApp" && (
          <div className="whatsapp-form-container">
            <div className="main-container-for-whatsapp">
              <div className="title-note">
                <div style={{ color: "#1877F2" }}>
                  Send {noteType} Message to <b>{mouseActive?.company}</b>
                </div>
                <div onClick={closeNotes} className="close-notes">
                  <CloseOutlinedIcon />
                </div>
              </div>
              <div className="whatsapp-list">
                {whatsApptemplates?.whatsapp_template?.map(
                  (template, index) => {
                    return (
                      <div className="whatsapp-list-item-container" key={index}>
                        <textarea
                          className="WhatsAppArea"
                          defaultValue={template?.message}
                          onChange={(e) => setTemplateMessage(e?.target?.value)}
                        />
                        <div className="whatsapp-btn-container">
                          <button
                            style={{
                              backgroundColor: "#1877F2",
                              borderRadius: "5px",
                              padding: "5px",
                              border: "none",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                            onClick={() =>
                              sendWhatsAppMessage(
                                templateMessage
                                  ? templateMessage
                                  : template?.message,
                                mouseActive
                              )
                            }
                          >
                            <SendIcon />
                          </button>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="summery_container">
          <div className="summery_card">
            <div className="summery_card_inner">
              <span>{allLeadData?.total_leads || 0}</span>
              <span>Total Leads</span>
            </div>
          </div>
          <div className="summery_card">
            <div className="summery_card_inner">
              <span>{allLeadData?.status_count?.new_lead || 0}</span>
              <span style={{ color: "green" }}>New Leads</span>
            </div>
          </div>
          <div className="summery_card">
            <div className="summery_card_inner">
              <span>{allLeadData?.status_count?.view_lead || 0}</span>
              <span style={{ color: "#1877F2" }}>Viewed Leads</span>
            </div>
          </div>
          <div className="summery_card">
            <div className="summery_card_inner">
              <span>{allLeadData?.status_count?.contacted_lead || 0}</span>
              <span style={{ color: "orange" }}>Contacted Leads</span>
            </div>
          </div>
          <div className="summery_card">
            <div className="summery_card_inner">
              <span>{allLeadData?.status_count?.qualified_lead || 0}</span>
              <span style={{ color: "green" }}>Qualified Leads</span>
            </div>
          </div>
          <div className="summery_card">
            <div className="summery_card_inner">
              <span>{allLeadData?.status_count?.unqualified_lead || 0}</span>
              <span style={{ color: "red" }}>Unqualified Leads</span>
            </div>
          </div>
          <div className="summery_card">
            <div className="summery_card_inner">
              <span>{allLeadData?.status_count?.task_pending || 0}</span>
              <span style={{ color: "orange" }}>Pending Tasks</span>
            </div>
          </div>
        </div>
      </div>
      {/*  *************** Lead table here ************ */}
      {openEditLeads === "lead_table" && (
        <div className="leads-table">
          <div className="leads-table">
            <table>
              <thead>
                {/* )} */}
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th colSpan={2}>Company Name</th>
                  <th
                    style={{
                      borderRight: "1px solid #C9C9C9",
                    }}
                  >
                    Phone
                  </th>
                  <th>Email</th>
                  <th>Expected Deal Value</th>
                  <th>Deal Value</th>
                  <th>Status</th>
                  <th>Lead Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allLeadData &&
                  allLeadData?.data?.map((leadItem, index) => {
                    let date = new Date(leadItem?.createdAt);
                    const convertedDate = convertDate(date);
                    const convertedLeadTime = convertTime(leadItem?.createdAt)
                    // let convertedDate = date.toLocaleDateString();
                    let convertedTime = date.toLocaleTimeString();
                    return (
                      <tr
                        style={
                          mouseActive?._id === leadItem?._id
                            ? {
                                backgroundColor: "#C9C9C9",
                                cursor: "pointer",
                              }
                            : {}
                        }
                        key={index + 1}
                        id={leadItem?._id}
                      >
                        <td onClick={() => setActiveDeal(null)}>
                          {index + (allLeadData?.current_page * 20 - 19)}
                        </td>
                        <td onClick={() => setActiveDeal(null)}>
                          {leadItem?.name}
                        </td>
                        <td onClick={() => setActiveDeal(null)}>
                          {leadItem?.company}
                        </td>
                        <td onClick={() => setActiveDeal(null)}>
                          <div id="btnContainer">
                            <div className="contact-action-container">
                              <div className="contact-action-inner">
                                <div
                                  data-title={
                                    "Call to " + leadItem?.company
                                  }
                                  className="action-btn-container"
                                >
                                  <CallOutlinedIcon fontSize="small" />
                                </div>
                                <div
                                  data-title={
                                    "Send message on WhatsApp to " +
                                    leadItem?.company
                                  }
                                  className="action-btn-container"
                                  onClick={() =>
                                    openNotes(leadItem, "WhatsApp")
                                  }
                                >
                                  <WhatsAppIcon fontSize="small" />
                                </div>
                                <a href={"mailto:" + leadItem?.email}>
                                  <div
                                    data-title={
                                      "Send email to " + leadItem?.company
                                    }
                                    className="action-btn-container"
                                  >
                                    <MailOutlineIcon fontSize="small" />
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          onClick={() => setActiveDeal(null)}
                          style={{
                            borderRight: "1px solid #C9C9C9",
                          }}
                        >
                          {leadItem?.mobile
}
                        </td>
                        <td onClick={() => setActiveDeal(null)}>
                          {leadItem?.email}
                        </td>
                        <td
                          onClick={() =>
                            dealValue(leadItem, "expectedDealValue")
                          }
                          style={{
                            fontWeight: "500",
                            minWidth: "100px",
                          }}
                        >
                          <div
                            className={
                              activeDeal?.id === leadItem?._id &&
                              activeDeal?.dealType === "expectedDealValue"
                                ? "deal-value"
                                : ""
                            }
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <span>
                              &#8377;{" "}
                              {leadItem?.expected_dealValue
                                ? leadItem?.expected_dealValue
                                : "----"}
                            </span>
                            {/* {activeDeal?.id === leadItem?.id && activeDeal?.dealType === "expectedDealValue" ? (
                                                            <div
                                                                style={{
                                                                    color: "#1877F2",
                                                                    position: "absolute",
                                                                    left: "-20%",
                                                                    top: "-2px",
                                                                    zIndex: "9999999",
                                                                }}
                                                            >

                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "10px",
                                                                        backgroundColor: "#FFFFFF",
                                                                        boxShadow: "0px 4px 12px #00000069",
                                                                        padding: "5px",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            border: "none",
                                                                            outline: "1px solid #1877F2",
                                                                            borderRadius: "5px",
                                                                            padding: "5px",
                                                                        }}
                                                                        defaultValue={expectedDealValue1}
                                                                        name="expectedDealInputValue"
                                                                        onChange={(e) =>
                                                                            setExpectedDealValue1(e.target.value)
                                                                        }
                                                                    />
                                                                    {console.log(expectedDealValue1)}
                                                                    <input
                                                                        type="datetime-local"
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            border: "none",
                                                                            outline: "1px solid #1877F2",
                                                                            borderRadius: "5px",
                                                                            padding: "5px",
                                                                        }}
                                                                        defaultValue={expecteDealClosingDate}
                                                                        name="expectedClosingDate"
                                                                        onChange={(e) =>
                                                                            setExpecteDealClosingDate(e?.target?.value)
                                                                        }
                                                                    />
                                                                    <button
                                                                        style={{
                                                                            padding: "5px",
                                                                            border: "none",
                                                                            backgroundColor: "#1877F2",
                                                                            color: "#FFFFFF",
                                                                            borderRadius: "5px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            currentStatusFunc(
                                                                                "expectedDealValue",
                                                                                expectedDealValue1,
                                                                                leadItem,
                                                                                allLeadData?.page)

                                                                        }

                                                                        }
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )} */}
                          </div>
                        </td>
                        <td
                          onClick={() => dealValue(leadItem, "dealValue")}
                          style={{
                            fontWeight: "500",
                            minWidth: "100px",
                          }}
                        >
                          <div
                            className={
                              activeDeal?.id === leadItem?._id &&
                              activeDeal?.dealType === "dealValue"
                                ? "deal-value"
                                : ""
                            }
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <span>
                              &#8377;{" "}
                              {leadItem?.dealValue
                                ? leadItem?.dealValue
                                : "----"}
                            </span>
                            {/* {activeDeal?.id === leadItem?.id && activeDeal?.dealType === "dealValue" ? (
                                                            <div
                                                                style={{
                                                                    color: "#1877F2",
                                                                    position: "absolute",
                                                                    left: "-20%",
                                                                    top: "-2px",
                                                                    right: "-10%",
                                                                    bottom: "-2px",
                                                                    zIndex: "9999999",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        gap: "10px",
                                                                        backgroundColor: "#FFFFFF",
                                                                        boxShadow: "0px 4px 12px #00000069",
                                                                        padding: "2px",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                >
                                                                    <input
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            border: "none",
                                                                            outline: "1px solid #1877F2",
                                                                            borderRadius: "5px",
                                                                            padding: "5px",
                                                                        }}
                                                                        defaultValue={leadItem?.dealValue}
                                                                        name="dealInputValue"
                                                                        onChange={(e) =>
                                                                            setDealValue(e?.target?.value)
                                                                        }
                                                                    />
                                                                    <button
                                                                        style={{
                                                                            padding: "0 5px",
                                                                            border: "none",
                                                                            backgroundColor: "#1877F2",
                                                                            color: "#FFFFFF",
                                                                            borderRadius: "5px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            currentStatusFunc(
                                                                                "dealValue",
                                                                                dealVal,
                                                                                leadItem,
                                                                                allLeadData?.page
                                                                            )
                                                                        }

                                                                        }
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )} */}
                          </div>
                        </td>
                        <td onClick={() => setActiveDeal(null)}>
                          <div
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              alignItems: "center",
                              gap: "10px",
                              minWidth: "50px",
                              position: "relative",
                            }}
                            onClick={() => activeStatusDropDown(leadItem)}
                          >
                            <div
                              style={{
                                color: `${
                                  leadItem?.status === "New"
                                    ? "#228B22"
                                    : leadItem?.status === "Viewed"
                                    ? "#1877F2"
                                    : leadItem?.status === "Contacted"
                                    ? "#FF4500"
                                    : leadItem?.status === "Deal Done"
                                    ? "#228B22"
                                    : leadItem?.status === "Unqualified"
                                    ? "#8B0000"
                                    : "#228B22"
                                }`,
                                backgroundColor: `${
                                  leadItem?.status === "New"
                                    ? "#228B2245"
                                    : leadItem?.status === "Viewed"
                                    ? "#1877F245"
                                    : leadItem?.status === "Contacted"
                                    ? "#FF450045"
                                    : leadItem?.status === "Deal Done"
                                    ? "#228B2245"
                                    : leadItem?.status === "Unqualified"
                                    ? "#8B000045"
                                    : "#228B2245"
                                }`,
                                padding: "5px",
                                borderRadius: "3px",
                                textAlign: "center",
                              }}
                            >
                              {leadItem?.status}
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "#393939",
                                }}
                                className={
                                  activeLeadStatus === leadItem?._id
                                    ? "rightCloseArrow"
                                    : "downCloseArrow"
                                }
                              >
                                &#9662;
                              </div>
                            </div>
                            {activeLeadStatus === leadItem?._id && (
                              <div className="dropdown-container">
                                <div className="dropdown-inner">
                                  <div className="list-container">
                                    {leadStatus?.map((item, index) => (
                                      <div
                                        onClick={() =>
                                          currentStatusFunc(
                                            "status",
                                            item,
                                            leadItem,
                                            allLeadData?.current_page
                                          )
                                        }
                                        className="status-iem"
                                        key={index}
                                      >
                                        {item}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                  
                        {/* <td>{leadItem?.created_at}</td> */}
                        <td onClick={() => setActiveDeal(null)}
                          data-title={
                            convertedLeadTime
                          }
                          >
                          {convertedDate}
                        </td>
                        <td
                          onClick={() => setActiveDeal(null)}
                          className="action_btns"
                        >
                          <AiOutlineEye
                            className="action_icon_view"
                            onClick={() => handleLeadView(leadItem)}
                          />
                          {/* <HiOutlinePencil
                                  className="action_icon_edit"
                                  onClick={() => handleLeadUpdate(leadItem)}
                                /> */}
                          {/* <MdDeleteOutline
                                  className="action_icon_delete"
                                  onClick={() => {
                                    handleClickOpen("Lead");
                                    dispatch(leadDeleteHandler(leadItem));
                                  }}
                                /> */}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {allLeadData?.data?.length < 1 && (
              <div
                style={{
                  textAlign: "center",
                  padding: "40px",
                  backgroundColor: "#c9c9c9",
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#595959",
                }}
              >
                No Data Available!
              </div>
            )}
             {allLeadData?.data?.length > 0 && (
                    <div className="pegination">
                      <div className="peginationinner">
                        <MdKeyboardArrowLeft
                          className="arrowPreNext"
                          onClick={() => {
                            allLeadData?.previous_page != null &&
                              getLeadData(allLeadData?.previous_page);
                          }}
                        />
                        <span>
                          {allLeadData?.current_page} out of{" "}
                          {Math.ceil(allLeadData?.pages)}
                        </span>
                        <MdKeyboardArrowRight
                          className="arrowPreNext"
                          onClick={() => {
                            allLeadData?.next_page != null &&
                              getLeadData(allLeadData?.next_page);
                          }}
                        />
                      </div>
                    </div>
                  )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeLeads;
