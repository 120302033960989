import React, { useEffect, useState } from "react";
import logo from "../../Assets/logo.png";
import { RxDashboard } from "react-icons/rx";
import { FiPhoneIncoming, FiUser, FiUserCheck } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown, MdOutlineContactSupport, MdOutlineReceipt } from "react-icons/md";
import {
  HiOutlineClipboardList,
  HiOutlineDocumentReport,
  HiOutlineLocationMarker,
  HiOutlineTicket,
} from "react-icons/hi";
import { RiQuestionMark } from "react-icons/ri";
import { AiOutlineSetting, AiOutlineWhatsApp } from "react-icons/ai";
import { FaRupeeSign, FaScribd } from "react-icons/fa";

const Sidebar = () => {
  const permission = localStorage.getItem("permissions");
  const [expandSales, setExpandSales] = useState("none");
  const [arrowHide, setArrowHide] = useState("arrowHide");
  // const [hideMenuTxt, setHideMenuTxt] = useState('')
  const salesAccordian = () => {
    if (expandSales === "none") {
      setExpandSales("block");
      setArrowHide("");
    } else if (expandSales === "block") {
      setExpandSales("none");
      setArrowHide("arrowHide");
    }
  };

  return (
    <div className="sidebar_main" id="sideMain">
      <div className="sidebar_inner">
        <div className="logo_container">
          <div className="logo_side">
            <img src={logo} alt="logo image" className="logo_image" />
          </div>
        </div>
        <div className="sidebar_menu_container">
          <>
            {permission === "superadmin" && (
              <div className={`menu_item `}>
                <Link
                  to="/dashboard"
                  className={`menu_inner ${
                    window.location.pathname === "/dashboard" && "active_menu"
                  }`}
                >
                  <span className="menu_icon">
                    <RxDashboard />
                  </span>
                  <span className="menu_txt" id="menuTxt1">
                    Dashboard
                  </span>
                </Link>
              </div>
            )}
            {(permission !== "superadmin" && permission !== null) &&
            JSON?.stringify(permission).includes("Company") ? (
              <div className={`menu_item `}>
                {/* {console.log(permission)} */}
                
                <Link
                  to="/customers"
                  className={`menu_inner ${
                    window.location.pathname === "/customers" ||
                    window.location.pathname === "/view-invoice/company" ||
                    window.location.pathname ===
                      "/view-invoice/company_proposal" ||
                    window?.location?.pathname === "/create_customers" ||
                    window?.location?.pathname === "/customer_profile" ||
                    window?.location?.pathname === "/view_payment/company"
                      ? "active_menu"
                      : ""
                  }`}
                >
                  <span className="menu_icon">
                    <FiUserCheck />
                  </span>
                  <span className="menu_txt" id="menuTxt3">
                    Companies
                  </span>
                </Link>
              </div>
            ) : (
              permission === "superadmin" && (
                <div className={`menu_item `}>
                  <Link
                    to="/customers"
                    className={`menu_inner ${
                      window.location.pathname === "/customers" ||
                      window.location.pathname === "/view-invoice/company" ||
                      window.location.pathname ===
                        "/view-invoice/company_proposal" ||
                      window?.location?.pathname === "/create_customers" ||
                      window?.location?.pathname === "/customer_profile" ||
                      window?.location?.pathname === "/view_payment/company"
                        ? "active_menu"
                        : ""
                    }`}
                  >
                    <span className="menu_icon">
                      <FiUserCheck />
                    </span>
                    <span className="menu_txt" id="menuTxt3">
                      Companies
                    </span>
                  </Link>
                </div>
              )
            )}
          </>
          {(permission !== "superadmin" && permission !== null) &&
          JSON?.stringify(permission).includes("Leads") ? (
           <>
            <div
              onClick={() => sessionStorage?.setItem("navigate", "Profile")}
              className={`menu_item`}
            >
              <Link
                to="/leads"
                className={`menu_inner ${
                  window.location.pathname === "/leads" ||
                  window?.location?.pathname === "/create-lead" ||
                  window?.location?.pathname === "/view-invoice/lead_proposal"
                    ? "active_menu"
                    : ""
                }`}
              >
                <span className="menu_icon">
                  <FiPhoneIncoming />
                </span>
                <span className="menu_txt" id="menuTxt">
                  Leads
                </span>
              </Link>
            </div>
            <div
              onClick={() => sessionStorage?.setItem("navigate", "Profile")}
              className={`menu_item`}
            >
              <Link
                to="/leads"
                className={`menu_inner ${
                  window.location.pathname === "/leads" ||
                  window?.location?.pathname === "/create-lead" ||
                  window?.location?.pathname === "/view-invoice/lead_proposal"
                    ? "active_menu"
                    : ""
                }`}
              >
                <span className="menu_icon">
                  <FiPhoneIncoming />
                </span>
                <span className="menu_txt" id="menuTxt8">
                  Leads2
                </span>
              </Link>
            </div>
           </>
          ) : (
            permission === "superadmin" && (
              <>
              <div
                onClick={() => sessionStorage?.setItem("navigate", "Profile")}
                className={`menu_item`}
              >
                <Link
                  to="/leads"
                  className={`menu_inner ${
                    window.location.pathname === "/leads" ||
                    window?.location?.pathname === "/create-lead" ||
                    window?.location?.pathname === "/view-invoice/lead_proposal"
                      ? "active_menu"
                      : ""
                  }`}
                >
                  <span className="menu_icon">
                    <FiPhoneIncoming />
                  </span>
                  <span className="menu_txt" id="menuTxt8">
                    Leads
                  </span>
                </Link>
              </div>
              <div
                onClick={() => sessionStorage?.setItem("navigate", "Profile")}
                className={`menu_item`}
              >
                <Link
                  to="/leads"
                  className={`menu_inner ${
                    window.location.pathname === "/leads" ||
                    window?.location?.pathname === "/create-lead" ||
                    window?.location?.pathname === "/view-invoice/lead_proposal"
                      ? "active_menu"
                      : ""
                  }`}
                >
                  <span className="menu_icon">
                    <FiPhoneIncoming />
                  </span>
                  <span className="menu_txt" id="menuTxt8">
                    Leads2
                  </span>
                </Link>
              </div>
              </>
            )
          )}
          {(permission != "superadmin" && permission != null) &&
          JSON?.stringify(permission).includes("Sales") ? (
            <div className={`menu_item `}>
              <div className="sales_accordian" onClick={salesAccordian}>
                <div
                  className={`menu_inner ${window.location.pathname === "/"}`}
                >
                  <span className="menu_icon">
                    <MdOutlineReceipt />
                  </span>
                  <span className="menu_txt" id="menuTxt4">
                    Sales
                  </span>
                  <span className={arrowHide + " " + "arrow"}>
                    <MdKeyboardArrowDown />
                  </span>
                </div>
              </div>
              <div className="expandable" style={{ display: expandSales }}>
                {/* <div className={`menu_item`}>
                  <Link
                    to="/sales_invoice"
                    className={`menu_inner ${
                      window.location.pathname ===
                        "/sales_invoice" && "active_menu"
                    }`}
                  >
                    <span className="menu_icon">
                      <TbFileInvoice />
                    </span>
                    <span className="menu_txt" id="menuTxt5">
                      Invoices
                    </span>
                  </Link>
                </div> */}
                <div className={`menu_item `}>
                  <Link
                    to="/sales_payment"
                    className={`menu_inner ${
                      window.location.pathname === "/sales_payment" &&
                      "active_menu"
                    }`}
                  >
                    <span className="menu_icon">
                      <HiOutlineClipboardList />
                    </span>
                    <span className="menu_txt" id="menuTxt6">
                      Payments
                    </span>
                  </Link>
                </div>
                {/* <div className={`menu_item `}>
                    <Link to="/sales_proposal" className={`menu_inner ${window.location.pathname === "/sales_proposal" && "active_menu"}`}>
                      <span className="menu_icon">
                        <RiFileList3Line />
                      </span>
                      <span className="menu_txt" id="menuTxt7">
                        Proposals
                      </span>
                    </Link>
                  </div> */}
              </div>
            </div>
          ) : (
            permission === "superadmin" && (
              <div className={`menu_item `}>
                <div className="sales_accordian" onClick={salesAccordian}>
                  <div
                    className={`menu_inner ${window.location.pathname === "/"}`}
                  >
                    <span className="menu_icon">
                      <MdOutlineReceipt />
                    </span>
                    <span className="menu_txt" id="menuTxt4">
                      Sales
                    </span>
                    <span className={arrowHide + " " + "arrow"}>
                      <MdKeyboardArrowDown />
                    </span>
                  </div>
                </div>
                <div className="expandable" style={{ display: expandSales }}>
                  {/* <div className={`menu_item`}>
                    <Link
                      to="/sales_invoice"
                      className={`menu_inner ${
                        window.location.pathname ===
                          "/sales_invoice" && "active_menu"
                      }`}
                    >
                      <span className="menu_icon">
                        <TbFileInvoice />
                      </span>
                      <span className="menu_txt" id="menuTxt5">
                        Invoices
                      </span>
                    </Link>
                  </div> */}
                  <div className={`menu_item `}>
                    <Link
                      to="/sales_payment"
                      className={`menu_inner ${
                        window.location.pathname === "/sales_payment" &&
                        "active_menu"
                      }`}
                    >
                      <span className="menu_icon">
                        <HiOutlineClipboardList />
                      </span>
                      <span className="menu_txt" id="menuTxt6">
                        Payments
                      </span>
                    </Link>
                  </div>
                  {/* <div className={`menu_item `}>
                    <Link to="/sales_proposal" className={`menu_inner ${window.location.pathname === "/sales_proposal" && "active_menu"}`}>
                      <span className="menu_icon">
                        <RiFileList3Line />
                      </span>
                      <span className="menu_txt" id="menuTxt7">
                        Proposals
                      </span>
                    </Link>
                  </div> */}
                </div>
              </div>
            )
          )}
          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link
                to="/subscription"
                className={`menu_inner ${
                  window.location.pathname === "/subscription" && "active_menu"
                }`}
              >
                <span className="menu_icon">
                  <FaScribd />
                </span>
                <span className="menu_txt" id="menuTxt9">
                  Subscription
                </span>
              </Link>
            </div>
          )}
          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link to="#" className={`menu_inner`}>
                <span className="menu_icon">
                  <HiOutlineDocumentReport />
                </span>
                <span className="menu_txt" id="menuTxt10">
                  Reports
                </span>
              </Link>
            </div>
          )}
          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link
                to="/employee"
                className={`menu_inner ${
                  window.location.pathname === "/employee" || window.location.pathname === "/employee_profile" ? "active_menu":""
                }`}
              >
                <span className="menu_icon">
                  <FiUser />
                </span>
                <span className="menu_txt" id="menuTxt2">
                  Employee
                </span>
              </Link>
            </div>
          )}

          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link
                to="/tickets"
                className={`menu_inner ${
                  window.location.pathname === "/tickets" ||
                  window?.location?.pathname === "/ticket_reply"
                    ? "active_menu"
                    : ""
                }`}
              >
                <span className="menu_icon">
                  <HiOutlineTicket />
                </span>
                <span className="menu_txt" id="menuTxt12">
                  Tickets
                </span>
              </Link>
            </div>
          )}
          {(permission !== "superadmin" && permission !== null) &&
            JSON?.stringify(permission).includes("Ticket") && (
              <div className={`menu_item`}>
                <Link
                  to="/tickets"
                  className={`menu_inner ${
                    window.location.pathname === "/tickets" ||
                    window?.location?.pathname === "/ticket_reply"
                      ? "active_menu"
                      : ""
                  }`}
                >
                  <span className="menu_icon">
                    <HiOutlineTicket />
                  </span>
                  <span className="menu_txt" id="menuTxt12">
                    Tickets
                  </span>
                </Link>
              </div>
            )}
          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link
                // to="/waba_details"
                className={`menu_inner ${
                  window.location.pathname === "/waba_details" && "active_menu"
                }`}
              >
                <span className="menu_icon">
                  <AiOutlineWhatsApp />
                </span>
                <span className="menu_txt" id="menuTxt13">
                  WABA Details
                </span>
              </Link>
            </div>
          )}
          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link
                to="/knowledge_base"
                className={`menu_inner ${
                  window.location.pathname === "/knowledge_base" &&
                  "active_menu"
                }`}
              >
                <span className="menu_icon">
                  <RiQuestionMark />
                </span>
                <span className="menu_txt" id="menuTxt14">
                  Knowledge Base
                </span>
              </Link>
            </div>
          )}
          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link
                to="/MAS"
                className={`menu_inner ${
                  window.location.pathname === "/MAS" && "active_menu"
                }`}
              >
                <span className="menu_icon">
                  <FaRupeeSign />
                </span>
                <span className="menu_txt" id="menuTxt15">
                  Message Amount
                </span>
              </Link>
            </div>
          )}
          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link
                to="/ip_details"
                className={`menu_inner ${
                  window.location.pathname === "/ip_details" && "active_menu"
                }`}
              >
                <span className="menu_icon">
                  <HiOutlineLocationMarker />
                </span>
                <span className="menu_txt" id="menuTxt16">
                  IP Details
                </span>
              </Link>
            </div>
          )}
          {permission === "superadmin" && (
            <div className={`menu_item`}>
              <Link
                to="/setting"
                className={`menu_inner ${
                  window.location.pathname === "/setting" && "active_menu"
                }`}
              >
                <span className="menu_icon">
                  <AiOutlineSetting />
                </span>
                <span className="menu_txt" id="menuTxt11">
                  Settings
                </span>
              </Link>
            </div>
          )}
          {/* {permission === "superadmin" && ( */}
            <div className={`menu_item`}>
              <Link
                to="/support"
                className={`menu_inner ${
                  window.location.pathname === "/support" && "active_menu"
                }`}
              >
                <span className="menu_icon">
                <MdOutlineContactSupport />
                </span>
                <span className="menu_txt" id="menuTxt18">
                  Support
                </span>
              </Link>
            </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
