import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Ticket.css";
import {
  MdCalendarToday,
  MdOutlineSend,
  MdPersonOutline,
} from "react-icons/md";
import { HiOutlineCalendarDays, HiOutlineFilter } from "react-icons/hi";
import { baseUrl, baseUrlImage } from "../../../BaseUrl";
import { useNavigate } from "react-router-dom";
import { convertDate, convertTime } from "../../webutils";
import toast from "react-hot-toast";
const TicketReply = () => {
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const ticketId = sessionStorage.getItem("ticket");
  const navigate = useNavigate();

  const ticketData = useSelector((store) => store?.main?.userData)

  // const [ticketData, setTicketData] = useState("");
  // console.log(ticketData, "top ticket data");
  const [attachment, setAttachment] = useState(null);
  const [wait, setWait] = useState("Send");
  const permission = localStorage.getItem("permissions")
  // const getTicketDetails = () => {
  //   axios
  //     .get(
  //       `${baseUrl}ticket-api/${
  //         permission === "Super_user"
  //           ? "ticket"
  //           : "admin-ticket"
  //       }/${ticketId}/`,
  //       config
  //     )
  //     .then((respo) => {
  //       if (respo.status === 200) {
  //         if (permission === "Super_user") {
  //           setTicketData(respo.data.results[0]);
  //         }else{
  //           setTicketData(respo.data.results[0]);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log(err)
  //     });
  // };
  // console.log(ticketData, "ticketData niche post wala", attachment);
  const [chatChatData, setChitChat] = useState([]);
  const getChitChat = () => {
    axios
      .get(`${baseUrl}super-admin/receive/ticket/response-via-superadmin/?ticket_id=${ticketData?._id}`, config)
      .then((respo) => {
        if (respo?.status) {
          setChitChat(respo?.data);
          setAttachment(null);
          // console.log("get---------", respo?.data?.ticketDetails);
        }
      });
  };
  let date = new Date(ticketData?.createdAt);
  let convertedDate = convertDate(ticketData.createdAt);
  let convertedTime = convertTime(ticketData.createdAt);
  const [message, setMessage] = useState("");

  const submitText = (e) => {
    e.preventDefault();
    setWait("Please wait...")
    const formData = new FormData();
    formData.append("message", message);
    formData.append("file", attachment);
    formData.append("ticket_id", ticketData?._id);
    // console.log('ticket for m data.////', formData, attachment);
    axios
      .post(`${baseUrl}super-admin/send-reply-superadmin`, formData, config)
      .then((respo) => {
        if (respo.status === 200) {
          toast.success(respo.data.message);
          setAttachment(null);
          getChitChat();
          setWait("Response sent successfully!")
          setTimeout(() => {
            setMessage("");
            setAttachment(null);
            setWait("Send")
          }, 1000);
        }
      })
      .catch((err) => {
        // console.log(err)
        toast.error(err.response.data.message);
        setWait("Can't Send");
        setTimeout(() => {
          setWait("Send")
        }, 1000);
      });
  };
  const closeTicket = (id) => {
    let formData = {
      id: id,
      status: "Closed",
    };

    axios
      .put(`${baseUrl}super-admin/close-tickets`, formData, config)
      .then((respo) => {
        if (respo.status === 200) {
          toast.success(respo.data.message);
          navigate("/tickets");
          // getTicketDetails();
        }
      })
      .catch((err) => {
        // console.log(err)
        toast.error(err.response.data.message);
      });;
  };
  useEffect(() => {
    getChitChat();
    // getTicketDetails();
  }, []);
  return (
    <div className="ticketReply">
      <div className="customers_headeing">
        <div className="heading_left">
          <div className="form_title">Ticket Solution <span style={{
            color: "#1877F2",
            fontSize: "14px",
            fontWeight: "500",
          }}>{ticketData?.company_id?.company_name}</span></div>
        </div>
        <div className="heading_right">
          <div
            className="back_btn"
            onClick={() => {
              navigate("/tickets");
            }}
          >
            <span>
              <HiOutlineFilter />
            </span>
            <span>Back</span>
          </div>
        </div>
      </div>
      <div className="ticketDetailsGrid">
        <div className="leftTicket">
          <div className="ticketResponseHeader">
            <div className="ticketTitle">Response</div>
            {(
              <div className="closeTicket">
                {ticketData?.status === "Closed" ? (
                  <button disabled>Closed</button>
                ) : (
                  <button
                    onClick={() => {
                      closeTicket(ticketId);
                    }}
                  >
                    Close Ticket
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="chitChatList">
            {ticketData?.status === "Closed"
              // ||
              // localStorage.getItem("permissions") === "Super_user" 
              ? (
                ""
              ) : (
                <div className="responseContainer">
                  <textarea
                    placeholder="Response here..."
                    className="ticketText"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div className="attachResponse">
                    <input
                      type="file"

                      onChange={(e) => setAttachment(e.target.files[0])}
                    />
                  </div>
                  <div
                    style={{
                      isplay: "flex",
                      justifyContent: "right",
                      marginTop: "5px",
                    }}
                  >
                    <button
                      onClick={(e) => submitText(e)}
                      className="sendBtnTicket"
                    >
                      {wait}
                    </button>
                  </div>
                </div>
              )}

            <div
              className="ticketResponseSection"
              style={{ marginTop: "14px" }}
            >
              {chatChatData?.ticketDetails?.map((chat, i) => {
                let date = new Date(chat?.createdAt);
                let chatDate = convertDate(date);
                let chatTime = date.toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                });
                const chat_media_url = chat?.file && chat?.file.replace('./', baseUrlImage);
              
                return (
                  <div className={"replyRow"}>
                    <div className="meassgeHeader">
                      <div className="personNameTicket">
                        <MdPersonOutline />{" "}
                        {chat?.user_type === "super_admin"
                          ? "Me"
                          : chat?.user_type}
                      </div>
                      <div className="messageTimeTicket">
                        <MdCalendarToday />
                        {chatDate}, {chatTime}
                      </div>
                    </div>
                    <div className="messageBoxTicket">
                      <div className="ticketContent">
                        <span>
                          {/* <span>{(chat?.message).replace(/[\r\n]+/gm, <br />)}</span> */}
                          <span style={{ whiteSpace: "pre-wrap" }}>
                            {chat?.message}
                          </span>
                          {/* <span className='replyTimeTicket'>{chatTime}</span> */}
                        </span>
                      </div>
                      <div className="ssContainer">
                        {chat?.file && (
                          <a
                            target="_blank"
                            href={`${chat_media_url}`}
                            rel="noreferrer"
                          >
                            <img
                                  src={`${chat_media_url}`}
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    borderRadius: "10px",
                                    objectFit: "contain",
                                  }}
                                  alt="chat_file"
                                  accept="*png, .jpeg, .jpg, .pdf"
                                />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <form onSubmit={(e) =>submitText(e)} className='ticketMessageBox'>
                        <input
                            placeholder='Reply Here...'
                            className='ticketText'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} />
                        <button className='sendBtnTicket'><MdOutlineSend /></button>
                    </form> */}
        </div>
        <div className="rightTicket">
          <div className="ticketTitle">Ticket-Details</div>
          <div className="deatilsGridForTicket">
            <div className="ticketTite">Ticket Number</div>
            <div>:</div>
            <div className="ticketNames">{ticketData?.ticket_id}</div>
            <div className="ticketTite">Category</div>
            <div>:</div>
            <div className="ticketNames">{ticketData?.sector}</div>
            <div className="ticketTite">Priority</div>
            <div>:</div>
            <div className="ticketNames">{ticketData?.priority}</div>
            <div className="ticketTite">Status</div>
            <div>:</div>
            <div
              className={`ticketNames`}
              style={{
                color: `${ticketData?.status === "New"
                  ? "green"
                  : ticketData?.status === "Pending"
                    ? "orange"
                    : "red"
                  }`,
              }}
            >
              {ticketData?.status}
            </div>
            <div className="ticketTite">Date</div>
            <div>:</div>
            <div className="ticketNames">{convertedDate}</div>
            <div className="ticketTite">Time</div>
            <div>:</div>
            <div className="ticketNames">{convertedTime}</div>
          </div>
          <div className="subjectTicket">Subject</div>
          <div className="ticketNames">{ticketData?.subject}</div>
          <div className="subjectTicket">Description</div>
          <div className="ticketNames" style={{ whiteSpace: "pre-wrap" }}>
            {ticketData?.description}
          </div>
          <div className="coreImageTicket">
            {ticketData?.file && (
              <a href={ticketData?.file} target="blank">
                <img width={"100%"} src={ticketData?.file} />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketReply;
