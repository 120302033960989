
import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import toast from "react-hot-toast";
import './addAmount.css';


const AddAmount = (props) => {
  const {
    openDialog,
    planListData,
    editedData = null,
    setEditedData,
    config,
    setOpenDialog,
    setmoneyDeductionListData,
    moneyDeductionlistData,
  } = props;
// console.log('plan list data',planListData);
  const [planError, setPlanError] = useState({});
  const [pricingData, setPricingData] = useState({
    plan: editedData?.plan || "",
    file_upload: null,
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPricingData({ ...pricingData, file_upload: file });
    }
  };

  const formHandler = (e) => {
    const { name, value } = e.target;
    setPricingData({ ...pricingData, [name]: value });
  };

  const pricingFormValidator = (data) => {
    let err = {};
    if (!data?.file_upload) {
      err["file_upload"] = "File not uploaded!";
    }
    if (!data?.plan) {
      err["plan"] = "Plan not selected!";
    }

    setPlanError(err);
    return Object.keys(err).length > 0 ? false : true;
  };

  const submitForm = async (for_update) => {
    try {
      let data = new FormData();
      data.append("plan", pricingData?.plan);
      data.append("file_upload", pricingData?.file_upload);

      let validate = pricingFormValidator(pricingData);
      if (!validate) {
        return;
      }

      if (for_update) {
        let update_api = `${baseUrl}add-payment-deduction/${editedData?.id}/`;
        let res = await axios.put(update_api, data, config);
        if (res?.status < 400) {
          toast.success("Data Updated!");
          dialogCloser();
        }
      } else {
        let create_api = `${baseUrl}message/price/upload`;
        let res = await axios.post(create_api, data, config);
        if (res?.status === 200) {
          toast.success("Message Amound Data Created Successfullly!");
          props.moneyDeductionList();
          dialogCloser();
        }
    
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const dialogCloser = () => {
    setPricingData({
      plan: "",
      file_upload: null,
    });
    setEditedData({});
    setPlanError({});
    setOpenDialog(false);
  };

  useEffect(() => {
    if (editedData && Object.keys(editedData).length > 0) {
      const { plan, file_upload } = editedData || {};
      setPricingData({
        plan: plan || "",
        file_upload: file_upload || null,
      });
    }
  }, [openDialog]);

  return (
    <Dialog onClose={dialogCloser} open={openDialog}>
      <p className="pricing-dialog-title">Set Message Pricing</p>
      <div className="pricing-dialog-main">
        <div className="select-wrapper">
          <div className="select-main">
            <label>Select Plan</label>
            <select
              name="plan"
              className="pricing-select"
              onChange={formHandler}
              value={pricingData?.plan}
            >
              <option>Select</option>
              {planListData?.map((item) => {
                return (
                  <option key={item?._id} value={item?._id}>
                    {item?.plan_name}
                  </option>
                );
              })}
            </select>
            {planError && planError?.plan && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {planError?.plan}
              </span>
            )}
          </div>
        </div>
        <div style={{ paddingTop: "15px" }}>
          <div className="pricing-mini-heading">
            <span className="text">Upload Pricing</span>
            <span className="line"></span>
          </div>
          <div className="pricing-section-main">
            <div className="mini-section">
              <span>Upload File</span> :{" "}
              <input
                type="file"
                name="file_upload"
                onChange={handleFileUpload}
              />
            </div>
          </div>
          {planError && planError?.file_upload && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {planError?.file_upload}
            </span>
          )}
        </div>
        <div className="button-wrapper">
          <button onClick={dialogCloser}>Cancel</button>
          <button
            onClick={() =>
              submitForm(editedData && Object.keys(editedData).length > 0 ? true : false)
            }
          >
            {editedData && Object.keys(editedData).length > 0 ? `Update` : "Create"}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddAmount;

