import React, { useEffect, useState } from "react";
import "../Customers/Customer.css";
import {
  MdDeleteOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineListAlt,
} from "react-icons/md";
import { HiOutlineFilter, HiOutlinePencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import InputField from "../../reusableComponent/InputField";
import Button from "../../reusableComponent/Button";
import axios from "axios";
import profile from "../../../Assets/profile.jpeg";
import { baseUrl } from "../../../BaseUrl";
import ConfirmDialog from "../../confirmDialog/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { Toaster, toast } from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { handleEmployeeData } from "../../../ReduxSlices/MainSlice";
// const baseurl = process.env.REACT_APP_API_URL
const EmployeeList = () => {
  const dispatch = useDispatch();
  const apiUrl = `${baseUrl}add-admin`;
  const apiUrlDelete = `${baseUrl}super-admin/`;
  const apiUrlActive = `${baseUrl}super-admin/`;
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const navigate = useNavigate();
  const [uuid, setUuid] = useState("");
  const [profilePic, setProfilePic] = useState();
  const [empName, setEmpName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [empPermission, setEmpPermission] = useState([]);
  const [wait, setWait] = useState("Save");
  const [waitUp, setWaitUp] = useState("Update");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [country, setCountry] = useState("");
  const [employeetitle, setEmployeeTitle] = useState("Add");
  const [allData, setAllData] = useState();
  const [filterShowHide, setFilterShowHide] = useState("none");
  const [popupToggle, setPopUpToggle] = useState("");
  const [reminderProfilePopup, setReminderProfilePopup] = useState("");
  const [imageIdentity, setImageIdentity] = useState("");
  const [confirmEnable, setConfirmEnable] = useState(false);
  const [confirmName, setConfirmName] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmId, setConfirmId] = useState("");
  const [employeeView, setEmployeeView] = useState();

  // Validation
  const [nameErr, setNameErr] = useState(0);
  const [emailErr, setEmailErr] = useState(0);
  const [mobileErr, setMobileErr] = useState(0);
  const [permissionErr, setPermissionErr] = useState(0);
  const [designationErr, setDesignationErr] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const handleAddEmployee = () => {
    setEmployeeTitle("Add");
    setPopUpToggle("ongoing_popup");
  };
  const editEmployeeData = (emp) => {
    setConfirmId(emp?._id);
    // console.log(emp, "emp1234");
    setEmployeeTitle("Update");
    setPopUpToggle("ongoing_popup");
    setEmpName(emp.name ? emp.name : "");
    setEmailId(emp.email ? emp.email : "");
    setMobileNumber(emp.mobile ? emp.mobile : "");
    setDesignation(emp.designation ? emp.designation : "");
    setEmpPermission(emp.permission ? JSON?.parse(emp.permission) : []);
    setProfilePic(emp?.profile_pic ? emp?.profile_pic : "");
    setGender(emp?.gender ? emp?.gender : "");
    setAddress(emp?.address ? emp?.address : "");
    setPinCode(emp?.pincode ? emp.pincode : "");
    setState(emp?.state ? emp.state : "");
    setCountry(emp?.country ? emp?.country : "");
    setImageIdentity("");
  };
  // console.log(empPermission, "permission");
  // console.log(profilePic, "image");
  const empChecked = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setEmpPermission([...empPermission, value]);
    } else {
      setEmpPermission(empPermission.filter((val) => val !== value));
    }
  };
  const dataForm = {
    name: empName,
    email: emailId,
    mobile: mobileNumber,
    permission: JSON.stringify(empPermission),
    designation: designation,
    gender: gender
  };

  const submitSubscription = () => {
    if (dataForm?.name === "") {
      setNameErr(1);
    } else if (dataForm?.email === "") {
      setEmailErr(1);
    } else if (dataForm?.mobile === "") {
      setMobileErr(1);
    } else if (dataForm?.designation === "") {
      setDesignationErr(1);
    } else if (empPermission?.length === 0) {
      setPermissionErr(1);
    } else {
      setWait("Please wait...");
      axios
        .post(`${apiUrl}/create`, dataForm, config)
        .then((respo) => {
          employeeListFunc(1);

          setWait("Saved Successfull");
          toast.success(respo.data.message); 
          setTimeout(() => {
            setPopUpToggle("");
            setEmpPermission([]);
            setEmpName("");
            setEmailId("");
            setMobileNumber("");
            setDesignation("");
          }, 2000);
          setTimeout(() => {
            setWait("Save");
          }, 3000);
        })
        .catch((err) => {
          // console.log(err)
          toast.error(err.response.data.message);

        });
    }
  };



  // const updateEmployee = () => {
  //   const updateData = new FormData();
  //   updateData.append("name", empName);
  //   updateData.append("email", emailId);
  //   updateData.append("mobile", mobileNumber);
  //   updateData.append("permission", JSON?.stringify(empPermission));
  //   updateData.append("designation", designation);
  //   updateData.append("gender", gender);
  //   updateData.append("profile_pic", profilePic);
  //   updateData.append("state", state);
  //   updateData.append("pincode", pinCode);
  //   updateData.append("address", address);
  //   updateData.append("country", country);
  //   console.log('saifBhai',updateData);
  //   console.log('name.....',empName);
  //   console.log('emailId.....',emailId);
  const updateEmployee = () => {
    // Create an object with the fields to update
    const updateData = {
      name: empName,
      email: emailId,
      mobile: mobileNumber,
      permission: empPermission,
      designation: designation,
      gender: gender,
      profile_pic: profilePic,
      state: state,
      pincode: pinCode,
      address: address,
      country: country
    };
    // console.log('data updte', updateData);
    setWaitUp("Please wait...");
    axios
      .patch(`${apiUrl}/${confirmId}/update-profile-via-superadmin`, updateData, config)
      .then((respo) => {
        // if (respo.status === 200) {
        // console.log(respo.data, "1234");
        toast.success(respo.data.message);
        employeeListFunc(allData.page);
        setTimeout(() => {
          setWaitUp("Data Updated");
          setPopUpToggle("");
        }, 200);
        setTimeout(() => {
          setWaitUp("Update");
          setPopUpToggle("");
        }, 250);
        // }
      })
      .catch((err) => {
        // console.log(err)
        toast.error(err.response.data.message);
      });
  };
  const employeeListFunc = (page, searchValue) => {
    axios
      .get(`${apiUrl}/profile-list-super-admin/?page=${page}&search=${searchValue}`, config)
      .then((respo) => {
        if (respo.status === 200) {
          setAllData(respo.data);
          // console.log(respo.data, "add admin..........///////");
        }
      })
      .catch((err) => {
        toast.error(`${err?.response?.data?.message}`);
        // console.log(err)
      });
  };
  useEffect(() => {
    employeeListFunc(1, searchValue);
  }, [searchValue]);

  // Delete Employee
  const [deleteBtnName, setDeleteBtnName] = useState("Delete");
  const deleteEmployee = (emp) => {
    setConfirmEnable(true);
    setConfirmName(emp?.name);
    setConfirmId(emp?._id)
  };
  const confirmdelete = () => {
    setConfirmDelete(true);
    setTimeout(() => {
      setConfirmDelete(false);
    }, 500);
  };
  // Cancel Form
  const cancelDelete = () => {
    setConfirmEnable(false);
  };
  // console.log('id delte wali..=',confirmId);
  useEffect(() => {
    if (confirmDelete) {
      setDeleteBtnName("Please wait...");
      axios
        .delete(`${apiUrlDelete}${confirmId}/delete-admin-via-superadmin`, config)
        .then((respo) => {
          if (respo.status === 200) {
            toast.success(respo.data.message);
            employeeListFunc(1);
            setDeleteBtnName("Data deleted!");
            setConfirmEnable(false);
            setTimeout(() => {
              setDeleteBtnName("Delete");
            }, 200);
          }
        })
        .catch((err) => {
          setConfirmEnable(true);
          toast.error(err.response.data.message);
          setDeleteBtnName("Can't delete!");
          setTimeout(() => {
            setDeleteBtnName("Delete");
          }, 2000);
        });
    }
  }, [confirmDelete]);

  const cancelForm = () => {
    setEmailId("");
    setEmpName("");
    setMobileNumber("");
    setDesignation("");
    setEmpPermission([]);
    setPopUpToggle("");
    setProfilePic();
  };
  const handleOpenDialog = (data) => {
    // setReminderProfilePopup("ongoing_popup");
    localStorage.setItem("employee_profile", JSON.stringify(data));
    setTimeout(() => {
      navigate("/employee_profile");
    }, 100);
    dispatch(handleEmployeeData(data));
    setEmployeeView(data);

    // console.log(data, "data for ");
  };
  const handlePopupClose = () => {
    setReminderProfilePopup("");
  };
  return (
    <div className="customers_page">
      <Toaster position="top-center" reverseOrder={false} />
      {/* View Popup */}
      <div
        className={reminderProfilePopup + " " + "ongoing_popup_hide"}
        disabled
      >
        {/* popup code open */}
        <div className="popup-main">
          <div className="popup-card">
            <div
              style={{
                width: "100%",
                padding: "0 10px",
                fontSize: "1rem",
                color: "#595959",
                fontWeight: "600",
              }}
            >
              Employee Details
            </div>
            <RxCross2 onClick={handlePopupClose} className="cross-icons" />
            <>
              <div
                style={{
                  fontSize: "0.8rem",
                  color: "#393939",
                }}
                className="task-details-main"
              >
                <div className="flex-class">
                  <span>Name</span>
                  <span>:</span>
                  <span>{employeeView?.name}</span>
                </div>
                <div className="flex-class">
                  <span>Email</span>
                  <span>:</span>
                  <span>{employeeView?.email}</span>
                </div>
                <div className="flex-class">
                  <span>Mobile</span>
                  <span>:</span>
                  <span>{employeeView?.mobile}</span>
                </div>
                <div className="flex-class">
                  <span>Gender</span>
                  <span>:</span>
                  <span>{employeeView?.gender}</span>
                </div>

                <div className="flex-class">
                  <span>Address</span>
                  <span>:</span>
                  <span>
                    {employeeView?.address ? employeeView?.address : "---"},{" "}
                    {employeeView?.city ? employeeView?.city : "---"},{" "}
                    {employeeView?.state ? employeeView?.state : "---"},{" "}
                    {employeeView?.pinCode ? employeeView?.pinCode : "---"}
                  </span>
                </div>
                <div className="flex-class">
                  <span>Designation</span>
                  <span>:</span>
                  <span>{employeeView?.designation}</span>
                </div>
                <div className="flex-class">
                  <span>Permissions</span>
                  <span>:</span>
                  <div>
                    {employeeView?.permission &&
                      employeeView?.permission?.map((item, i) => {
                        return (
                          <div>
                            <span>
                              {i + 1}. {item}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="flex-class">
                  <span>Status</span>
                  <span>:</span>
                  <span
                    style={{
                      color: `${employeeView?.active_status ? "#228B22" : "#FF5733"
                        }`,
                    }}
                  >
                    {employeeView?.active_status ? "Active" : "Deactivated"}
                  </span>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className={popupToggle + " " + "ongoing_popup_hide"}>
        {/* popup code open */}
        <div className="popup-main">
          <div className="popup-card">
            <div className="popup_ttl">{employeetitle} Employee</div>
            {/* <div className="profileContainer">
              <div className="imageSupport">
                <input
                  type={"file"}
                  className="proInput"
                  name="proImage"
                  onChange={(e) => {
                    setProfilePic(e.target.files[0]);
                    setImageIdentity("uploadImage");
                  }}
                />
                {imageIdentity !== "uploadImage" && profilePic ? (
                  <img src={profilePic} alt="profile pic" className="proPic" />
                ) : imageIdentity === "uploadImage" && profilePic ? (
                  <img
                    src={URL.createObjectURL(profilePic)}
                    alt="profile pic"
                    className="proPic"
                  />
                ) : (
                  <img src={profile} alt="profile pic" className="proPic" />
                )}
              </div>
            </div> */}
            <div className="employeeInputs">
              <div className="fields-wrapper">
                <p>Name</p>
                <InputField
                  placeholder={"Sunil Bhatiya"}
                  value={empName}
                  onChange={(e) => setEmpName(e.target.value)}
                />
                <span
                  style={{ fontSize: "11px", color: "red", opacity: nameErr }}
                >
                  Please enter name
                </span>
              </div>
              <div className="fields-wrapper">
                <p>Email Id</p>
                <InputField
                  placeholder={"example@gmail.com"}
                  value={emailId}
                  type={"email"}
                  onChange={(e) => setEmailId(e.target.value)}
                />
                <span
                  style={{ fontSize: "11px", color: "red", opacity: emailErr }}
                >
                  Please enter email id
                </span>
              </div>
              <div className="fields-wrapper">
                <p>Mobile Number</p>
                <InputField
                  placeholder={"+91 8990899089"}
                  value={mobileNumber}
                  maxLength={10}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
                <span
                  style={{ fontSize: "11px", color: "red", opacity: mobileErr }}
                >
                  Please enter mobile number
                </span>
              </div>
              <div className="fields-wrapper">
                <p>Designation</p>
                <InputField
                  placeholder={"Exa: Sales"}
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                />
                <span
                  style={{
                    fontSize: "11px",
                    color: "red",
                    opacity: designationErr,
                  }}
                >
                  Please enter designation
                </span>
              </div>
              {employeetitle === "Update" && (
                <>
                  <div className="fields-wrapper">
                    <p>Gender</p>
                    <select
                      className="selectInput"
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                      <option value={"other"}>Other</option>
                    </select>
                    {/* <span style={{fontSize: '11px', color: 'red', opacity: genderErr}}>Please select gender</span> */}
                  </div>
                  <div className="fields-wrapper">
                    <p>Address</p>
                    <InputField
                      placeholder={"Exa: D 337, Najafgarh"}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <p>State</p>
                    <InputField
                      placeholder={"Exa: Delhi"}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <p>Pin Code</p>
                    <InputField
                      placeholder={"Exa: 110043"}
                      value={pinCode}
                      onChange={(e) => setPinCode(e.target.value)}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <p>Country</p>
                    <InputField
                      placeholder={"Exa: India"}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="popup_ttl">Permission</div>
            <div className="allInputwrapper">
              <div className="inputWrapper">
                <input
                  className="emp_check"
                  type={"checkbox"}
                  id={"planA"}
                  value={"Company"}
                  onChange={(e) => empChecked(e)}
                  checked={empPermission?.includes("Company") && true}
                />{" "}
                <label className="emp_label" htmlFor="planA">
                  Company
                </label>
              </div>
              <div className="inputWrapper">
                <input
                  className="emp_check"
                  type={"checkbox"}
                  id={"planB"}
                  value={"Leads"}
                  onChange={(e) => empChecked(e)}
                  checked={empPermission?.includes("Leads") && true}
                />{" "}
                <label className="emp_label" htmlFor="planB">
                  Leads
                </label>
              </div>
              <div className="inputWrapper">
                <input
                  className="emp_check"
                  type={"checkbox"}
                  id={"planC"}
                  value={"Sales"}
                  onChange={(e) => empChecked(e)}
                  checked={empPermission?.includes("Sales") && true}
                />{" "}
                <label className="emp_label" htmlFor="planC">
                  Sales
                </label>
              </div>
              <div className="inputWrapper">
                <input
                  className="emp_check"
                  type={"checkbox"}
                  id={"planD"}
                  value={"Ticket"}
                  onChange={(e) => empChecked(e)}
                  checked={empPermission?.includes("Ticket") && true}
                />{" "}
                <label className="emp_label" htmlFor="planD">
                  Ticket
                </label>
              </div>
            </div>
            <span
              style={{ fontSize: "11px", color: "red", opacity: permissionErr }}
            >
              Please select atleast one permission
            </span>
            <div className="btn_wrapper">
              <Button
                text={"Cancel"}
                className="cancelBtn"
                onClick={() => cancelForm()}
              />
              {employeetitle === "Update" ? (
                <Button
                  text={waitUp}
                  className="saveBtn"
                  onClick={() => updateEmployee()}
                />
              ) : (
                <Button
                  text={wait}
                  className="saveBtn"
                  onClick={() => submitSubscription()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* popup code close */}
      <div className="customers_inner">
        <div className="customers_headeing">
          <div className="heading_left">
            <div
              className="create_btn"
              onClick={() => {
                handleAddEmployee();
              }}
            >
              Add Employee
            </div>
          </div>

          {/* #############SEARCH FILTER################### */}
          <div className="search-filter-container">
            <input
              type="search"
              placeholder="Search by name or mobile number"
              className="search-input"
              name="search_employee"
              value={searchValue}
              onChange={(e) => setSearchValue(e?.target?.value)}
            />
          </div>
        </div>
        <div className="customer_table_container">
          <div className="customer_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Mobile Number</th>
                  <th>Email Id</th>
                  <th>Activated</th>
                  <th>Permissions</th>
                  <th>Department</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allData?.data?.length > 0 ? (
                  allData?.data?.map((emp, i) => {
                    // console.log(emp);
                    return (
                      <tr key={i}>
                        <td>
                          {i +
                            (allData?.currentPage * allData?.page_size -
                              (allData?.page_size - 1))}
                        </td>
                        <td>{emp?.name ? emp?.name : "Unknown"}</td>
                        <td>{emp?.mobile ? emp?.mobile : "Unknown"}</td>
                        <td>{emp?.email ? emp?.email : "Unknown"}</td>
                        <td>
                          <input
                            type="checkbox"
                            // checked={emp?.active_status}
                            checked={!emp?.is_deactivated}
                            id={i}
                            onChange={(e) => {
                              let dataForm = {
                                is_deactivated: !e.target.checked,
                              };

                              axios
                                .post(
                                  `${apiUrlActive}${emp._id}/admin-toggle-deactivation/`,
                                  dataForm,
                                  config
                                )
                                .then((respo) => {
                                  if (respo.status === 200) {
                                    // console.log('resp emp', respo.data.success);
                                    if (respo?.data.success === true) {
                                      toast.success("Employee Activated");
                                    } else {
                                      toast.success("Employee De-Activated");
                                    }
                                    let filteredTest = allData?.data?.map(
                                      (i) => {
                                        if (i?._id === emp?._id) {
                                          return {
                                            ...i,
                                            is_deactivated: e.target.checked,
                                          };
                                        }
                                        return i;
                                      }
                                    );
                                    setAllData({
                                      ...allData,
                                      data: filteredTest,
                                    });
                                  }
                                });
                            }}
                          />
                          <label htmlFor={i}>Toggle</label>
                        </td>
                        <td>
                          {emp?.permission?.map((item, index) => (
                            <span>{item}, </span>
                          ))}
                        </td>
                        <td>{emp?.designation ? emp?.designation : "---"}</td>
                        <td>
                          <div className="action_btns">
                            <AiOutlineEye
                              className="action_icon_view reminder-eye-icon"
                              onClick={() => {
                                handleOpenDialog(emp);
                              }}
                            />
                            <HiOutlinePencil
                              className="action_icon_edit"
                              onClick={() => {
                                editEmployeeData(emp);
                              }}
                            />
                            <MdDeleteOutline
                              className="action_icon_delete"
                              onClick={() => {
                                deleteEmployee(emp);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                    // style={{
                    //   textAlign: "center",
                    //   padding: "10px",
                    //   // backgroundColor: "#c9c9c9",
                    //   fontSize: "16px",
                    //   fontWeight: "500",
                    //   color: "#595959",
                    // }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          padding: "40px",
                          backgroundColor: "#c9c9c9",
                          fontSize: "30px",
                          fontWeight: "600",
                          color: "#595959",
                        }}
                      >
                        No Data Available!
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {allData?.data?.length > 0 && (
              <div className="pegination">
                <div className="peginationinner">
                  <MdKeyboardArrowLeft
                    className="arrowPreNext"
                    onClick={() => {
                      allData?.previousPage != null &&
                        employeeListFunc(allData?.previousPage);
                    }}
                  />
                  <span>
                    {allData?.currentPage} out of{" "}
                    {Math.ceil(allData?.totalPages / allData?.page_size)}
                  </span>
                  <MdKeyboardArrowRight
                    className="arrowPreNext"
                    onClick={() => {
                      allData?.nextPage != null &&
                        employeeListFunc(allData?.nextPage);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {confirmEnable && (
        <ConfirmDialog
          confirmIcon={<DeleteIcon />}
          confirmText={`Do you want to delete ${confirmName} ?`}
          submitBtnText={deleteBtnName}
          confirmSubmit={confirmdelete}
          confirmCancel={cancelDelete}
        />
      )}
    </div>
  );
};

export default EmployeeList;
