import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmployeeDashboard from "./EmployeeDashboard";
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EmployeeLeads from "./EmployeeLeads";
import Reminder from "../Leads/Reminder";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useDispatch } from "react-redux";
import { handleEmployeeAnalytics } from "../../../ReduxSlices/MainSlice";
import EmployeeCompany from "./EmployeeCompany";
import EmployeeTicket from "./EmployeeTicket";
import EmployeeNoteTask from "./EmployeeNoteTask";
// import EmployeeCompany from "./EmployeeCompany";
var token;
if (localStorage.getItem("saToken2"))
  token = localStorage.getItem("saToken2");

var config = { headers: { Authorization: `Bearer ${token}` } };
const EmployeeProfile = () => {
  const pageValue = sessionStorage?.getItem("navigate");
  // const employeeAllData = useSelector((store) => store?.main?.employeeData);
  const employeeAllData = JSON?.parse(localStorage.getItem("employee_profile"));
  const [profileComponent, setProfileComponent] = useState(<EmployeeDashboard />);
  const [selectedBtn, setSelectedBtn] = useState("Profile");
  const dispatch = useDispatch();
  // console.log('emp profile',employeeAllData);
  const getEmployeeData = () => {
    axios(`${baseUrl}leads/employee/fetch-leademployee-list-via-superadmin/${employeeAllData?._id}`, config).then((respo) => {
      // console.log(respo?.data, "employee90")
      dispatch(handleEmployeeAnalytics(respo?.data));
    }).catch((err) => {
      // console.log(err)
    })
  }
  useEffect(() => {
    getEmployeeData()
  }, [])
  const navigate = useNavigate();
  const handleToggleComponent = (value) => {
    setSelectedBtn(value);
    sessionStorage?.setItem("navigate", value)
    if (value === "Profile") {
      setProfileComponent(<EmployeeDashboard />);
    } else if (value === "Leads") {
      setProfileComponent(<EmployeeLeads />);
    } else if (value === "Tickets") {
      setProfileComponent(<EmployeeTicket />);
    } else if (value === "Company") {
      setProfileComponent(<EmployeeCompany />);
    } else if (value == "Note" || value === "Task") {
        setProfileComponent(<EmployeeNoteTask />);
    } else if (value == "SentMAil") {
      //   setProfileComponent(<SentMail />);
    } else {
      setProfileComponent(null);
    }
  };
  useEffect(() => {
    handleToggleComponent(pageValue);
  }, [pageValue]);
  return (
    <div className="customers_page">
      <div className="customers_inner">
        <div className="customers_headeing">
          <div className="heading_left">
            <div className="form_title">Lead Profile<span style={{ color: '#1877F2', fontWeight: '500' }}>({employeeAllData?.name})</span></div>
          </div>
          <div className="heading_right">
            <div
              className="back_btn"
              onClick={() => {
                sessionStorage?.setItem("navigate", "Profile")
                navigate("/employee");
                // {
                //   employeeAllData && setOpenEditLeads("lead_table");
                // }
              }}
            >
              <span>
                {/* <HiOutlineFilter /> */}
              </span>
              <span>Back</span>
            </div>
          </div>
        </div>
        <div className="customer_table_container">
          <div className="customer_profile_up">
            <div className="profile_options">
              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Profile")}
                style={{
                  border: selectedBtn === "Profile" && "1px solid gray",
                }}
              >
                <PersonOutlineOutlinedIcon />
                <div className="option_txt">Profile</div>
              </div>
              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Leads")}
                style={{
                  border: selectedBtn === "Leads" && "1px solid gray",
                }}
              >
                <PhoneCallbackOutlinedIcon />
                <div className="option_txt">Leads</div>
              </div>

              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Company")}
                style={{
                  border: selectedBtn === "Company" && "1px solid gray",
                }}
              >
                <PhoneCallbackOutlinedIcon />
                <div className="option_txt">Company</div>
              </div>

              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Tickets")}
                style={{
                  border: selectedBtn === "Tickets" && "1px solid gray",
                }} d
              >
                <PhoneCallbackOutlinedIcon />
                <div className="option_txt">Tickets</div>
              </div>

              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Note")}
                style={{
                  border: selectedBtn === "Note" && "1px solid gray",
                }}
              >
                <TextSnippetOutlinedIcon />
                <div className="option_txt">Notes</div>
              </div>
              <div
                className="profile_option"
                onClick={() => handleToggleComponent("Task")}
                style={{
                  border: selectedBtn === "Task" && "1px solid gray",
                }}
              >
                <AssignmentOutlinedIcon />
                <div className="option_txt">Tasks</div>
              </div>
            </div>
          </div>
          {selectedBtn === "Profile" && (
            <EmployeeDashboard />
          )}
          {selectedBtn === "Leads" && (
            <EmployeeLeads />
          )}
          {selectedBtn === "Company" && (
            <EmployeeCompany />
          )}
          {selectedBtn === "Tickets" && (
            <EmployeeTicket />
          )}
          {(selectedBtn === "Note" || selectedBtn === "Task") && (
            <EmployeeNoteTask employeeAllData={employeeAllData} noteType={selectedBtn}/>
          )}
        </div>
      </div>
    </div>
  )
}

export default EmployeeProfile