import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Components/Authentication/Login";
import Home from "./Components/Home/Home";
import CreateInvoice from "./Components/Outlet/CreateInvoice/createInvoice";
import CustomerInvoice from "./Components/Outlet/CustomerInvoice/customerInvoice";
import CreateCustomer from "./Components/Outlet/Customers/CreateCustomer";
import CustomerList from "./Components/Outlet/Customers/CustomerList";
import CustomerProfile from "./Components/Outlet/Customers/CustomerProfile/CustomerProfile";
import ViewPayment from "./Components/Outlet/Customers/CustomerProfile/ViewPayment";
import Dashboard from "./Components/Outlet/Dashboard/Dashboard";
import EmployeeList from "./Components/Outlet/Employee/EmployeeList";
import CreateLead from "./Components/Outlet/Leads/createLead";
import Leads from "./Components/Outlet/Leads/leads";
import ViewLeads from "./Components/Outlet/Leads/ViewLead";
import SalesInvoice from "./Components/Outlet/Sales/Invoices/CustomerInvoice/customerInvoice";
import SalesPayment from "./Components/Outlet/Sales/Payments/Payment";
import SalesProposals from "./Components/Outlet/Sales/Propsals/Proposals";
import Sales from "./Components/Outlet/Sales/Sales";
import Setting from "./Components/Outlet/Setting/Setting";
import Subscription from "./Components/Outlet/Subscription/subscription";
import TicketList from "./Components/Outlet/TicketSystem/TicketList";
import TicketReply from "./Components/Outlet/TicketSystem/TicketReply";
import ViewInvoice from "./Components/Outlet/ViewInvoice/viewInvoice";
import ViewSubs from "./Components/Outlet/ViewSubs/viewSubs";
import ProfileCard from "./Components/Outlet/Setting/ProfileCard/ProfileCard";
import WABADetails from "./Components/Outlet/WABADetails/WABADetails";
import KnowledgeBase from "./Components/Outlet/KnowledgeBase/KnowledgeBase";
import PlanSetting from "./Components/Outlet/Setting/PlanSetting";
import IPDetails from "./Components/Outlet/IPDetails/IPDetails";
import Support from "./Components/Outlet/Support/support";
import EmployeeProfile from "./Components/Outlet/Employee/EmployeeProfile";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route element={<Home />}>
          <Route path="/profile" element={<ProfileCard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/customers" element={<CustomerList />} />
          <Route path="/employee" element={<EmployeeList />} />
          <Route path="/employee_profile" element={<EmployeeProfile />} />
          <Route path="/create_customers" element={<CreateCustomer />} />
          <Route path="/customer_profile" element={<CustomerProfile />} />
          <Route path="/view_payment/:sales" element={<ViewPayment />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/create-lead" element={<CreateLead />} />
          <Route path="/view-lead" element={<ViewLeads />} />
          <Route path="/lead-task" element={<CreateInvoice />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/customer-invoice" element={<CustomerInvoice />} />
          <Route path="/view-invoice/:sales" element={<ViewInvoice />} />
          <Route path="/create-invoice" element={<CreateInvoice />} />
          <Route path="/proposals" element={<CreateInvoice />} />
          <Route path="/lead-proposals" element={<CreateInvoice />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/view-subscription" element={<ViewSubs />} />
          {/* Sales */}
          <Route path="/sales_invoice" element={<SalesInvoice />} />
          <Route path="/sales_payment" element={<SalesPayment />} />
          {/* <Route path="/sales_proposal" element={<SalesProposals />} /> */}
          <Route path="/setting" element={<Setting />} />
          <Route path="/tickets" element={<TicketList />} />
          <Route path="/waba_details" element={<WABADetails />} />
          <Route path="/ip_details" element={<IPDetails />} />
          <Route path="/knowledge_base" element={<KnowledgeBase />} />
          <Route path="/MAS" element={<PlanSetting />} />
          <Route path="/ticket_reply" element={<TicketReply />} />
          <Route path="/support" element={<Support />} />
        </Route>
      </Routes>
    </div>
  );
}
export default App;
