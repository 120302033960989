import React, { useEffect, useState } from "react";
import Button from "../../reusableComponent/Button";
import { RiAddLine } from "react-icons/ri";
import "./leads.css";
import { RxCross2 } from "react-icons/rx";
import InputField from "../../reusableComponent/InputField";
import { AiOutlineClose, AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import whatsApptemplates from "./whatsApptemplates.json";
import SendIcon from "@mui/icons-material/Send";
import {
  MdDeleteOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import axios from "axios";
import CreateLead from "./createLead";
import ViewLeads from "./ViewLead";
import { TailSpin } from 'react-loader-spinner';
import { SiSourceforge } from "react-icons/si";
import { IoChevronDownSharp } from "react-icons/io5";
import { FaTasks } from "react-icons/fa";
import { baseUrl, SOCKET_URL } from "../../../BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAllLeadDataList,
  handleLeadData,
  leadDeleteHandler,
} from "../../../ReduxSlices/MainSlice";
import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Modal,
  Paper,
} from "@mui/material";
import MuiButton from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import { Toaster, toast } from "react-hot-toast";
import { type } from "@testing-library/user-event/dist/type";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { convertDate, convertTime } from "../../webutils";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import image from '../../../Assets/user.png';
import TextArea from "../../reusableComponent/TextArea";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { UpdateDialog } from "./leadTemplateUpdate";
import RefreshIcon from '@mui/icons-material/Refresh';
import { DotLoader } from "react-spinners";

const Leads = () => {
  let nav = sessionStorage.getItem("navigate");
  const dispatch = useDispatch();
  const [wait, setWait] = useState("Assign");
  const [employeeId, setEmployeeId] = useState("");
  const [searchFilterToggle, setSearchFilterToggle] = useState(null);
  const navigate = useNavigate();
  const [allLeadData, setAllLeadData] = useState([]);
  // console.log("leadddddddddddd",allLeadData)
  const [allReminderData, setAllReminderData] = useState([]);
  const [openEditLeads, setOpenEditLeads] = useState("lead_table");
  const [editSelectedLead, setEditSelectedLead] = useState([]);
  const reminderAllDataAPI = `${baseUrl}leads/fetch-lead-superadmin`;
  const [reminderProfilePopup, setOpenReminderProfilePopup] = useState("");
  const [selectedReminderData, setSelectedReminderData] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState("task");
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [reminderSearchInputValue, setReminderSearchInputValue] =
    useState(null);
  const [searchElement, setSearchElement] = useState("");
  const [filteredNote, setFilteredNote] = useState([]);
  const [filterToggle, setFilterToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const filterReminderApi = `${baseUrl}super_admin_lead/note`;
  const selectedLeadDelete = useSelector((store) => store?.main);
  const [leadSearch, setLeadSearch] = useState("");
  const { editSelectedLeadd, allLeadDataList } = useSelector((store) => store?.main);
  // console.log("editSelectedLeaddeditSelectedLeadd",editSelectedLeadd);

  //refresh icon loading ---- 10/08/2024-SAIF
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    {
      nav === "Proposals" && setOpenEditLeads("lead_profile");
    }
  }, [nav]);
  const [noteTaskData, setNoteTaskData] = useState(null);
  const [mouseActive, setMouseActive] = useState(null);
  const [activeNotes, setActiveNotes] = useState(false);
  const [activeDeal, setActiveDeal] = useState(null);
  const [defaultStatus, setDefaultStatus] = useState();
  const [activePopUpForSomeActions, setActivePopUpForSomeActions] = useState();
  const [templateMessage, setTemplateMessage] = useState(null);
  const [leadStatus, setLeadStatus] = useState([
    "New",
    "Viewed",
    "Contacted",
    "Deal Done",
    "Unqualified",
  ]);
  const [statusValue, setStatusValue] = useState("New");
  const [activeLeadStatus, setActiveLeadStatus] = useState(null);
  const [isCurrentLead, setIsCurrentLead] = useState(null);
  const [noteType, setNoteType] = useState("");
  const [dealVal, setDealValue] = useState("");
  const [expectedDealvalue, setExpectedDealValue] = useState({
    expectedDealInputValue: "",
    expectedClosingDate: "",
  });
  const [expectedDealValue1, setExpectedDealValue1] = useState(null);
  const [expecteDealClosingDate, setExpecteDealClosingDate] = useState(null);
  const [recentList, setRecentList] = useState([]);

  //template
  const [openDialog, setOpenDialog] = useState(false);
  const [handleExpendComment, setHandleExpendComment] = useState({
    id: null,
    display: false,
  });

  const [leadFormData, setLeadFormData] = useState({
    template_heading: "",
    template_body: "",
    template_footer: ""
  })
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCompany(false);
  }
  const handleOpenDialog = () => {
    // console.log("handle...........")
    setOpenDialog(true);
  }


  //Template automation --10-8-2024-SAIF
  const [openDialogForAutomation, setOpenDialogForAutomation] = useState(false);
  // const [selectedTemplate, setSelectedTemplate] = useState('');

  const [openTimeDialog, setOpenTimeDialog] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState('');
  const [timeInterval, setTimeInterval] = useState('');
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const handleOpenDialogForAutomation = () => {
     setOpenDialogForAutomation(true);
  };
  const handleTemplateTypeSelect = (event) => {
    setSelectedTemplateType(event.target.value);
    setSelectedTemplates([]); // Reset selected template
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplates((prev) => {
      const uniqueKey = template.template_unique_key; // Store the unique key
      if (prev.includes(uniqueKey)) {
        return prev.filter(t => t !== uniqueKey); // Remove if already selected
      }
      if (prev.length < 2) {
        return [...prev, uniqueKey]; // Add if not already selected and under limit
      }
      return prev; // Do not allow more than 2 templates
    });
  };
  const handleCloseDialogForAutomation = () => {
    setOpenDialogForAutomation(false);
    setSelectedTemplateType('');
    // setShowTemplateList(false);
    setSelectedTemplates([]);
  };

  const handleTemplateAutomation = async (e) => {
    // console.log("template.........", timeInterval, selectedTemplates);
    e.preventDefault();
    var token;
    if (localStorage.getItem("saToken2"))
      token = localStorage.getItem("saToken2");
    var config = { headers: { Authorization: `Bearer ${token}` } };
    const data = {
      selected_templates: selectedTemplates,
      interval_time: timeInterval * 1000,
    }
    // console.log("console.........",conf);
    try {
      const res = await axios.post(
        `${baseUrl}double-tick/send/auto/text-template?is_Autosend=${true}`, data,
        config
      );
      // console.log("res", res);
      toast.success(res.data.message);
      setOpenDialogForAutomation(false);
    } catch {
      // console.log("res");

    }
  }

  //createTemplate_Saif_01/10/2024
  const handleCreateTemplate = async (e) => {
    const formData = {
      heading: leadFormData.template_heading,
      body: leadFormData.template_body,
      footer: leadFormData.template_footer
    }
    const token = localStorage.getItem("saToken2");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    e.preventDefault();
    const res = await axios.post(`${baseUrl}double-tick/template/add`, formData, config)
      .then((respo) => {
        // console.log(respo, "respo of template add");
        setOpenDialog(false);
        toast.success("Template created successfully!");
      })
  }
  useEffect(() => {
    setLeadFormData("");
  }, [openDialog])
  const [commentsList, setCommentsList] = useState([]);
  const [commentValue, setCommentValue] = useState("");
  const [errorComment, setErrorComment] = useState("none");
  const expendCommentHandler = (item) => {
    // console.log("expand.........",item,!handleExpendComment.display);
    setHandleExpendComment({
      id: item?._id,
      display: !handleExpendComment?.display,
    });
  };
  const [leadName, setLeadName] = useState("");
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };

  // search
  const [startDate, setStartDate] = useState(""); // Set a default date
  const [endDate, setEndDate] = useState("");
  const [isDateTypeSearch, setIsDateTypeSearch] = useState(false);
  const [page, setPage] = useState(parseInt(localStorage.getItem('currentPage')) || 1);
  const [showAlert, setShowAlert] = useState(false);
  // console.log("pageeeeeeeeeeeeee above",page);
  const handleSearchType = () => {
    setIsDateTypeSearch(!isDateTypeSearch);
  };
  const handleSearchChange = (e) => {
    // const regex = /^[A-Za-z0-9]*$/;
    // if (regex.test(e.target.value)) {
    setLeadSearch(e.target.value);
    // }
  };
  const handleDateChange = (e, dateType) => {
    const value = e.target.value;
    if (dateType === 'startDate') {
      setStartDate(value);
    } else if (dateType === 'endDate') {
      setEndDate(value);
    }
  };

  //socket 
  const getLeadData = async (page) => {
    setLoading(true);
    var token;
    if (localStorage.getItem("saToken2"))
      token = localStorage.getItem("saToken2");
    var config = { headers: { Authorization: `Bearer ${token}` } };
    try {
      const res = await axios.get(
        `${baseUrl}leads/fetch-lead-list-superadmin?page=${page}&queue=${leadSearch}&${startDate ? "startDate=" + startDate : ""}&${endDate ? "endDate=" + endDate : ""}`,
        config
      );
      setAllLeadData(res?.data);
      dispatch(handleAllLeadDataList(res?.data?.leads_data));
    } catch (error) {
      console.error("Error fetching lead data:", error);
       } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    getLeadData(1);
    localStorage.setItem('currentPage', '1');
  }, []);
  useEffect(() => {
    getLeadData(page);
  }, [page]);

  useEffect(() => {

    if (page === 1) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 300000);
      return () => clearTimeout(timer);
    } else {
      setShowAlert(false);
    }
  }, [page, allLeadDataList]);

  //soket connect 
  useEffect(() => {
    const socket = new WebSocket(SOCKET_URL);

    socket.onopen = () => {
      // console.log('Connected to the WebSocket server');
    };

    socket.onmessage = (event) => {
      const newLead = JSON.parse(event.data);
      dispatch(handleAllLeadDataList([...newLead, ...allLeadDataList]));
    };

    socket.onclose = () => {
      // console.log('Disconnected from the WebSocket server');
    };

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, [dispatch, allLeadDataList]);

  // handleTaskNotesPage-:
  const taskNotesPageHandler = (newPage) => {
    // console.log("openEdit...........", openEditLeads);
  }
  // SAIF- 29/OCTOBER/2024
  const handlePageChange = (newPage) => {
    localStorage.setItem("currentPage", newPage);
    setPage(newPage);
  }

  const renderPageNumber = () => {
    const totalPages = Math.ceil(allLeadData?.pages);
    const maxPageNumbersShowAtATime = 10;
    const pageNumbersToShow = [];

    //Calulate pages range
    let startPage = Math.max(1, page - 4); //4 Pages before current page
    let endPage = Math.min(totalPages, page + 5);

    if (page < 6) {
      endPage = Math.min(maxPageNumbersShowAtATime, totalPages);
    }

    if (totalPages - page < 5) {
      startPage = Math.max(1, totalPages - maxPageNumbersShowAtATime + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      const buttonStyle = {
        padding: '6px 10px',
        margin: '0 2px',
        cursor: 'pointer',
        backgroundColor: i === page ? '#007bff' : '#f8f9fa',
        color: i === page ? '#ffffff' : '#000000',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontWeight: i === page ? 'bold' : 'normal',
      };
      pageNumbersToShow.push(
        <button
          key={i}
          onClick={()=>handlePageChange(i)}
          style={buttonStyle}
          type="button" 
        >
          {i}
        </button>
      );
    }

    return pageNumbersToShow;
  }
  useEffect(() => {
    getLeadData(1);
  }, [leadSearch, startDate, endDate]);

  useEffect(() => {
    setStartDate("");
    setEndDate("");
    setLeadSearch("");
    setSearchElement("");
  }, [isDateTypeSearch, openEditLeads]);

  useEffect(() => {
    getEmployeeList();
  }, []);
  // Assign button
  const [employeeData, setEmployeeData] = useState();
  const getEmployeeList = () => {
    var token;
    if (localStorage.getItem("saToken2"))
      token = localStorage.getItem("saToken2");
    var config = { headers: { Authorization: `Bearer ${token}` } };
    axios
      .get(`${baseUrl}add-admin/profile-list-super-admin`, config)
      .then((respo) => {
        if (respo.status === 200) {
          setEmployeeData(respo?.data?.data);
          // console.log('tick',respo?.data);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  const [toggleAssign, setToggleAssign] = useState({
    classN: "assignPopup",
    lead_id: "",
    company
      : "",
    category: "",
    status: "",
    date: "",
    time: "",
    description: "",
  });
  const formData = {
    lead_id: toggleAssign?.lead_id,
    admin_user: employeeId,
  };
  const assignAdminUser = () => {
    // console.log(formData, "lead form");
    axios
      .post(`${baseUrl}super-admin/leads-assigned`, formData, config)
      .then((respo) => {
        if (respo.status === 200 || 201) {
          toast.success(respo.data.message);
          setWait("Assigned");
          // console.log("ticktick", respo);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setWait("Err");
        // console.log(err)
      });
  };
  // Note and task
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let myData = {
      ...noteTaskData,
      [name]: value,
    };

    // console.log(myData)
    setNoteTaskData(myData);
  };
  const formDataForNoteTask = {
    lead_id: mouseActive?._id,
    description: noteTaskData?.noteDescription,
    note_type: noteType,
    datetime: noteType === "Note" ? null : noteTaskData?.taskDateTime,
  };
  const submitNoteNTask = () => {
    // console.log("execute 1")
    axios
      .post(
        `${baseUrl}leads/${formDataForNoteTask.lead_id}/create-lead-superadmin/${formDataForNoteTask.note_type}`,
        formDataForNoteTask,
        config
      )
      .then((respo) => {
        // console.log(respo);
        setActiveNotes(false);
        setNoteTaskData(null);
        toast?.success(
          `${noteType} for ${leadName} is submitted successfully!`
        );
      })
      .catch((err) => {
        toast?.failed("failed");
      });
  };
  const handleSaveComment = (item) => {
    const commentData = {
      comment: commentValue,
    };
    if (commentData.comment !== null && commentData.comment !== "") {
      setErrorComment("none");
      axios
        .post(`${baseUrl}leads/${item?._id}/create-comment`, commentData, config)
        .then((respo) => {
          // console.log(respo);
          getComments(item?._id);
          setCommentValue("");
          toast?.success("Comment is saved successfully!");
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      setErrorComment("block");
    }
  };
  const getComments = (item) => {
    axios
      .get(`${baseUrl}leads/${item}/fetch-comment`, config)
      .then((respo) => {
        // console.log(respo);
        setCommentsList(respo?.data);
      });
  };
  useEffect(() => {
    // console.log(handleExpendComment, "jkhjgjgjgjhgjhgj")
    getComments(handleExpendComment?.id);
  }, [handleExpendComment]);
  const reminderAllData = () => {
    setFilteredNote([]);
    axios
      .get(`${reminderAllDataAPI}/${openEditLeads}?search=${searchElement}&${startDate ? "startDate=" + startDate : ""}&${endDate ? "endDate=" + endDate : ""}`, config)
      .then((res) => {
        setAllReminderData(res?.data);
        setFilteredNote(res?.data);
        // console.log("notee----", res?.data)
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    reminderAllData(1);
  }, [searchElement, startDate, endDate])
  // useEffect(() => {
  //   // if(!searchElement){
  //   //   setFilteredNote(allReminderData)
  //   // }
  //   var fData = allReminderData?.filter((item) => {
  //     return (
  //       item?.status?.toLowerCase()?.match(searchElement?.toLowerCase()) ||
  //       item?.description?.toLowerCase()?.match(searchElement?.toLowerCase())
  //     );
  //   });
  //   setFilteredNote(fData);
  // }, [searchElement]);
  useEffect(() => {
    {
      openEditLeads === "lead_table" && getLeadData(1);
    }
    {
      openEditLeads === "Note" && reminderAllData();
    }
    {
      openEditLeads === "Task" && reminderAllData();
    }
  }, [openEditLeads]);
  // const handleLeadUpdate = (item) => {
  //   setEditSelectedLead(item);
  //   setOpenEditLeads("update_lead");
  // };
  const handleLeadView = (item) => {
    sessionStorage?.setItem("c_id", item?._id);
    sessionStorage?.setItem("navigate", "Profile");
    dispatch(handleLeadData(item));
    setOpenEditLeads("lead_profile");
  };
  const handleReminderView = (data) => {
    setOpenReminderProfilePopup("ongoing_popup");
    setSelectedReminderData(data);
  };

  const handleReminderDelete = () => {
    let id = localStorage?.getItem("noteTaskId");
    axios
      ?.delete(`${baseUrl}super_admin_lead/note/${id}/`, config)
      .then((respo) => {
        handleClose();
        toast?.success(`Your ${openEditLeads} is deleted successfully!`);
        reminderAllData();
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleToggleComponent = (activeClass) => {
    setSelectedComponent(activeClass);
  };
  const handlePopupClose = () => {
    setOpenReminderProfilePopup("");
    handleToggleComponent("task");
    setDefaultStatus();
  };

  const taskDataCompanyWise = () => {
    // console.log(selectedReminderData, "lid note");
    axios
      .get(
        `${baseUrl}leads/${selectedReminderData?.lead_id}/fetch-lead-details-superadmin`,
        config
      )
      .then((res) => {
        setSelectedCompanyData(res.data.data);
        // console.log(res.data, "lead detils");
      });
  };
  const handleFilterChange = (value, openEditLeads) => {
    setSearchElement(value);
    setOpenEditLeads(openEditLeads);
    setSearchFilterToggle();
  };
  const filterReminder = () => {
    axios
      .get(
        // `${baseUrl}fetch-lead-superadmin/Note${searchQuery}${searchQuery && "/"}`,
        `${baseUrl}fetch-lead-superadmin/Note?search=${searchQuery}`,
        config
      )
      .then((res) => {
        // console.log(res.data);
        setAllReminderData(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleLeadDelete = () => {
    axios
      .delete(
        `${baseUrl}lead/${selectedLeadDelete?.selectedLeadDelete?._id}/`,
        config
      )
      .then((respo) => {
        if (respo.status === 200) {
          getLeadData(1);
          setOpen(false);
        }
      });
  };

  useEffect(() => {
    filterReminder();
  }, [searchQuery, reminderSearchInputValue]);

  useEffect(() => {
    reminderProfilePopup === "ongoing_popup" && taskDataCompanyWise();
  }, [reminderProfilePopup]);

  const [open, setOpen] = useState({
    open: false,
    type: null,
  });

  const handleClose = () => {
    setOpen(false);
    dispatch(leadDeleteHandler(null));
  };
  // Hover showContactActionButtons ############### Lead Deal ######################

  const activeStatusDropDown = (item) => {
    if (activeLeadStatus === null) {
      setActiveLeadStatus(item?._id);
    } else {
      setActiveLeadStatus(null);
    }
  };
  const handleChangeExpectedValues = (e) => {
    const { name, value } = e?.target;
    setExpectedDealValue({ ...expectedDealvalue, [name]: value });
  };
  // console.log(expectedDealValue1, expecteDealClosingDate, "expedate")
  const currentStatusFunc = (keys, value, leadItem, page) => {
    const data =
      keys === "status"
        ? { status: value }
        : keys === "dealValue"
          ? {
            dealValue: value,
            dealClosingDate: new Date(),
          }
          : {
            expected_dealValue: value,
            expected_dealClosingDate: expecteDealClosingDate,
          };
    // setStatusValue(value);
    setIsCurrentLead(leadItem?._id);
    // console.log('saifBhai',data);
    axios
      .put(
        `${baseUrl}leads/${leadItem?._id}/update-lead-status-superadmin`,
        data,
        config
      )
      .then((respo) => {
        // console.log(respo, "updateLead");
        if (respo?.status === 200) {
          toast.success(respo?.data?.message);
        }
        getLeadData(page);
        setActiveDeal(null);
        setDealValue("");
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  const getRecentList = (item, note) => {
    // console.log("recent id...", item, note)
    var token;
    if (localStorage.getItem("saToken2"))
      token = localStorage.getItem("saToken2");
    var config = { headers: { Authorization: `Bearer ${token}` } };
    axios
      .get(
        `${baseUrl}leads/fetch-lead-superadmin/${note}?leadId=${item?._id}&search=${searchElement}`, config
      )
      .then((respo) => {
        if (respo?.data?.data) {
          const leads = respo.data.data;
          let recentData = leads.slice(0, 5);
          setRecentList(recentData);

        } else {
          // console.log("No leads found in response.");
        }
        // console.log(respo?.data,"respo in recent list")
        // const reversedLeads = respo?.data?.data?.reverse();
        // console.log("reverse.........",reversedLeads)
        // let recentData = reversedLeads.slice(0, 5);
        // setRecentList(recentData);
      });
  };

  //Whatsapp
  const [refresh, setRefresh] = useState("");
  // delete whatsappMessage
  const deleteWhatsAppMessage = async (template_unique_key, leadItem) => {
    const token = localStorage.getItem("saToken2");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    axios
      .delete(`${baseUrl}double-tick/template/${template_unique_key}/delete`, config)
      .then((respo) => {
        // console.log("respo in delete.....", respo);
        toast.success("Template deleted successfully!");
        setRefresh(Date.now());
        // console.log("timeeeeee........", Date.now());
      })
  }

  // //update WhatsappMessage
  // const updateWhatsAppMessage = async (template_unique_key, leadItem) => {
  //   const token = localStorage.getItem("saToken2");
  //   const config = { headers: { Authorization: `Bearer ${token}` } };
  //   const formData = {
  //     heading: leadFormData.template_heading,
  //     body: leadFormData.template_body,
  //     footer: leadFormData.template_footer
  //   }
  //   const res = await axios.post(`${baseUrl}double-tick/template/add?unique_key=${template_unique_key}`, formData, config)
  //     .then((respo) => {
  //       console.log(respo, "respo of template add")
  //     })
  // }
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [updatedContent, setUpdatedContent] = useState({ heading: '', body: '', footer: '' });
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(false);
  const handleCompanySelect = async (e) => {
    // console.log(e, "handelC--------------")
    // setLeadFormData({ ...leadFormData, customer_name: e.target.value });
    setSelectedCompany(true);
  }
  const updateWhatsAppMessage = async (template_unique_key, leadFormData) => {
    const token = localStorage.getItem("saToken2");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const formData = {
      heading: leadFormData.heading,
      body: leadFormData.body,
      footer: leadFormData.footer
    };

    try {
      const res = await axios.post(`${baseUrl}double-tick/template/add?unique_key=${template_unique_key}`, formData, config);
      // console.log(res, "Response from template update");

      toast.success("Template updated successfullly!")
    } catch (error) {
      console.error("Error updating template:", error);
      // Handle error (e.g., show notification);
    }
  };

  const handleUpdateClick = (template) => {
    setEditingTemplate(template);
    setOpenUpdateDialog(true);
    setUpdatedContent({
      heading: template.template_heading,
      body: template.template_body,
      footer: template.template_footer,
    });
  };

  const handleUpdateSubmit = async () => {
    try {
      await updateWhatsAppMessage(editingTemplate.template_unique_key, updatedContent);
      // Optionally update the local state if needed
      setOpenUpdateDialog(false);
      setEditingTemplate(null);
    } catch (error) {
      console.error("Error updating template:", error);
    }
  };
  const sendWhatsAppMessage = (template_unique_key, leadtitem) => {
    let formattedNumber = leadtitem.mobile;

    // Check if the number is Indian and adjust accordingly
    if (formattedNumber.length === 10 && /^[789]\d{9}$/.test(formattedNumber)) {
      formattedNumber = '91' + formattedNumber; // Prepend '91' for 10-digit Indian numbers
    }
    const formData = {
      number: formattedNumber,
      unique_key: template_unique_key,
    };
    // console.log("tempalte i n sen d", formData)
    axios
      .get(`${baseUrl}double-tick/send/text?number=${formData.number}&unique_key=${formData.unique_key}&name=${leadtitem.name}`, config)
      .then((respo) => {
        // console.log(respo?.data, "respo");
        toast?.success("Template sent successfully!");
        closeNotes();
        // setTimeout(() => {
        //   closeNotes();
        // }, 500);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const [whatsAppTemplates, setWhatsappTemplate] = useState('');

  const getWhatsappTemplate = () => {
    axios
      .get(`${baseUrl}double-tick/template/fetch?module_name=leads`, config)
      .then((respo) => {
        handleOpenTemplate(true);
        // console.log("respo", respo?.data?.fetch_list_of_templates);
        setWhatsappTemplate(respo?.data)
        // toast?.success("Whatsapp template");
        //   setTimeout(() => {
        //     setSendBtnWait(<SendIcon />);
        //     setOpenPaymentRequest(false);
        //     setWhatsAppMessage(null);
        //   }, 500);
      });
  }
  useEffect(() => {
    getWhatsappTemplate()
  }, [mouseActive, refresh])
  // Notes open and close command
  const openNotes = (item, note) => {
    setMouseActive(item);
    setLeadName(item?.company
    );
    setActiveNotes(true);
    setNoteType(note);
    if (note === "Note" || note === "Task") {
      getRecentList(item, note);
    }
  };
  const closeNotes = () => {
    setActiveNotes(false);
    setMouseActive(null);
    setNoteType("");
    setNoteTaskData(null);
  };

  const dealValue = (item, dealType) => {
    // console.log(item, dealType, "dealValue");
    setActiveDeal({
      dealType: dealType,
      _id: item?._id,
    });
    if (dealType === "dealValue") {
      setDealValue(item?.dealValue);
    } else {
      setExpectedDealValue1(item?.expected_dealValue);
      setExpecteDealClosingDate(item?.expected_dealClosingDate);
    }
  };

  let taskDateFormate = new Date(
    selectedReminderData?.datetime
      ? selectedReminderData?.datetime
      : selectedReminderData?.created_at
  );
  let taskDate = convertDate(taskDateFormate);
  let taskTime = taskDateFormate?.toLocaleTimeString();
  const updateStatusNote = (id, changeStatus) => {
    const formDataForNote = {
      status: changeStatus,
    };
    axios
      ?.put(`${baseUrl}leads/${id}/update/task-status`, formDataForNote, config)
      .then((respo) => {
        toast?.success(`${openEditLeads} updated Successfully!`);
        reminderAllData();
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const [openTemplate, setOpenTemplate] = React.useState(false);
  const handleOpenTemplate = () => {
    setOpenTemplate(true);
  };
  const handleCloseTemplate = () => {
    setOpenTemplate(false);
  };
  return (
    <div className="leads-main">
      {/* Assign empployee Portal */}
      <div style={{}} className={toggleAssign?.classN} id="assignEmployee">
        <div className="assignPopupInner">
          <div className="containerTicket">
            <div
              className="cutAssign"
              onClick={() => {
                setToggleAssign({
                  classN: "assignPopup",
                  lead_id: "",
                  company
                    : "",
                  category: "",
                  status: "",
                  date: "",
                  time: "",
                  description: "",
                });
              }}
            >
              <AiOutlineClose />
            </div>
            <div className="assign_heading" style={{ textAlign: "center" }}>
              Assign Employee For Lead
            </div>
            <div className="ticketDetailsContainer">
              <div className="rightTicket">
                <div className="ticketTitle">Lead-Details</div>
                <div className="deatilsGridForTicket">
                  <div className="ticketTite">Lead Company</div>
                  <div>:</div>
                  <div className="ticketNames">
                    {toggleAssign?.company
                    }
                  </div>
                  <div className="ticketTite">Category</div>
                  <div>:</div>
                  <div className="ticketNames">
                    {toggleAssign?.category}
                  </div>
                  <div className="ticketTite">Status</div>
                  <div>:</div>
                  <div className="ticketNames">{toggleAssign?.status}</div>
                </div>
                <div className="subjectTicket">Description</div>
                <div className="ticketNames">{toggleAssign?.description}</div>
              </div>
              <div
                className="chooseEmployeeSection"
                style={{ padding: "10px" }}
              >
                <div className="assign_heading">
                  Select Employee To Assign This Lead
                </div>
                <select
                  className="selectEmployee"
                  onChange={(e) => setEmployeeId(e.target.value)}
                >
                  <option value={""}>Choose Employee</option>
                  {employeeData?.map((data, i) => {
                    return (
                      <option key={i} value={data?.id}>
                        {data?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="assignBtn">
              <button
                onClick={() => {
                  assignAdminUser();
                  setTimeout(() => {
                    getLeadData(1);
                  }, 1000);
                  setTimeout(() => {
                    setToggleAssign({
                      classN: "assignPopup",
                      lead_id: "",
                      company: "",
                      category: "",
                      status: "",
                      date: "",
                      time: "",
                      description: "",
                    });
                  }, 2000);
                }}
              >
                {wait}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Toaster position="top-center" reverseOrder={false} />
      {/* Notes Popup */}
      <div
        style={activeNotes ? { display: "block" } : { display: "none" }}
        className="notes-overlay"
      >
        {(noteType === "Note" || noteType === "Task") && (
          <div className="notes-overlay-inner">
            <div className="notes-form-container">
              <div className="note-list">
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#1877F2",
                  }}
                >
                  Recent {noteType}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  {recentList.map((item, index) => {
                    // console.log("recentList",recentList)
                    let date = new Date(
                      noteType === "Note" ? item?.createdAt : item?.datetime
                    );
                    let dateOfNote = convertDate(date);
                    let timeOfNote = date?.toLocaleTimeString();
                    return (
                      <div key={index}>
                        <div className="recent-note-list">
                          <div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#393939",
                              }}
                            >
                              {item?.description}
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                color: "#1877F2",
                              }}
                            >
                              {dateOfNote},{timeOfNote}
                            </div>
                          </div>
                          <div
                            onClick={() => expendCommentHandler(item)}
                            className="comment-btn-container"
                          >
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <InsertCommentOutlinedIcon
                                style={{
                                  fontSize: "18px",
                                }}
                              />
                            </div>
                            <div className="comment-btn">
                              Comment{" "}
                              <div
                                style={{
                                  rotate: "-90deg",
                                }}
                              >
                                <ArrowBackIosNewOutlinedIcon
                                  style={{
                                    fontSize: "18px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display:
                              // handleExpendComment?.display &&
                              handleExpendComment?.id === item?._id
                                ? "block"
                                : "none",
                          }}
                          className="comments-container"
                        >
                          {commentsList?.data?.map((comment, index) => {
                            let commentDate = new Date(comment?.createdAt);
                            let commentDateOfNote =
                              commentDate?.toLocaleDateString();
                            let commentTimeOfNote =
                              commentDate?.toLocaleTimeString();
                            return (
                              <div className="comments-list-row">
                                <div style={{ fontSize: "14px" }}>
                                  {comment?.comment}
                                </div>
                                <div
                                  style={{ color: "#1877F2", fontSize: "10px" }}
                                >
                                  {commentDateOfNote}, {commentTimeOfNote}
                                </div>
                              </div>
                            );
                          })}
                          <div className="comment-input-container">
                            <div className="comment-input">
                              <textarea
                                placeholder="Write comments"
                                className="comment-input-area"
                                value={commentValue}
                                onChange={(e) =>
                                  setCommentValue(e.target.value)
                                }
                              />
                              <span
                                style={{
                                  display: errorComment,
                                  color: "red",
                                  fontSize: "10px",
                                }}
                              >
                                Please enter comment!
                              </span>
                            </div>
                            <div className="comment-action-btns">
                              <button
                                className="note-save-btn"
                                onClick={() => handleSaveComment(item)}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <div className="title-note">
                  <div style={{ color: "#1877F2" }}>Add {noteType}</div>
                  <div onClick={closeNotes} className="close-notes">
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className="notes-form">
                  <div className="note-input-box">
                    <label className="note-label">Write Your {noteType}</label>
                    <textarea
                      style={{
                        maxWidth: "300px",
                      }}
                      className="note-inchar note-text"
                      name="noteDescription"
                      value={noteTaskData?.noteDescription}
                      onChange={handleChange}
                    />
                  </div>
                  {noteType === "Task" &&
                    <div className="note-input-box">
                      <label className="note-label">Pick Date and Time</label>
                      <input
                        className="note-inchar"
                        type="datetime-local"
                        name="taskDateTime"
                        value={noteTaskData?.taskDateTime}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>}
                  <button
                    onClick={() => submitNoteNTask()}
                    className="note-save-btn"
                  >
                    Save {noteType}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {noteType === "WhatsApp" && (
          <div className="whatsapp-form-container" >
            {/* <Modal
        open={openTemplate}
        onClose={handleCloseTemplate}
         aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      > */}
            <div className="main-container-for-whatsapp">
              <div className="title-note">
                <div style={{ color: "#1877F2" }}>
                  Send {noteType} Message to <b>{mouseActive?.company
                  }</b>
                </div>
                <div onClick={closeNotes} className="close-notes">
                  <CloseOutlinedIcon />
                </div>
              </div>
              <div className="whatsapp-list">
                {whatsAppTemplates?.fetch_list_of_templates?.map((template, index) => {
                  return (

                    <div className="whatsapp-list-item-container" key={index}>
                      <div className="template-header">
                        {template?.template_heading}
                      </div>
                      <div>
                        {template?.template_body}
                      </div>
                      <div className="template-footer">
                        {template?.template_footer}
                      </div>

                      <div className="whatsapp-btn-container">
                        <button
                          data-title={
                            "Send " + template?.template_heading
                          }
                          className="whatsapp-button"
                          onClick={() =>
                            sendWhatsAppMessage(
                              template.template_unique_key,
                              mouseActive
                            )
                          }
                        >
                          <SendIcon />
                        </button>

                        {/* <div className="button-group"> */}
                        {/* <button
                            //     data-title={
                            //       "Delete"+ template?.template_heading
                            //  }
                            className="whatsapp-button"
                            onClick={() =>
                              deleteWhatsAppMessage(
                                template.template_unique_key,
                                mouseActive
                              )
                            }
                          >
                            <DeleteIcon />
                          </button> */}

                        {/* <button
                            //  data-title={
                            //       "Update"+ template?.template_heading
                            //  }
                            className="whatsapp-button"
                            onClick={() => handleUpdateClick(template)}
                          >
                            <UpdateIcon />
                          </button> */}
                        {/* </div> */}
                      </div>
                    </div>

                  );
                })}

              </div>


              {/* Update Dialog */}
              <UpdateDialog
                open={openUpdateDialog}
                handleClose={() => setOpenUpdateDialog(false)}
                updatedContent={updatedContent}
                setUpdatedContent={setUpdatedContent}
                handleUpdateSubmit={handleUpdateSubmit}
              />

              {/* <div className="whatsapp-list">
                {whatsAppTemplates?.fetch_list_of_templates?.map(
                  (template, index) => {
                    return (
                      <div className="whatsapp-list-item-container" key={index}>
                        <div>
                          {template?.template_heading}
                        </div>
                        <div>
                          {template?.template_body}
                        </div>
                        <div>
                          {template?.template_footer}
                        </div>
                        <textarea
                          className="WhatsAppArea"
                          defaultValue={template?.message}
                          onChange={(e) => setTemplateMessage(e?.target?.value)}
                        />
                        <div className="whatsapp-btn-container">
                          <button
                            style={{
                              backgroundColor: "#1877F2",
                              borderRadius: "5px",
                              padding: "5px",
                              border: "none",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                            onClick={() =>
                              sendWhatsAppMessage(
                                templateMessage
                                  ? templateMessage
                                  : template?.message,
                                mouseActive
                              )
                            }
                          >
                            <SendIcon />
                          </button>
                        </div>
                      </div>
                    );
                  }
                )}
              </div> */}
            </div>
            {/* </Modal> */}
          </div>
        )}
      </div>
      {/* Notes Popup End */}
      {/* Delete Confirm box start */}
      <div>

      </div>
      {/* Delete Confirm box End */}
      <div
        className={reminderProfilePopup + " " + "ongoing_popup_hide"}
        disabled
      >
        {/* popup code open */}
        <form className="popup-main">
          <div className="popup-card">
            <RxCross2 onClick={handlePopupClose} className="cross-icons" />
            <div className="link-wrapper">
              <Link
                className={selectedComponent === "task" ? "active-class" : null}
                onClick={() => handleToggleComponent("task")}
              >
                {openEditLeads}
              </Link>
              <Link
                className={
                  selectedComponent === "company" ? "active-class" : null
                }
                onClick={() => handleToggleComponent("company")}
              >
                Lead Profile
              </Link>
            </div>
            {selectedComponent === "task" && (
              <div className="task-details-main">
                <div className="flex-class">
                  <span>Date</span>
                  <span>:</span>
                  <span>{taskDate}</span>
                </div>
                <div className="flex-class">
                  <span>Time</span>
                  <span>:</span>
                  <span>{taskTime}</span>
                </div>
                <div className="flex-class">
                  <span>Description</span>
                  <span>:</span>
                  <span>{selectedReminderData?.description}</span>
                </div>
                <div className="flex-class">
                  <span>Status</span>
                  <span>:</span>
                  <div
                    style={{
                      display: "inline",
                    }}
                  >
                    <select
                      onChange={(e) => {
                        updateStatusNote(
                          selectedReminderData?._id,
                          e?.target?.value
                        );
                        setDefaultStatus(e?.target?.value);
                      }}
                      value={
                        defaultStatus
                          ? defaultStatus
                          : selectedReminderData?.status
                      }
                      name="changeStatus"
                      style={{
                        border: "none",
                        outline: `1px solid ${defaultStatus === "Pending" ||
                          selectedReminderData?.status === "Pending"
                          ? "#ff8c00"
                          : "#008000"
                          }`,
                        borderRadius: "3px",
                        padding: "5px",
                        backgroundColor: `${defaultStatus === "Pending" ||
                          selectedReminderData?.status === "Pending"
                          ? "#FFA50030"
                          : "#00FF0030"
                          }`,
                        color: `${defaultStatus === "Pending" ||
                          selectedReminderData?.status === "Pending"
                          ? "#ff8c00"
                          : "#008000"
                          }`,
                      }}
                    >
                      {selectedReminderData?.status === "Completed" ? (
                        <option value="Completed">Completed</option>
                      ) : (
                        <>
                          <option value="Pending">Pending</option>
                          <option value="Completed">Completed</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            )}
            {selectedComponent === "company" && (
              <div className="task-details-main">
                <div className="flex-class">
                  <span>Lead's Name</span>
                  <span>:</span>
                  <span>{editSelectedLeadd?.name}</span>
                </div>
                <div className="flex-class">
                  <span>Company</span>
                  <span>:</span>
                  <span>{editSelectedLeadd?.company}</span>
                </div>
                <div className="flex-class">
                  <span>Email</span>
                  <span>:</span>
                  <span>{editSelectedLeadd?.email}</span>
                </div>
                <div className="flex-class">
                  <span>Mobile No.</span>
                  <span>:</span>
                  <span>{editSelectedLeadd?.mobile}</span>
                </div>
                <div className="flex-class">
                  <span>Business Category</span>
                  <span>:</span>
                  <span>{editSelectedLeadd?.category}</span>
                </div>
                <div className="flex-class">
                  <span>City</span>
                  <span>:</span>
                  <span>{editSelectedLeadd?.city}</span>
                </div>
                {/* <div className="flex-class">
                  <span>Country</span>
                  <span>:</span>
                  <span>{editSelectedLeadd?.country}</span>
                </div> */}
              </div>
            )}
          </div>
        </form>
      </div>
      {/* popup code close */}
      <>
        {(openEditLeads === "lead_table" ||
          openEditLeads === "Note" ||
          openEditLeads == "Task") && (
            <div
              className="leads-btn-wrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            ></div>
          )}
        {(openEditLeads === "lead_table" ||
          openEditLeads === "Note" ||
          openEditLeads === "Task") && (
            <div className="leads-inner">
              <div className="lead-action-wrapper">
                <d
                  iv
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {/* <Button
                    text={"New Leads"}
                    className="new-subs-btn"
                    startIcon={<RiAddLine />}
                    onClick={() => {
                      navigate("/create-lead");
                    }}
                  /> */}

                  <div
                    className={
                      openEditLeads === "lead_table"
                        ? `selected-action-button leads-section-title`
                        : `leads-section-title`
                    }
                    onClick={() => setOpenEditLeads("lead_table")}
                  >
                    <SiSourceforge />
                    <span>Leads</span>
                  </div>
                  <div className="lead-action-wrapper leads-section-title" onClick={() => getLeadData(page)}>

                    {loading ? (
                      <DotLoader color="#1976D2" loading={true} size={20} />) : (
                      <>
                        <RefreshIcon />
                        <span> Refresh Leads</span>
                      </>
                    )}
                  </div>
                  {/* <div
                    className={`leads-section-title`}
                    onClick={() => handleOpenDialogForAutomation()}
                  >
                    <SiSourceforge />
                    <span>Select Template Automation</span>
                  </div> */}
                  <div>
                    <Dialog open={openDialogForAutomation} onClose={handleCloseDialogForAutomation}>
                      <DialogTitle>{"Select Template Automation"}</DialogTitle>
                      <DialogContent>
                        <form onSubmit={handleTemplateAutomation}>
                          <div className="inner-form-title">
                            <label>Select Template Automation </label>
                            <select value={selectedTemplateType} onChange={handleTemplateTypeSelect}
                            // value={leadFormData.customer_name || ''}
                            // onChange={handleCompanySelect}
                            >
                              <option value="" disabled>Select Template Automation </option>
                              {/* Populate with actual customer names */}
                              <option value="automatic">Automatic</option>
                              {/* <option value="manual">Manual</option> */}
                              {/* Add more customers as needed */}
                            </select>
                          </div>
                          {selectedTemplateType && (
                            <div className="inner-form-title">
                              <label>Select Templates</label>
                              <div className="template-list">
                                {/* {console.log("whatsappppppp",whatsAppTemplates)} */}
                                {whatsAppTemplates?.fetch_list_of_templates?.map((template, index) => (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        checked={selectedTemplates.includes(template.template_unique_key)}
                                        onChange={() => handleTemplateSelect(template)}
                                        color="primary"
                                      />
                                    }
                                    label={template.template_heading}
                                  />
                                ))}
                              </div>
                              <small>You can select up to 2 templates.</small>
                            </div>
                          )}

                          {selectedTemplates.length === 2 && (
                            <div className="inner-form-title">
                              <label>Time Interval</label>
                              <input
                                type="text"
                                value={timeInterval}
                                onChange={(e) => setTimeInterval(e.target.value)}
                                placeholder="Enter the time in seconds"
                              />
                            </div>
                          )}
                        </form>
                      </DialogContent>
                      <DialogActions>
                        <MuiButton
                          className="templateSubmitBtn"
                          color='primary'
                          autoFocus
                          onClick={handleTemplateAutomation}
                        >
                          Submit
                        </MuiButton>
                        <MuiButton onClick={handleCloseDialogForAutomation}>Cancel</MuiButton>
                      </DialogActions>
                    </Dialog>

                    <style jsx>{`
        .template-list {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
        }
        .inner-form-title {
          margin-bottom: 16px;
        }
        small {
          color: gray;
        }
      `}</style>



                  </div>


                  <div
                    style={{
                      position: "relative",
                    }}
                    className={
                      openEditLeads === "Note"
                        ? `selected-action-button leads-section-title`
                        : `leads-section-title`
                    }
                    onClick={() => setOpenEditLeads("Note")}
                  >
                    <CgNotes />
                    Notes
                    {allLeadData?.results?.task_note_pending_count
                      ?.note_pending_count?.count > 0 && (
                        <span className="notification-counter-circle">
                          {
                            allLeadData?.results?.task_note_pending_count
                              ?.note_pending_count?.count
                          }
                        </span>
                      )}
                  </div>
                  <div
                    style={{
                      position: "relative",
                    }}
                    className={
                      openEditLeads === "Task"
                        ? `leads-section-title selected-action-button`
                        : ` leads-section-title `
                    }
                    onClick={() => setOpenEditLeads("Task")}
                  >
                    <FaTasks />
                    Tasks
                    {allLeadData?.results?.task_note_pending_count
                      ?.task_pending_count?.count > 0 && (
                        <span className="notification-counter-circle">
                          {
                            allLeadData?.results?.task_note_pending_count
                              ?.task_pending_count?.count
                          }
                        </span>
                      )}
                  </div>
                </d>
                {/* ------------------------------ Leads Template here------------------------------ */}
                {/* {    console.log("edit in temp.......",openEditLeads)} */}
                {/* {openEditLeads === "Template" ? (
                  <div className="customer_table_container">
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                      <DialogTitle>{"Create Template"}</DialogTitle>
                      <DialogContent>
                        <form onSubmit={handleCreateTemplate}>
                          <div className="inner-form-title">
                            <div>Template Heading</div>
                            <div>
                            <InputField
                              inputLabel={"Template Heading"}
                              type="text"
                              name={"heading"}
                              // value={leadFormData.name}
                              // onChange={(e) => setLeadFormData({ ...leadFormData, name: e.target.value })}
                              // errorResponse={errors.name}
                            />
                            </div>
                          </div>
                          <div className="inner-form-title">
                            <div>Template Body</div>
                            <div>
                            <InputField
                              inputLabel={"Template Body"}
                              type="text"
                              name={"body"}
                              // value={leadFormData.name}
                              // onChange={(e) => setLeadFormData({ ...leadFormData, name: e.target.value })}
                              // errorResponse={errors.name}
                            />
                            </div>
                          </div>
                          <div className="inner-form-title">
                            <div>Template Footer</div>
                            <div>
                            <InputField
                              inputLabel={"Template Footer"}
                              type="text"
                              name={"footer"}
                              // value={leadFormData.name}
                              // onChange={(e) => setLeadFormData({ ...leadFormData, name: e.target.value })}
                              // errorResponse={errors.name}
                            />
                            </div>
                          </div>

                        </form>
                      </DialogContent>
                    </Dialog>
                  </div>

                ) : (
                  <></>
                )
                } */}

                {openEditLeads === "Note" || openEditLeads === "Task" ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <button
                        className="plan-input-field"
                        style={{
                          width: "fit-content",
                          backgroundColor: "#1877F259",
                          border: "none",
                          outline: "none",
                          color: "#1877F2",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSearchType()}
                      >
                        Search by {isDateTypeSearch ? "other" : "date"}
                      </button>
                      {/* Search By Date From & To */}
                      {
                        isDateTypeSearch ? (
                          <>
                            <div>
                              <input
                                style={{ width: "120px" }}
                                className="plan-input-field"
                                type="date"
                                placeholder="Start Date"
                                onChange={(e) =>
                                  handleDateChange(e, "startDate")
                                }
                              />
                            </div>
                            <div>-</div>
                            <div>
                              <input
                                style={{ width: "120px" }}
                                className="plan-input-field"
                                type="date"
                                placeholder="End Date"
                                onChange={(e) => handleDateChange(e, "endDate")}
                              />
                            </div>
                          </>
                        ) : (
                          <div>
                            <InputField
                              style={{
                                marginTop: "-3px"
                              }}
                              placeholder="Search..."
                              endIcon={<AiOutlineSearch />}
                              className="searchInput"
                              value={searchElement}
                              onChange={(e) => setSearchElement(e?.target?.value)}
                            // onChange={filterReminderDataOnSearch}
                            />
                          </div>
                        )
                      }
                    </>
                  </div>
                  // <InputField
                  //   placeholder="Search..."
                  //   endIcon={<AiOutlineSearch />}
                  //   className="searchInput"
                  //   value={searchElement}
                  //   onChange={(e) => setSearchElement(e?.target?.value)}
                  // // onChange={filterReminderDataOnSearch}
                  // />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <button
                        className="plan-input-field"
                        style={{
                          width: "fit-content",
                          backgroundColor: "#1877F259",
                          border: "none",
                          outline: "none",
                          color: "#1877F2",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSearchType()}
                      >
                        Search by {isDateTypeSearch ? "other" : "date"}
                      </button>
                      {/* Search By Date From & To */}
                      {
                        isDateTypeSearch ? (
                          <>
                            <div>
                              <input
                                style={{ width: "120px" }}
                                className="plan-input-field"
                                type="date"
                                placeholder="Start Date"
                                onChange={(e) =>
                                  handleDateChange(e, "startDate")
                                }
                              />
                            </div>
                            <div>-</div>
                            <div>
                              <input
                                style={{ width: "120px" }}
                                className="plan-input-field"
                                type="date"
                                placeholder="End Date"
                                onChange={(e) => handleDateChange(e, "endDate")}
                              />
                            </div>
                          </>
                        ) : (
                          <div>
                            <InputField
                              style={{
                                marginTop: "-3px"
                              }}
                              placeholder="Search..."
                              endIcon={<AiOutlineSearch />}
                              className="leadSearch"
                              onChange={(e) => {
                                handleSearchChange(e);
                              }}
                              value={leadSearch}
                            />
                          </div>
                        )
                      }
                    </>
                  </div>
                )}
              </div>
              <div>
                <div className="summery_container">
                  {openEditLeads === "lead_table" ? (
                    <>                  <div className="summery_card" onClick={() => setLeadSearch("")}>
                      <div className="summery_card_inner">
                        <span>{allLeadData?.status_count?.total_lead_count || 0}</span>
                        <span>Total Leads</span>
                      </div>
                    </div>
                      <div className="summery_card" onClick={() => setLeadSearch("New")}>
                        <div className="summery_card_inner">
                          <span>{allLeadData?.status_count?.new_lead || 0}</span>
                          <span style={{ color: "green" }}>New Leads</span>
                        </div>
                      </div>
                      <div className="summery_card" onClick={() => setLeadSearch("Viewed")}>
                        <div className="summery_card_inner">
                          <span>{allLeadData?.status_count?.view_lead || 0}</span>
                          <span style={{ color: "#1877F2" }}>Viewed Leads</span>
                        </div>
                      </div>
                      <div className="summery_card" onClick={() => setLeadSearch("Contacted")}>
                        <div className="summery_card_inner">
                          <span>
                            {allLeadData?.status_count?.contacted_lead || 0}
                          </span>
                          <span style={{ color: "orange" }}>Contacted Leads</span>
                        </div>
                      </div>
                      <div className="summery_card" onClick={() => setLeadSearch("Qualified")}>
                        <div className="summery_card_inner">
                          <span>
                            {allLeadData?.status_count?.qualified_lead || 0}
                          </span>
                          <span style={{ color: "green" }}>Qualified Leads</span>
                        </div>
                      </div>
                      <div className="summery_card" onClick={() => setLeadSearch("Unqualified")}>
                        <div className="summery_card_inner">
                          <span>
                            {allLeadData?.status_count?.unqualified_lead || 0}
                          </span>
                          <span style={{ color: "red" }}>Unqualified Leads</span>
                        </div>
                      </div>
                      <div className="summery_card" onClick={() => setLeadSearch("Deal")}>
                        <div className="summery_card_inner">
                          <span>
                            {allLeadData?.status_count?.deal_done_lead || 0}
                          </span>
                          <span style={{ color: "#1877F2" }}>Deal Done Leads</span>
                        </div>
                      </div>
                    </>
                  ) : ("")}
                  {openEditLeads === "Task" || openEditLeads === "lead_table" ? (
                    <>
                      <div className="summery_card" onClick={() => handleFilterChange("Pending", "Task")}>
                        <div className="summery_card_inner">
                          <span>{allLeadData?.status_count?.task_pending || 0}</span>
                          <span style={{ color: "orange" }}>Pending Tasks</span>
                        </div>
                      </div>
                      <div className="summery_card" onClick={() => handleFilterChange("Completed", "Task")}>
                        <div className="summery_card_inner">
                          <span>
                            {allLeadData?.status_count?.task_compelted || 0}
                          </span>
                          <span style={{ color: "green" }}>Completed Tasks</span>
                        </div>
                      </div>

                      {showAlert && (
                        <div className="summery_cardd" style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          // height: '100vh' // Make sure the div takes the full viewport height
                        }}>

                          <div style={{
                            width: '100%',
                            fontSize: '14px'
                          }}>
                            {/* Show lead data latest entry */}
                            <Alert variant="outlined" severity="info" className="animated-alert">
                              Latest Lead: {allLeadData?.leads_data ? allLeadData?.leads_data[0]?.name : ""} captured at {(allLeadData?.leads_data?.length > 0) && convertDate(allLeadData?.leads_data[0]?.createdAt)}, {(allLeadData?.leads_data?.length > 0) && convertTime(allLeadData?.leads_data[0].createdAt)}
                            </Alert>
                          </div>
                        </div>
                      )}
                    </>) : <>
                    <div className="summery_card" onClick={() => handleFilterChange("Pending", openEditLeads)}>
                      <div className="summery_card_inner">
                        <span>{filteredNote?.status_count?.pending_note || 0}</span>
                        <span style={{ color: "orange" }}>Pending Notes</span>
                      </div>
                    </div>
                    <div className="summery_card" onClick={() => handleFilterChange("Completed", openEditLeads)}>
                      <div className="summery_card_inner">
                        <span>
                          {filteredNote?.status_count?.completed_note || 0}
                        </span>
                        <span style={{ color: "green" }}>Completed Notes</span>
                      </div>
                    </div>
                  </>}
                </div>
              </div>
              {/*  *************** Lead table here ************ */}
              {openEditLeads === "lead_table" && (
                <div className="leads-table">
                  <div className="leads-table">
                    <table>
                      <thead>
                        {/* )} */}
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Company Name</th>
                          <th>City</th>
                          <th>Category</th>
                          <th
                            style={{
                              // borderRight: "1px solid #C9C9C9",
                            }}
                          >
                            Mobile
                          </th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>EDV</th>
                          <th>DV</th>
                          <th>Status</th>
                          <th>Lead Date</th>
                          <th>Lead Captured Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {console.log("all lerad above................", allLeadData)} */}
                        {allLeadDataList &&
                          allLeadDataList?.map((leadItem, index) => {
                            let date = new Date(leadItem?.date);
                            let convertedDate = convertDate(date);
                            let createdLeadDate = convertDate(leadItem?.createdAt);
                            let createdLeadTime = convertTime(leadItem?.createdAt);
                            let convertedTime = date.toLocaleTimeString();
                            const isNewLead = leadItem?.status === "New";

                            return (
                              <tr
                                // className={`table-row ${isNewLead ? "highlighted" : ""}`} // Conditionally apply the highlighted class
                                style={
                                  mouseActive?.id === leadItem?._id
                                    ? {
                                      backgroundColor: "#C9C9C9",
                                      cursor: "pointer",
                                    }
                                    : {}
                                }
                                key={index + 1}
                                id={leadItem?._id}
                              >
                                <td onClick={() => setActiveDeal(null)}>
                                  {index + ((allLeadData?.current_page - 1) * allLeadData?.page_size) + 1}
                                </td>
                                <td onClick={() => setActiveDeal(null)}>
                                  {leadItem?.prefix} {leadItem?.name
                                  }
                                </td>
                                <td onClick={() => setActiveDeal(null)}>
                                  {leadItem?.company
                                  }
                                </td>
                                <td onClick={() => setActiveDeal(null)}>
                                  {leadItem?.city
                                  }
                                </td>
                                <td onClick={() => setActiveDeal(null)}>
                                  {leadItem?.category
                                  }
                                </td>

                                {/* <td onClick={() => setActiveDeal(null)}>
                                  <div id="btnContainer">
                                    <div className="contact-action-container">
                                      <div className="contact-action-inner">
                                        <div
                                          data-title={
                                            "Add Notes for " +
                                            leadItem?.name

                                          }
                                          className="action-btn-container"
                                          onClick={() =>
                                            openNotes(leadItem, "Note")
                                          }
                                        >
                                          <NoteAddOutlinedIcon fontSize="small" />
                                        </div>
                                        <div
                                          data-title={
                                            "Add Task for " +
                                            leadItem?.name

                                          }
                                          className="action-btn-container"
                                          onClick={() =>
                                            openNotes(leadItem, "Task")
                                          }
                                        >
                                          <AddTaskOutlinedIcon fontSize="small" />
                                        </div>
                                        <div
                                          data-title={
                                            "Add other details of " +
                                            leadItem?.name

                                          }
                                          className="action-btn-container"
                                          onClick={() =>
                                            handleLeadUpdate(leadItem)
                                          }
                                        >
                                          <TextSnippetOutlinedIcon fontSize="small" />
                                        </div>

                                        <div
                                          data-title={
                                            "Call to " + leadItem?.name

                                          }
                                          className="action-btn-container"
                                        >
                                          <CallOutlinedIcon fontSize="small" />
                                        </div>
                                        <div
                                          data-title={
                                            "Send message on WhatsApp to " +
                                            leadItem?.name

                                          }
                                          className="action-btn-container"
                                          onClick={() =>
                                            openNotes(leadItem, "WhatsApp")
                                          }
                                        >
                                          <WhatsAppIcon fontSize="small" />
                                        </div>
                                        <a
                                          href={
                                            "mailto:" + leadItem?.email
                                          }
                                        >
                                          {/* <div
                                            data-title={
                                              "Send email to " +
                                              leadItem?.name

                                            }
                                            className="action-btn-container"
                                          >
                                            <MailOutlineIcon fontSize="small" />
                                          </div> */}
                                {/* </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>  */}
                                <td onClick={() => setActiveDeal(null)}>
                                  <div
                                    data-title={"Call to " + leadItem?.mobile}
                                    className="action-btn-container"
                                    style={{
                                      // display: 'flex',
                                      // alignItems: 'center', // Align items vertically centered
                                    }}
                                  >
                                    <CallOutlinedIcon fontSize="small" style={{ marginRight: '4px' }} />
                                    {leadItem?.mobile}
                                  </div>
                                </td>
                                <td
                                  onClick={() => setActiveDeal(null)}
                                >
                                  <div
                                    data-title={"Call to " + leadItem?.name}
                                    className="action-btn-container"
                                    style={{
                                      // borderRight: "1px solid #C9C9C9",
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: 10
                                    }}
                                  >
                                    <CallOutlinedIcon fontSize="small" style={{ marginRight: '4px' }} />
                                    {leadItem?.phone}
                                  </div>

                                </td>

                                {/* <td onClick={() => setActiveDeal(null)}>
                                  {leadItem?.email}
                                </td> */}
                                <div>
                                  <a
                                    href={
                                      "mailto:" + leadItem?.email
                                    }
                                  >
                                    <div
                                      data-title={
                                        leadItem.email ? `Send email to ${leadItem.email}` : `Send email to ${leadItem.name}`
                                      }
                                      style={{
                                        // borderRight: "1px solid #C9C9C9",
                                        display: 'flex',
                                        justifyContent: "center",
                                        marginTop: 23,
                                        alignItems: 'center',
                                      }}
                                      className="action-btn-container"
                                    >
                                      <MailOutlineIcon fontSize="small" />
                                    </div>
                                  </a>
                                </div>

                                <td
                                  style={{
                                    fontWeight: "500",
                                    minWidth: "100px",
                                  }}
                                >
                                  <div
                                    className={
                                      activeDeal?._id === leadItem?._id &&
                                        activeDeal?.dealType === "expectedDealValue"
                                        ? "deal-value"
                                        : ""
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      onClick={() =>
                                        dealValue(leadItem, "expectedDealValue")
                                      }
                                    >
                                      &#8377;{" "}
                                      {leadItem?.expected_dealValue
                                        ? leadItem?.expected_dealValue
                                        : "----"}
                                    </span>
                                    {activeDeal?._id === leadItem?._id &&
                                      activeDeal?.dealType ===
                                      "expectedDealValue" ? (
                                      <div
                                        style={{
                                          color: "#1877F2",
                                          position: "absolute",
                                          left: "-20%",
                                          top: "-2px",
                                          zIndex: "9999999",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                            backgroundColor: "#FFFFFF",
                                            boxShadow: "0px 4px 12px #00000069",
                                            padding: "5px",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              border: "none",
                                              outline: "1px solid #1877F2",
                                              borderRadius: "5px",
                                              padding: "5px",
                                            }}
                                            defaultValue={expectedDealValue1}
                                            name="expectedDealInputValue"
                                            onChange={(e) =>
                                              setExpectedDealValue1(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <input
                                            type="datetime-local"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              border: "none",
                                              outline: "1px solid #1877F2",
                                              borderRadius: "5px",
                                              padding: "5px",
                                            }}
                                            defaultValue={expecteDealClosingDate}
                                            name="expectedClosingDate"
                                            onChange={(e) =>
                                              setExpecteDealClosingDate(
                                                e?.target?.value
                                              )
                                            }
                                          />
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              width: "100%",
                                            }}
                                          >
                                            <button
                                              style={{
                                                padding: "5px",
                                                border: "none",
                                                backgroundColor: "#f34747",
                                                color: "#FFFFFF",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                                width: "100%",
                                              }}
                                              onClick={() => setActiveDeal(null)}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              style={{
                                                padding: "5px",
                                                border: "none",
                                                backgroundColor: "#1877F2",
                                                color: "#FFFFFF",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                                width: "100%",
                                              }}
                                              onClick={() => {
                                                currentStatusFunc(
                                                  "expectedDealValue",
                                                  expectedDealValue1,
                                                  leadItem,
                                                  allLeadData?.current_page
                                                );
                                              }}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                                <td
                                  onClick={() => dealValue(leadItem, "dealValue")}
                                  style={{
                                    fontWeight: "500",
                                    minWidth: "100px",
                                  }}
                                >
                                  <div
                                    className={
                                      activeDeal?._id === leadItem?._id &&
                                        activeDeal?.dealType === "dealValue"
                                        ? "deal-value"
                                        : ""
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <span>
                                      &#8377;{" "}
                                      {leadItem?.dealValue
                                        ? leadItem?.dealValue
                                        : "----"}
                                    </span>
                                    {activeDeal?._id === leadItem?._id &&
                                      activeDeal?.dealType === "dealValue" ? (
                                      <div
                                        style={{
                                          color: "#1877F2",
                                          position: "absolute",
                                          left: "-20%",
                                          top: "-2px",
                                          right: "-10%",
                                          bottom: "-2px",
                                          zIndex: "9999999",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            gap: "10px",
                                            backgroundColor: "#FFFFFF",
                                            boxShadow: "0px 4px 12px #00000069",
                                            padding: "2px",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <input
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              border: "none",
                                              outline: "1px solid #1877F2",
                                              borderRadius: "5px",
                                              padding: "5px",
                                            }}
                                            defaultValue={leadItem?.dealValue}
                                            name="dealInputValue"
                                            onChange={(e) =>
                                              setDealValue(e?.target?.value)
                                            }
                                          />
                                          <button
                                            style={{
                                              padding: "0 5px",
                                              border: "none",
                                              backgroundColor: "#1877F2",
                                              color: "#FFFFFF",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              currentStatusFunc(
                                                "dealValue",
                                                dealVal,
                                                leadItem,
                                                allLeadData?.current_page
                                              );
                                            }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                                <td onClick={() => setActiveDeal(null)}>
                                  <div
                                    style={{
                                      display: "flex",
                                      cursor: "pointer",
                                      alignItems: "center",
                                      gap: "10px",
                                      minWidth: "50px",
                                      position: "relative",
                                    }}
                                    onClick={() => activeStatusDropDown(leadItem)}
                                  >
                                    <div
                                      style={{
                                        color: `${leadItem?.status === "New"
                                          ? "#228B22"
                                          : leadItem?.status === "Viewed"
                                            ? "#1877F2"
                                            : leadItem?.status === "Contacted"
                                              ? "#FF4500"
                                              : leadItem?.status === "Deal Done"
                                                ? "#228B22"
                                                : leadItem?.status === "Unqualified"
                                                  ? "#8B0000"
                                                  : "#228B22"
                                          }`,
                                        backgroundColor: `${leadItem?.status === "New"
                                          ? "#228B2245"
                                          : leadItem?.status === "Viewed"
                                            ? "#1877F245"
                                            : leadItem?.status === "Contacted"
                                              ? "#FF450045"
                                              : leadItem?.status === "Deal Done"
                                                ? "#228B2245"
                                                : leadItem?.status === "Unqualified"
                                                  ? "#8B000045"
                                                  : "#228B2245"
                                          }`,
                                        padding: "5px",
                                        borderRadius: "3px",
                                        textAlign: "center",
                                        // border: leadItem?.status === "New" ? "2px solid #228B22" : "none",
                                        // transition: "border 0.3s ease", // Animation for border
                                        // boxShadow: leadItem?.status === "New" ? "0 0 10px rgba(34,139,34,0.5)" : "none", 
                                      }}
                                    >
                                      {leadItem?.status}
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          color: "#393939",
                                        }}
                                        className={
                                          activeLeadStatus === leadItem?._id
                                            ? "rightCloseArrow"
                                            : "downCloseArrow"
                                        }
                                      >
                                        &#9662;
                                      </div>
                                    </div>
                                    {activeLeadStatus === leadItem?._id && (
                                      <div className="dropdown-container">
                                        <div className="dropdown-inner">
                                          <div className="list-container">
                                            {leadStatus?.map((item, index) => (
                                              <div
                                                onClick={() =>
                                                  currentStatusFunc(
                                                    "status",
                                                    item,
                                                    leadItem,
                                                    allLeadData?.current_page
                                                  )
                                                }
                                                className="status-iem"
                                                key={index}
                                              >
                                                {item}
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                                {/* <td>{leadItem?.created_at}</td> */}
                                <td onClick={() => setActiveDeal(null)}>
                                  <div
                                    data-title={
                                      leadItem?.time
                                    }
                                  // className="action-btn-container"
                                  >
                                    {convertedDate}
                                  </div>
                                </td>
                                <td onClick={() => setActiveDeal(null)}>
                                  <div
                                    data-title={
                                      createdLeadTime
                                    }
                                  >
                                    {createdLeadDate}
                                  </div>
                                </td>
                                {/* <td
                                  onClick={() => setActiveDeal(null)}
                                  className="action_btns"
                                >      */}
                                <td onClick={() => setActiveDeal(null)}>
                                  <div id="btnContainer">
                                    <div className="contact-action-container">
                                      <div className="contact-action-inner">
                                        <div>
                                          <AiOutlineEye
                                            className="action_btn action_icon_views"
                                            onClick={() => handleLeadView(leadItem)}
                                          />
                                        </div>
                                        <div
                                          data-title={
                                            "Add Notes for " +
                                            leadItem?.name

                                          }
                                          className="action-btn-container"
                                          onClick={() =>
                                            openNotes(leadItem, "Note")
                                          }
                                        >
                                          <NoteAddOutlinedIcon fontSize="small" />
                                        </div>
                                        <div
                                          data-title={
                                            "Add Task for " +
                                            leadItem?.name

                                          }
                                          className="action-btn-container"
                                          onClick={() =>
                                            openNotes(leadItem, "Task")
                                          }
                                        >
                                          <AddTaskOutlinedIcon fontSize="small" />
                                        </div>
                                        {/* <div
                                          data-title={
                                            "Add other details of " +
                                            leadItem?.name

                                          }
                                          className="action-btn-container"
                                          onClick={() =>
                                            handleLeadUpdate(leadItem)
                                          }
                                        >
                                          <TextSnippetOutlinedIcon fontSize="small" />
                                        </div> */}

                                        {/* <div
                                          data-title={
                                            "Call to " + leadItem?.name

                                          }
                                          className="action-btn-container"
                                        >
                                          <CallOutlinedIcon fontSize="small" />
                                        </div> */}
                                        <div
                                          data-title={
                                            "Send message on WhatsApp to " +
                                            leadItem?.name

                                          }
                                          className="action-btn-container"
                                          onClick={() =>
                                            openNotes(leadItem, "WhatsApp")
                                          }
                                        >
                                          <WhatsAppIcon fontSize="small" />
                                        </div>
                                        {/* <a
                                          href={
                                            "mailto:" + leadItem?.email
                                          }
                                        > */}
                                        {/* <div
                                            data-title={
                                              "Send email to " +
                                              leadItem?.name

                                            }
                                            className="action-btn-container"
                                          >
                                            <MailOutlineIcon fontSize="small" />
                                          </div> */}
                                        {/* </a> */}

                                        <div className="action_btns"
                                          data-title={
                                            "Assign" + leadItem.name + "to"
                                          }
                                        >
                                          {leadItem?.assign_to ? (
                                            <div style={{ position: "relative" }}>
                                              <span>{leadItem?.assign_to?.name}</span>
                                              <button
                                                className={`reAssignment`}
                                                onClick={() => {
                                                  // console.log('lead click',leadItem?._id);
                                                  setToggleAssign({
                                                    classN: "assignPopupShow",
                                                    lead_id: leadItem?._id,
                                                    company
                                                      : leadItem?.company,
                                                    category:
                                                      leadItem?.category,
                                                    status: leadItem?.status,
                                                    date: convertedDate,
                                                    time: convertedTime,
                                                    description: leadItem?.description,
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={image}
                                                  alt="User Icon"
                                                  style={{
                                                    width: '20px', // Adjust size as needed
                                                    height: '20px', // Adjust size as needed
                                                    // marginRight: '5px', 

                                                  }}
                                                />
                                              </button>
                                            </div>
                                          ) : (
                                            <div className="action_btns">
                                              <AssignmentIndIcon
                                                className="assignTickets"
                                                onClick={() => {
                                                  setToggleAssign({
                                                    classN: "assignPopupShow",
                                                    lead_id: leadItem?._id,
                                                    company
                                                      : leadItem?.company
                                                    ,
                                                    category: leadItem?.category,
                                                    status: leadItem?.status,
                                                    date: convertedDate,
                                                    time: convertedTime,
                                                    description: leadItem?.description,
                                                  });
                                                }}
                                              />
                                            </div>
                                            // <button
                                            //   className="assignTickets"
                                            //   onClick={() => {
                                            //     setToggleAssign({
                                            //       classN: "assignPopupShow",
                                            //       lead_id: leadItem?._id,
                                            //       company
                                            //         : leadItem?.company
                                            //       ,
                                            //       category: leadItem?.category,
                                            //       status: leadItem?.status,
                                            //       date: convertedDate,
                                            //       time: convertedTime,
                                            //       description: leadItem?.description,
                                            //     });
                                            //   }}
                                            // >
                                            //   Assign
                                            // </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* </td> */}

                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {allLeadData?.leads_data?.length === 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "40px",
                          backgroundColor: "#c9c9c9",
                          fontSize: "30px",
                          fontWeight: "600",
                          color: "#595959",
                        }}
                      >
                        No Data Available!
                      </div>
                    )}
                    {allLeadData?.leads_data?.length > 0 && (
                      <div className="pegination">
                        <div className="peginationinner">
                          <MdKeyboardArrowLeft
                            className="arrowPreNext"
                            onClick={() => {
                              allLeadData?.previous_page !== null &&
                                // getLeadData(allLeadData?.previous_page);
                                handlePageChange(allLeadData?.previous_page);
                            }}
                          />
                          {renderPageNumber()}
                          {/* <span>
                            {allLeadData?.current_page} out of{" "}
                            {Math.ceil(allLeadData?.pages)}
                          </span> */}
                          <MdKeyboardArrowRight
                            className="arrowPreNext"
                            onClick={() => {
                              allLeadData?.next_page !== null &&
                                handlePageChange(allLeadData?.next_page)
                              // getLeadData(allLeadData?.next_page);
                            }}
                          />
                          <span>
                            {page} out of{" "}
                            {Math.ceil(allLeadData?.pages)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* ***************** Notes table here ************** */}
              {(openEditLeads === "Note" || openEditLeads === "Task") && (
                <div className="leads-table">
                  <div className="leads-table">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{openEditLeads}</th>
                          <th>Date & Time</th>
                          <th>Lead</th>
                          <th
                            onClick={() => {
                              if (searchFilterToggle === null) {
                                setSearchFilterToggle("interested");
                              } else if (searchFilterToggle !== null) {
                                setSearchFilterToggle(null);
                              }
                            }}
                          >
                            Status <IoChevronDownSharp />
                            <div className="filterToggle tableFilterToggle">
                              {searchFilterToggle === "interested" && (
                                <ul>
                                  <li onClick={(e) => handleFilterChange("", openEditLeads)}>
                                    All
                                  </li>
                                  <li
                                    value={"Pending"}
                                    onClick={(e) => handleFilterChange("Pending", openEditLeads)}
                                  >
                                    Pending
                                  </li>

                                  <li
                                    value="Completed"
                                    onClick={(e) =>
                                      handleFilterChange("Completed", openEditLeads)
                                    }
                                  >
                                    Completed
                                  </li>
                                </ul>
                              )}
                            </div>
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredNote &&
                          filteredNote?.data?.map((reminderItem, index) => {
                            let date = new Date(reminderItem?.createdAt);
                            let convertedDate = convertDate(date);
                            let convertedTime = date.toLocaleTimeString();
                            let dateTask = new Date(reminderItem?.datetime);
                            let taskDate = convertDate(dateTask);
                            let taskTime = dateTask.toLocaleTimeString();
                            return (
                              <tr key={index + 1}>
                                <td onClick={() => setActiveDeal(null)}>
                                  {index + ((filteredNote?.current_page - 1) * allLeadData?.page_size) + 1}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "300px",
                                  }}
                                >
                                  {reminderItem.description}
                                </td>
                                <td>
                                  {openEditLeads === "Note"
                                    ? convertedDate
                                    : taskDate}
                                  ,{" "}
                                  {openEditLeads === "Note"
                                    ? convertedTime
                                    : taskTime}
                                </td>
                                <td>{reminderItem?.name}</td>
                                <td
                                  // className={
                                  //   reminderItem?.status === "PENDING"
                                  //     ? `pending-class lead-status`
                                  //     : reminderItem?.status === "CONVERTED"
                                  //     ? `converted-class lead-status`
                                  //     : `new-class lead-status`
                                  // }
                                  style={{
                                    color: `${reminderItem?.status === "Completed"
                                      ? "#008000"
                                      : "#ff8c00"
                                      }`,
                                  }}
                                >
                                  {reminderItem?.status}
                                </td>
                                <td
                                  style={{
                                    display: "inline-flex",
                                    gap: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <AiOutlineEye
                                    className="action_icon_view reminder-eye-icon"
                                    onClick={() =>
                                      handleReminderView(
                                        reminderItem,
                                        editSelectedLead
                                      )
                                    }
                                  />
                                  {/* <MdOutlineDelete
                                  className="delete-button reminder-eye-icon"
                                  onClick={() => {
                                    handleClickOpen(openEditLeads);
                                    localStorage?.setItem(
                                      "noteTaskId",
                                      reminderItem?._id
                                    );
                                    // console.log("dle");
                                  }}
                                /> */}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {filteredNote?.data?.length === 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "40px",
                          backgroundColor: "#c9c9c9",
                          fontSize: "30px",
                          fontWeight: "600",
                          color: "#595959",
                        }}
                      >
                        No Data Available!
                      </div>
                    )}
                    {filteredNote?.data?.length > 0 && (
                      <div className="pegination">
                        <div className="peginationinner">
                          <MdKeyboardArrowLeft
                            className="arrowPreNext"
                            onClick={() => {
                              filteredNote?.previous_page !== null &&
                                taskNotesPageHandler(filteredNote?.previous_page);
                            }}
                          />
                          <span>
                            {filteredNote?.current_page} out of{" "}
                            {Math.ceil(filteredNote?.pages)}
                          </span>
                          <MdKeyboardArrowRight
                            className="arrowPreNext"
                            onClick={() => {
                              filteredNote?.next_page !== null &&
                                taskNotesPageHandler(filteredNote?.next_page);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
      </>
      {
        openEditLeads === "update_lead" && (
          <CreateLead
            editSelectedLead={editSelectedLead}
            setOpenEditLeads={setOpenEditLeads}
            setAllLeadData={setAllLeadData}
          />
        )
      }
      {
        openEditLeads === "lead_profile" && (
          <ViewLeads
            // selectedButton={nav}
            // editSelectedLead={editSelectedLeadd}
            setOpenEditLeads={setOpenEditLeads}
            setAllLeadData={setAllLeadData}
          />
        )
      }
    </div >
  );
};

export default Leads;

