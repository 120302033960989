import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import InputField from "../../../reusableComponent/InputField";
import { convertDate, convertTime } from "../../../webutils";
// const baseUrl = process.env.REACT_APP_API_URL;
const Payment = () => {
  const s_id = sessionStorage.getItem("s_id");
  const navigate = useNavigate();
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [statusPayment, setStatusPayment] = useState("");
  const [paymentData, setPaymentData] = useState();
  const [planCategory, setPlanCategory] = useState("");
  const [togglePayCat, setTogglePayCat] = useState("downPaymentCat");
  const [searchData, setSearchData] = useState("");
  const getPayment = (page, search) => {
    setTogglePayCat("downPaymentCat");
    axios
    .get(`${baseUrl}super-admin/payment-list?page=${page}&sub_company_id=${s_id}&search=${search}&status=${statusPayment}`, config)
      .then((respo) => {
        if (respo.status === 200) {
          // console.log(respo.data," respo in trasaction details............/.")
          setPaymentData(respo.data);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  const handlePayCatDropDown = () => {
    if (togglePayCat === "downPaymentCat") {
      setTogglePayCat("rightPaymentCat");
    } else if (togglePayCat === "rightPaymentCat") {
      setTogglePayCat("downPaymentCat");
    }
  };
  useEffect(() => {
    getPayment(1, searchData,statusPayment);
  }, [planCategory,statusPayment]);
  useEffect(() => {
    getPayment(1, searchData,statusPayment);
  }, [searchData, statusPayment]);
  return (
    <div className="customer_table">
      <div className="ttl_flex">
          <div className="tag-title">Payments</div>
          <div className="heading_right">
            <div style={{
              display: "flex",
              gap: "10px",
              alignItems: "center"
            }}>
              <div className={statusPayment === "" ? "profile_option profile_option_active" : "profile_option"} onClick={() => setStatusPayment("")}>Reset</div>
              <div className={statusPayment === "success" ? "profile_option profile_option_active" : "profile_option"} onClick={() => setStatusPayment("success")}>Success</div>
              <div className={statusPayment === "failed" ? "profile_option profile_option_active" : "profile_option"} onClick={() => setStatusPayment("failed")}>Failed</div>
              <InputField
                onChange={(e) => setSearchData(e?.target?.value)}
                placeholder={"search..."}
                endIcon={<AiOutlineSearch />}
              />
            </div>
          </div>
        </div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Order Id</th>
            <th>Transaction Id</th>
            <th>Amount</th>
            <th
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => handlePayCatDropDown()}
              >
                Category <div className={togglePayCat}>&#9660;</div>
              </div>
              <div
                style={{
                  display: `${
                    togglePayCat === "downPaymentCat" ? "none" : "block"
                  }`,
                  position: "absolute",
                  top: "35px",
                  left: "-20%",
                  width: "fit-content",
                  height: "fit-content",
                  backgroundColor: "white",
                  boxShadow: "0px 3px 8px #00000049",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <div
                  onClick={() => {
                    setPlanCategory("/CST");
                  }}
                  className="catDropDown"
                >
                  CST
                </div>
                <div
                  onClick={() => {
                    setPlanCategory("/MKT");
                  }}
                  className="catDropDown"
                >
                  MKT
                </div>
                <div
                  onClick={() => {
                    setPlanCategory("/Top-up");
                  }}
                  className="catDropDown"
                >
                  Top-up
                </div>
                <div
                  onClick={() => {
                    setPlanCategory("/Media-storage");
                  }}
                  className="catDropDown"
                >
                  Media-storage
                </div>
                <div
                  onClick={() => {
                    setPlanCategory("/WhatsApp Green Tick");
                  }}
                  className="catDropDown"
                >
                  WhatsApp Green Tick
                </div>
              </div>
            </th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {paymentData?.data ? (
            paymentData?.data?.map((item, index) => {
              return (
                <tr>
                  <td>{index + ((paymentData?.current_page - 1)* paymentData?.page_size) +1}</td>
                  <td>
                    <div
                      style={{
                        width: "130px",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item?.order_id ? item?.order_id : "---"}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        width: "200px",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item?.transaction_id ? item?.transaction_id : "---"}
                    </div>
                  </td>
                  <td>&#8377; {item?.payable_amount ? item?.payable_amount : "---"}</td>
                  <td>
                    <div
                      style={{
                        width: "100px",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item?.plan ? item?.plan : "---"}
                    </div>
                  </td>
                  <td>{convertDate(item?.createdAt)} | {convertTime(item?.createdAt)}</td>
                  <td
                    style={{
                      color:
                        item?.transaction_status === "Success" ? "green" : "red",
                    }}
                  >
                    {item?.transaction_status
                      ? item?.transaction_status
                      : "Failed"}
                  </td>
                  <td>
                    <div className="action_btns">
                      <AiOutlineEye
                        className="action_icon_view"
                        onClick={() => {
                          navigate("/view_payment/" + "company");
                          sessionStorage.setItem("p_id", item?._id);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8}
                style={{
                  fontWeight: "600",
                  color: "#595959",
                  fontSize: "14px",
                  textAlign: "center"
                }}
              >
                No data available!
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {paymentData?.data?.length === 0 && (
        <div
          style={{
            textAlign: "center",
            padding: "40px",
            backgroundColor: "#c9c9c9",
            fontSize: "30px",
            fontWeight: "600",
            color: "#595959",
          }}
        >
          No Data Available!
        </div>
      )}
      {paymentData?.data?.length > 0 && (
        <div className="pegination">
          <div className="peginationinner">
            <MdKeyboardArrowLeft
              className="arrowPreNext"
              onClick={() => {
                paymentData?.previous_page!= null &&
                  getPayment(paymentData?.previous_page,"");
              }}
            />
            <span>
              {paymentData?.current_page} out of{" "}
              {Math.ceil(paymentData?.total_payment_count / paymentData?.page_size)}
            </span>
            <MdKeyboardArrowRight
              className="arrowPreNext"
              onClick={() => {
                paymentData?.next_page != null &&
                  getPayment(paymentData?.next_page,"");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
