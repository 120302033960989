import React, { useState } from 'react'
import '../Customers/Customer.css'
import './Sales.css'
import {MdDeleteOutline, MdOutlineListAlt, MdOutlineSummarize} from 'react-icons/md'
import {HiOutlineFilter, HiOutlinePencil} from 'react-icons/hi'
import {AiOutlineEye} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const Sales = () => {
    const [filterShowHide, setFilterShowHide] = useState('none');
    const navigate = useNavigate();

  return (
    <div className='customers_page'>
      <div className='customers_inner'>
        <div className='customers_headeing'>
            <div className='heading_left'>
               <div className='create_btn' onClick={() => {
                navigate('/create_customers')
               }}>
                Create Company
               </div>
            </div>
            <div className='heading_right'>
                <div className='filter_btn' onClick={() => {
                    if (filterShowHide === 'none') {
                        setFilterShowHide('flex')
                    }else if (filterShowHide === 'flex') {
                        setFilterShowHide('none')
                    }
                }}>
                    <HiOutlineFilter/>
                </div>
                <div style={{display: filterShowHide}} className='filter_option_container'>
                    <div className='filter_option'>
                        <div className='filter_icon'>
                            <MdOutlineListAlt/>
                        </div>
                        <div className='filter_txt'>
                            All
                        </div>
                    </div>
                    <div className='filter_option'>
                        <div className='filter_icon'>
                            <MdOutlineListAlt/>
                        </div>
                        <div className='filter_txt'>
                            Groups
                        </div>
                    </div>
                    <div className='filter_option'>
                        <div className='filter_icon'>
                            <MdOutlineListAlt/>
                        </div>
                        <div className='filter_txt'>
                            Country
                        </div>
                    </div>
                    <div className='filter_option'>
                        <div className='filter_icon'>
                            <MdOutlineListAlt/>
                        </div>
                        <div className='filter_txt'>
                            State
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='customer_table_container'>
            <div className='customer_table_up'>
                <div className='customer_summery'>
                    <div className='summery_icon'>
                        <MdOutlineSummarize/>
                    </div>
                    <div className='summer_heading'>
                        Company Details
                    </div>
                </div>
                <div className='summery_container'>
                    <div className='summery_card'>
                        <div className='summery_card_inner'>
                            <span>45</span>
                            <span>Total Customers</span>
                        </div>
                    </div>
                    <div className='summery_card'>
                        <div className='summery_card_inner'>
                            <span>25</span>
                            <span style={{color: 'green'}}>Active Customers</span>
                        </div>
                    </div>
                    <div className='summery_card'>
                        <div className='summery_card_inner'>
                            <span>15</span>
                            <span style={{color: 'red'}}>Inactive Customers</span>
                        </div>
                    </div>
                    <div className='summery_card'>
                        <div className='summery_card_inner'>
                            <span>23</span>
                            <span style={{color: 'green'}}>Active Contacts</span>
                        </div>
                    </div>
                    <div className='summery_card'>
                        <div className='summery_card_inner'>
                            <span>5</span>
                            <span style={{color: 'red'}}>Inactive Contacts</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='customer_table'>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Company</th>
                            <th>Owner Name</th>
                            <th>Contact Number</th>
                            <th>Email Id</th>
                            <th>Active</th>
                            <th>Groups</th>
                            <th>Date & Time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Comany Name</td>
                            <td>Shubham</td>
                            <td>787877878</td>
                            <td>ghghgg@gmail.com</td>
                            <td>
                            <input type="checkbox" id="1" />
                            <label htmlFor="1">Toggle</label>
                            </td>
                            <td>goup name</td>
                            <td>26/12/2022, 05:45 PM</td>
                            <td>
                                <div className='action_btns'>
                                    <AiOutlineEye className='action_icon_view' onClick={() => {
                                        navigate('/customer_profile')
                                    }}/>
                                    <HiOutlinePencil className='action_icon_edit'/>
                                    <MdDeleteOutline className='action_icon_delete'/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Comany Name</td>
                            <td>Shubham</td>
                            <td>787877878</td>
                            <td>ghghgg@gmail.com</td>
                            <td><input type="checkbox" id="2" />
                            <label htmlFor="2">Toggle</label></td>
                            <td>goup name</td>
                            <td>26/12/2022, 05:45 PM</td>
                            <td>
                                <div className='action_btns'>
                                    <AiOutlineEye className='action_icon_view' onClick={() => {
                                        navigate('/customer_profile')
                                    }}/>
                                    <HiOutlinePencil className='action_icon_edit'/>
                                    <MdDeleteOutline className='action_icon_delete'/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Comany Name</td>
                            <td>Shubham</td>
                            <td>787877878</td>
                            <td>ghghgg@gmail.com</td>
                            <td><input type="checkbox" id="3" />
                            <label htmlFor="3">Toggle</label></td>
                            <td>goup name</td>
                            <td>26/12/2022, 05:45 PM</td>
                            <td>
                                <div className='action_btns'>
                                    <AiOutlineEye className='action_icon_view' onClick={() => {
                                        navigate('/customer_profile')
                                    }}/>
                                    <HiOutlinePencil className='action_icon_edit'/>
                                    <MdDeleteOutline className='action_icon_delete'/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Comany Name</td>
                            <td>Shubham</td>
                            <td>787877878</td>
                            <td>ghghgg@gmail.com</td>
                            <td><input type="checkbox" id="4" />
                            <label htmlFor="4">Toggle</label></td>
                            <td>goup name</td>
                            <td>26/12/2022, 05:45 PM</td>
                            <td>
                                <div className='action_btns'>
                                    <AiOutlineEye className='action_icon_view' onClick={() => {
                                        navigate('/customer_profile')
                                    }}/>
                                    <HiOutlinePencil className='action_icon_edit'/>
                                    <MdDeleteOutline className='action_icon_delete'/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Comany Name</td>
                            <td>Shubham</td>
                            <td>787877878</td>
                            <td>ghghgg@gmail.com</td>
                            <td><input type="checkbox" id="5" />
                            <label htmlFor="5">Toggle</label></td>
                            <td>goup name</td>
                            <td>26/12/2022, 05:45 PM</td>
                            <td>
                                <div className='action_btns'>
                                    <AiOutlineEye className='action_icon_view' onClick={() => {
                                        navigate('/customer_profile')
                                    }}/>
                                    <HiOutlinePencil className='action_icon_edit'/>
                                    <MdDeleteOutline className='action_icon_delete'/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Comany Name</td>
                            <td>Shubham</td>
                            <td>787877878</td>
                            <td>ghghgg@gmail.com</td>
                            <td><input type="checkbox" id="6" />
                            <label htmlFor="6">Toggle</label></td>
                            <td>goup name</td>
                            <td>26/12/2022, 05:45 PM</td>
                            <td>
                                <div className='action_btns'>
                                    <AiOutlineEye className='action_icon_view' onClick={() => {
                                        navigate('/customer_profile')
                                    }}/>
                                    <HiOutlinePencil className='action_icon_edit'/>
                                    <MdDeleteOutline className='action_icon_delete'/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Comany Name</td>
                            <td>Shubham</td>
                            <td>787877878</td>
                            <td>ghghgg@gmail.com</td>
                            <td><input type="checkbox" id="7" />
                            <label htmlFor="7">Toggle</label></td>
                            <td>goup name</td>
                            <td>26/12/2022, 05:45 PM</td>
                            <td>
                                <div className='action_btns'>
                                    <AiOutlineEye className='action_icon_view' onClick={() => {
                                        navigate('/customer_profile')
                                    }}/>
                                    <HiOutlinePencil className='action_icon_edit'/>
                                    <MdDeleteOutline className='action_icon_delete'/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Sales
