import React, { useEffect, useState } from "react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineListAlt,
} from "react-icons/md";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { HiOutlineFilter } from "react-icons/hi";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import InputField from "../../../reusableComponent/InputField";
import {convertDate, convertTime} from "../../../webutils";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
// const baseUrl = process.env.REACT_APP_API_URL;
const SalesPayment = () => {
  const dispatch = useDispatch();
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [searchData, setSearchData] = useState("");
  const [statusPayment, setStatusPayment] = useState("");
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState();
  const getPayment = (page, search) => {
    axios
      .get(
        `${baseUrl}super-admin/payment-list?page=${page}&search=${search}&status=${statusPayment}`,
        // `${baseUrl}super-admin/payment-list`,
        config
      )
      .then((respo) => {
        if (respo.status === 200) {
          // toast.success(respo?.data?.message);
          // console.log(respo.data, "payment list");
          setPaymentData(respo.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        // console.log(err)
      });
  };
  useEffect(() => {
    getPayment(1, searchData);
  }, [searchData, statusPayment]);
  return (
    <div className="customers_page">
      <div className="customers_inner">
        <div className="customers_headeing">
          <div className="heading_left">
            <div className="">Payment List</div>
          </div>
          <div className="heading_right">
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <div
                className={
                  statusPayment === ""
                    ? "profile_option profile_option_active"
                    : "profile_option"
                }
                onClick={() => {
                  setStatusPayment("");
                  setSearchData("");
                }}
              >
                Reset
              </div>
              <div
                className={
                  statusPayment === "success"
                    ? "profile_option profile_option_active"
                    : "profile_option"
                }
                onClick={() => setStatusPayment("success")}
              >
                Success
              </div>
              <div
                className={
                  statusPayment === "failed"
                    ? "profile_option profile_option_active"
                    : "profile_option"
                }
                onClick={() => setStatusPayment("failed")}
              >
                Failed
              </div>
              <InputField
                style={{
                  margin: "0",
                }}
                onChange={(e) => setSearchData(e?.target?.value)}
                placeholder={"search..."}
                endIcon={<AiOutlineSearch />}
              />
              {/* <InputField
                onChange={(e) => setStatusPayment(e?.target?.value)}
                placeholder={"status..."}
                endIcon={<AiOutlineSearch />}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="customer_table_container">
        <div className="customer_table">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Company</th>
                <th>Order Id</th>
                <th>Payment Mode</th>
                <th>Transaction Id</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentData?.data &&
                paymentData?.data?.map((item, index) => {
                  let date = new Date(item?.startdate);
                  let convertedDate = convertDate(item?.createdAt);
                  let convertedTime = convertTime(item?.createdAt);
                  return (
                    <tr>
                      <td>{index + (paymentData?.current_page * paymentData?.page_size - (paymentData?.page_size-1))}</td>
                      <td><div>
                        <div style={{fontSize: "15px"}}>{item?.company_name ? item?.company_name : "---"}</div>
                        <div style={{
                          fontSize: "12px",
                          backgroundColor: "#1877F231",
                          color: "#1877F2",
                          width: "fit-content",
                          padding: "5px",
                          borderRadius: "5px",
                        }}>{item?.sub_company_name ? item?.sub_company_name : "---"}</div>
                        </div></td>
                      <td>{item?.order_id ? item?.order_id : "---"}</td>
                      <td>
                        {item?.payment_method ? item?.payment_method : "Online"}
                      </td>
                      <td>{item?.transaction_id}</td>
                      <td>&#8377; {item?.payable_amount}</td>
                      <td>{convertedDate}, {convertedTime}</td>
                      <td
                        style={{
                          color:
                            item?.transaction_status === "Success"
                              ? "green"
                              : "red",
                        }}
                      >
                        {item?.transaction_status || "Failed"}
                      </td>
                      <td>
                        <div className="action_btns">
                          <AiOutlineEye
                            className="action_icon_view"
                            onClick={() => {
                              navigate("/view_payment/" + "sales");
                              sessionStorage.setItem("p_id", item?._id);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {paymentData?.data?.length === 0 && (
            <div
              style={{
                textAlign: "center",
                padding: "40px",
                backgroundColor: "#c9c9c9",
                fontSize: "30px",
                fontWeight: "600",
                color: "#595959",
              }}
            >
              No Data Available!
            </div>
          )}
          {paymentData?.data?.length > 0 && (
            <div className="pegination">
              <div className="peginationinner">
                <MdKeyboardArrowLeft
                  className="arrowPreNext"
                  onClick={() => {
                    paymentData?.previous_page != null &&
                      getPayment(paymentData?.previous_page, searchData);
                  }}
                />
                <span>
                  {paymentData?.current_page} out of{" "}
                  {Math.ceil(paymentData?.pages)}
                </span>
                <MdKeyboardArrowRight
                  className="arrowPreNext"
                  onClick={() => {
                    paymentData?.next_page != null &&
                      getPayment(paymentData?.next_page, searchData);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesPayment;
