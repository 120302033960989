import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../BaseUrl";
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { UpdateDialog } from "../Leads/leadTemplateUpdate";
import toast from "react-hot-toast";
// import "./TemplateUpdate.css";
import { RiAddLine } from "react-icons/ri";
// import "../Leads/leads.css";
import InputField from "../../reusableComponent/InputField";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import MuiButton from "@mui/material/Button";

const TemplateAutomation = () => {
    const [whatsAppTemplates, setWhatsappTemplate] = useState('');
    const [selectedValue, setSelectedValue] = useState('leads');
    const [selectedValueForAutomation ,setSelectedValueForAutomation] = useState('leads');
    const getWhatsappTemplate = () => {
        var token;
        if (localStorage.getItem("saToken2"))
            token = localStorage.getItem("saToken2");
        var config = { headers: { Authorization: `Bearer ${token}` } };

        axios
            .get(`${baseUrl}double-tick/template/fetch?module_name=${selectedValueForAutomation}`, config)
            .then((respo) => {
                // handleOpenTemplate(true)
                // console.log("respo", respo?.data?.fetch_list_of_templates);
                setWhatsappTemplate(respo?.data);
            })
            .catch((error)=>{
                setWhatsappTemplate({});
            })
            ;
    }

    useEffect(() => {
        getWhatsappTemplate();
    }, [selectedValueForAutomation]);
    
    //module select 
    const handleChangeDropdown = (e) => {
        const value = e.target.value;
        setSelectedValue(value);
    }

    const handleChangeDropdownForAutomation = (e) => {
        const value = e.target.value;
       
        setSelectedValueForAutomation(value);
    }

 

    const [existingId, setExistingId] = useState('');
    const [templateAutomation, setTemplateAutomation] = useState('');
    //fetch automation api for automatic
    const getTemplateAutomation = () => {
        var token;
        if (localStorage.getItem("saToken2"))
            token = localStorage.getItem("saToken2");
        var config = { headers: { Authorization: `Bearer ${token}` } };
        setTemplateAutomation([]);
        axios
            .get(`${baseUrl}double-tick/fetch/selected-template?module_name=${selectedValue}`, config)
            .then((respo) => {
                // console.log("responses..........", respo);
                setTemplateAutomation(respo?.data?.selected_templates);
                setExistingId(respo?.data?._id);
                // handleOpenTemplate(true)
                // console.log("respo", respo?.data?.fetch_list_of_templates);
                // setWhatsappTemplate(respo?.data);
            });
    }

    useEffect(() => {
        getTemplateAutomation();
    }, [selectedValue]);

    //delete automation api
    const deleteWhatsAppMessage = async () => {
        const token = localStorage.getItem("saToken2");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        axios
            .delete(`${baseUrl}double-tick/${existingId}/delete/selected-template`, config)
            .then((respo) => {
                // console.log("respo in delete.....", respo);
                toast.success("Template deleted successfully!");
                getTemplateAutomation();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
              });
    }

    useEffect(() => {
        getTemplateAutomation();
    }, []);
    const [openDialogForAutomation, setOpenDialogForAutomation] = useState(false);
    // const [selectedTemplate, setSelectedTemplate] = useState('');
    const [openTimeDialog, setOpenTimeDialog] = useState(false);
    const [selectedTemplateType, setSelectedTemplateType] = useState('');
    const [timeInterval, setTimeInterval] = useState('');
    const [selectedTemplates, setSelectedTemplates] = useState([]);

    const handleOpenDialogForAutomation = () => {
        // console.log("openmmmm........")
        setOpenDialogForAutomation(true);
    };
    const handleTemplateTypeSelect = (event) => {
        setSelectedTemplateType(event.target.value);
        setSelectedTemplates([]); // Reset selected template
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplates((prev) => {
            const uniqueKey = template.template_unique_key; // Store the unique key
            if (prev.includes(uniqueKey)) {
                return prev.filter(t => t !== uniqueKey); // Remove if already selected
            }
            if (prev.length < 2) {
                return [...prev, uniqueKey]; // Add if not already selected and under limit
            }
            return prev; // Do not allow more than 2 templates
        });
    };
    const handleCloseDialogForAutomation = () => {
        setOpenDialogForAutomation(false);
        setSelectedTemplateType('');
        // setShowTemplateList(false);
        setSelectedTemplates([]);
    };
    //create template automtaion
    const handleTemplateAutomation = async (e) => {
        // console.log("template.........", timeInterval, selectedTemplates);
        e.preventDefault();
        var token;
        if (localStorage.getItem("saToken2"))
            token = localStorage.getItem("saToken2");
        var config = { headers: { Authorization: `Bearer ${token}` } };
        const data = {
            selected_templates: selectedTemplates,
            interval_time: timeInterval * 1000,
            module_name: selectedValueForAutomation
        }
        // console.log("console.........",conf);

        await axios.post(
            `${baseUrl}double-tick/send/auto/text-template?is_Autosend=${true}&exist_id=${existingId}`, data,
            config
        )
            .then((res) => {
                toast.success(res.data.message);
                setOpenDialogForAutomation(false);
                getTemplateAutomation();
            })
            .catch((err) => {
                // console.log("errr", err)
                toast.error(err?.response?.data?.message);
            })
        // console.log("res", res);



    }

    return (<div>
        <div>
            {/* <div className="customers_headeing">
                <div className="heading_left">
                    <div
                        className="create_btn"
                        onClick={() => handleOpenDialogForAutomation()}
                    >
                        <RiAddLine />
                        <span>Template Automation</span>
                    </div>
                 
                </div>
            </div>
            <div className="customers_headeing">
                <div className="heading_left">
                <span
                        className="create_btn"
                        onClick={() =>
                            deleteWhatsAppMessage()}>
                        <DeleteIcon />
                        <span>Delete Automation Template</span>
                    </span>
                    
                </div>
            </div> */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom:'10px' }}>
            <div >
                    {/* <FormControl fullWidth variant="outlined"> */}
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="dropdown-label">Select a module</InputLabel>
                        <Select
                            labelId="dropdown-label"
                            id="demo-simple-select"
                            label="Select a module"
                            value={selectedValue}
                            onChange={handleChangeDropdown}
                        >
                            <MenuItem value={'company_templates'}>Company</MenuItem>
                            <MenuItem value={'leads'}>Leads</MenuItem>
                            <MenuItem value={'leads2'}>Leads2</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div
                    className="create_btn"
                    onClick={() => handleOpenDialogForAutomation()}
                    style={{ marginRight: '15px', cursor: 'pointer' }}
                >
                    <RiAddLine />
                    <span>Template Automation</span>
                </div>

                <span
                    className="create_btn"
                    onClick={() => deleteWhatsAppMessage()}
                    style={{ cursor: 'pointer', fontSize: '0.8em' }} // Smaller font size for delete button
                >
                    <DeleteIcon style={{ fontSize: '1.2em' }} /> {/* Adjust size of the icon if needed */}
                    <span>Delete Automation Template</span>
                </span>
            </div>

            <div>
                <Dialog open={openDialogForAutomation} onClose={handleCloseDialogForAutomation}>
                    <DialogTitle>{"Select Template Automation"}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleTemplateAutomation}>
                            <div className="inner-form-title">
                                <label>Select Template Automation </label>
                                <select value={selectedTemplateType} onChange={handleTemplateTypeSelect}
                                // value={leadFormData.customer_name || ''}
                                // onChange={handleCompanySelect}
                                >
                                    <option value="" disabled>Select Template Automation </option>
                                    {/* Populate with actual customer names */}
                                    <option value="automatic">Automatic</option>
                                    {/* <option value="manual">Manual</option> */}
                                    {/* Add more customers as needed */}
                                </select>
                            </div>
                            {selectedTemplateType && (
                                <>
                                <div >
                                {/* <FormControl fullWidth variant="outlined"> */}
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel id="dropdown-label">Select a module</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        id="demo-simple-select"
                                        label="Select a module"
                                        value={selectedValueForAutomation}
                                        onChange={handleChangeDropdownForAutomation}
                                    >
                                        <MenuItem value={'company_templates'}>Company</MenuItem>
                                        <MenuItem value={'leads'}>Leads</MenuItem>
                                        <MenuItem value={'leads2'}>Leads2</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                                <div className="inner-form-title">
                                    <label>Select Templates</label>
                                    <div className="template-list">
                                        {/* {console.log("whatsappppppp",whatsAppTemplates)} */}
                                        {whatsAppTemplates?.fetch_list_of_templates?.map((template, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={selectedTemplates.includes(template.template_unique_key)}
                                                        onChange={() => handleTemplateSelect(template)}
                                                        color="primary"
                                                    />
                                                }
                                                label={template.template_heading}
                                            />
                                        ))}
                                    </div>
                                    <small>You can select up to 2 templates.</small>
                                </div>
                                </>
                            )}

                            {selectedTemplates.length === 2 && (
                                <div className="inner-form-title">
                                    <label>Time Interval</label>
                                    <input
                                        type="text"
                                        value={timeInterval}
                                        onChange={(e) => setTimeInterval(e.target.value)}
                                        placeholder="Enter the time in seconds"
                                    />
                                </div>
                            )}
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <MuiButton
                            className="templateSubmitBtn"
                            color='primary'
                            autoFocus
                            onClick={handleTemplateAutomation}
                        >
                            Submit
                        </MuiButton>
                        <MuiButton onClick={handleCloseDialogForAutomation}>Cancel</MuiButton>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
        <div className="whatsapp-lis" style={{
            // width:'100%'
        }}

        >
            {templateAutomation && templateAutomation?.map((template, index) => {
                return (
                    <div className="whatsapp-list-item-containe" key={index}>
                        <div className="template-header">
                            {template?.template_heading}
                        </div>
                        <div>
                            {template?.template_body}
                        </div>
                        <div className="template-footer">
                            {template?.template_footer}
                        </div>
                        {/* 
                            <div className="whatsapp-btn-container">
                                <div className="button-group"> */}
                        {/* <button
                                        data-title={
                                            "Delete " + template?.template_heading
                                        }
                                        className="whatsapp-button"
                                        onClick={() =>
                                            deleteWhatsAppMessage(
                                                template._id
                                            )
                                        }
                                    >
                                        <DeleteIcon />
                                    </button> */}

                        {/* <button
                                        data-title={
                                            "Update " + template?.template_heading
                                        }
                                        className="whatsapp-button"
                                        onClick={() => handleUpdateClick(template)}
                                    >
                                        <UpdateIcon />
                                    </button> */}
                        {/* </div>
                            </div> */}
                    </div>

                );
            })}
            {/* <UpdateDialog
                    open={openUpdateDialog}
                    handleClose={() => setOpenUpdateDialog(false)}
                    updatedContent={updatedContent}
                    setUpdatedContent={setUpdatedContent}
                    handleUpdateSubmit={handleUpdateSubmit}
                /> */}
        </div>


    </div>
    )
}

export default TemplateAutomation;