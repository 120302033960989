import React from 'react';

const ConfirmDialog = ({
confirmIcon,
confirmText,
confirmCancel,
confirmSubmit,
submitBtnText,
}) => {
  return (
    <div className='confirm-overlay'>
      <div className='confirm-container'>
        <div className='confirm-main-container'>
           <div className='confirm-icon'>
            {confirmIcon}
           </div>
           <div className='confirm-text'>
            <p>{confirmText}</p>
           </div>
           <div className='confirm-buttons'>
            <button className='confirm-cancel' onClick={() => confirmCancel()}>Cancel</button>
            <button className='confirm-submit' onClick={() => confirmSubmit()}>{submitBtnText}</button>
           </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDialog;
