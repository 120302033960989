import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyData: [],
  userData: "",
  allCompanyDataAPI: [],
  profileDisplay: [],
  leadData: [],
  allLeadDataList:[],
  allSubscriptionList: [],
  selectedSubscriptionDelete: [],
  selectedLeadDelete: [],
  selectedCompanyProposal : [],
  selectedCompanyInvoice:[],
  forgotPasswordDialog: false,
  employeeData:"",
  employeeAnalytics: "",
  isCompanyProfileActive: true,
  leadProposalDetails: "",
};

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    //all lead data
    handleAllLeadDataList :(state, {payload})=>{
      state.allLeadDataList = payload;
    },
    // lead proposal Details
    handleLeadProposalDetails: (state, {payload})=>{
      state.leadProposalDetails = payload;
    },
    updateCompanyData: (state, { payload }) => {
      state.companyData = payload;
    },
    updateUserData: (state, { payload }) => {
      state.userData = payload;
    },
    handleCompanyData: (state, { payload }) => {
      state.allCompanyDataAPI = payload;
    },
    handleProfileData: (state, { payload }) => {
      state.profileDisplay = payload;
    },
    handleLeadData: (state, { payload }) => {
      state.leadData = payload;
    },
    handleSubscriptionList: (state, { payload }) => {
      state.allSubscriptionList = payload;
    },
    handleSubscriptionDelete: (state, { payload }) => {
      state.selectedSubscriptionDelete = payload;
    },
    leadDeleteHandler: (state, { payload }) => {
      state.selectedLeadDelete = payload;
    },
    companyProposalViewDataHandler: (state, {payload}) => {
      state.selectedCompanyProposal  = payload
    },
    companyInvoiceViewDataHandler: (state, {payload}) => {
      state.selectedCompanyInvoice  = payload
    },
    handleForgotPasswordDialog: (state, {payload}) => {
      state.forgotPasswordDialog  = payload
    },
    handleEmployeeData: (state, {payload}) => {
      state.employeeData = payload
    },
    handleEmployeeAnalytics: (state, {payload}) => {
      state.employeeAnalytics = payload
    },
    handleIsCompanyProfileActive: (state, {payload}) => {
      state.isCompanyProfileActive = payload
    }
  },
});

export default mainSlice.reducer;
export const {
  handleAllLeadDataList,
  companyInvoiceViewDataHandler,
  handleLeadProposalDetails,
  updateCompanyData,
  updateUserData,
  handleCompanyData,
  handleProfileData,
  handleLeadData,
  handleSubscriptionList,
  handleSubscriptionDelete,
  leadDeleteHandler,
  companyProposalViewDataHandler,
  handleForgotPasswordDialog,
  handleEmployeeData,
  handleEmployeeAnalytics,
  handleIsCompanyProfileActive
} = mainSlice.actions;
