import React, { useEffect, useState } from "react";
import "./mystyle/style.css";
import "./mystyle/responsive.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import CountryCode from "../../Outlet/Setting/ProfileCard/CountryCode.json";
import toast from "react-hot-toast";
// const baseUrl = process.env.REACT_APP_API_URL;
const BillingAddress = ({ createdCompanyData }) => {
  // console.log('created company adress.///',createdCompanyData);
  const [allBillingData, setAllBillingData] = useState([]);
  const [sameShippingAddress, setSameShippingAddress] = useState(true);
  const [sameBillingAddress, setSameBillingAddress] = useState(true);
  const [wait, setWait] = useState("Save");
  const [permamentAddress, setPermamentAddress] = useState({
    p_address: "",
    p_city: "",
    p_state: "",
    p_pin_code: "",
    p_country: "",
  });
  const [billingFormData, setBillingFormData] = useState({
    billing_address: "",
    bill_city: "",
    bill_state: "",
    bill_pin_code: "",
    bill_country: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    shipping_address: "",
    ship_city: "",
    ship_state: "",
    ship_pin_code: "",
    ship_country: "",
  });
  // console.log(createdCompanyData,"from somewhere")
  const navigate = useNavigate();
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const handlePermamentAddress = (e) => {
    const { value, name } = e.target;
    setPermamentAddress({
      ...permamentAddress,
      [name]: value,
    });
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setBillingFormData({
      ...billingFormData,
      [name]: value,
    });
  };
  const handleShippingData = (e) => {
    const { name, value } = e.target;
    setShippingAddress({
      ...shippingAddress,
      [name]: value,
    });
  };
  const handleBillAddress = () => {
    setSameBillingAddress(!sameBillingAddress);
    if (sameBillingAddress) {
      setBillingFormData({
        billing_address: createdCompanyData.address,
        bill_city: createdCompanyData.city,
        bill_state: createdCompanyData.state,
        bill_pin_code: createdCompanyData.pincode,
        bill_country: createdCompanyData.country,
      });
    } else {
      setBillingFormData({
        billing_address: "",
        bill_city: "",
        bill_state: "",
        bill_pin_code: "",
        bill_country: "",
      });
    }
  };
  const submitBilling = () => {
    setWait("Please wait...");
    let formData = {
      address_of : "billing",
      address: billingFormData?.billing_address,
      city: billingFormData?.bill_city,
      state: billingFormData?.bill_state,
      pincode: billingFormData?.bill_pin_code,
      country: billingFormData?.bill_country,
      uuid: createdCompanyData?._id,
    };
    // console.log('form bill,/.',formData);

    axios
      .put(`${baseUrl}super-admin/company/address-update`, formData, config)
      .then((res) => {
        if (res.status === 200 || 201) {
          setWait("Billing address saved!");
          toast.success("Billing address saved successfully!");
        }
   

     
        // console.log(res);
      })
      .catch((err) => {
        
        setWait("Failed to save billing address!");
        toast.error(err?.response?.data?.message);
        // console.log(err);
      });
  };
  const submitShipping = () => {
    setWait("Please wait...");
    let formData = {
      address_of : "shipping",
      address: shippingAddress.shipping_address,
      city: shippingAddress.ship_city,
      state: shippingAddress?.ship_state,
      pincode: shippingAddress?.ship_pin_code,
      country: shippingAddress?.ship_country,
      uuid: createdCompanyData?._id,
    };
    axios
      .put(`${baseUrl}super-admin/company/address-update`, formData, config)
      .then((res) => {
        if (res.status === 200 || 201) {
          setWait("Shiping address saved!");
          toast.success("Shiping address saved successfully!");
          navigate("/customers");
        }
           // console.log(res);
      })
      .catch((err) => {
        setWait("Failed to save shiping address!");
        toast.error(err?.response?.data?.message);

        // console.log(err);
      });
  };
  const [waitBill, setWaitBill] = useState("Save");
  const [waitShip, setWaitShip] = useState("Save");
  const handleOnClick = (e, type) => {
    e.preventDefault();
    setAllBillingData([billingFormData, ...allBillingData]);
    if (type === "bill") {
      submitBilling();
    } else if (type === "ship") {
      submitShipping();
    }else if (type === "skip") {
      navigate("/customers")
    }
  };

  const isAddressCheck = () => {
    setSameShippingAddress(!sameShippingAddress);
    // console.log("console 1");
    if (sameShippingAddress) {
      setShippingAddress({
        shipping_address: billingFormData.billing_address,
        ship_city: billingFormData.bill_city,
        ship_state: billingFormData.bill_state,
        ship_pin_code: billingFormData.bill_pin_code,
        ship_country: billingFormData.bill_country,
      });
    } else {
      setShippingAddress({
        shipping_address: "",
        ship_city: "",
        ship_state: "",
        ship_pin_code: "",
        ship_country: "",
      });
    }
  };

  // console.log(billingFormData);
  // console.log(shippingAddress);

  return (
    <div className="login">
      <div className="container">
        <div className="agent_details">
          <form className="form">
            <div className="col_1_billing">
              <h3>
                Billing Address
                <span onClick={handleBillAddress}>
                  <input type={"checkbox"} />
                  Same as Permament Address
                </span>
              </h3>
              <div className="line_1">
                <label>Street</label>
                <div className="form-control">
                  <textarea
                    name="billing_address"
                    value={billingFormData.billing_address}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>City</label>
                <div className="form-control">
                  <input
                    type="text"
                    required
                    name="bill_city"
                    value={billingFormData.bill_city}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>State</label>
                <div className="form-control">
                  <input
                    type="text"
                    required
                    name="bill_state"
                    value={billingFormData.bill_state}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Zip Code</label>
                <div className="form-control">
                  <input
                    type="text"
                    required
                    name="bill_pin_code"
                    value={billingFormData.bill_pin_code}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Country</label>
                <div className="form-control">
                  <select
                    name="bill_country"
                    value={billingFormData.bill_country}
                    onChange={handleFormChange}
                  >
                    <option value="volvo">select</option>
                    {CountryCode?.countries?.map((item) => {
                      return <option value={item?.name}>{item?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="end_section">
                <button onClick={(e) => handleOnClick(e, "bill")}>
                  {waitBill}
                </button>
              </div>
            </div>
            <div className="col_2_shipping">
              <h3>
                Shipping Address
                <span style={{ cursor: "pointer" }} onClick={isAddressCheck}>
                  <input type={"checkbox"} />
                  Copy Billing Address
                </span>
              </h3>
              <div className="line_1">
                <label>Street</label>
                <div className="form-control">
                  <textarea
                    name={"shipping_address"}
                    value={shippingAddress.shipping_address}
                    onChange={handleShippingData}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>City</label>
                <div className="form-control">
                  <input
                    type="text"
                    required
                    name={"ship_city"}
                    value={shippingAddress.ship_city}
                    onChange={handleShippingData}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>State</label>
                <div className="form-control">
                  <input
                    type="text"
                    required
                    name={"ship_state"}
                    value={shippingAddress.ship_state}
                    onChange={handleShippingData}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Zip Code</label>
                <div className="form-control">
                  <input
                    type="text"
                    required
                    name={"ship_pin_code"}
                    value={shippingAddress.ship_pin_code}
                    onChange={handleShippingData}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Country</label>
                <div className="form-control">
                  <select
                    name={"ship_country"}
                    value={shippingAddress.ship_country}
                    onChange={handleShippingData}
                  >
                    <option value="volvo">select</option>
                    {CountryCode?.countries?.map((item) => {
                      return <option value={item?.name}>{item?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="end_section">
                <button onClick={(e) => handleOnClick(e, "ship")}>
                  {waitShip}
                </button>
                <button style={{backgroundColor: "red"}} onClick={(e) => handleOnClick(e, "skip")}>
                  Skip
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BillingAddress;
