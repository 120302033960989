import React, { useEffect, useState } from "react";
import "../Customers/CustomerProfile/ViewPayment.css";
import "./viewInvoice.css";
import logo from "../../../Assets/logo.png";
import {
  HiOutlineDownload,
  HiOutlineFilter,
  HiOutlineMail,
  HiOutlinePrinter,
} from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import toast, { Toaster } from "react-hot-toast";
// import { DotLoader } from "react-spinners";
import DotLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
const ViewInvoice = () => {
  // const baseUrl = "http://192.168.114.239:8000/";
  var total_amt;
  const navigate = useNavigate();
  const downloadPDF = () => {
    window.print();
  };
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  // token =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkzMTMzNDU1LCJpYXQiOjE2OTMwNDcwNTUsImp0aSI6IjdiMWYxODY4NzkzODRiOThiMmFmNTdkMTA0OTFkN2FmIiwidXNlcl9pZCI6MSwidXNlcm5hbWUiOiJyIn0.OqPlHszfn9T-MpsGPTk__DxZTnFZgl7lN5jkLy-KqBQ";
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const { sales } = useParams();
  const companyProposalDetails = useSelector((store)=> store?.main?.selectedCompanyProposal);
  const { companyData } = useSelector((store) => store.main);
  const leadProposalDetail = useSelector((store) => store?.main?.leadProposalDetails);
  const editSelectedLead = useSelector((store) => store?.main?.leadData);
  const invoiceData = useSelector((store)=>store?.main?.selectedCompanyInvoice);
  // console.log("invoice dataaaaaaaaa",invoiceData,sales);
  // console.log("compnay............",companyProposalDetails,companyData,sales);
  // console.log(leadProposalDetail, editSelectedLead, "invoice ----------------");
  // Lead Details
  const cid = sessionStorage?.getItem("c_id");
  const [leadData, setLeadData] = useState();
  const [mailLoader, setMailLoader] = useState(<HiOutlineMail />);
  const [downloadLoader, setDownloadLoader] = useState(<HiOutlineDownload />);
  const getLeadDetails = () => {
    axios
      ?.get(
        `${baseUrl}${sales === "company_proposal" || sales === "company"
          ? "Invoice_for_company_details"
          : "super_admin_lead/lead"
        }/${cid}/`,
        config
      )
      .then((respo) => {
        setLeadData(
          sales === "company_proposal" || sales === "company"
            ? respo?.data?.company_detials[0]
            : respo?.data
        );
        // console.log(respo?.data?.company_detials[0], "companyData");
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  // Proposal View..........................//
  const lp_id = sessionStorage.getItem("lp_id");
  const [proposalData, setProposalData] = useState();
  const getLeadProposal = () => {
    axios
      .get(
        `${baseUrl}super_admin_lead/${sales === "company_proposal"
          ? "company-proposal"
          : sales === "company"
            ? "company_invoice"
            : "proposal"
        }/${lp_id}/`,
        config
      )
      .then((respo) => {
        // if (respo.status === 200) {
        // console.log(respo?.data);
        setProposalData(respo?.data[0]);
        // console.log(JSON.parse(respo?.data[0]?.container))
        // }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // Company Invoice View ..........................//
  const iv_id = sessionStorage.getItem("iv_id");
  // console.log(iv_id)
  const [invData, setInvData] = useState();
  const getInvoice = () => {
    // console.log('invoice api hit')
    axios
      .get(`${baseUrl}Invoice_for_company/${iv_id}/`, config)
      .then((respo) => {
        if (respo.status === 200) {
          // console.log(respo.data)
          setInvData(respo.data);
        }
      });
  };
  useEffect(() => {
    // getInvoice()
    getLeadDetails();
    getLeadProposal();
    // if (sales === "lead_proposal") {
    //   // getLeadDetails();
    //   // getLeadProposal();
    // } else if (sales === "company" || sales === "sales") {
    //   getInvoice();
    // }
    // {
    //   sales === 'lead_proposal' && getLeadProposal();
    //   sales === 'company' || sales === 'sales' && getInvoice();
    // }
  }, [sales]);
  const [a, b] = useState([]);

  // let date = new Date(proposalData?.createAt);
  // console.log(date)
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  let date =null;
  if(sales === "company_proposal"){
    date = new Date(companyProposalDetails?.createdAt);
  }else if(sales === "company"){
    date = new Date(invoiceData?.createdAt)
  }else{
    date = new Date(leadProposalDetail?.createdAt);
  }
  const day = date.getDate();
  const monthAbbreviation = months[date.getMonth()];
  const year = date.getFullYear();

  const dateC = `${day}-${monthAbbreviation}-${year}`;
  // console.log(dateC);


  // let dateC = date.toLocaleDateString();
  // let timeC = date.toLocaleTimeString();

  // Download pdf with jsPDF from here
  const createPDF = () => {
    setDownloadLoader(<DotLoader color="#f57842" loading={true} size={20} />);
    const formData = {
      proposal_invoice_Id: sales=== "lead_proposal" ? leadProposalDetail?._id : sales === "company_proposal" ? companyProposalDetails?._id : sales === "company" ? invoiceData?._id : "",
      mail_type:
        sales === "company_proposal" || sales === "lead_proposal"
          ? sales
          : "invoice",
    };
    // console.log(formData, "form data for download");
    axios
      .post(`${baseUrl}super-admin/download/pdf-proposal`, formData, {
        responseType: "arraybuffer",
        headers: config.headers
      })
      .then((respo) => {
        toast?.success(
          `Your ${sales === "company_proposal" || sales === "lead_proposal"
            ? "Proposal"
            : "Invoice"
          } is downloaded`
        );
        // setMailLoader(<HiOutlineMail />);
        // console.log(respo?.data, "gjdfgjdfgjh");
        const blob = new Blob([respo.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${sales === "company_proposal" || sales === "lead_proposal"
            ? "proposal-" + leadProposalDetail?._id?.slice(0, 5)
            : "INV-" + leadProposalDetail?.invoice_number
          }.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloadLoader(<HiOutlineDownload />);
      })
      .catch((error) => {
        // Handle error
        // setMailLoader(<HiOutlineMail />);
        setDownloadLoader(<HiOutlineDownload />);
        console.error("Error sending email", error);
      });
  };

  const sendMail = () => {
    setMailLoader(<DotLoader color="#f57842" loading={true} size={20} />);
    // console.log(leadData, "leadData");
    // leadData?.email
    // console.log(leadProposalDetail, "leadData");
    const formData = {
      subject: sales === "company" ? "Invoice to " + companyData?.company_name : sales==="company_proposal" ? "Proposal to " + companyData?.company_name : "Proposal to " + editSelectedLead?.name,
      discription: `Please find your ${sales === "company" ? "invoice" : "proposal"} in attachment`,
      tomail: leadData?.company_email,
      proposal_invoice_Id: sales=== "lead_proposal" ? leadProposalDetail?._id : sales === "company_proposal" ? companyProposalDetails?._id : sales === "company" ? invoiceData?._id : "",
      leadID: leadData?._id,
      filename:
        sales === "company"
          ? leadProposalDetail?.invoice_number
          : `proposal-${dateC}`,
      cc: ["shubhamdubey@dialsense.in"],
      mail_type:
        sales === "company_proposal" || sales === "lead_proposal"
          ? sales
          : "invoice",
    };
    // console.log(formData, "mail pdf--------");
    axios
      .post(`${baseUrl}super-admin/send/mail-proposal/`, formData, config)
      .then(() => {
        toast?.success("Email sent successfully!");
        setMailLoader(<HiOutlineMail />);
      })
      .catch((error) => {
        // Handle error
        setMailLoader(<HiOutlineMail />);
        console.error("Error sending email", error);
      });
  };

  // console.log(leadProposalDetail, "leadProposalDetail");
  return (
    <div className="view_receipt_container">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="view_heading">
        <div className="heading_left">
          <div className="form_title">
            {sales === "lead_proposal" || sales === "company_proposal"
              ? "Proposal"
              : "Invoice"}
          </div>
        </div>
        <div className="heading_right">
          <div
            className="back_btn"
            onClick={() => {
              if (sales === "sales") {
                navigate("/sales_invoice");
              } else if (sales === "company_proposal" || sales === "company") {
                navigate("/customer_profile");
                sessionStorage.setItem("navigate", "Proposals");
              } else if (sales === "lead_proposal") {
                navigate("/leads");
              }
            }}
          >
            <span>
              <HiOutlineFilter />
            </span>
            <span>Back</span>
          </div>
        </div>
      </div>
      <div className="receipt_main_container">
        <div className="receipt_section" id="printable2">
          <div className="receipt_handling">
            <div className="receipt_flex">
              <div className="rec_left">{/* Payment */}</div>
              <div className="rec_right">
                <span onClick={() => createPDF()}>
                  {downloadLoader}
                </span>
                <span onClick={() => sendMail()}>{mailLoader}</span>
                <span onClick={() => downloadPDF()}>
                  <HiOutlinePrinter />
                </span>
              </div>
            </div>
          </div>
          <div
            className="downloadinvoice"
            id="printable"
            style={{ "@page": { size: "A4", margin: "0" } }}
          >
            <div className="recipt_payment">
              <div className="container">
                <div className="main_payment">
                  <div className="first_side">
                    <div className="left_side">
                      <div style={{ color: "#323232", fontSize: "14px" }}>
                        From
                      </div>
                      <div className="logo_chatsense">
                        <img src={logo} alt="" />
                      </div>
                      <div className="company_name">
                        <h3 style={{ fontSize: "14px", color: "#393939" }}>
                          Brimming Grace Capital Pvt. Ltd.
                        </h3>
                      </div>
                      <div className="company_details">
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          108, Udyog Vihar
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          Phase - 1, Sector - 20
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          Gurugram, Haryana
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          GST Number: 07AAICB6042L1ZI
                        </p>
                      </div>
                    </div>
                    <div className="right_side">
                      <div className="cmpny_name">
                        <div style={{ color: "#323232", fontSize: "14px" }}>
                          {sales === "lead_proposal" ||
                            sales === "company_proposal"
                            ? "To,"
                            : "Bill To,"}
                        </div>
                      </div>
                      <div className="cmpny_details">
                        <h3 style={{ fontSize: "14px", color: "#393939" }}>
                            {
                              sales === "company_proposal" || sales === "company" ? companyData?.company_name : sales === "lead_proposal" 
                               ? editSelectedLead?.name : "--"
                            }
                          {/* {sales === "lead_proposal" ||
                            sales === "company_proposal" ||
                            sales === "company"
                            ? editSelectedLead?.company_name && editSelectedLead?.company_name
                            : sales === "company" || sales === "sales"
                              ? // ? invData?.Company_Details[0]?.company_name &&
                              //   invData?.Company_Details[0]?.company_name
                              ""
                              : "--"} */}
                        </h3>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          {sales === "lead_proposal"
                            ? editSelectedLead?.lead_address && editSelectedLead?.lead_address
                            : sales === "company_proposal" ||
                              sales === "company"
                              ? companyData?.company_details?.company_address
                              : "--"}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          { sales === "company_proposal" ||
                            sales === "company" ? `${companyData?.company_details?.city}, ${companyData?.company_details?.state}, ${companyData?.company_details?.country} - ${companyData?.company_details?.area_pin_code}`
                            : (sales === "lead_proposal" )
                            ? `${editSelectedLead?.city}, ${editSelectedLead?.state}, ${editSelectedLead?.country} - ${editSelectedLead?.pincode}`
                            : sales === "company" || sales === "sales"
                              ? ""
                              : // ? invData?.Company_Details[0]?.city &&
                              //   `${invData?.Company_Details[0]?.city}, ${invData?.Company_Details[0]?.state}, ${invData?.Company_Details[0]?.country} - ${invData?.Company_Details[0]?.pincode}`
                              "--"}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#393939",
                          }}
                        >
                          GST Number:{" "}
                          {sales === "company_proposal" ||
                            sales === "company" ? companyData?.gst_number : 
                          sales === "lead_proposal" 
                            ? editSelectedLead?.gst && editSelectedLead?.gst
                            : sales === "company" || sales === "sales"
                              ? ""
                              : // ? invData?.Company_Details[0]?.gst &&
                              //   invData?.Company_Details[0]?.gst
                              "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main_container">
              <div className="second_contain">
                <div className="payment_recipt">
                  <h1>
                    {sales === "lead_proposal" || sales === "company_proposal"
                      ? "PROPOSAL"
                      : "INVOICE"}
                  </h1>
                </div>

                <div className="invTtl">
                  {sales === "lead_proposal" ||
                    sales === "company_proposal" ||
                    sales === "company" ? (
                    <div>
                      <span>
                        {sales === "company" ? "Invoice No." : "Subject"} :{" "}
                      </span>
                      <span>
                        {sales === "company"
                          ? leadProposalDetail?.invoice_number
                          : sales=== "lead_proposal" ? leadProposalDetail?.subject : companyProposalDetails?.subject}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="invLeft">Date: {`${dateC}`}</div>
                </div>
                <div className="payFor">
                  {/* <h1>Payment For</h1> */}
                  <table className="table invTable">
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          #
                        </th>
                        <th
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Plan Type
                        </th>
                        <th
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Plan Description
                        </th>
                        <th
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Number of Users
                        </th>
                        <th
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Unit Price
                        </th>
                        <th
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Total Amount(inc. 18% GST)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        leadProposalDetail?.plan_description?.map(
                          (item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                    }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                    }}
                                  >
                                    {item?.planType}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                    }}
                                  >
                                    {item?.planDescription}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                    }}
                                  >
                                    {item?.teamLength}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                    }}
                                  >
                                    &#8377; {(+item?.unitPrice)?.toFixed(2)}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                    }}
                                  >
                                    &#8377; {(+item?.amount)?.toFixed(2)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    &#8377; {item?.totalAmount.toFixed(2)}
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )
                        // : (
                        //   <>
                        //     <tr>
                        //       <td>1</td>
                        //       <td>
                        //         {invData?.Invoice_Details[0]?.plan_descriptions}
                        //       </td>
                        //       <td>
                        //         &#8377;{" "}
                        //         {(+invData?.Invoice_Details[0]
                        //           ?.plan_price).toFixed(2)}
                        //       </td>
                        //       <td></td>
                        //       <td></td>
                        //       <td></td>
                        //       <td style={{ textAlign: "right" }}>
                        //         &#8377;{" "}
                        //         {(+invData?.Invoice_Details[0]
                        //           ?.plan_price).toFixed(2)}
                        //       </td>
                        //     </tr>
                        //     <tr>
                        //       <td>2</td>
                        //       <td>
                        //         {invData?.Invoice_Details[0]?.invoice_description}
                        //       </td>
                        //       <td></td>
                        //       <td>{invData?.Invoice_Details[0]?.chat_count}</td>
                        //       <td>
                        //         &#8377; {invData?.Invoice_Details[0]?.chat_rate}
                        //       </td>

                        //       <td>&#8377; {bill}</td>
                        //       <td style={{ textAlign: "right" }}>
                        //         &#8377; {bill}
                        //       </td>
                        //     </tr>
                        //   </>
                        // )
                      }
                      <tr>
                        <td
                          colSpan={6}
                          style={{
                            textAlign: "center",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          Grand Total
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          &#8377;{" "}
                          {sales==="lead_proposal" ? leadProposalDetail?.totalAmount : sales === "company_proposal" ? companyProposalDetails?.totalAmount
                          :  invoiceData?.plan_description &&
                            JSON?.parse(invoiceData?.plan_description)
                              ?.reduce(
                                (sum, planItem) => sum + +planItem?.totalAmount,
                                0
                              )
                              ?.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {sales === "company" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#1877F2",
                        color: "#FFFFFF",
                        fontSize: "0.8rem",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          color: "#FFFFFF",
                        }}
                        href={invoiceData?.payment_link}
                        target="blank"
                      >
                        Proceed To Pay
                      </a>
                    </div>
                  </div>
                )}
                {/* <div className="terms_container">
                  <div className="terms_heading">Terms & Condition</div>
                  <div className="terms_desc">
                    Qui distinctio rerum repellendus at et voluptates et. Itaque
                    nisi sint tempore dolorem ipsa autem. Hic iure est
                    perferendis quia id dolorum dolores nihil.
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInvoice;
