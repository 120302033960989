import React from 'react'

const DealValueCard = ({
    borderColor,
    className,
    titleText,
    titleTextClassName,
    amount,
    dealDate,
    dealTime
}) => {
    return (
        <div style={{
            borderColor: borderColor ,
            width: "200px"
        }} className="deal-card">
            <div className={className || "deal-type"}>
                {titleText}
            </div>
            <div className={titleTextClassName || "deal-card-value"}>
                &#8377;    {amount || 0}
            </div>
            <div className="deal-card-date">
                {dealDate}, {dealTime}
            </div>
        </div>
    )
}

export default DealValueCard