import React, { useEffect, useRef, useState } from "react";
import { RiQuestionMark } from "react-icons/ri";
import "./KnowledgeBase.css";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { HiOutlinePencil } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import ConfirmDialog from "../../confirmDialog/ConfirmDialog";
import { baseUrl } from "../../../BaseUrl";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";

const KnowledgeBase = () => {
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [activeComponent, setActiveComponent] = useState("table");
  const [addNewParentValue, setAddNewParentValue] = useState("selectParent");
  const [parentName, setParentName] = useState('');

  const [parentListData, setParentListData] = useState([]);
  const [allDataforContent, setAllDataforContent] = useState([]);
  const [confirmEnable, setConfirmEnable] = useState(false);
  const [confirmName, setConfirmName] = useState();
  const [inputParentField, setInputParentField] = useState(false);
  const [parentNameUpdate, setParentNameUpdate] = useState();
  const [disabledButtonCSS, setDisabledButtonCSS] =useState("buttonToSaveParent");
  const [someData, setSomeData] = useState({
    name: "",
    heading: "",
    sub_heading: "",
    image: null,
    embed_code: "",
  });
  const [searchQuery, setSearchQuery] = useState('');
  // Get parent List #############################################
  const parentList = () => {
    axios
      .get(`${baseUrl}super-admin/parent-knowledge-base/fetch-list?search=${searchQuery}`, config)
      .then((respo) => {
        // console.log(respo?.data, "parentList Response...");
        setParentListData(respo?.data?.data);
        setParentName(respo?.data?.data?.parent_name);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    parentList(1);
  }, [searchQuery]);
  // const filteredParentList = parentListData.filter(item =>
  //   item.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // Post parent  #############################################
  const parentData = {
    parent_name: parentName,
  };
  const postNewParent = () => {
    axios
      .post(
        `${baseUrl}super-admin/parent-knowledge-base/create`,
        parentData,
        config
      )
      .then((respo) => {
        // console.log(respo?.data, "data Posted");
        if (respo.status === 200 || 201) {
          toast.success(respo.data.message);
        }
        setParentName(respo?.data?.data?._id);
        setDisabledButtonCSS("disabledButtonCSS");
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  // ####### Post OnClick #############
  const saveParent = () => {
    postNewParent();
  };
  // Component handling
  const handleChangeComponent = (componentInstance) => {
    if (componentInstance === "addKB") {
      setActiveComponent("addKB");
    }
    if (componentInstance === "table") {
      setActiveComponent("table");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSomeData({
      ...someData,
      [name]: value,
    });
  };
  const editorRef = useRef(null);
  const contentFieldChanaged = (data) => {
    setSomeData({ ...someData, content: data });
  };
  useEffect(() => {
    let parentId = {
      parent: parentName,
    };
    setSomeData({ ...someData, ...parentId });
  }, [parentName]);
  // console.log('someDataBefore post',someData);
  // Post Child and it's content ########################################
  const submitDataForKB = (e) => {
    e.preventDefault();
    axios
      .post(
        `${baseUrl}super-admin/child-knowledge-base/create`,
        someData,
        config
      )
      .then((respo) => {
        // console.log(respo?.data, "dataOnpostChild");
        toast?.success("Knowledge Base has been created successfully!");
     
        setTimeout(() => {
          // console.log("Create",respo);
          setActiveComponent("table");
          parentList();
          getListForAll();
          setSomeData({
            name: "",
            heading: "",
            sub_heading: "",
            image: null,
            embed_code: "",
          });
          setParentName('');
          setDisabledButtonCSS("buttonToSaveParent");
        }, 1000);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        // console.log(err);
      });
  };
  // get all data list for Knowledge base #####################################
  const getListForAll = () => {
    axios
      .get(`${baseUrl}super-admin/child-knowledge-base/fetch-list`, config)
      .then((respo) => {
        // console.log(respo?.data, "listofChild");
        setAllDataforContent(respo?.data?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    parentList();
    getListForAll();
  }, []);
  // Update Parent #############################################
  const handleParentUpdate = (pid) => {
    // console.log(pid, "pidup");
    setInputParentField({
      id: pid._id,
      isTrue: true,
    });
    setParentNameUpdate(pid?.parent_name);
  };
  const handleEditParentChane = (e) => {
    setParentNameUpdate(e?.target?.value);
  };
  const updateParentName = (e, pid) => {
    e.preventDefault();
    let data = {
      parent_name: parentNameUpdate,
    };
    axios
      ?.put(
        `${baseUrl}super-admin/parent-knowledge-base/${pid}/update`,
        data,
        config
      )
      .then((respo) => {
        toast?.success("Parent updated successfully!");
        setInputParentField(false);
        parentList();
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  //  Delete Parent ###############################################
  const handleDeleteParent = (pData) => {
    // console.log(pData, "pidDel");
    setConfirmEnable(true);
    setConfirmName(pData);
  };
  const confirmdelete = () => {
    // console.log("confirmmmmmmmm.....", confirmName);
    axios
      .delete(
        `${baseUrl}super-admin/parent-knowledge-base/${confirmName?._id}/delete`,
        config
      )
      .then((respo) => {
        // console.log(respo, "delrespoParent................................................................");
        if (respo.status === 200 || 201) {
          toast.success(respo.data.message);
          setTimeout(() => {
            setConfirmEnable(false);
            parentList();
          }, 1000);
        }

      }).catch((err) => {
        // console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const confirmdeleteChild = () => {
    axios
      .delete(
        `${baseUrl}super-admin/child-knowledge-base/${confirmName?._id}/delete`,
        config
      )
      .then((respo) => {
        // console.log(respo, "delrespo");
        if (respo.status === 200 || 201) {
          toast.success(respo.data.message);
          setTimeout(() => {
            setConfirmEnable(false);
            parentList();
            getListForAll();
          }, 1000);
        }

      }).catch((err) => {
        // console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const cancelDelete = () => {
    setConfirmEnable(false);
  };

  // ##################################################################################################//
  return (
    <div
      style={{
        maxHeight: "92vh",
        overflow: "auto",
      }}
      className="customers_page"
    >
      <Toaster position="top-center" reverseOrder={false} />
      <div className="customers_inner">
        {/* Button and Search bar */}
        <div className="customers_headeing">
          <div className="heading_left">
            {activeComponent === "table" && (
              <div
                onClick={() => handleChangeComponent("addKB")}
                className="create_btn"
              >
                Add New Knowledge Base
              </div>
            )}
            {activeComponent === "addKB" && (
              <div className="summer_heading">Add New Knowledge Base</div>
            )}
          </div>

          {/* #############SEARCH FILTER################### */}
          <div className="search-filter-container">
            {activeComponent === "table" && (
              <input
                type="search"
                placeholder="Search by name"
                className="search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            )}
            {activeComponent === "addKB" && (
              <div
                onClick={() => handleChangeComponent("table")}
                className="create_btn"
              >
                Back
              </div>
            )}
          </div>
        </div>
        {/* Button and Search bar  End here*/}
        {activeComponent === "addKB" && (
          <div className="">
            <form
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
                className="formContainer"
              >
                <div className="parentContainer">
                  <div className="parentInnerContainer">
                    <div className="parentInputContainer">
                      <div className="parentKBLabel">
                        Select or Create New Parent
                      </div>
                      <div className="selectOrInput">
                        {addNewParentValue === "selectParent" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "5px 0",
                              gap: "5px",
                            }}
                          >
                            <select
                              onChange={(e) => setParentName(e?.target?.value)}
                              name="parentMenu"
                              className="parent-select"
                            >  
                              <option value=""> Select Parent</option>
                              {parentListData?.length > 0 ? (
                                parentListData?.map((item, i) => {
                                  return (
                                    <option value={item?._id}>
                                      {item?.parent_name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option>
                                  No parent data here! <br />
                                  Add New Parent
                                </option>
                              )}
                            </select>
                            <span>or</span>
                            <div
                              onClick={() =>
                                setAddNewParentValue("customParent")
                              }
                              className="buttonToAddParent"
                            >
                              + New Parent
                            </div>
                          </div>
                        )}
                        {addNewParentValue === "customParent" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "5px 0",
                              gap: "5px",
                            }}
                          >
                            <input
                              onChange={(e) => setParentName(e?.target?.value)}
                              // value={parentName}
                              name="parentMenu"
                              type="text"
                              placeholder="Parent Menu"
                              className="search-input"
                              disabled={
                                disabledButtonCSS === "disabledButtonCSS"
                                  ? true
                                  : false
                              }
                            />
                            <div
                              onClick={() => saveParent()}
                              className={disabledButtonCSS}
                            >
                              Save
                            </div>
                            <span>or</span>
                            <div
                              onClick={() =>
                                setAddNewParentValue("selectParent")
                              }
                              className="buttonToAddParent"
                            >
                              Select Parent
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="parentInputContainer">
                      <div className="parentKBLabel">Enter Child Menu Name</div>
                      <input
                        onChange={(e) => handleChange(e)}
                        value={someData?.name}
                        name="name"
                        type="text"
                        placeholder="Child Menu"
                        className="search-input"
                      />
                    </div>
                  </div>
                  <div className="contentInputContainer">
                    <div className="parentKBLabel">Enter Heading</div>
                    <input
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => handleChange(e)}
                      value={someData?.heading}
                      name="heading"
                      type="text"
                      placeholder="Heading"
                      className="search-input"
                    />
                  </div>
                  <div className="contentInputContainer">
                    <div className="parentKBLabel">Enter Sub Heading</div>
                    <input
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => handleChange(e)}
                      value={someData?.sub_heading}
                      name="sub_heading"
                      type="text"
                      placeholder="Sub heading"
                      className="search-input"
                    />
                  </div>
                  <div className="contentInputContainer">
                    <div className="parentKBLabel">Write Content</div>
                    <JoditEditor
                      ref={editorRef}
                      value={someData?.content}
                      onChange={(newContent) =>
                        contentFieldChanaged(newContent)
                      }
                    />
                  </div>
                  <div className="KBButtons">
                    <button
                      onClick={(e) => submitDataForKB(e)}
                      className="buttonToAddParent"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              {/* RIGHT CONTAINER FOR YOUTUBE ATTACHMENT */}
              <div className="rightFormContainer">
                <div className="innerRightContainer">
                  <div className="parentInputContainer">
                    <div className="parentKBLabel">Enter YouTube Embed Id</div>
                    <input
                      onChange={(e) => handleChange(e)}
                      value={someData?.embed_code}
                      name="embed_code"
                      type="text"
                      placeholder="Paste youtube embed code here"
                      className="search-input"
                    />
                  </div>
                  <div
                    style={{
                      display: `${someData?.embed_code ? "block" : "none"}`,
                    }}
                    className="previewBoxVideo"
                  >
                    <div
                      style={{
                        padding: "5px 5px 5px 0",
                      }}
                    >
                      Preview
                    </div>
                    <iframe
                      width="294"
                      // height="345"
                      src={`https://www.youtube.com/embed/${someData?.embed_code}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                      style={{
                        borderRadius: "5px",
                      }}
                    ></iframe>
                    {/* <iframe

                      width="294"
                      // height="480"
                      src={`https://www.youtube.com/embed/kQDd1AhGIHk`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                      style={{
                        borderRadius: "5px",
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
        {/* Table Start from here with title*/}
        {activeComponent === "table" && (
          <>
            <div className="customer_table_container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="customer_table_up"
              >
                <div className="customer_summery">
                  <div className="summery_icon">
                    <RiQuestionMark />
                  </div>
                  <div className="summer_heading">Knowledge Base</div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
                className="customer_table"
              >
                {parentListData?.length > 0 ? (
                  parentListData?.map((item, i) => {
                    return (
                      <>
                        <details className="detailsExpender">
                          <div
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                              zIndex: "10px",
                            }}
                          >
                            <div className="action_btns">
                              <HiOutlinePencil
                                className="action_icon_edit"
                                onClick={() => handleParentUpdate(item)}
                              />
                              <MdDeleteOutline
                                className="action_icon_delete"
                                onClick={() => {
                                  let passValue = {
                                    ...item,
                                    source: "parent",
                                  };
                                  handleDeleteParent(passValue);
                                }}
                              />
                              {/* <MdDeleteOutline className="action_icon_delete" /> */}
                            </div>
                          </div>
                          <summary className="detailsSummaryTitle">
                            {inputParentField?.isTrue &&
                              inputParentField?.id === item?._id ? (
                              <form
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                                onSubmit={(e) => updateParentName(e, item?._id)}
                              >
                                <input
                                  type="text"
                                  name="parent_name"
                                  value={parentNameUpdate}
                                  onChange={handleEditParentChane}
                                  className="search-input"
                                />
                                <button
                                  style={{
                                    padding: "5px 10px",
                                    border: "none",
                                    backgroundColor: "#1877F2",
                                    color: "#FFFFFF",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Update
                                </button>
                              </form>
                            ) : (
                              item?.parent_name
                            )}
                          </summary>
                          {allDataforContent
                            ?.filter((data) => data?.parent_id === item?._id)
                            ?.map((innerItem, index) => {
                              return (
                                <div
                                  style={{
                                    borderBottom: "1px solid #cbcbcb",
                                  }}
                                  className="summaryInnerBody"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        color: "#1877F2",
                                        fontSize: "1rem",
                                      }}
                                    >{`${index + 1}. ${innerItem?.name}`}</h2>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#1877F2",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {" "}
                                        {/* <EditIcon /> */}
                                      </span>
                                      <span
                                        style={{
                                          color: "#FF0000",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <DeleteIcon
                                          onClick={() => {
                                            let passValue = {
                                              ...innerItem,
                                              source: "child",
                                            };
                                            handleDeleteParent(passValue);
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <h4
                                    style={{
                                      padding: "5px",
                                      color: "#393939",
                                    }}
                                  >
                                    {innerItem?.heading}
                                  </h4>
                                  {/* content ############## */}
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      className="contentContainerHTML"
                                      style={{
                                        color: "#292929",
                                        padding: "0 5px",
                                        fontSize: "0.8rem",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: innerItem?.content,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        display: `${innerItem?.embed_code !== ""
                                            ? "block"
                                            : "none"
                                          }`,
                                        padding: "10px",
                                      }}
                                      className="previewBoxVideo"
                                    >
                                      <iframe
                                        width="294"
                                        // height="480"
                                        // src={`https://www.youtube.com/embed/kQDd1AhGIHk`}
                                        src={`https://www.youtube.com/embed/${innerItem?.embed_code}`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded youtube"
                                        style={{
                                          borderRadius: "20px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </details>
                      </>
                    );
                  })
                ) : (
                  <option>
                    No data here! <br />
                    Add New Parent
                  </option>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {confirmEnable && (
        <ConfirmDialog
          confirmIcon={<DeleteIcon />}
          confirmText={`Do you want to delete "${confirmName?.source === "parent"
              ? confirmName?.parent_name
              : confirmName?.name
            }" ?`}
          submitBtnText={"Delete"}
          confirmSubmit={
            confirmName?.source === "parent"
              ? confirmdelete
              : confirmdeleteChild
          }
          confirmCancel={cancelDelete}
        />
      )}
    </div>
  );
};

export default KnowledgeBase;
