import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { MdSend } from "react-icons/md";
import { HiOutlinePlus, HiOutlinePhotograph } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import useWebSocket from "react-use-websocket";
import toast, { Toaster } from "react-hot-toast";
import { TbPlugConnectedX } from "react-icons/tb";
import { HiOutlineDocument } from "react-icons/hi2";
import { SOCKET_URL_CST, baseUrl } from "../../../BaseUrl";
import { useSelector } from "react-redux";

const Support = () => {
  const senderData = useSelector((store) => store?.main?.userData);
  // console.log(senderData, "senderData");
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [message, setMessage] = useState("");
  const [listOfUsers, setListOfUsers] = useState([]);
  const [allChatPerUser, setAllChatPerUser] = useState([]);
  const [currentUser, setCurrentuser] = useState("");
  const [reminderProfilePopup, setReminderProfilePopup] = useState("");
  const [toAssignEmployee, setToAssignEmployee] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [fileVal, setFileVal] = useState(null);
  const [openCloseFile, setOpenCloseFile] = useState(false);
  const scrollChatRef = useRef();
  const scrollCompleteBottom = (scrollChatRef) => {
    scrollChatRef?.current?.scroll({
      top: 20000,
      behavior: "smooth",
    });
  };
  const userType = localStorage?.getItem("chatUserType");
  // const [listOfUsersAppend, setListOfUsersAppend] = useState([]);
  const listOfAllChatUser = () => {
    // console.log(senderData, "selectorData");
    axios
      .get(`${baseUrl}chat-admin/get-chat-company/${senderData?.id}/`, config)
      ?.then((respo) => {
        setListOfUsers(respo?.data);
        if (respo?.data?.length > 0) {
          handleOpenChat(respo?.data[0]);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleOpenChat = (data) => {
    // console.log(data, "data of selected user");
    setCurrentuser(data);
    axios
      .get(
        `${baseUrl}chat-admin/all-chat/?user=${data?.chat_client?.id}`,
        config
      )
      .then((respo) => {
        setOpenCloseFile(false);
        setFileVal(null);
        setAllChatPerUser(respo?.data?.messages);
        if (data?.unreadcount > 0 || data?.status?.toLowerCase() === "new") {
          updateStatus(data?.id);
        }
        setTimeout(() => {
          scrollCompleteBottom(scrollChatRef);
        }, 100);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  // console.log(currentUser);
  // console.log(fileVal, "fileVal");
  const handleSendMessage = () => {
    if (message !== "" || fileVal !== null) {
      // console.log(message, "message");
      // console.log(currentUser?.chat_client?.id, "hhhhhhhhh");
      sendJsonMessage({
        message,
        sender: currentUser?.chat_client?.id,
        receiver: "201103ec-30b4-45d6-ba54-b633614d139d",
        is_admin: true,
      });
      // sendJsonMessage(formData);
    }
  };
  // console.log(senderData, 'senderData');
  const { sendJsonMessage } = useWebSocket(
    `${SOCKET_URL_CST}201103ec-30b4-45d6-ba54-b633614d139d/`,
    {
      onOpen: (e) => {
        // console.log(e, "open");
        toast.success("Connection established !");
      },
      onMessage: (message) => {
        // console.log(message?.data && JSON?.parse(message?.data), "socket ka msg h");
        let dataM = message?.data && JSON?.parse(message?.data)
        handleOpenChat(currentUser);
        if (dataM?.is_admin) {
          setMessage("");
        }
        listOfAllChatUser();
      },
      shouldReconnect: (closeEvent) => true,
    }
  );
  const sendMessage = (e) => {
    setFileVal(null);
    setOpenCloseFile(false);
    if (e.key === "Enter") {
      handleSendMessage();
    }
    setTimeout(() => {
      scrollCompleteBottom(scrollChatRef);
    }, 500);
  };
  const getEmployeeList = () => {
    axios
      .get(`${baseUrl}chat-admin/chat-agents/`, config)
      .then((respo) => {
        setEmployeeList(respo?.data);
        // console.log(respo);
        if (respo?.data?.message) {
          setTimeout(() => {
            setReminderProfilePopup("");
          }, 100);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const updateStatus = (id) => {
    axios
      .put(`${baseUrl}chat-admin/get-chat-company/${id}/`, "", config)
      .then((respo) => {
        listOfAllChatUser()
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleAssignEmployee = () => {
    // console.log(currentUser?.id, "curent");
    // if(!toAssignEmployee){
    //   return
    // }
    // console.log(toAssignEmployee);
    const data = {
      assign_chat_id : toAssignEmployee,
      custom_id: currentUser?.id
    }
    axios.post(`${baseUrl}chat-admin/get-chat-company/`, data, config).then((respo) => {
      // console.log(respo);
    }).catch((err) => {
      // console.log(err);
    })
  }
  const handlePopupClose = () => {
    setReminderProfilePopup("");
  };
  const handleOpenDialog = () => {
    // console.log("object");
    getEmployeeList("", "");
    setReminderProfilePopup("ongoing_popup");
  };
  const openCloseFileContainer = () => {
    setOpenCloseFile(!openCloseFile);
  };

  useEffect(() => {
    listOfAllChatUser();
  }, [senderData]);
  // useEffect(() => {
  //   let filteredCompanyList = []
  //   console.log(listOfUsers, )
  //   filteredCompanyList = listOfUsers?.results?.filter((item, i) => {
  //    return item?.created_by !== null
  //   })
  //   // console.log(filteredCompanyList, 'filteredCompanyList');
  //   setListOfUsersAppend(filteredCompanyList)
  // },[listOfUsers])
  // console.log(listOfUsers, "listOfUsers");
  // console.log(listOfUsersAppend, "listOfUsersAppend");
  return (
    <div className="customers_page">
      <Toaster position="top-center" reverseOrder={false} />
      <div
        className={reminderProfilePopup + " " + "ongoing_popup_hide"}
        disabled
      >
        {/* popup code open */}
        <div className="popup-main">
          <div className="popup-card">
            <div
              style={{
                width: "100%",
                padding: "0 10px",
                fontSize: "1rem",
                color: "#595959",
                fontWeight: "600",
              }}
            >
              Assign Customer to Employee
            </div>
            <RxCross2 onClick={handlePopupClose} className="cross-icons" />
            <>
              <div
                style={{
                  fontSize: "0.8rem",
                  color: "#393939",
                }}
                className="task-details-main"
              >
                <div className="assign-emp-container">
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontSize: "16px",
                    }}
                  >
                    {currentUser?.company_name}
                  </div>
                  <span className="plugAssign">
                    <TbPlugConnectedX className="plugAssign" />
                  </span>
                  <select
                    className="parent-select"
                    value={toAssignEmployee}
                    onChange={(e) => {
                      setToAssignEmployee(e?.target?.value);
                    }}
                  >
                    <option value={""}>{"Select"}</option>
                    {employeeList?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <button
                    style={{
                      border: "1px solid #1877F259",
                      backgroundColor: "transparent",
                      color: "#1877F2",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => handleAssignEmployee()}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className="customers_inner">
        <div className="chat-whole-container">
          <div className="chat-side-bar">
            <div className="side-search">
              <input
                type="search"
                placeholder="Search"
                style={{
                  outline: "1px solid #FFFFFF",
                  borderRadius: "5px",
                  padding: "5px",
                  border: "none",
                  backgroundColor: "#FFFFFF29",
                  margin: "5px",
                  color: "#FFFFFF",
                }}
              />
            </div>
            <div
              style={{
                overflow: "auto",
                height: "90%",
                fontSize: "14px",
                color: "#595959",
              }}
              className="list-bar"
            >
              {listOfUsers &&
                listOfUsers?.map((item) => {
                  return (
                    <div
                      onClick={() => handleOpenChat(item)}
                      className="list-item"
                      style={{
                        backgroundColor:
                          item?.id === currentUser?.id && "#d9d9d9",
                      }}
                    >
                      <div className="symbolic-name">
                        {item?.company_name[0]}
                      </div>
                      <div className="customer-name-container">
                        <div
                          style={{ position: "relative" }}
                          className="last-message-info"
                        >
                          <div className="customer-name">
                            {item?.company_name}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "12px",
                                width: "100px",
                                overflow: "hidden",
                                paddingRight: "5px",
                              }}
                            >
                              {item?.last_message}
                            </div>
                            <span style={{
                              display: `${item?.status != 'new' && 'none'}`
                            }} className="New-circle">{item?.status}</span>
                          </div>
                          <span style={{
                            display: `${item?.unreadcount === 0 && 'none'}`
                          }} className="unread-circle">
                            {item?.unreadcount}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <div>
                <button onClick={() => listOfAllChatUser(listOfUsers?.links?.next)}>Load More</button>
              </div> */}
            </div>
          </div>
          <div className="chat-body">
            <div className="chat-nav-bar">
              <div
                style={{
                  textTransform: "capitalize",
                  color: "#FFFFFF",
                }}
              >
                {currentUser?.company_name}
              </div>
              <div>
                <button
                  style={{
                    border: "1px solid #FFFFFF59",
                    backgroundColor: "transparent",
                    color: "#FFFFFF",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontWeight: "600",
                    cursor: "pointer",
                    display: userType === "employee" && "none",
                  }}
                  onClick={handleOpenDialog}
                >
                  Assign
                </button>
              </div>
            </div>
            <div ref={scrollChatRef} className="chat-panel">
              {allChatPerUser?.map((chat) => {
                const dateTime = new Date(chat?.created_at);
                const options = {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                  timeZone: "Asia/Kolkata",
                };
                const formattedTime = dateTime?.toLocaleTimeString([], options);
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: chat?.is_admin ? "end" : "start",
                      alignItems: "center",
                      padding: "5px",
                    }}
                  >
                    <div
                      className={
                        chat?.is_admin
                          ? "chat-bubble-right"
                          : "chat-bubble-left"
                      }
                    >
                      {chat?.message}
                      <span
                        style={{
                          fontSize: "9px",
                          color: "#696969",
                          marginLeft: "5px",
                        }}
                      >
                        {formattedTime}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="chat-input">
              <div
                style={{
                  height: "100%",
                  width: "38px",
                  // backgroundColor: "#393939",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                  color: "#595959",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: openCloseFile === false && "none",
                  }}
                  className="file-input-container"
                >
                  <div className="file-input-box">
                    <input
                      type="file"
                      name="file"
                      className="file-type"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => setFileVal(e?.target?.files[0])}
                    />
                    <span>
                      {" "}
                      <HiOutlinePhotograph />
                    </span>
                  </div>
                  <div className="file-input-box">
                    <input
                      type="file"
                      name="file"
                      className="file-type"
                      accept=".pdf"
                      onChange={(e) => setFileVal(e?.target?.files[0])}
                    />
                    <span>
                      {" "}
                      <HiOutlineDocument />
                    </span>
                  </div>
                </div>
                <span onClick={openCloseFileContainer}>
                  <HiOutlinePlus
                    style={{
                      transform: openCloseFile === true && "rotate(45deg)",
                      transition: "linear 0.2s",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  width: "93%",
                  height: "75%",
                  border: "1px solid #d9d9d9",
                  padding: "3px 0 3px 0",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <span
                  style={{
                    width: "40px",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BsEmojiSmile />
                </span> */}
                <input
                  type="text"
                  name="message"
                  className="message-input"
                  placeholder="Type your message"
                  value={message}
                  onChange={(e) => setMessage(e?.target?.value)}
                  onKeyDown={sendMessage}
                />
              </div>
              <button
                style={{
                  border: "none",
                  fontSize: "24px",
                  width: "40px",
                  height: "100%",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#1877F2",
                }}
                onClick={handleSendMessage}
              >
                <MdSend />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;

// import React from 'react';

// const Support = () => {
//   return (
//     <div>

//     </div>
//   );
// }

// export default Support;
