import React, { useEffect, useState } from "react";
import "./mystyle/style.css";
import "./mystyle/responsive.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../../BaseUrl";
import { handleCompanyData } from "../../../ReduxSlices/MainSlice";
import toast, { Toaster } from "react-hot-toast";
// const baseUrl = process.env.REACT_APP_API_URL;
const CreateForm = ({
  setDetailBillingComp,
  setBorderBottomA,
  setBorderBottomB,
  editSelectedItem,
  setOpenEditCompany,
  setCreatedCompanyData,
  setAllCompanyData,
}) => {
  const [allCreateFromData, setAllCreateFromData] = useState([]);
  const [createFormData, setCreateFormData] = useState({
    company_name: editSelectedItem ? editSelectedItem?.company_name : "",
    gst_number: editSelectedItem ? editSelectedItem?.gst : "",
    owner_name: editSelectedItem ? editSelectedItem?.owner_name : "",
    contact_number: editSelectedItem ? editSelectedItem?.contact_number : "",
    company_email: editSelectedItem ? editSelectedItem?.company_email : "",
    company_website_link: editSelectedItem ? editSelectedItem?.company_website_link : "",
    bussiness_category: editSelectedItem
      ? editSelectedItem?.bussiness_category
      : "",
    street: editSelectedItem ? editSelectedItem?.company_address : "",
    city: editSelectedItem ? editSelectedItem?.city : "",
    state: editSelectedItem ? editSelectedItem?.state : "",
    area_pin_code: editSelectedItem ? editSelectedItem?.area_pin_code : "",
    country: editSelectedItem ? editSelectedItem?.country : "",
  });

  const companyDataFromRedux = useSelector(
    (store) => store.main.allCompanyDataAPI
  );
  const [companyData, setCompanyData] = useState(companyDataFromRedux);
  const dispatch = useDispatch();
  const createCompanyFormHandler = (e) => {
    const { name, value } = e.target;
    setCreateFormData({
      ...createFormData,
      [name]: value,
    });
  };

  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const [compNameErr, setCompNameErr] = useState(0);
  const [emailErr, setEmailErr] = useState(0);
  const [mobileErr, setMobileErr] = useState(0);
  const [wait, setWait] = useState(editSelectedItem ? "Update" : "Save");

  let apisData = {
    uuid: editSelectedItem ? editSelectedItem?.id : null,
    company_name: createFormData?.company_name,
    owner_name: createFormData?.owner_name,
    contact_number: createFormData?.contact_number,
    company_email: createFormData?.company_email,
    gst_number: createFormData?.gst_number ? createFormData?.gst_number : "",
    company_website_link: createFormData?.company_website_link,
    bussiness_category: createFormData?.bussiness_category,
    company_address: createFormData?.street,
    city: createFormData?.city,
    state: createFormData?.state,
    area_pin_code: createFormData?.area_pin_code,
    country: createFormData?.country,
  };
  let {contact_number, ...UpdateApisData} = apisData;
  // console.log(UpdateApisData, apisData, "updateApisData")
  const handleCompanyEdit = (e) => {
    e.preventDefault();
    if (createFormData?.company_name === "") {
      setCompNameErr(1);
    } else if (createFormData?.contact_number === "") {
      setMobileErr(1);
    } else if (createFormData?.company_email === "") {
      setEmailErr(1);
    } else {
      setWait("Please wait...")
      axios
        .patch(`${baseUrl}company/`, UpdateApisData)
        // .put(`${baseUrl}cst/company_update_profile/${editSelectedItem?.id}/`, UpdateApisData, config)
        .then((res) => {
          // console.log(res);
          setCompanyData((old) => {
            // console.log(old);
            return old?.results?.paginated_queryset?.map((obj) => {
              if (obj?.id == editSelectedItem?.id) {
                return {
                  ...obj,
                  company_name: createFormData?.company_name,
                  gst_number: createFormData?.gst,
                  owner_name: createFormData?.owner_name,
                  contact_number: createFormData?.contact_number,
                  company_email: createFormData?.company_email,
                  company_website_link: createFormData?.company_website_link,
                  bussiness_category: createFormData.bussiness_category,
                };
              }
              return obj;
            });
          });
          toast?.success("Data updated successfully!");
          setWait("Data updated")
          setTimeout(() => {
            setOpenEditCompany("dashboard");
          }, 500);
        })
        .catch((err) => {
          // console.log(err);
          toast?.error("Failed to update!");
          setWait("Server error");
          setTimeout(() => {
            setWait("Update")
          }, 1000);
          // setOpenEditCompany("dashboard");
        });
    }
  };
  useEffect(() => {
    dispatch(handleCompanyData(companyData))
  }, [companyData])

  // console.log(companyData, "formData")
 
  const handleOnClick = (e) => {
    e.preventDefault();
    if (createFormData?.company_name === "") {
      setCompNameErr(1);
    } else if (createFormData?.contact_number === "") {
      setMobileErr(1);
    } else if (createFormData?.company_email === "") {
      setEmailErr(1);
    } else {
      setWait("Please wait...")
      setAllCreateFromData([createFormData, ...allCreateFromData]);
      axios
        .post(`${baseUrl}super-controller/company/register`, apisData, config)
        .then((res) => {
          // console.log("createeeeeeeeeeeCompany",res?.data);
          setWait("Company created")
          toast?.success("Company created successfully!");
          setCreatedCompanyData(res?.data?.data);
          setDetailBillingComp("BillingAddress");
          setBorderBottomA("2px solid #ffffff");
          setBorderBottomB("2px solid #555555");
        })
        .catch((err) => {
          setWait("Failed to create")
          toast.error(err?.response?.data?.message);
          setTimeout(() => {
            setWait("Try again")
          }, 1000);
          // console.log(err);
        });
      
    }
  };
  const compNameErrHandle = () => {
    setCompNameErr(0);
  };
  const mobileErrHandle = () => {
    setMobileErr(0);
  };
  const emailErrHandle = () => {
    setEmailErr(0);
  };
  // console.log(allCreateFromData);
  return (
    <div className="login">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="container">
        <div className="agent_details">
          <h1>
            {editSelectedItem ? "Update Company Details" : "Company Detail"}
          </h1>
          <form className="company-form">
            <div className="col_1">
              <div className="line_1">
                <label>Company Full Name</label>
                <div  className="form-control formControl1">
                  <input
                  placeholder="e.g. Company Ltd"
                    type="text"
                    required
                    name="company_name"
                    value={createFormData.company_name}
                    onChange={createCompanyFormHandler}
                    onKeyUp={compNameErrHandle}
                  />
                  <span
                    style={{
                      fontSize: "11px",
                      color: "red",
                      opacity: compNameErr,
                    }}
                  >
                    Please enter company name
                  </span>
                </div>
              </div>
              <div className="line_1">
                <label>Owner Name</label>
                <div className="form-control formControl1">
                  <input
                  placeholder="e.g. John"
                    type="text"
                    required
                    name="owner_name"
                    value={createFormData.owner_name}
                    onChange={createCompanyFormHandler}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Mobile Number</label>
                <div className="form-control formControl1">
                  <input
                  style={{
                    backgroundColor: `${editSelectedItem ? "#c9c9c9" : "#FFFFFF"}`
                  }}
                  placeholder="e.g. 918130XXXXXX"
                    type="text"
                    required
                    name="contact_number"
                    value={createFormData.contact_number}
                    onChange={createCompanyFormHandler}
                    onKeyUp={mobileErrHandle}
                    maxLength={10}
                    disabled = {editSelectedItem && true}
                  />
                  <span
                    style={{
                      fontSize: "11px",
                      color: "red",
                      opacity: mobileErr,
                    }}
                  >
                    Please enter mobile number
                  </span>
                </div>
              </div>
              <div className="line_1">
                <label>Email Id</label>
                <div className="form-control formControl1">
                  <input
                  style={{
                    backgroundColor: `${editSelectedItem ? "#c9c9c9" : "#FFFFFF"}`
                  }}
                  placeholder="e.g. example@domain.com"
                    type="company_email"
                    required
                    name="company_email"
                    value={createFormData?.company_email}
                    onChange={createCompanyFormHandler}
                    onKeyUp={emailErrHandle}
                    disabled = {editSelectedItem && true}
                  />
                  <span
                    style={{
                      fontSize: "11px",
                      color: "red",
                      opacity: emailErr,
                    }}
                  >
                    Please enter company email id
                  </span>
                </div>
              </div>
              <div className="line_1">
                <label>Websites</label>
                <div className="form-control formControl1">
                  <input
                  placeholder="e.g. www.example.com"
                    type="text"
                    required
                    name="company_website_link"
                    value={createFormData.company_website_link}
                    onChange={createCompanyFormHandler}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Category Of Business</label>
                <div className="form-control formControl1">
                  <select
                    name="bussiness_category"
                    value={createFormData.bussiness_category}
                    onChange={createCompanyFormHandler}
                  >
                    <option value="">Select</option>
                    <option value="INSURTECH">INSURTECH</option>
                    <option value="ADTECH">ADTECH</option>
                    <option value="EDTECH">EDTECH</option>
                    <option value="FINANCIAL">FINANCIAL</option>
                    <option value="HOSPITALITY">HOSPITALITY</option>
                    <option value="HOTELS">HOTELS</option>
                    <option value="TRANSPORTATION">TRANSPORTATION</option>
                    <option value="OTHER">OTHER</option>
                  </select>
                </div>
              </div>
              <div className="line_1">
                <label>GST Number</label>
                <div className="form-control formControl1">
                  <input
                  placeholder="e.g. DHS89889XXX"
                    type="text"
                    required
                    name="gst_number"
                    value={createFormData.gst_number}
                    onChange={createCompanyFormHandler}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Street</label>
                <div className="form-control formControl1">
                  <input
                 placeholder="e.g. street name"
                    type="text"
                    required
                    name="street"
                    value={createFormData.street}
                    onChange={createCompanyFormHandler}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>City</label>
                <div className="form-control formControl1">
                  <input
                  placeholder="e.g. New York"
                    type="text"
                    required
                    name="city"
                    value={createFormData.city}
                    onChange={createCompanyFormHandler}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>State</label>
                <div className="form-control formControl1">
                  <input
                  placeholder="e.g. state"
                    type="text"
                    required
                    name="state"
                    value={createFormData.state}
                    onChange={createCompanyFormHandler}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Pincode</label>
                <div className="form-control formControl1">
                  <input
                 placeholder="e.g. 889888"
                    type="text"
                    required
                    name="area_pin_code"
                    value={createFormData.area_pin_code}
                    onChange={createCompanyFormHandler}
                  />
                </div>
              </div>
              <div className="line_1">
                <label>Country</label>
                <div className="form-control formControl1">
                  <select
                  // style={{
                  //   maxWidth: "200px"
                  // }}
                    name="country"
                    value={createFormData.country}
                    onChange={createCompanyFormHandler}
                  >
                    <option value="select">Select</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Thailand">Thailand</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="end_section">
              {editSelectedItem ? (
                <>
                  <button
                    onClick={() => setOpenEditCompany("dashboard")}
                    style={{ backgroundColor: "gray" }}
                  >
                    Back
                  </button>
                  <button onClick={(e) => handleCompanyEdit(e)}>{wait}</button>
                </>
              ) : (
                <button onClick={(e) => handleOnClick(e)}>{wait}</button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateForm;
