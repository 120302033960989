// ##################### Local server 2 ######################################

// export const baseUrl = 'http://192.168.1.6:8001/';
// export const baseUrlImage = 'http://192.168.1.6:8001';
// export const SOCKET_URL_CST = `ws://192.168.1.6:8001/wss/`;

// #################### Local server office #####################################
// export const baseUrl = 'http://192.168.59.36:5003/api/v1/';
// export const baseUrlImage = 'http://192.168.59.36:5003/api/v1';
// export const SOCKET_URL_CST = `ws://192.168.59.36:5003/wss/api/v1/`;

// #################### Local server Mayank sir office #####################################
// export const baseUrl = 'http://192.168.1.3:5008/api/v1/';
// export const baseUrlImage = 'http://192.168.1.3:5008/';
// export const SOCKET_URL_CST = `ws://192.168.1.3:5008/wss/api/v1/`;

// #################### Local server saif desktop #####################################
// export const baseUrl = 'http://192.168.1.5:5004/api/v1/';
// export const baseUrlImage = 'http://192.168.1.5:5004/';
// export const SOCKET_URL_CST = `ws://192.168.1.5:5004/wss/api/v1/`;

// ##################### Live server ######################################

export const baseUrl = 'https://chatsense.in/app/api/v1/';
export const baseUrlImage = 'https://chatsense.in/app/api/v1/';
export const SOCKET_URL_CST = `wss://chatsense.in/app/api/v1/`;
export const SOCKET_URL= `wss://chatsense.in/app/api/v1/send_json_message`;
