import React, { useEffect, useState } from "react";
import "./ViewPayment.css";
import logo from "../../../../Assets/logo.png";
import {
  HiOutlineDownload,
  HiOutlineFilter,
  HiOutlineMail,
  HiOutlinePrinter,
} from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import "jspdf-autotable";
import toast, { Toaster } from "react-hot-toast";
import DotLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
// const baseUrl = process.env.REACT_APP_API_URL;

const ViewPayment = () => {
  var token;
  if (localStorage.getItem("saToken2"))
    token = localStorage.getItem("saToken2");
  var config = { headers: { Authorization: `Bearer ${token}` } };
  const navigate = useNavigate();
  const { sales } = useParams();
  const p_id = sessionStorage.getItem("p_id");
  const [paymentDetails, setPaymentDetails] = useState();
  const [mailLoader, setMailLoader] = useState(<HiOutlineMail />);
  const [downloadLoader, setDownloadLoader] = useState(<HiOutlineDownload />);
  const getPaymentDeatils = () => {
    axios
      .get(`${baseUrl}super-admin/${p_id}/payment-details`, config)
      .then((respo) => {
        if (respo.status === 200) {
          // console.log(respo?.data, "viewwww");
          setPaymentDetails(respo?.data?.data);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  useEffect(() => {
    getPaymentDeatils();
    // setPaymentDetails(p_id);
  }, []);

  const companyDetails = paymentDetails?.companyDeatails?.company_details || {};

  const renderField = (field, fallback = "---") => {
    if (field && typeof field === 'object' && Object.keys(field).length > 0) {
      return fallback;
    }
    return field || fallback;
  };
  // console.log(paymentDetails, "payDetails");
  let date = new Date(paymentDetails?.paymentDetails?.createdAt);
  // console.log(date)
  let dateC = date.toLocaleDateString();
  let timeC = date.toLocaleTimeString();
  let invDate = new Date(paymentDetails?.paymentDetails?.createdAt);
  // Edit for invoice data if invoice date get
  const downloadPDF = () => {
    window.print();
  };
  // Download PDF
  const createPDF = () => {
    setDownloadLoader(<DotLoader color="#f57842" loading={true} size={20} />);
    const data = {
      mail_type: "payment",
      proposal_invoice_Id: paymentDetails?.paymentDetails?._id,
    };
    axios({
      url: `${baseUrl}super-admin/download/html-to-pdf/${data.proposal_invoice_Id}`,
      method: 'POST',
      responseType: 'blob',
      headers: config.headers
    })

      .then((response) => {
        // console.log('response', response?.data);

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `payment-receipt-${paymentDetails?.paymentDetails?._id?.slice(0, 6)}.pdf`; // Specify the desired filename
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        setDownloadLoader(<HiOutlineDownload />);
        toast.success("Payment receipt downloaded successfully! Customize file name accordingly.");
      })
      .catch((error) => {
        setDownloadLoader(<HiOutlineDownload />);
        toast.error("Downloading failed, Please try again.");
        console.error("Error downloading PDF:", error);
      });
  };


  const sendEmail = async () => {
    setMailLoader(<DotLoader color="#f57842" loading={true} size={20} />);
    // console.log(paymentDetails);
    const formData = {
      subject: paymentDetails?.plan_catogery + " payment receipt",
      discription: "Please find your payment receipt in attachment",
      tomail: paymentDetails?.company_id?.email,
      proposal_invoice_Id: paymentDetails?.paymentDetails?._id,
      leadID: paymentDetails?.id,
      cc: ["shubhamdubey@dialsense.in"],
      mail_type: "payment",
    }; 
    // console.log('pay id', paymentDetails);
    // console.log("mail data./././", formData);
    axios
      .post(
        `${baseUrl}super-admin/send/mail-pdf/${formData.proposal_invoice_Id}`,
        formData,
        config
      )
      .then(() => {
        toast?.success("Email sent successfully!");
        setMailLoader(<HiOutlineMail />);
      })
      .catch((error) => {
        setMailLoader(<HiOutlineMail />);
        toast?.error("Email sending failed, Please try again.");
        // console.error("Error sending email", error);
      });
  };

  return (
    <div className="view_receipt_container">
      <Toaster position="top-center" reverseOrder={false} />
      <div
        style={{ paddingLeft: "10%", paddingRight: "10%" }}
        className="view_heading"
      >
        <div className="heading_left">
          <div className="form_title">Payment Receipt</div>
        </div>
        <div className="heading_right">
          <div
            className="back_btn"
            onClick={() => {
              if (sales === "company") {
                navigate("/customer_profile");
              } else if (sales === "sales") {
                navigate("/sales_payment");
              }
            }}
          >
            <span>Back</span>
          </div>
        </div>
      </div>
      <div className="receipt_main_container">
        <div className="receipt_section">
          <div className="receipt_handling">
            <div className="receipt_flex">
              <div className="rec_left">Payment</div>
              <div className="rec_right">
                <span onClick={() => createPDF()}>{downloadLoader}</span>
                <span onClick={() => sendEmail()}>{mailLoader}</span>
                <span onClick={() => downloadPDF()}>
                  <HiOutlinePrinter />
                </span>
              </div>
            </div>
          </div>
          <div
            className="downloadinvoice"
            id="printable"
            style={{ "@page": { size: "A4", margin: "0" } }}
          >
            <div className="recipt_payment">
              <div className="container">
                <div className="main_payment">
                  <div className="first_side">
                    <div className="left_side">
                      <div className="cmpny_name">
                        <h5>From</h5>
                      </div>
                      <div className="logo_chatsense">
                        <img src={logo} alt="" />
                      </div>
                      <div className="cmpny_name">
                        <h5
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Brimming Grace Capital Pvt Ltd
                        </h5>
                      </div>
                      <div className="cmpny_details">
                        <p
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          108, Udyog Vihar
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Pahse-1, Sector-20, Gurugram, Haryana
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <span>GST No. : </span>
                          <span>07AAICB6042L1ZI</span>
                        </p>
                      </div>
                    </div>
                    <div className="right_side">
                      <div className="cmpny_name">
                        <h5>To</h5>
                      </div>
                      <div className="cmpny_name">
                        <h5>{paymentDetails?.companyDeatails?.company_name || "----"}</h5>
                      </div>
                      <div className="company_details">
                        <p>
                          {renderField(companyDetails.company_address)}
                        </p>
                        <p>
                          {`${renderField(companyDetails.city)}, 
          ${renderField(companyDetails.state)}, 
          ${renderField(companyDetails.country)} -
          ${renderField(companyDetails.area_pin_code)}`}
                        </p>
                        <p>
                          <span>GST No.: </span>
                          <span>{paymentDetails?.companyDeatails?.gst_number || "---"}</span>
                        </p>
                        {/* Add more fields as needed */}
                      </div>
                      {/* <div className="company_details">
                        <p>
                          {paymentDetails?.companyDeatails?.company_details
                                ?.company_address?.default ? "---" : paymentDetails?.companyDeatails?.company_details
                                ?.company_address }
                        </p>
                        <p>{`${paymentDetails?.companyDeatails?.company_details?.city?.default ? "---" : paymentDetails?.companyDeatails?.company_details?.city}, 
                            ${paymentDetails?.companyDeatails?.company_details?.state?.default ? "---" : paymentDetails?.companyDeatails?.company_details?.state}, 
                            ${paymentDetails?.companyDeatails?.company_details?.country?.default ? "---" : paymentDetails?.companyDeatails?.company_details?.country} - 
                            ${paymentDetails?.companyDeatails?.company_details?.area_pin_code?.default ? "---" : paymentDetails?.companyDeatails?.company_details?.area_pin_code}`}</p>
                        <p>
                          <span>GST No. : </span>
                          <span>
                            {paymentDetails?.companyDeatails?.gst_number
                              ? paymentDetails?.companyDeatails?.gst_number
                              : "---"}
                          </span>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main_container">
              <div className="second_contain">
                <div className="payment_recipt">
                  <h1>PAYMENT RECEIPT</h1>
                </div>
                <div className="payment_mode">
                  <div className="pay_date">
                    <div className="pay_detail">
                      <div className="payDate">
                        <span>Payment Date:</span>
                        <span>{`${dateC}, ${timeC}`}</span>
                      </div>
                      <div className="payMode">
                        <span>Payment Mode:</span>
                        <span>
                          {paymentDetails?.paymentDetails?.payment_method
                            ? paymentDetails?.paymentDetails?.payment_method
                            : "Online"}
                        </span>
                      </div>
                      <div className="payMode">
                        <span>Payment ID:</span>
                        <span>
                          {paymentDetails?.paymentDetails?.order_id
                            ? paymentDetails?.paymentDetails?.order_id
                            : "-----"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="pay_date">
                    <div className="pay_detail">
                      <div className="payDate">
                        <span>Transaction Details:</span>
                        {/* <span>{`${dateC}, ${timeC}`}</span> */}
                      </div>
                      <div className="payMode">
                        <span>Transaction ID:</span>
                        <span
                          style={{
                            overflowWrap: "break-word",
                            maxWidth: "10rem",
                          }}
                        >
                          {paymentDetails?.paymentDetails?.transaction_id || "---"}
                        </span>
                      </div>
                      <div className="payMode">
                        <span>Transaction Status:</span>
                        <span
                          style={{
                            color: `${paymentDetails?.paymentDetails
                              ?.transaction_status === "Cancel"
                              ? "red"
                              : "green"
                              }`,
                          }}
                        >
                          {paymentDetails?.paymentDetails?.transaction_status
                            ? paymentDetails?.paymentDetails?.transaction_status
                            : "---"}
                        </span>
                      </div>
                      <div className="payMode">
                        <span>Amount (including all taxes):</span>
                        <span>
                          INR {paymentDetails?.paymentDetails?.payable_amount || "---"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="payFor">
                  <h2>Payment For</h2>
                  <table id="table" className="table">
                    <tr>
                      <th style={{ color: "#393939" }}>Payment For</th>
                      <th style={{ color: "#393939" }}>Payment Date</th>
                      <th style={{ color: "#393939" }}>Paid Amount</th>
                      <th style={{ color: "#393939" }}>Due Amount</th>
                    </tr>
                    <tr>
                      <td style={{ color: "#393939" }}>
                        {paymentDetails?.paymentDetails?.plan
                          ? paymentDetails?.paymentDetails?.plan
                          : paymentDetails?.paymentDetails?.transcation_for}
                      </td>
                      <td style={{ color: "#393939" }}>
                        {invDate?.toLocaleDateString()}
                      </td>
                      <td style={{ color: "#393939" }}>
                        INR {paymentDetails?.paymentDetails?.payable_amount}
                      </td>
                      <td style={{ color: "#393939" }}>
                        INR{" "}
                        {(
                          +paymentDetails?.paymentDetails?.payable_amount -
                          +paymentDetails?.paymentDetails?.payable_amount
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};






export default ViewPayment;




